import { Box, Container, Grid, Paper, Stack } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchAPI } from '../../../api/request';
import Alert from '../../../components/common/Alert.component';
import Back from '../../../components/common/Back.component';
import Loading from '../../../components/common/Loading.component';
import AddView from '../../../views/Add.view';
import dayjs from 'dayjs';
import { API as departments } from '../../../module/departments/api';
import _ from 'lodash';
import NewYachtForm from '../../../components/yacht/NewYachtForm.component';
import { API as yachts } from '../../../module/yachts/api';
import AgentNAForm from '../../../components/agentNA/AgentNAForm.component';

const TechnicalAddPage = ({module: {code, query,create }}) => {
  const { jwt, user } = useSelector((state) => state.login);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [idDepartment, setIdDepartment] = useState(null);
  const [valueTab, setValueTab] = useState(0);
  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const onError = useCallback(() => {
    setSuccess(false);
    setLoading(false);
    setError(true);
    // eslint-disable-next-line
  }, []);

  const onSuccess = useCallback(
    (values) => {
      const dataNewYacht = {
        yacht_name: values?.yacht_name,
        registry_type: values?.registry_type?.id,
        port_of_registry: values?.port_of_registry,
        loa: values?.loa,
        beam: values?.beam,
        draft: values?.draft,
        gross_tonn: values?.gross_tonn,
        yacht_type: values?.yacht_type?.id,
        date_submit_signature: dayjs().format('YYYY-MM-DD'),
        registration_status: 'PENDING',
      };

      const dataTechnicalWork = {
        id_yacht: values?.id_yacht?.id,
        id_operation_list: values?.id_operation_list,
      };

      const data = values?.id_yacht
        ? dataTechnicalWork
        : { ...dataNewYacht, ...dataTechnicalWork };

      setLoading(false);
      fetchAPI(query.create.settings2, {}, '', { data: data }, jwt)
        .then(() => {
          setSuccess(true);
          setError(false);
        })
        .catch(onError);
    },
    [setLoading, setSuccess, jwt, onError, query]
  );

  const onSubmit = useCallback(
    (values) => {
      const data = {
        ...values,
        id_department: idDepartment,
        id_user_last_updated: user?.id_users_nautica?.id || null,

      };
      setError(false);
      setSuccess(false);
      setLoading(true);
      fetchAPI(query.create.settings, {}, '', data, jwt)
        .then((resp) =>
          onSuccess({
            id_operation_list: resp?.data?.id,
            ...values,
          })
        )
        .catch(onError);
    },
    [jwt, onError, onSuccess, idDepartment, user, query]
  );

  useEffect(() => {
    fetchAPI(departments.select, { code: code }, '', null, jwt)
      .then((response) => {
        setIdDepartment(_.head(response)?.id);
      })
      .catch(onError);
    // eslint-disable-next-line
  }, []);

  return (
    <Container disableGutters={true} maxWidth={false} sx={{ height: '100%' }}>
      <Stack
        spacing={2}
        sx={{ padding: 2 }}
        justifyContent='center'
        alignItems='center'
      >
        <Back title={'technical'} />
        <Container>
          {loading && <Loading />}
          {success && <Alert severity='success'>{'Success: Add'}</Alert>}
          {error && <Alert severity='error'>{'An error has occurred'}</Alert>}

          <Paper variant='outlined'>
            <Box sx={{ padding: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <AddView
                    conf={
                      valueTab !== 0
                        ? create.conf.newYacht
                        : create.conf.id_yacht
                    }
                    disabled={loading || success}
                    onAddSubmit={onSubmit}
                    onAddError={onError}
                    onAddSuccess={onSuccess}
                    actions={
                      <NewYachtForm
                        valueTab={valueTab}
                        handleChange={handleChangeTab}
                        api={yachts.selectNoDRAFT}
                      >
                        {valueTab === 0 && <AgentNAForm />}
                      </NewYachtForm>
                    }
                  />
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Container>
      </Stack>
    </Container>
  );
};

export default TechnicalAddPage;
