import React from 'react';
import { API as yachts } from '../../module/yachts/api';
import AsyncAutocomplete from '../form/components/AsyncAutocomplete.component';
import { Grid } from '@mui/material';
import Divider from '../form/components/Divider.component';

const YachtForm = ({
  api = yachts.select,
  required = true,
  disabled = false,
}) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Divider label='Yacht' />
      </Grid>
      <Grid item xs={12}>
        <AsyncAutocomplete
          id='id_yacht'
          label='Yacht'
          name='id_yacht'
          required={required}
          api={api}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
};

export default YachtForm;
