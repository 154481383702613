import React, { useCallback, useEffect, useState } from 'react';
import MuiCheckbox from '@mui/material/Checkbox';
import { useSelector } from 'react-redux';
import { fetchAPI } from '../../../api/request';
import MuiFormControl from '@mui/material/FormControl';
import MuiFormLabel from '@mui/material/FormLabel';
import MuiFormGroup from '@mui/material/FormGroup';
import MuiFormControlLabel from '@mui/material/FormControlLabel';
import Error from './Error.component';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
//import { QuantityType } from '../../../module/provisions/conf/Data.conf';
import { useController, useFormContext } from 'react-hook-form';
import Loading from '../../common/Loading.component';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import PropTypes from 'prop-types';

const AsyncListItems = ({ name, required, api, label }) => {
  const { jwt } = useSelector((state) => state.login);
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const {
    field: { ref, onChange, value, ...inputProps },
  } = useController({
    name: name,
    control: control,
    rules: { required },
  });

  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const onSuccess = useCallback((values) => {
    setLoading(false);
    setOptions(
      values?.reduce((acc, curr) => {
        if (!acc[curr.attributes.category]) {
          acc[curr.attributes.category] = [];
        }
        curr.label = `${curr.attributes.name_ITA} (${curr.attributes.name_ENG})`;
        acc[curr.attributes.category].push(curr);
        return acc;
      }, {})
    );
  }, []);

  const onChangeCheckbox = useCallback(
    (valueCheckbox) => {
      const currentIndex = value
        ? value.findIndex((item) => item.id === valueCheckbox.id)
        : -1;
      const newChecked = value ? [...value] : [];
      if (currentIndex === -1) {
        newChecked.push({ ...valueCheckbox, value: '1', type: 'Kg' });
      } else {
        newChecked.splice(currentIndex, 1);
      }
      onChange(newChecked);
    },
    // eslint-disable-next-line
    [value]
  );

  const onChangeInput = useCallback(
    (input) => {
      const currentIndex = value
        ? value.findIndex((item) => item.id === input.id)
        : -1;
      const newChecked = value ? [...value] : [];
      if (currentIndex === -1) {
        newChecked.push({ ...input, type: 'Kg' });
      } else {
        if (input.value !== '') {
          newChecked[currentIndex] = {
            ...value[currentIndex],
            value: input.value,
          };
        } else {
          newChecked.splice(currentIndex, 1);
        }
      }
      onChange(newChecked);
    },
    // eslint-disable-next-line
    [value]
  );
  // const onChangeType = useCallback(
  //   (input) => {
  //     const currentIndex = value
  //       ? value.findIndex((item) => item.id === input.id)
  //       : -1;
  //     const newChecked = value ? [...value] : [];
  //     if (currentIndex !== -1) {
  //       newChecked[currentIndex] = { ...value[currentIndex], type: input.type };
  //     }

  //     onChange(newChecked);
  //   },
  //   // eslint-disable-next-line
  //   [value]
  // );

  useEffect(() => {
    setLoading(true);
    fetchAPI(api, {}, {}, {}, jwt)
      .then((response) => {
        onSuccess(response?.data);
      })
      .catch(() => {
        setLoading(false);
        setOptions([]);
      });
  }, [jwt, api, onSuccess]);

  return (
    <Box display='flex' flexDirection={'column'}>
      {loading && <Loading />}
      <MuiFormControl
        required={required}
        error={!!errors[name]?.message}
        component='fieldset'
        variant='standard'
      >
        <MuiFormLabel component='legend'>{label}</MuiFormLabel>
        <MuiFormGroup ref={ref} {...inputProps}>
          {Object.keys(options).map((key) => {
            return (
              <Accordion
                key={key}
                expanded={expanded === key}
                onChange={handleChange(key)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel1bh-content'
                  id='panel1bh-header'
                >
                  <Typography>{key}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {options[key]?.map((item) => {
                    const index = value
                      ? value.findIndex((item2) => item2.id === item.id)
                      : -1;

                    return (
                      <Grid
                        container
                        alignItems='center'
                        spacing={2}
                        key={item.id}
                      >
                        <Grid item xs={8}>
                          <MuiFormControlLabel
                            key={item.id}
                            name={name}
                            control={
                              <MuiCheckbox
                                onChange={(e) => {
                                  onChangeCheckbox(item);
                                }}
                                checked={index !== -1}
                              />
                            }
                            label={item.label}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            type='number'
                            size='small'
                            placeholder='Quantity'
                            name='input'
                            value={index !== -1 ? value[index].value : ''}
                            onChange={(event) => {
                              onChangeInput({
                                ...item,
                                value: event.target.value,
                              });
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          {/* <MuiAutocomplete
                          disableClearable
                          options={QuantityType}
                          size='small'
                          defaultValue={QuantityType[0]}
                          // value={value&&value[index]}
                          disabled={index === -1}
                          onChange={(_, value) =>
                            onChangeType({ ...item, type: value.id })
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={label}
                              fullWidth
                              margin='dense'
                            />
                          )}
                        /> */}
                        </Grid>
                      </Grid>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            );
          })}
        </MuiFormGroup>
      </MuiFormControl>
      <Error error={errors[name]?.message} />
    </Box>
  );
};

AsyncListItems.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  api: PropTypes.object,
};

export default AsyncListItems;
