import * as yup from 'yup';
import { API as selectCourier } from '../../api';
import { DutyPaidOptions } from '../Data.conf';

export const ShippingDutyPaidIncomingConf = {
  schema: {
    courier_price: yup.string().nullable(),
    tracking_number: yup.string().nullable(),
    sender: yup.string().nullable(),
    storage: yup.string().nullable(),
    invoice_value: yup.string().nullable(),
    duty_paid: yup.object().nullable(),
    details: yup.string().nullable(),
    handling: yup.string().nullable(),
    is_parcel_for_you: yup.boolean().nullable(),
    id_courier: yup.object().when('tracking_number', {
      is: (tracking_number) =>
        tracking_number === null || tracking_number === '',
      then: yup.object().nullable(),
      otherwise: yup
        .object()
        .nullable()
        .required('Courier is required when Tracking Number is provided'),
    }),
  },
  defaultValues: {
    tracking_number: '',
    sender: '',
    details: '',
    invoice_value: '',
    duty_paid: '',
    is_parcel_for_you: false,
    id_courier: null,
    storage: '',
  },
  fields: [
    {
      id: 'id_courier',
      name: 'id_courier',
      type: 'async_autocomplete',
      label: 'Courier',
      api: selectCourier.couriers,
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'tracking_number',
      name: 'tracking_number',
      type: 'text',
      label: 'Tracking Number',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'sender',
      name: 'sender',
      type: 'text',
      label: 'Sender',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'storage',
      name: 'storage',
      type: 'text',
      label: 'Storage',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'invoice_value',
      name: 'invoice_value',
      type: 'text',
      label: 'Invoice Value',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'duty_paid',
      name: 'duty_paid',
      type: 'autocomplete',
      label: 'Duty Paid',
      options: DutyPaidOptions,
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'details',
      name: 'details',
      type: 'text',
      label: 'Details',
      required: false,
      xs: 12,
    },
    {
      id: 'is_parcel_for_you',
      name: 'is_parcel_for_you',
      label: 'Parcel For You',
      type: 'switch',
      required: false,
      xs: 12,
    },
  ],
};
