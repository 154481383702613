import { Status } from './Data.conf';
import _ from 'lodash';

export const CalendarConf = {
  render: {
    eventPropGetter: (item) => {
      const color = _.find(
        Status,
        (i) =>
          i.label.toUpperCase() === _.get(item, 'booking_status').toUpperCase()
      );
      return {
        style: {
          color: color?.color ? '#fff' : color?.textHex,
          backgroundColor: color.hex,
          fontWeight: 500,
        },
      };
    },
  },
  initialState: {
    sort: {
      by: 'id_yacht.yacht_name',
      direction: 'asc',
    },
  },
};
