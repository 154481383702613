// CONF
import { CreateConf } from './conf/Create.conf';
import { UpdateConf } from './conf/Update.conf';
import { ListConf } from './conf/List.conf';
import { FilterConf } from './conf/Filter.conf';
import { CalendarConf } from './conf/Calendar.conf';
import { SearchConf } from './conf/Search.conf';
import { ViewsConf } from './conf/Views.conf';
import { DetailConf } from './conf/Detail.conf';

// REDUX
import { reducer, actions, detailSelector, editSelector } from './slice';

// SELECTOR
import { tag, querySelector, listSelector, calendarSelector } from './slice';

// SAGA
import { saga } from './saga';

// API
import { API } from './api';

export { reducer, saga, tag };

const booking = {
  tag,
  query: {
    all: {
      selector: querySelector,
      action: actions.fetchAllAction,
      settings: API.bookings,
      populate: '*',
    },
    detail: {
      action: actions.fetchDetailAction,
      settings: API.booking,
      populate: '*',
    },
    edit: {
      settings: API.update,
    },
    create: {
      settings: API.create,
    },
    delete: {
      settings: API.delete,
    },
  },
  views: {
    conf: ViewsConf,
    actions: {
      change: actions.valueChange,
    },
  },
  search: {
    conf: SearchConf,
    actions: {
      search: actions.searchChange,
    },
  },
  filter: {
    conf: FilterConf,
    actions: {
      filter: actions.filterChange,
    },
  },
  list: {
    conf: ListConf,
    actions: {
      pagination: actions.paginationChange,
      sort: actions.sortChange,
    },
    selector: {
      data: listSelector,
    },
    add: {
      to: 'add',
    },
  },
  calendar: {
    conf: CalendarConf,
    selector: {
      data: calendarSelector,
    },
    add: {
      to: 'add',
    },
  },
  detail: {
    conf: DetailConf,
    selector: {
      data: detailSelector,
    },
    edit: {
      to: 'edit',
    },
  },
  edit: {
    conf: UpdateConf,
    selector: {
      data: editSelector,
    },
  },
  create: {
    conf: CreateConf,
  },
  remove: {
    selector: {
      data: detailSelector,
    },
    navigate: -1,
  },
};

export default booking;
