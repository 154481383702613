export const Types = [
  { label: 'M/Y', id: 'M/Y' },
  { label: 'S/Y', id: 'S/Y' },
];

export const RegistryTypes = [
  { label: 'COMMERCIAL', id: 'COMMERCIAL' },
  { label: 'PRIVATE', id: 'PRIVATE' },
  { label: 'YET', id: 'YET' },
];

export const InvoicingEntity = [
  { id: 'COMPANY', label: 'COMPANY' },
  { id: 'NATURAL PERSON', label: 'NATURAL PERSON' },
];

export const PortOfRegistry = [
  { id: 'MARINA DI OLBIA', label: 'MARINA DI OLBIA' },
  { id: 'MOLO BRIN / ISOLA BIANCA', label: 'MOLO BRIN / ISOLA BIANCA' },
  { id: 'CAGLIARI', label: 'CAGLIARI' },
];

export const FeesType = [
  { id: 'DIRECTLY', label: 'DIRECTLY', value: "I'll pay the ports directly." },
  {
    id: 'NOT DIRECTLY',
    label: 'NOT DIRECTLY',
    value: 'Nautica Assistance will take care of the berth payments.',
  },
];

export const RegistrationStatus = [
  { id: 'DRAFT', label: 'DRAFT', color: 'warning', hex: '#ed6c02' },
  { id: 'COMPLETED', label: 'COMPLETED', color: 'success', hex: '#2e7d32' },
  { id: 'DELETED', label: 'DELETED', color: 'error', hex: '#d32f2f' },
  // { id: 'LITE', label: 'LITE', hex: '#abcdef' },
  { id: 'PENDING', label: 'PENDING', hex: '#ffee58' },
  { id: 'ONLINE', label: 'ONLINE', color: 'success', hex: '#2e7d32' },
  { id: 'MANUAL', label: 'MANUAL' },
];

export const DataConf = {
  initialState: {
    loading: false,
    error: null,
    data: [],
    meta: null,
  },
};
