import React, { Fragment, useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Loading from '../../../components/common/Loading.component';
import Back from '../../../components/common/Back.component';
import { fetchAPI } from '../../../api/request';
import Alert from '../../../components/common/Alert.component';
import { API as listAPI } from '../../../module/list/api';
import _ from 'lodash';
import { API as listItemsDepot } from '../../../module/listItemsDepot/api';
import itemsDepot from '../../../module/listItemsDepot';
import ProductsListComponent from '../../../components/depot/products/ProductsList.component';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import ModalLogbook from '../../../components/logbook/modal/ModalLogbook.component';
import { SimpleCreateDepotConf } from '../../../module/logbook/conf/SimpleCreateDepot.conf';
import depot from '../../../module/depot';
import ItemAdd from '../../../components/listItem/create';
import PaperTypography from '../../../components/common/PaperTypography.component';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import DownloadIcon from '@mui/icons-material/Download';
import EditList from '../../../components/list/edit/EditList.component';
import OrderInfo from '../../../components/order/OrderInfo.component';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import InfoIcon from '@mui/icons-material/Info';
import ErrorIcon from '@mui/icons-material/Error';

const STATUS_LOGBOOK = 'DELIVERED';
const AvatarStyle = {
  avatar: {
    sx: {
      color: '#005488',
      opacity: 0.9,
    },
  },
};

const ItemsDepotListPage = ({ module: { query, detail, code } }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, id_operation } = useParams();
  const { jwt } = useSelector((state) => state.login);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  const [list, setList] = useState(null);
  const { state } = useLocation();
  const [expanded, setExpanded] = useState({ products: true, logbook: true });
  const { loading: loadingAPI, data } = useSelector(detail.selector.data);
  const [checked, setChecked] = useState(null);
  const [idLogbook, setIdLogbook] = useState(null);
  const _query = useSelector(query.all.selector);
  const [download, setDownload] = useState(false);

  useEffect(() => {
    if (data?.query2[0]?.attributes?.id_logbook?.data?.id) {
      setIdLogbook(data?.query2[0]?.attributes?.id_logbook?.data);
    }
    if (data?.query) {
      // const allItems = _.filter(data?.query, (item) => {
      //   const availableDepot = item?.attributes?.available;
      //   return (
      //     availableDepot === AVAILABLE || availableDepot === AVAILABLE_IN_STORE
      //   );
      // });
      const allItems = data?.query;
      setChecked(_.map(allItems, 'id'));
    }
  }, [data, success]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded({ ...expanded, [panel]: isExpanded });
  };

  const onError = useCallback((error) => {
    setSuccess(false);
    setLoading(false);
    setError(error);
    // eslint-disable-next-line
  }, []);

  const handleDownload = useCallback((resp) => {
    setError(false);
    setLoading(false);
    const csvBlob = new Blob([resp], { type: 'text/csv;charset=utf-8' });
    // Salva e scarica il file CSV
    const nameCSV = `products_list_${dayjs().format('DD-MM-YYYY_HH:mm')}.csv`;
    saveAs(csvBlob, nameCSV);
  }, []);

  const onClick = useCallback(() => {
    setDownload(!download);
    if (idLogbook?.id) {
      setLoading(true);
      setSuccess(false);
      fetchAPI(
        query.CSV.settings,
        {},
        { ..._query, populate: query.all.populate },
        { id_logbook: idLogbook.id },
        jwt
      )
        .then((resp) => handleDownload(resp))
        .catch(onError);
    }
  }, [idLogbook, jwt, _query, handleDownload, query, onError, download]);

  useEffect(() => {
    fetchAPI(listAPI.item, { id }, '', null, jwt)
      .then((resp) => {
        setList(resp?.data?.attributes);
      })
      .catch((error) => onError(error));
    // eslint-disable-next-line
  }, [id, success, jwt]);

  useEffect(() => {
    if (
      (error &&
        (error?.response?.status === 404 || error?.response?.status === 500)) ||
      state?.id_operation !== id_operation
    )
      navigate('/404');
  }, [error, navigate, state, id_operation]);

  useEffect(() => {
    dispatch(
      query.detail.action({
        settings: query.detail.settings, //ITEMS LIST
        token: jwt,
        params: { id },
        query: { populate: query.detail.populate },
        query2: { populate: '*' },
        settings2: listItemsDepot.listItemsWithLogbook, //ITEMS IN THE LOGBOOK
      })
    );
    return () => {
      dispatch(
        query.detail.action({
          settings: null,
          token: null,
          params: null,
          query: null,
        })
      );
    };
    // eslint-disable-next-line
  }, [id, success, download]);

  return (
    <Fragment>
      <Container disableGutters={true} maxWidth={false} sx={{ height: '100%' }}>
        {error && <Alert severity='error'>{'An error has occurred'}</Alert>}
        {success && <Alert severity='success'>{'Success'}</Alert>}
        <Back title='Back' page='Items List' />

        {loading || loadingAPI ? (
          <Loading />
        ) : (
          <Stack>
            {list && (
              <Grid container pt={3} spacing={2.5} alignItems={'center'}>
                <Grid item xs={12} md={list?.warning_notes ? 8 : 12}>
                  <OrderInfo
                    isLoading={loading || loadingAPI}
                    data={[
                      {
                        id: 'yacht_name',
                        value:
                          list?.id_operation_list?.data?.attributes?.id_yacht
                            ?.data?.attributes?.yacht_name,
                        icon: (
                          <DirectionsBoatIcon
                            style={{ width: 16, height: 16 }}
                          />
                        ),
                        sxTypography: {
                          fontSize: 17,
                        },
                        ...AvatarStyle,
                      },
                      {
                        id: 'date',
                        value:
                          list?.id_operation_list?.data?.attributes
                            ?.requested_date,
                        icon: (
                          <CalendarMonthIcon
                            style={{ width: 16, height: 16 }}
                          />
                        ),
                        ...AvatarStyle,
                      },
                      {
                        id: 'hour',
                        value:
                          list?.id_operation_list?.data?.attributes
                            ?.requested_hour ||
                          list?.id_operation_list?.data?.attributes?.hour_type
                            ? `${
                                list?.id_operation_list?.data?.attributes
                                  ?.requested_hour
                                  ? `   ${dayjs(
                                      list?.id_operation_list?.data?.attributes
                                        ?.requested_hour,
                                      'HH:mm:ss'
                                    ).format('HH:mm')}`
                                  : ''
                              } ${
                                list?.id_operation_list?.data?.attributes
                                  ?.hour_type
                                  ? list?.id_operation_list?.data?.attributes
                                      ?.hour_type
                                  : ''
                              }`
                            : null,
                        icon: (
                          <QueryBuilderIcon style={{ width: 16, height: 16 }} />
                        ),
                        ...AvatarStyle,
                      },
                      {
                        id: 'location',
                        value:
                          list?.id_operation_list?.data?.attributes?.id_marina
                            ?.data?.attributes?.marina,
                        icon: (
                          <LocationOnIcon style={{ width: 16, height: 16 }} />
                        ),
                      },
                      {
                        id: 'status_operation_booking',
                        value:
                          list?.id_operation_list?.data?.attributes
                            ?.status_operation_booking,
                        icon: <InfoIcon style={{ width: 16, height: 16 }} />,
                        ...AvatarStyle,
                      },
                    ]}
                    height={100}
                    backgroundColor={'#005488'}
                  />
                </Grid>
                {list?.warning_notes && (
                  <Grid item xs={12} md={4}>
                    <OrderInfo
                      isLoading={loading || loadingAPI}
                      data={[
                        {
                          id: 'warning_notes',
                          value: list?.warning_notes,
                          sxTypography: {
                            fontSize: 15,
                          },
                        },
                      ]}
                      icon={ErrorIcon}
                      height={100}
                      backgroundColor={'rgb(216, 67, 21)'}
                    />
                  </Grid>
                )}
              </Grid>
            )}
            <Stack
              direction={{ xs: 'column', lg: 'row' }}
              height={{ xs: 'auto', lg: 56 }}
              mt={4}
              mb={4}
              alignItems={{ lg: 'center' }}
            >
              <EditList
                item={list}
                department={code}
                setSuccessEdit={setSuccess}
                successEdit={success}
              />
              {idLogbook?.id ? (
                <Stack direction={'row'} spacing={2} ml={{ xs: 0, md: 2 }}>
                  <Tooltip title='Logbook'>
                    <Link to={`/logbook/${idLogbook?.id}`}>
                      <IconButton
                        sx={{
                          backgroundColor: '#3f51b5',
                          color: '#fff',
                          borderRadius: 1,
                          '& :hover': {
                            color: '#3f51b5',
                          },
                        }}
                      >
                        <LibraryBooksIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                  <Button
                    variant='contained'
                    startIcon={<DownloadIcon />}
                    sx={{ minWidth: 150, height: 40 }}
                    onClick={onClick}
                  >
                    <Typography>Items List</Typography>
                  </Button>
                </Stack>
              ) : (
                <Box width={170} ml={2}>
                  <ModalLogbook
                    disabled={_.isEmpty(data?.query)}
                    conf={SimpleCreateDepotConf}
                    setSuccess={setSuccess}
                    productsList={
                      data &&
                      _.concat(
                        _.map(data?.query, 'id'),
                        _.map(data?.query2, id)
                      )
                    }
                    setChecked={setChecked}
                    operationType='DELIVERY'
                    module={depot}
                  />
                </Box>
              )}
            </Stack>

            {idLogbook?.attributes?.status_logbook !== STATUS_LOGBOOK && (
              <Box sx={{ mb: 1 }}>
                <ItemAdd
                  setSuccessAdd={setSuccess}
                  module={{
                    ...itemsDepot,
                    create: {
                      conf: idLogbook?.id
                        ? itemsDepot.create.conf.hasLogbook
                        : itemsDepot.create.conf.default,
                    },
                  }}
                  idLogbook={idLogbook?.id}
                  productsList={data && _.concat(data?.query, data?.query2)}
                />
              </Box>
            )}

            {_.isEmpty(data?.query) && !idLogbook?.id && (
              <PaperTypography title={'Add Items to list'} />
            )}
            {idLogbook?.id ? (
              data?.query2.length !== 1 ? (
                <ProductsListComponent
                  data={data?.query2}
                  checked={checked}
                  expanded={expanded}
                  setChecked={setChecked}
                  setSuccess={setSuccess}
                  handleChange={handleChange}
                  title='Sent to Logbook'
                />
              ) : (
                <ProductsListComponent
                  data={[{ ...data?.query2[0], disabledDelete: true }]}
                  checked={checked}
                  expanded={expanded}
                  setChecked={setChecked}
                  setSuccess={setSuccess}
                  handleChange={handleChange}
                  title='Sent to Logbook'
                />
              )
            ) : (
              !_.isEmpty(data?.query) && (
                <ProductsListComponent
                  data={data?.query}
                  checked={checked}
                  expanded={expanded}
                  setChecked={setChecked}
                  setSuccess={setSuccess}
                  handleChange={handleChange}
                />
              )
            )}
          </Stack>
        )}
      </Container>
    </Fragment>
  );
};

export default ItemsDepotListPage;
