import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { saveAs } from 'file-saver';
import { Box, Button, Container, Paper, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAPI } from '../../../api/request';
import Loading from '../../../components/common/Loading.component';
import Alert from '../../../components/common/Alert.component';
import dayjs from 'dayjs';
import ToolbarComponent from '../../../components/toolbar/Toolbar.component';
import ListView from '../../../views/List.view';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';


const ContactsListPage = ({module:{ tag, query, list, search, filter }}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    data: { loading: loadingGET },
    list: { selected },
    filter: { value: valueFilter },
  } = useSelector((state) => state[tag]);
  const { from, to } = useSelector((state) => state.year);

  const { jwt, user } = useSelector((state) => state.login);
  const _query = useSelector(query.all.selector);
  const { role } = useSelector((state) => state.contacts.filter.value);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const userAuth = useSelector((state) => state.login?.user);
  const hasPermissionsRead = useMemo(
    () =>
      userAuth.permissions[`${_.toUpper(tag)}_${_.toUpper(role?.id)}`]?.READ ===
      'true',
    [tag, userAuth, role]
  );
  const hasPermissionsDownload = useMemo(
    () =>
      userAuth.permissions[`${_.toUpper(tag)}_${_.toUpper(role?.id)}`]
        ?.DOWNLOAD_CSV === 'true',
    [tag, userAuth, role]
  );

  const onError = useCallback((error) => {
    setLoading(false);
    setError(error);
    // eslint-disable-next-line
  }, []);

  const onClickDownload = () => {
    setLoading(true);
    fetchAPI(
      query.CSV.settings,
      {},
      { ..._query, populate: query.all.populate },
      {},
      jwt
    )
      .then((resp) => handleDownload(resp))
      .catch(onError);
  };

  const onClick = useCallback(
    (item) => {
      navigate(`${item.id}`, { state: { from: tag } });
    },
    [navigate, tag]
  );
  const handleDownload = (resp) => {
    setError(false);
    setLoading(false);

    const csvBlob = new Blob([resp], { type: 'text/csv;charset=utf-8' });

    const nameCSV = `contacts_${dayjs().format('MM-D-YYYY_HH:mm')}.csv`;
    saveAs(csvBlob, nameCSV);
  };

  const getApiSettings = useCallback(() => {
    return user.role === 'Administrator'
      ? query.all.settings
      : query.allNotBlocked.settings;
  }, [user.role, query]);

  useEffect(() => {
    const params = id ? { id } : {};
    if (hasPermissionsRead) {
      const filtersCreatedAt = {
        'filters[createdAt][$gte]': from,
        'filters[createdAt][$lte]': to,
      };
      dispatch(
        query.all.action({
          settings: getApiSettings(),
          token: jwt,
          params,
          query: {
            ..._query,
            populate: query.all.populate,
            ...(valueFilter?.role?.id === 'Crew' ? filtersCreatedAt : {}),
          },
        })
      );
    }
  }, [_query, dispatch, jwt, query, id, hasPermissionsRead, from, to, valueFilter, getApiSettings]);

  const onSelectItem = useCallback(
    (row) => dispatch(list.actions.selected(row.id)),
    [dispatch, list]
  );

  return (
    <Box
      display='flex'
      justifyContent={'end'}
      flexDirection='column'
      alignItems={'end'}
    >
      {!!hasPermissionsDownload && (
        <Button
          onClick={onClickDownload}
          variant='contained'
          size='small'
          sx={{ maxWidth: 150 }}
        >
          Download CSV
        </Button>
      )}
      {(loading || loadingGET) && <Loading />}
      {error && (
        <Alert severity='error'>
          {error?.response?.data?.error.message
            ? error?.response?.data?.error.message
            : 'An error has occurred'}
        </Alert>
      )}
      <Container disableGutters={true} maxWidth={false} sx={{ height: '100%' }}>
        <Box display={'flex'} justifyContent='space-between' padding={3}>
          <Typography variant='h4' sx={{ fontWeight: 'bold' }}>
            {_.upperFirst(tag)}
          </Typography>
        </Box>
        <Paper variant='outlined'>
          <ToolbarComponent
            tag={tag}
            search={search}
            filter={filter}
            add={list?.add}
            link={list?.link}
            tagPermissions={`${_.toUpper(tag)}_${_.toUpper(role?.id)}`}
          />
          {loading && <Loading />}
          {hasPermissionsRead && (
            <ListView
              list={list}
              onClick={onClick}
              onSelectItem={onSelectItem}
              selected={selected}
            />
          )}
        </Paper>
      </Container>
    </Box>
  );
};

export default ContactsListPage;
