import { createSlice } from '@reduxjs/toolkit';
import { saveItem } from '../../../utility/Storage.utility';

export const yearSlice = (name, initialState) =>
  createSlice({
    name,
    initialState: {
      ...initialState,
    },
    reducers: {
      yearSave: (state, action) => {
        state.loading = false;
        state.error = null;
        state.from = action.payload.from;
        state.to = action.payload.to;
        saveItem('from', action.payload.from);
        saveItem('to', action.payload.to);
      },
      yearError: (state, action) => {
        state.loading = false;
        state.error = action.payload;
      },
      yearLoading: (state) => {
        state.loading = true;
        state.error = null;
      },
    },
  });
