import * as yup from 'yup';
import { StatusDefault } from './Data.conf';
import { API as marina } from '../../marina/api';
import dayjs from 'dayjs';
import { StatusOperationBookingType } from '../../operations/conf/Data.conf';

const Conf = {
  schema: yup.object({
    id_yacht: yup.object().nullable().required(),
    check_in_date: yup.date().required('Check In is required'),
    check_out_date: yup
      .date()
      .nullable()
      .required('Check Out is required')
      .when('check_in_date', (check_in_date, schema) => {
        const date = check_in_date && dayjs(check_in_date).add(1, 'day');
        return date
          ? schema.min(
              date,
              'The check out date cannot be equal to or less than the check in date'
            )
          : schema;
      }),
    cash: yup.string(),
    booking_status: yup.object().nullable().required('Status is required'),
    id_marina: yup.object().nullable().required('Marina is required'),
    note: yup.string().nullable(),
  }),
  defaultValues: {
    id_yacht: null,
    check_in_date: null,
    check_out_date: null,
    cash: false,
    booking_status: null,
    id_marina: null,
    note: '',
  },
  fields: [
    {
      id: 'date_divider',
      type: 'divider',
      label: 'Check In & Check Out',
      xs: 12,
    },
    {
      id: 'check_in_date',
      name: 'check_in_date',
      type: 'date',
      label: 'Check In',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'check_out_date',
      name: 'check_out_date',
      type: 'date',
      label: 'Check Out',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'status_divider',
      type: 'divider',
      label: 'Marina',
      xs: 12,
    },
    {
      id: 'id_marina',
      name: 'id_marina',
      type: 'async_autocomplete',
      label: 'Marina',
      api: marina.select,
      required: true,
      xs: 12,
    },
    {
      id: 'status_divider',
      type: 'divider',
      label: 'Booking Info',
      xs: 12,
    },
    {
      id: 'booking_status',
      name: 'booking_status',
      type: 'autocomplete',
      label: 'Status',
      options: StatusDefault,
      required: true,
      xs: 12,
      disableClearable: true,
    },
    {
      id: 'note',
      name: 'note',
      type: 'text',
      label: 'Note',
      xs: 12,
    },
    {
      id: 'cash',
      name: 'cash',
      type: 'switch',
      label: 'Cash',
      // required: true,
      xs: 12,
      sm: 12,
    },
  ],
};
export const UpdateConf = {
  DEFAULT: Conf,
  OPERATION: {
    schema: Conf?.schema,
    defaultValues: Conf?.defaultValues,
    fields: [
      ...Conf?.fields.slice(0, 6),
      {
        id: 'booking_status',
        name: 'booking_status',
        type: 'autocomplete',
        label: 'Status',
        options: StatusOperationBookingType,
        required: true,
        xs: 12,
        disableClearable: true,
      },
      ...Conf?.fields.slice(7),
    ],
  },
};
