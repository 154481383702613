import {
  Autocomplete,
  Box,
  Container,
  Grid,
  Paper,
  Stack,
  TextField,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchAPI } from '../../../api/request';
import Alert from '../../../components/common/Alert.component';
import Back from '../../../components/common/Back.component';
import Loading from '../../../components/common/Loading.component';
import AddView from '../../../views/Add.view';
import { ConciergeOptions } from '../../../module/concierge/conf/Data.conf';
import { API as departments } from '../../../module/departments/api';
import _ from 'lodash';
import YachtForm from '../../../components/yacht/YachtForm.component';
import AgentNAForm from '../../../components/agentNA/AgentNAForm.component';
import { API as yachts } from '../../../module/yachts/api';
import ServiceForm from '../../../components/service/ServiceForm';

const ConciergeAddPage = ({module:{ code, query,create } }) => {
  const { jwt, user } = useSelector((state) => state.login);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [reservationType, setReservationType] = useState(null);
  const [error, setError] = useState(false);
  const [idDepartment, setIdDepartment] = useState(null);

  const onError = useCallback(() => {
    setSuccess(false);
    setLoading(false);
    setError(true);
    // eslint-disable-next-line
  }, []);

  const onSuccess = useCallback(
    (values) => {
      const data = {
        ...values,
        reservation_type: reservationType.id,
      };
      setLoading(false);
      fetchAPI(
        query.create.settings2,
        {},
        '',
        data,
        jwt,
      )
        .then(() => {
          setSuccess(true);
          setError(false);
        })
        .catch(onError);
    },
    [setLoading, setSuccess, jwt, onError, reservationType, query]
  );

  const onSubmit = useCallback(
    (values) => {
      const data = {
        ...values,
        id_department: idDepartment,
        id_user_last_updated: user?.id_users_nautica?.id || null,

      };
      setError(false);
      setSuccess(false);
      setLoading(true);
      fetchAPI(
        query.create.settings, {}, '', data, jwt
      )
        .then((resp) =>
          onSuccess({
            id_operation_list: resp?.data?.id,
            ...values,
          })
        )
        .catch(onError);
    },
    [setLoading, jwt, onError, onSuccess, idDepartment, user, query]
  );

  useEffect(() => {
    fetchAPI(departments.select, { code: code }, '', null, jwt)
      .then((response) => {
        setIdDepartment(_.head(response)?.id);
      })
      .catch(onError);
    // eslint-disable-next-line
  }, []);

  return (
    <Container disableGutters={true} maxWidth={false} sx={{ height: '100%' }}>
      <Stack
        spacing={2}
        sx={{ padding: 2 }}
        justifyContent='center'
        alignItems='center'
      >
        <Back title={'CONCIERGE'} />
        <Container>
          {loading && <Loading />}
          {success && <Alert severity='success'>{'Success: Add'}</Alert>}
          {error && <Alert severity='error'>{'An error has occurred'}</Alert>}

          <Paper variant='outlined'>
            <Box sx={{ padding: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Autocomplete
                    disablePortal
                    options={ConciergeOptions}
                    onChange={(_, newValue) => {
                      setReservationType(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label='Reservation Type' />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  {reservationType && (
                    <AddView
                      conf={create.conf[reservationType.id]}
                      disabled={loading || success}
                      onAddSubmit={onSubmit}
                      onAddError={onError}
                      onAddSuccess={onSuccess}
                      actions={
                        <Stack spacing={1}>
                          <YachtForm api={yachts.selectNoDRAFT} />
                          <AgentNAForm />
                          <Grid item xs={12}>
                            <ServiceForm />
                          </Grid>
                        </Stack>
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Container>
      </Stack>
    </Container>
  );
};

export default ConciergeAddPage;
