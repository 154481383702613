import React, { useCallback, useEffect } from 'react';
import { Stack } from '@mui/material';
import Loading from '../../components/common/Loading.component';
import LoginView from '../../views/Login.view';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '../../components/common/Alert.component';
import { useNavigate } from 'react-router-dom';
import GuestCard from '../../components/guest/GuestCard.component';
import _ from 'lodash';
import { DepartmentsConf } from '../../core/dashboard/conf/Data.conf';

const LoginTemplate = ({ tag, login, query, children, title, subTitle, }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error, user } = useSelector((state) => state[tag]);
  const userAuth = useSelector((state) => state.login?.user);
  const role = _.toUpper(userAuth?.role);



  const onSubmit = useCallback(
    (values) => {
      dispatch(
        query.login.action({
          settings: query.login.settings,
          query: null,
          body: { ...values },
        })
      );
    },
    [dispatch, query]
  );

  useEffect(() => {
    if (user) {
      if (role === 'ADMINISTRATOR') {
        navigate(login.to, { replace: true });
      } else {
        const userDepartment = _.toUpper(userAuth?.department);
        const department = _.find(
          DepartmentsConf,
          (item) => _.toLower(item?.id) === _.toLower(userDepartment)

        );
        const departmentDeliveries = _.find(
          DepartmentsConf,
          (item) => _.toLower(item?.title) === _.toLower(userDepartment));

        //REDIRECT TO DEPARTMENT
        if (department && !department.disabled && userDepartment !== 'DELIVERIES') {
          navigate(`/department/${_.toLower(userDepartment)}`,
            {
            replace: true,
          });
        }
        else if (departmentDeliveries && !departmentDeliveries.disabled && userDepartment === 'DELIVERIES')
          navigate(`/logbook`, {
            replace: true,
          });
        else {
          navigate('/yachts', {
            replace: true,
          });
        }
      }
    }
  }, [user, login, navigate, role, userAuth]);

  return (
    <GuestCard title={title} subTitle={subTitle}>
      <Stack spacing={2}>
        {loading && <Loading />}
        {error && <Alert severity='error'>{error}</Alert>}
        <LoginView conf={login.conf} onLoginSubmit={onSubmit}>
          {children}
        </LoginView>
      </Stack>
    </GuestCard>
  );
};

export default LoginTemplate;