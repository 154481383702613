// CONF
import { DetailConf } from './conf/Detail.conf';

// SELECTOR
import { tag } from './slice';

// SAGA
import { saga } from './saga';

// REDUX
import { reducer, actions, detailSelector } from './slice';

// API
import { API } from './api';

export { reducer, saga, tag };

const me = {
  tag,
  query: {
    detail: {
      action: actions.fetchDetailAction,
      settings: API.me,
      populate: '*',
    },
  },
  detail: {
    conf: DetailConf,
    selector: {
      data: detailSelector,
    },
  },
};

export default me;
