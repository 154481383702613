import { dataSlice as createDataSLice } from '../../../core/data';
import { sliceList as createListSlice } from '../../../core/list';
import { sliceFilter as createFilterSlice } from '../../../core/filter';
import { sliceSearch as createSearchSlice } from '../../../core/search';
import { detailSlice as createDetailSlice } from '../../../core/detail';
import {
  combineReducers,
  createAction,
  createSelector,
} from '@reduxjs/toolkit';
import { ListConf } from '../conf/List.conf';
import { SearchConf } from '../conf/Search.conf';
import { FilterConf } from '../conf/Filter.conf';
import { DataConf } from '../conf/Data.conf';
import { QueryConf } from '../conf/Query.conf';
import { DetailConf } from '../conf/Detail.conf';

export const tag = 'operations';

// REDUCERS
const dataSlice = createDataSLice(`${tag}/data`, DataConf.initialState);
const listSlice = createListSlice(`${tag}/list`, ListConf.initialState);
const filterSlice = createFilterSlice(`${tag}/filter`, FilterConf.initialState);
const searchSlice = createSearchSlice(`${tag}/search`, SearchConf.initialState);
const detailSlice = createDetailSlice(`${tag}/detail`, DetailConf.initialState);

export const reducer = combineReducers({
  search: searchSlice.reducer,
  data: dataSlice.reducer,
  list: listSlice.reducer,
  filter: filterSlice.reducer,
  detail: detailSlice.reducer,
});

// ACTIONS
export const actions = {
  fetchAllAction: createAction(`${tag}/fetchAll`),
  fetchDetailAction: createAction(`${tag}/fetchOne`),
  ...searchSlice.actions,
  ...dataSlice.actions,
  ...listSlice.actions,
  ...filterSlice.actions,
  ...detailSlice.actions,
};

// QUERY
export const querySelector = createSelector(
  (state) => state[tag].list,
  (state) => state[tag].filter,
  (state) => state[tag].search,
  (list, filter, search) => QueryConf.get(list, filter, search)
);

// LIST
export const listSelector = createSelector(
  (state) => state[tag].data,
  (state) => state[tag].list,
  ({ data, meta }, { pagination, sort }) => ({
    data,
    meta,
    pagination,
    sort,
  })
);

// DETAIL
export const detailSelector = createSelector(
  (state) => state[tag].detail,
  (detail) => detail
);
