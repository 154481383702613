import React, { useEffect, useState } from 'react';
import MUIAutocomplete from '@mui/material/Autocomplete';
import MUITextField from '@mui/material/TextField';
import Error from './Error.component';
import { useController, useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { fetchAPI } from '../../../api/request';

const AsyncAutocompleteMultiple = ({
  name,
  required,
  url,
  api,
  label,
  disabled,
  ...props
}) => {
  const [options, setOptions] = useState([]);
  const { jwt } = useSelector((state) => state.login);
  const [loading, setLoading] = useState(false);

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const {
    field: { ref, onChange, value, ...inputProps },
  } = useController({
    name: name,
    control: control,
    rules: { required },
  });

  useEffect(() => {
    setLoading(true);
    //onChange(null)
    fetchAPI(api, {}, {}, {}, jwt)
      .then((response) => {
        setLoading(false);
        setOptions(response);
      })
      .catch(() => {
        setLoading(false);
        setOptions([]);
      });
  }, [jwt, api]);

  return (
    <Box display='flex' flexDirection={'column'}>
      <MUIAutocomplete
        {...inputProps}
        multiple
        value={value || []}
        options={options}
        getOptionLabel={(option) => option?.name || ''}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        loading={loading}
        renderInput={(params) => (
          <MUITextField
            {...params}
            label={label}
            required={required}
            error={!!errors[name]?.message}
          />
        )}
        onChange={(e, data) => {
          onChange(data);
        }}
        disabled={disabled}
        {...props}
      />
      <Error error={errors[name]?.message} />
    </Box>
  );
};

AsyncAutocompleteMultiple.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
};

export default AsyncAutocompleteMultiple;
