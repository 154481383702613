import * as yup from 'yup';

export const CreateNewYachtConf = {
  schema: {
    yacht_name: yup.string().nullable().required(),
    loa: yup.string().nullable().required(),
    beam: yup.string().nullable().required(),
    draft: yup.string().nullable().required(),
    gross_tonn: yup.string().nullable().required(),
    yacht_type: yup.object().nullable().required(),
  },
  defaultValues: {
    yacht_name: null,
    loa: null,
    beam: null,
    draft: null,
    gross_tonn: null,
    yacht_type: null,
    id_yacht: null,
  },
  fields: [],
};
