import { Grid } from '@mui/material';
import AsyncAutocomplete from '../form/components/AsyncAutocomplete.component';
import Autocomplete from '../form/components/Autocomplete.component';
import TextField from '../form/components/TextField.component';
import { API } from '../../module/provenenceLists/api';
import { first_contact } from '../../module/attendances/conf/Data.conf';
import { API as users_nautica } from '../../module/users_nautica/api';
import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import Divider from '../form/components/Divider.component';

const AttendanceForm = ({ data }) => {
  const { watch, setValue } = useFormContext();
  const watchProvenenceGroup = watch(
    'provenence_group',
    data?.provenence_group
  );
  const watchProvenenceSalesChannel = watch('provenence_sales_channel');
  const watchFirstContact = watch('first_contact');

  useEffect(() => {
    if (watchProvenenceGroup === null) {
      setValue('provenence_sales_channel', null);
    }
    // eslint-disable-next-line
  }, [watchProvenenceGroup]);

  useEffect(() => {
    if (watchProvenenceSalesChannel === null) {
      setValue('provenence', null);
    }
    // eslint-disable-next-line
  }, [watchProvenenceSalesChannel]);

  useEffect(() => {
    if (watchFirstContact === null || watchFirstContact?.id === 'INFO') {
      setValue('users_nauticas', null);
    }
    // eslint-disable-next-line
  }, [watchFirstContact]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12}>
        <Divider label='Provenance' />
      </Grid>
      <Grid item xs={12} sm={12}>
        <AsyncAutocomplete
          id='provenence_group'
          label='Group'
          name='provenence_group'
          required={true}
          api={API.group}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <AsyncAutocomplete
          id='provenence_sales_channel'
          label='Sales channel'
          name='provenence_sales_channel'
          required={false}
          disabled={watchProvenenceGroup === null}
          api={{
            ...API.sales_channel,
            path: `${API.sales_channel.path}filters[provenence_groups][group]=${watchProvenenceGroup?.name}`,
          }}
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <AsyncAutocomplete
          id='provenence'
          label='Provenance'
          name='provenence'
          required={false}
          disabled={watchProvenenceSalesChannel === null}
          api={{
            ...API.provenences,
            path: `${API.provenences.path}filters[provenence_sales_channels][sales_channel]=${watchProvenenceSalesChannel?.name}`,
          }}
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <Autocomplete
          name={'first_contact'}
          required={true}
          id={'first_contact'}
          label={'First contact'}
          options={first_contact}
          defaultValue={first_contact[0]}
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <AsyncAutocomplete
          id='users_nauticas'
          label='User'
          name='users_nauticas'
          required={watchFirstContact?.id === 'USER'}
          disabled={watchFirstContact?.id !== 'USER'}
          api={users_nautica.selectName}
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <TextField name={'note'} id={'note'} label={'Note'} />
      </Grid>
    </Grid>
  );
};

export default AttendanceForm;
