import React from 'react';
import TableRowSort from './TableRowSort.component';
import { TableHead } from '@mui/material';

const TableHeaderSort = ({
  header = [],
  handleSort = () => {},
  order,
  orderBy,
  position = 'sticky',
  backgroundColor,
  colorText,
  sx,
}) => {
  return (
    <TableHead
      sx={{
        position: position,
        top: 0,
        zIndex: 20,
        ...sx,
      }}
    >
      <TableRowSort
        columns={header}
        onSort={handleSort}
        order={order}
        orderBy={orderBy}
        backgroundColor={backgroundColor}
        colorText={colorText}
      />
    </TableHead>
  );
};
export default TableHeaderSort;
