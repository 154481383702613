import React, { useCallback } from 'react';
import SearchBar from '../components/search/Search.component';
import { useSearch } from '../core/search';
import { useSelector } from 'react-redux';

const SearchView = ({ search, tag }) => {
    const {
        conf: { placeholder },
        actions: { search: searchChange },
    } = search;
    const { handleChangeSearch } = useSearch(searchChange);
    const { value } = useSelector((state) => state[tag].search);

    const onChange = useCallback(
        (event) => {
            const text = event.target.value;
            // handleChangeSearch(text.length >= 2 ? text : '')
            handleChangeSearch(text);
        },
        // eslint-disable-next-line
        [handleChangeSearch, tag]
    );

    return (
        <SearchBar placeholder={placeholder} onChange={onChange} value={value} />
    );
};

export default SearchView;
