export const API = {
  all: {
    path: '/lists',
    method: 'GET',
    get: null,
    auth: true,
    fallback: [],
  },
  item: {
    path: '/lists/{{id}}?populate[id_list_items]=%2A&populate[id_operation_list][populate][id_yacht]=%2A&populate[id_operation_list][populate][id_marina]=%2A',
    method: 'GET',
    get: null,
    auth: true,
    fallback: [],
  },
  update: {
    path: '/lists/{{id}}',
    method: 'PUT',
    get: null,
    auth: true,
    fallback: null,
  },
};