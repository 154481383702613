import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedItem: ['dashboard'],
  drawerOpen: false,
};


export const menuSlice = (name) =>
  createSlice({
    name,
    initialState,
    reducers: {
      activeItem(state, action) {
        state.selectedItem = action.payload;
      },

      openDrawer(state, action) {
        state.drawerOpen = action.payload;
      },
    },
  });
