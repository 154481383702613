import * as yup from 'yup';
import { PermitType } from '../Data.conf';
import { API as marina } from '../../../marina/api';

export const ParkPermitConf = {
  schema: {
    permit_type: yup.object().nullable().required('Permit type is required'),
    days: yup.string().nullable().required('Days is required'),
    id_marina: yup.object().nullable(),
    permit_number: yup
      .string()
      .nullable()
      .required('Permit number is required'),
    crew_name: yup.string().nullable().required("Captain's Name is required"),
    check_in_date: yup.date().nullable().required('Start Date is required'),
    check_out_date_reservation: yup
      .date()
      .nullable()
      .required('End Date is required')
      .when('check_in_date', (check_in_date, schema) =>
        check_in_date
          ? schema.min(
            check_in_date,
            "Check out date can't be before Check in date"
          )
          : schema
      ),

  },
  defaultValues: {
    permit_type: null,
    check_in_date: null,
    check_out_date_reservation: null,
    days: null,
    permit_number: null,
    id_marina: null,
    crew_name: null,

  },
  fields: [
    {
      id: 'reservation_divider',
      type: 'divider',
      label: 'Reservation',
      xs: 12,
    },
    {
      id: 'permit_type',
      name: 'permit_type',
      type: 'autocomplete',
      label: 'Permit type',
      options: PermitType,
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'days',
      name: 'days',
      type: 'number',
      label: 'Days',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'check_in_date',
      name: 'check_in_date',
      type: 'date',
      label: 'Start date',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'check_out_date_reservation',
      name: 'check_out_date_reservation',
      type: 'date',
      label: 'End date',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'permit_number',
      name: 'permit_number',
      type: 'number',
      label: 'Permit number',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'crew_name',
      name: 'crew_name',
      type: 'text',
      label: "Captain's Name",
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'id_marina',
      name: 'id_marina',
      type: 'async_autocomplete',
      label: 'Location',
      api: marina.select,
      required: false,
      xs: 12,
      sm: 6,
    },
  ],
};
