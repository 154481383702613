import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Container, Stack, Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Back from '../../../components/common/Back.component';
import { useNavigate, useParams } from 'react-router-dom';
import EditView from '../../../views/Edit.view';
import Loading from '../../../components/common/Loading.component';
import { fetchAPI } from '../../../api/request';
import Alert from '../../../components/common/Alert.component';
import _ from 'lodash';

const BookingEditPage = ({
  module: { tag, edit, query, detail },
  children,
  actions,
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { jwt } = useSelector((state) => state.login);
  const { loading, error } = useSelector(detail.selector.data);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      query.detail.action({
        settings: query.detail.settings,
        token: jwt,
        params: { id },
        query: { populate: query.detail.populate },
      })
    );

    return () => {
      dispatch(
        query.detail.action({
          settings: null,
          token: null,
          params: null,
          query: null,
        })
      );
    };
  }, [id, jwt, query, dispatch]);

  useEffect(() => {
    if (
      error &&
      (error?.response?.status === 404 || error?.response?.status === 500)
    )
      navigate('/404');
  }, [error, navigate]);

  return (
    <Fragment>
      {loading && <Loading />}
      {_.isEmpty(error) && (
        <EditContainer module={{ tag, edit, query, detail }} actions={actions}>
          {children}
        </EditContainer>
      )}
    </Fragment>
  );
};

const EditContainer = ({ module: { tag, edit, query }, children, actions }) => {
  const { id } = useParams();
  const { jwt } = useSelector((state) => state.login);
  const { loading: loadingGet, data } = useSelector(edit.selector.data);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const onSuccess = useCallback(() => {
    setSuccess(true);
    setError(false);
    setLoading(false);
  }, []);

  const onError = useCallback(() => {
    setSuccess(false);
    setLoading(false);
    setError(true);
    // eslint-disable-next-line
  }, []);

  const onSubmit = useCallback(
    (values) => {
      setSuccess(false);
      setError(false);
      setLoading(true);
      fetchAPI(query.edit.settings, { id }, '', { ...values }, jwt)
        .then(onSuccess)
        .catch(onError);
    },
    [setLoading, id, jwt, onError, query, onSuccess]
  );

  return (
    <Container disableGutters={true} maxWidth={false} sx={{ height: '100%' }}>
      <Stack spacing={2} justifyContent='center' alignItems='center' width={1}>
        <Back title={tag} />
        {(loading || loadingGet) && <Loading />}
        {success && <Alert severity='success'>{'Success!!'}</Alert>}
        {error && <Alert severity='error'>{'An error has occurred'}</Alert>}

        <Paper variant='outlined' sx={{ padding: 2, width: '100%' }}>
          <EditView
            data={data}
            conf={
              !data?.id_reservation ? edit.conf?.DEFAULT : edit.conf?.OPERATION
            }
            api={query.edit}
            onEditSubmit={onSubmit}
            disabled={loading || loadingGet}
            onEditError={onError}
            actions={actions}
          >
            {children}
          </EditView>
        </Paper>
      </Stack>
    </Container>
  );
};

export default BookingEditPage;
