import MuiTypography from '@mui/material/Typography';
import { Stack } from '@mui/system';
import dayjs from 'dayjs';
import CalendarIcon from '@mui/icons-material/CalendarMonth';
import PersonIcon from '@mui/icons-material/Person';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import _ from 'lodash';
import moment from 'moment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Fragment } from 'react';
import RowItem from '../../../components/listItem/table/RowItem.component';
import Box from '@mui/material/Box';
import Modal from '../../../components/common/Modal.component';
import TableItems from '../../../components/listItem/table/TableItems.component';
import Typography from '../../../components/common/Typography.component';
import { v4 as uuidv4 } from 'uuid';
import itemsDepot from '../../listItemsDepot';
import { Status } from '../../booking/conf/Data.conf';
import RectangleChip from '../../../components/common/RectangleChip.component';
import SwitchForm from '../../../components/operations/SwitchForm.component';
import depot from '../index'
import TableCellEdit from '../../../components/table/components/TableCellEdit.component';
import { API } from '../../operations/api';
import { ServiceOptions } from './Data.conf';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';

export const ListConf = {
	initialState: {
		pagination: {
			page: 1,
			pageSize: 100,
		},
		sort: {
			by: 'requested_date',
			direction: 'asc',
		},
	},
	rowsPerPageOptions: [25, 50, 100],
	columns: [
		{
			id: 'attributes.status_operation_booking',
			key: 'status_operation_booking',
			sort_key: 'status_operation_booking',
			sortable: true,
			label: 'Status',
			width: '200px',
		},
		{
			id: 'attributes.id_yacht.data.attributes.yacht_name',
			key: 'yacht_name',
			sort_key: 'id_yacht.yacht_name',
			sortable: true,
			label: 'Yacht Name',
			width: '180px'
		},
		{
			id: 'attributes.id_service.data.attributes.service',
			key: 'service',
			sort_key: 'id_service.service',
			sortable: true,
			label: 'Service',
			width: '180px',
		},
		{
			id: 'attributes.id_list.data.attributes.id_list_items.data',
			key: 'products',
			sortable: false,
			label: 'ITEMS',
			width: 300,
		},
		{
			id: 'attributes.requested_date',
			key: 'requested_date',
			sort_key: 'requested_date',
			sortable: true,
			label: 'Delivery Date',
			width: '160px',
		},
		{
			id: 'attributes.requested_hour',
			key: 'requested_hour',
			sort_key: 'requested_hour',
			sortable: true,
			label: 'Time',
			width: '120px',
		},

		{
			id: 'attributes.id_marina.data.attributes.marina',
			key: 'id_marina',
			sort_key: 'id_marina.marina',
			sortable: true,
			label: 'Location',
			width: 160,
		},
		{
			id: 'attributes.id_user_in_charge.data.attributes.id_users_permissions_user.data.attributes',
			key: 'id_user_in_charge',
			sort_key: 'id_user_in_charge.id_users_permissions_user.name',
			sortable: true,
			label: 'User in charge',
			width: '180px',
		},
		{
			id: 'attributes.status_operation_BC',
			key: 'status_operation_BC',
			sort_key: 'status_operation_BC',
			sortable: true,
			label: 'BC',
			width: '50px',
		},
		{
			id: 'attributes.status_operation_BF',
			key: 'status_operation_BF',
			sort_key: 'status_operation_BF',
			sortable: true,
			label: 'BF',
			width: '50px',
		},
		{
			id: 'attributes.ft_id',
			key: 'ft_id',
			sort_key: 'ft',
			sortable: true,
			label: 'Invoiced',
			width: '50px',
			checkbox: true,
		},
		{
			id: 'attributes.operation_date',
			key: 'operation_date',
			sort_key: 'operation_date',
			sortable: true,
			label: 'Request date',
			width: '140px',
		},
	],
	render: {
		formatHead: (key, value) => {
			switch (key) {
				default:
					return (
						<MuiTypography
							variant='body1'
							sx={{ fontWeight: 'bold', color: 'white' }}
						>
							{value.toUpperCase()}
						</MuiTypography>
					);
			}
		},
		formatValue: (key, value, onClick) => {
			switch (key) {
				case 'status_operation_booking':
					const color = _.find(
						Status,
						(item) => item.label.toUpperCase() === value.toUpperCase()
					);
					return (
						<Box onClick={onClick}>
							{value ? (
								<RectangleChip
									value={value}
									backgroundColor={color?.hex}
									color='#fff'
								/>
							) : (
								'-'
							)}
						</Box>
					);
				case 'yacht_name': {
					return (
						<MuiTypography
							variant='body1'
							onClick={onClick}
							sx={{ height: '100%', fontWeight: 'bold' }}
						>
							{value || '-'}
						</MuiTypography>
					);
				}
				case 'date_out':
					return (
						<Stack direction={'row'} spacing={1} alignItems={'center'}>
							<CalendarIcon color='action' />
							<MuiTypography
								sx={{ fontWeight: 'bold', height: 1 }}
								variant='body1'
								onClick={onClick}
							>
								{value || '-'}
							</MuiTypography>
						</Stack>
					);

				case 'operation_date':
				case 'requested_date': {
					return (
						<Stack direction={'row'} spacing={1} alignItems={'center'}>
							<CalendarIcon color='action' />
							<MuiTypography
								sx={{ fontWeight: 'bold', height: 1 }}
								variant='body1'
								onClick={onClick}
							>
								{value ? dayjs(value).format('DD-MM-YYYY') : '-'}
							</MuiTypography>
						</Stack>
					);
				}

				case 'requested_hour': {
					return value ? (
						<Stack direction={'row'} spacing={1} alignItems={'center'}>
							<AccessTimeIcon color='action' />
							<MuiTypography
								sx={{ fontWeight: 'bold', height: 1 }}
								variant='body1'
								onClick={onClick}
							>
								{moment(value, 'HH:mm:ss').format('HH:mm')}
							</MuiTypography>
						</Stack>
					) : (
							<MuiTypography
								variant='body1'
								onClick={onClick}
								sx={{ height: '100%' }}
							>
							-
						</MuiTypography>
					);
				}
				case 'id_user_in_charge':
				case 'name': {
					return value ? (
						<Stack direction={'row'} spacing={1} alignItems={'center'}>
							<PersonIcon color='action' fontSize='small' />
							<MuiTypography
								sx={{ height: 1 }}
								variant='body1'
								onClick={onClick}
							>
								{`${value?.name} ${value?.surname}`}
							</MuiTypography>
						</Stack>
					) : (
						'-'
					);
				}
				case 'status_operation_BC':
				case 'status_operation_BF': {
					return value === 'OPEN' ? (
						<CancelIcon color='error' />
		  ) : value === 'CLOSED' ? (
			  <CheckCircleIcon color='success' />
					) : <DoNotDisturbIcon color='disabled' />;
				}
				case 'ft_id':
					return (
						<TableCellEdit
							module={{
								...depot,
								query: {
									...depot.query,
									edit: { settings: API.updateFromList },
								},
							}}
							value={value}
							name={'ft'}
							defaultValues={{ ft: value?.ft }}
							form={<SwitchForm value={value} name={'ft'} />}
						>
							{!!value?.ft ? (
								<CheckCircleIcon color='success' />
							) : (
								<CancelIcon color='error' />
							)}
						</TableCellEdit>
					);

				case 'service':
					const serviceColors = _.find(
						ServiceOptions,
						(item) => item?.id === value
					)?.color;
					return value ? (
						<RectangleChip
							value={value}
							width={'auto'}
							backgroundColor={serviceColors}
							color='#fff'
						/>
					) : (
						'-'
					);


				case 'products':
					const sortProducts = _.orderBy(
						value,
						['attributes.id_logbook.data.id'],
						['asc']
					);
					const chunkSize = 3;
					const liteProducts = _.head(_.chunk(sortProducts, chunkSize));

					return !_.isEmpty(value) ? (
						<Fragment>
							{liteProducts?.map((item) => {
								return (
									<RowItem key={uuidv4()} data={item} module={itemsDepot} />
								);
							})}

							{sortProducts?.length > chunkSize && (
								<Modal
									label={'View all products'}
									sx={{ color: 'text.primary', textDecoration: 'underline' }}
									width={{ xs: '80%', md: 1100 }}
								>
									<Stack spacing={2}>
										<Typography
											variant='h4'
											sx={{
												fontWeight: 'bold',
												alignItems: 'center',
												display: 'flex',
											}}
										>
											Items List
										</Typography>
										<TableItems
											minWidth={900}
											data={sortProducts}
											module={itemsDepot}
										/>
									</Stack>
								</Modal>
							)}
						</Fragment>
					) : (
						<MuiTypography
							variant='body1'
							onClick={onClick}
							sx={{ height: '100%' }}
						>
							-
						</MuiTypography>
					);

				default:
					return (
						<MuiTypography
							variant='body1'
							onClick={onClick}
							sx={{ height: '100%' }}
						>
							{value || '-'}
						</MuiTypography>
					);
			}
		},
	},
};