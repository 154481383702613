import React, { useEffect, useState } from 'react';
import { API as users_nautica } from '../../module/users_nautica/api';
import { Grid } from '@mui/material';
import Divider from '../form/components/Divider.component';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { fetchAPI } from '../../api/request';
import Autocomplete from '../form/components/Autocomplete.component';

const UserNauticaForm = ({ api = users_nautica.selectName,
    required = true,
    disabled = false, }) => {
    const { user, jwt } = useSelector((state) => state.login);
    const { setValue } = useFormContext();
    const [options, setOptions] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true);
        fetchAPI(api, {}, '', {}, jwt)
            .then((resp) => {
                const mappedOptions = resp.map((item) => ({ id: item.id, label: item.name }));
                const initialValueExists = mappedOptions.some(option => option.id === user?.id_users_nautica?.id && option.label === user?.name);
                if (!initialValueExists && user?.id_users_nautica?.id && user?.name) {
                    mappedOptions.unshift({ id: user.id_users_nautica.id, label: user.name });
                }
                setOptions(mappedOptions);
                setValue('id_operator', { id: user?.id_users_nautica?.id, label: user?.name });
                setLoading(false);
            })
            .catch(() => {
                setValue('id_operator', null);
                setOptions([]);
                setLoading(false);
            });
    }, [jwt, setValue, user, api]);


    return (
        <Grid container>
            <Grid item xs={12}>
                <Divider label='User & Deparment' />
            </Grid>
            <Grid item xs={12}>
                <Autocomplete
                    name={'id_operator'}
                    id={'id_operator'}
                    label={'User'}
                    options={options}
                    required={required}
                    disabled={false}
                    disableClearable={true}
                    loading={loading}
                />

            </Grid>
        </Grid>
    );
}

export default UserNauticaForm;
