import dayjs from 'dayjs';
import _ from 'lodash';
import moment from 'moment';

//TO DO: ADD NEW SHORT DESCRIPTION FOR NEW DEPARTMENTS
export const API = {
  logbooks: {
    path: '/logbooks?populate[id_driver_in_charge][populate][id_users_permissions_user][fields]=name,surname&populate[id_location][marina][$containsi]=&populate[id_yacht]=%2A&populate[id_department]=%2A&populate[id_supplier]=%2A&populate[manager][populate][id_users_permissions_user][fields]=name,surname&populate[id_operator][populate][id_users_permissions_user][fields]=name,surname&populate[id_list_items]=%2A&sort[]=collection_delivery_date:asc',
    method: 'GET',
    get: null,
    auth: true,
    fallback: null,
  },
  operation: {
    path: '/logbooks/{{id}}?populate[id_driver_in_charge][populate][id_users_permissions_user][fields]=name,surname&populate[id_location][marina][$containsi]=&populate[id_department]=%2A&populate[id_supplier]=%2A&populate[manager][populate][id_users_permissions_user][fields]=name,surname&populate[id_yacht][populate][id_flag]=%2A&populate[id_yacht][populate][id_attendance][populate][id_agent_NA][populate][id_users_permissions_user][fields][0][1]=name,surname&populate[id_list_items][populate][id_list][populate]=id_operation_list&populate[id_operator][populate][id_users_permissions_user][fields]=name,surname&populate[id_user_last_updated][populate][id_users_permissions_user]=%2A',
    method: 'GET',
    get: null,
    auth: true,
    fallback: null,
    transform: (data) => ({
      ...data,
      data: {
        attributes: {
          ...data?.data?.attributes,
          id_list_items: !_.isEmpty(data?.data?.attributes?.id_list_items?.data)
            ? {
                ...data?.data?.attributes?.id_list_items,
                id_department:
                  data?.data?.attributes?.id_department?.data?.attributes
                    ?.short_description,
                id_logbook: data?.data?.id,
              }
            : null,
          requested_date: data?.data?.attributes?.requested_date
            ? moment(data?.data?.attributes?.requested_date).format(
                'DD-MM-YYYY'
              )
            : null,
          list_status: !_.isEmpty(data?.data?.attributes?.id_list_items?.data)
            ? {
                list_item_status:
                  data?.data?.attributes?.id_list_items?.data[0]?.attributes
                    ?.id_list?.data?.attributes?.list_item_status,
                list_item_status_depot:
                  data?.data?.attributes?.id_list_items?.data[0]?.attributes
                    ?.id_list?.data?.attributes?.list_item_status_depot,
                id_department:
                  data?.data?.attributes?.id_department?.data?.attributes
                    ?.short_description,
              }
            : null,
          id_list: !_.isEmpty(data?.data?.attributes?.id_list_items?.data)
            ? {
                ...data?.data?.attributes?.id_list_items?.data[0]?.attributes
                  ?.id_list,
                check_depot_department: {
                  id_department:
                    data?.data?.attributes?.id_department?.data?.attributes
                      ?.short_description,
                  check_depot:
                    data?.data?.attributes?.id_list_items?.data[0]?.attributes
                      ?.id_list?.data?.attributes?.check_depot,
                },
              }
            : null,
        },
      },
    }),
  },
  update: {
    path: '/logbooks/{{id}}?populate[id_driver_in_charge][populate][id_users_permissions_user][fields]=name,surname&populate[id_location][marina][$containsi]=&populate[id_yacht]=%2A&populate[id_department]=%2A&populate[id_supplier]=%2A&populate[manager][populate][id_users_permissions_user][fields]=name,surname&populate[id_list_items][populate][id_list]=%2A',
    method: 'PUT',
    get: null,
    auth: true,
    fallback: null,
    adapter: (body) => ({
      data: {
        ...body,
        id_yacht: body?.id_yacht?.id,
        operation_type: body?.operation_type,
        id_marina: body?.id_marina?.id,
        id_location: body?.id_location?.id,
        id_department: body?.id_department?.id,
        id_supplier: body?.id_supplier?.id,
        id_driver_in_charge: body?.id_driver_in_charge?.id,
        priority: body?.priority?.id,
        status_logbook: body?.status_logbook,
        collection_delivery_date: body?.collection_delivery_date
          ? dayjs(body?.collection_delivery_date).format('YYYY-MM-DD')
          : null,
        collection_delivery_time: body?.collection_delivery_time
          ? dayjs(body?.collection_delivery_time).format('HH:mm:ss')
          : null,
        logbook_date: body?.logbook_date
          ? dayjs(body?.logbook_date).format('YYYY-MM-DD')
          : null,
      },
    }),
  },
  delete: {
    path: '/logbooks/{{id}}',
    method: 'DELETE',
    get: null,
    auth: true,
    fallback: null,
  },
  create: {
    path: '/logbooks',
    method: 'POST',
    get: null,
    auth: true,
    fallback: null,
    adapter: (body) => {
      return {
        data: {
          ...body,
          id_yacht: body?.id_yacht?.id,
          operation_type: body?.operation_type,
          manager: body?.manager?.id,
          id_marina: body?.id_marina?.id,
          id_department: body?.id_department?.id,
          id_supplier: body?.id_supplier?.id,
          id_driver_in_charge: body?.id_driver_in_charge?.id,
          priority: body?.priority?.id,
          status_logbook: body?.status_logbook,
          collection_delivery_date: body?.collection_delivery_date
            ? dayjs(body?.collection_delivery_date).format('YYYY-MM-DD')
            : null,
          collection_delivery_time: body?.collection_delivery_time
            ? dayjs(body?.collection_delivery_time).format('HH:mm:ss')
            : null,
          logbook_date: body?.logbook_date
            ? dayjs(body?.logbook_date).format('YYYY-MM-DD')
            : null,
        },
      };
    },
  },
  createFromList: {
    path: '/logbooks',
    method: 'POST',
    get: null,
    auth: true,
    fallback: null,
    adapter: (body) => {
      return {
        data: {
          ...body,
        },
      };
    },
  },
};
