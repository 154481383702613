import { useCallback } from "react"
import { useDispatch } from "react-redux"

export const useFilter = (filterChange) => {

    const dispatch = useDispatch()

    const handleChangeFilter = useCallback((values) => {
        dispatch(filterChange(values))
    }, [filterChange, dispatch])

    return {
        handleChangeFilter,
    }
}