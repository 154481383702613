import * as yup from 'yup';
import { OperationConf as Operation } from '../../operations/conf/Create.conf';
import _ from 'lodash';
import { ShippingIncomingConf } from './common/ShippingIncoming.conf';
import { ShippingSentConf } from './common/ShippingSent.conf';
import { ShippingDutyPaidIncomingConf } from './common/ShippingDutyPaidIncoming.conf';
import { ShippingCollectedConf } from './common/ShippingCollected.conf';
import { ShippingStorageConf } from './common/ShippingStorage.conf';

const OperationConf = {
  schema: _.omit(Operation.schema, ['payment_type']),
  defaultValues: _.omit(Operation.defaultValues, ['payment_type']),
  fields: Operation.fields.filter(field => field.id !== 'payment_type'),
};

export const CommonFields = [
  {
    id: 'yacht_divider',
    type: 'divider',
    label: 'Yacht',
    xs: 12,
  },

  {
    id: 'yacht_name',
    name: 'yacht_name',
    type: 'text',
    label: 'Yacht',
    required: false,
    disabled: true,
    xs: 12,
  },
  {
    id: 'agent_NA_name',
    name: 'agent_NA_name',
    type: 'text',
    label: 'NA Agent',
    disabled: true,
    required: false,
    xs: 12,
  },
];

export const ServiceFields = [
  {
    id: 'service_generic',
    type: 'divider',
    label: 'Parcel Details',
    xs: 12,
  },
];

export const UpdateConf = {
  'INCOMING PARCEL': {
    schema: yup.object({
      ...OperationConf.schema,
      ...ShippingIncomingConf.schema,
    }),
    defaultValues: {
      ...OperationConf.defaultValues,
      ...ShippingIncomingConf.defaultValues,
    },
    fields: _.concat(
      CommonFields,
      ServiceFields,
      {
        id: 'shipping_type',
        name: 'shipping_type',
        type: 'text',
        label: 'Shipping Type',
        required: false,
        disabled: true,
        xs: 12,
      },
      {
        id: 'requested_date',
        name: 'requested_date',
        type: 'date',
        label: 'Arrival Date',
        required: true,
        xs: 12,
        sm: 6,
      }, {
      id: 'check_out_date',
      name: 'check_out_date',
      type: 'date',
      label: 'Delivery Date',
      required: false,
      xs: 12,
      sm: 6,
    },
      ShippingIncomingConf.fields, OperationConf.fields.slice(5, 10), OperationConf.fields.slice(12, 24)),
  },
  'PARCEL COLLECTION': {
    schema: yup.object({
      ...OperationConf.schema,
      ...ShippingCollectedConf.schema,
    }),
    defaultValues: {
      ...OperationConf.defaultValues,
      ...ShippingCollectedConf.defaultValues,
    },
    fields: _.concat(
      CommonFields,
      ServiceFields,
      {
        id: 'shipping_type',
        name: 'shipping_type',
        type: 'text',
        label: 'Shipping Type',
        required: false,
        disabled: true,
        xs: 12,
      },
      {
        id: 'requested_date',
        name: 'requested_date',
        type: 'date',
        label: 'Collection Date',
        required: true,
        xs: 12,
        sm: 6,
      },
      {
        id: 'check_out_date',
        name: 'check_out_date',
        type: 'date',
        label: 'Arrival Date',
        required: false,
        xs: 12,
        sm: 6,
      },
      ShippingCollectedConf.fields, OperationConf.fields.slice(5, 10), OperationConf.fields.slice(12, 24)),
  },
  STORAGE: {
    schema: yup.object({
      ...OperationConf.schema,
      ...ShippingStorageConf.schema,
    }),
    defaultValues: {
      ...OperationConf.defaultValues,
      ...ShippingStorageConf.defaultValues,
    },
    fields: _.concat(
      CommonFields,
      ServiceFields,
      {
        id: 'shipping_type',
        name: 'shipping_type',
        type: 'text',
        label: 'Shipping Type',
        required: false,
        disabled: true,
        xs: 12,
      },
      {
        id: 'requested_date',
        name: 'requested_date',
        type: 'date',
        label: 'Check in Date',
        required: true,
        xs: 12,
        sm: 6,
      }, {
      id: 'check_out_date',
      name: 'check_out_date',
      type: 'date',
        label: 'Check out Date',
      required: false,
        xs: 12,
        sm: 6,
    },
      ShippingStorageConf.fields, OperationConf.fields.slice(5, 10), OperationConf.fields.slice(12, 24)),

  },

  'INCOMING CUSTOMS PARCEL': {
    schema: yup.object({
      ...OperationConf.schema,
      ...ShippingDutyPaidIncomingConf.schema,
    }),
    defaultValues: {
      ...OperationConf.defaultValues,
      ...ShippingDutyPaidIncomingConf.defaultValues,
    },
    fields: _.concat(
      CommonFields,
      ServiceFields,
      {
        id: 'shipping_type',
        name: 'shipping_type',
        type: 'text',
        label: 'Shipping Type',
        required: false,
        disabled: true,
        xs: 12,
      },
      {
        id: 'requested_date',
        name: 'requested_date',
        type: 'date',
        label: 'Arrival Date',
        required: true,
        xs: 12,
        sm: 6,
      }, {
      id: 'check_out_date',
      name: 'check_out_date',
      type: 'date',
      label: 'Delivery Date',
      required: false,
      xs: 12,
      sm: 6,
    },
      ShippingDutyPaidIncomingConf.fields, OperationConf.fields.slice(5, 10), OperationConf.fields.slice(12, 24)),

  },
  'OUTGOING PARCEL': {
    schema: yup.object({
      ...OperationConf.schema,
      ...ShippingSentConf.schema,
    }),
    defaultValues: {
      ...OperationConf.defaultValues,
      ...ShippingSentConf.defaultValues,
    },
    fields: _.concat(
      CommonFields,
      ServiceFields,
      {
        id: 'shipping_type',
        name: 'shipping_type',
        type: 'text',
        label: 'Shipping Type',
        required: false,
        disabled: true,
        xs: 12,
      },
      {
        id: 'requested_date',
        name: 'requested_date',
        type: 'date',
        label: 'Shipping Date',
        required: true,
        xs: 12,
        sm: 6,
      }, {
        id: 'check_out_date',
        name: 'check_out_date',
        type: 'date',
        label: 'Estimated Delivery Date',
        required: false,
        xs: 12,
        sm: 6,
    }, 
      ShippingSentConf.fields, OperationConf.fields.slice(5, 10), OperationConf.fields.slice(12, 24)),

  },
};
