import PropTypes from 'prop-types';

export const SelectOption = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.string,
});

export const DynamicFieldData = PropTypes.shape({
  id: PropTypes.string,
  type: PropTypes.oneOf([
    'text',
    'text_multiline',
    'password',
    'select',
    'number',
    'checkbox',
    'checkbox_group',
    'autocomplete',
    'async_autocomplete',
    'divider',
    'upload',
    'date',
    'radio',
    'typography',
    'iframe',
    'draw',
    'space',
    'boolean',
    'time',
    'async_list',
    'add_more_form_fields',
    'country-autocomplete',
    'switch',
    'autocomplete_multiple',
    'async_autocomplete_multiple',
    'password-eye',
    'virtualized_async_autocomplete',
    'select-custom',
  ]).isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  xs: PropTypes.number,
  sm: PropTypes.number,
});
