import MuiTypography from '@mui/material/Typography';
import CalendarIcon from '@mui/icons-material/CalendarMonth';
import PersonIcon from '@mui/icons-material/Person';
import { Box } from '@mui/material';
import moment from 'moment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Fragment } from 'react';
import { green, red } from '@mui/material/colors';
export const ListConf = {
  initialState: {
    pagination: {
      page: 1,
      pageSize: 100,
    },
    sort: {
      by: 'createdAt',
      direction: 'desc',
    },
  },
  rowsPerPageOptions: [25, 50, 100],
  columns: [
    {
      id: 'attributes.mexal_validation',
      key: 'mexal_validation',
      sort_key: 'mexal_validation',
      sortable: true,
      label: 'Mexal',
      maxWidth: 70,
      width: 70,
    },
    {
      id: 'attributes.id_yacht.data.attributes.yacht_type',
      key: 'yacht_type',
      sort_key: 'id_yacht.yacht_type',
      sortable: true,
      label: 'Yacht Type',
      maxWidth: 105,
      width: 50,
    },
    {
      id: 'attributes.id_yacht.data.attributes.yacht_name',
      key: 'yacht_name',
      sort_key: 'id_yacht.yacht_name',
      sortable: true,
      label: 'Yacht Name',
    },
    {
      id: 'attributes.id_agent_NA.data.attributes.id_users_permissions_user.data.attributes',
      key: 'id_agent_NA',
      sort_key: 'id_agent_NA.id_users_permissions_user.name',
      sortable: true,
      label: 'NA Agency',
    },
    {
      id: 'attributes.formalities',
      key: 'formalities',
      sort_key: 'formalities',
      sortable: true,
      label: 'Formalities',
    },

    {
      id: 'attributes.id_provenence_list.data.attributes.provenence_group.data.attributes.group',
      key: 'id_provenence_list.provenance_group.group',
      sort_key: 'id_provenence_list.provenance_group.group',
      sortable: true,
      label: 'Category',
    },
    {
      id: 'attributes.id_provenence_list.data.attributes.provenence_sales_channel.data.attributes.sales_channel',
      key: 'id_provenence_list.provenance_sales_channel.sales_channel',
      sort_key: 'id_provenence_list.provenance_sales_channel.sales_channel',
      sortable: true,
      label: 'Sales Channel',
    },
    {
      id: 'attributes.id_provenence_list.data.attributes.provenence.data.attributes.provenence',
      key: 'id_provenence_list.provenence.provenence',
      sort_key: 'id_provenence_list.provenence.provenence',
      sortable: true,
      label: 'Provenance',
    },
    {
      id: 'attributes.is_central_agent',
      key: 'is_central_agent',
      sort_key: 'is_central_agent',
      sortable: true,
      label: 'Central Agency',
      width: 100,
    },
  ],
  render: {
    formatHead: (key, value) => {
      switch (key) {
        default:
          return (
            <MuiTypography
              variant='body1'
              sx={{ fontWeight: 'bold', color: 'white' }}
            >
              {value.toUpperCase()}
            </MuiTypography>
          );
      }
    },
    formatValue: (key, value, onClick) => {
      switch (key) {
        case 'id_agent_NA':
          return (
            <Box display='flex' alignItems='center' onClick={onClick}>
              {value ? (
                <Fragment>
                  <PersonIcon color='action' fontSize='small' />
                  <MuiTypography sx={{ ml: 1 }} variant='body1'>
                    {`${value?.name} ${value?.surname}`}
                  </MuiTypography>
                </Fragment>
              ) : (
                '-'
              )}
            </Box>
          );
        case 'yacht_type':
        case 'yacht_name':
          return (
            <MuiTypography variant='body1' sx={{ fontWeight: 'bold' }}>
              {value.toUpperCase()}
            </MuiTypography>
          );

        case 'is_central_agent':
        case 'mexal_validation':
          return (
            <Box display='flex' alignItems='center' onClick={onClick}>
              {value ? (
                <CheckCircleIcon sx={{ color: green[500] }} />
              ) : (
                <CancelIcon sx={{ color: red[500] }} />
              )}
            </Box>
          );
        case 'registration_date':
          return (
            <Box display='flex' alignItems='center' onClick={onClick}>
              {value ? (
                <Fragment>
                  <CalendarIcon color='action' />
                  <MuiTypography sx={{ ml: 1 }} variant='body1'>
                    {moment(value).format('DD-MM-YYYY')}
                  </MuiTypography>
                </Fragment>
              ) : (
                '-'
              )}
            </Box>
          );
        default:
          return (
            <MuiTypography variant='body1' onClick={onClick}>
              {value ? value : '-'}
            </MuiTypography>
          );
      }
    },
  },
};
