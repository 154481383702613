import { useCallback } from "react"
import { useDispatch } from "react-redux"

export const useList = (paginationChange, sortChange) => {

    const dispatch = useDispatch()

    const handleChangePagination = useCallback((p) => {
        dispatch(paginationChange(p))
    }, [dispatch, paginationChange])

    const handleChangeSort = useCallback((s) => {
        dispatch(sortChange(s))
    }, [dispatch, sortChange])

    return {
        handleChangePagination,
        handleChangeSort
    }
}