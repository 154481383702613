export const API = {
  select: {
    path: '/locations?pagination[page]=1&pagination[pageSize]=1000',
    method: 'GET',
    get: 'data',
    auth: true,
    fallback: [],
    transform: (data) =>
      data.map((item) => ({
        name: `${item.attributes.location} - ${item.attributes.marina}`,
        id: item.id,
      })),
  },
};
