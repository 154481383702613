export const SearchConf = {
  query: {
    keys: [
      {
        key: 'id_yacht.yacht_name',
        operator: '$containsi'
      },
      {
        key: 'id_yacht.id_attendance.id_agent_NA.id_users_permissions_user.name',
        operator: '$containsi'
      },
      {
        key: 'id_yacht.id_attendance.id_agent_NA.id_users_permissions_user.surname',
        operator: '$containsi'
      }
    ],
  },
  placeholder: 'Search by yacht name or agent',
  initialState: {
    value: ''
  },
};
