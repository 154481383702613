import React, { useCallback, useEffect, useState } from 'react';
import { Container, Stack, Paper, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Back from '../../../components/common/Back.component';
import EditView from '../../../views/Edit.view';
import Loading from '../../../components/common/Loading.component';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { fetchAPI } from '../../../api/request';
import dayjs from 'dayjs';
import Alert from '../../../components/common/Alert.component';
import technicalWork from '../../../module/technicalWork';
import _ from 'lodash';

const TechnicalWorkEditPage = () => {
  const { state } = useLocation();
  const { id, id_operation } = useParams();
  const { jwt } = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const {
    loading: loadingGet,
    data,
    error: errorGET,
  } = useSelector(technicalWork.edit.selector.data);
  const navigate = useNavigate();

  const onError = useCallback(() => {
    setSuccess(false);
    setLoading(false);
    setError(true);
    // eslint-disable-next-line
  }, []);

  const onSuccess = useCallback(() => {
    setSuccess(true);
    setError(false);
    setLoading(false);
    // eslint-disable-next-line
  }, []);

  const onSubmit = useCallback(
    (values) => {
      const obj = {
        ...values,
        time_in: values?.time_in
          ? dayjs(values?.time_in).format('HH:mm:ss')
          : null,
        time_out: values?.time_out
          ? dayjs(values?.time_out).format('HH:mm:ss')
          : null,
      };
      const formData = new FormData();

      if (_.isEmpty(data?.na_tech_signature))
        formData.append(
          `files.na_tech_signature`,
          obj?.na_tech_signature,
          obj?.na_tech_signature?.name
        );
      if (_.isEmpty(data?.yacht_crew_signature))
        formData.append(
          `files.yacht_crew_signature`,
          obj?.yacht_crew_signature,
          obj?.yacht_crew_signature?.name
        );
      formData.append('data', JSON.stringify(obj));

      setError(false);
      setSuccess(false);
      setLoading(true);

      fetchAPI(
        { path: `technical-works/${id}`, method: 'PUT' },
        {},
        '',
        formData,
        jwt
      )
        .then(onSuccess)
        .catch(onError);
    },
    [jwt, onError, onSuccess, id, data]
  );

  useEffect(() => {
    dispatch(
      technicalWork.query.detail.action({
        settings: technicalWork.query.detail.settings,
        token: jwt,
        params: { id },
        query: { populate: technicalWork.query.detail.populate },
      })
    );

    return () => {
      dispatch(
        technicalWork.query.detail.action({
          settings: null,
          token: null,
          params: null,
          query: null,
        })
      );
    };
  }, [dispatch, jwt, id]);

  useEffect(() => {
    if (
      (errorGET &&
        (errorGET?.response?.status === 404 ||
          errorGET?.response?.status === 500)) ||
      state?.id !== id_operation
    )
      navigate('/404');
  }, [errorGET, navigate, state, id_operation]);

  return (
    <Container disableGutters={true} maxWidth={false} sx={{ height: '100%' }}>
      <Stack
        spacing={2}
        sx={{ padding: 2 }}
        justifyContent='center'
        alignItems='center'
      >
        <Back />
        <Container>
          {loading && <Loading />}
          {success && <Alert severity='success'>{'Success: Add'}</Alert>}
          {error && <Alert severity='error'>{'An error has occurred'}</Alert>}

          <Paper variant='outlined'>
            <Box sx={{ padding: 2 }}>
              <EditView
                data={data}
                conf={
                  data?.yacht_crew_signature && data?.na_tech_signature
                    ? technicalWork.edit.conf.technical_work
                    : technicalWork.edit.conf.new_technical_work
                }
                onEditSubmit={onSubmit}
                disabled={loading || loadingGet}
              />
            </Box>
          </Paper>
        </Container>
      </Stack>
    </Container>
  );
};

export default TechnicalWorkEditPage;
