import React from 'react';
import { Stack, Typography } from '@mui/material';

const Detail = ({ item }) => {
  const nameENG = item?.attributes?.id_item.data?.attributes?.name_ENG;
  const nameITA = item?.attributes?.id_item.data?.attributes?.name_ITA;
  return (
    <Stack direction={'row'} spacing={0.5} display='inline'>
      {nameITA && (
        <Typography sx={{ fontWeight: 'bold', display: 'inline' }}>
          {`${nameITA}`}
        </Typography>
      )}
      {nameENG && (
        <Typography sx={{ fontStyle: 'italic', display: 'inline' }}>
          {`(${nameENG})`}
        </Typography>
      )}
    </Stack>
  );
};

export default Detail;
