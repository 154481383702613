import React from 'react';
import MuiTypography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { Link as MuiLink, Stack } from '@mui/material';

const Link = ({ label, value, variant = 'subtitle1' }) => {
  return (
    <Stack direction={'row'} spacing={1}>
      <MuiLink href={value} target='_blank'>
        <MuiTypography
          variant={variant}
          textTransform={'capitalize'}
          sx={{ color: 'blue' }}
        >
          {label}
        </MuiTypography>
      </MuiLink>
    </Stack>
  );
};

Link.propTypes = {
  label: PropTypes.string,
  //value: PropTypes.string,
};

export default Link;
