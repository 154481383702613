import { FormProvider, useForm } from 'react-hook-form';
import { DynamicControl } from './DynamicControl';
import PropTypes from 'prop-types';
import { DynamicFieldData } from './DynamicControlTypes';
import FormComponent from './components/Form.component';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { schemaValidation } from './conf/FormSchema.conf';

export const Form = ({
  fields,
  schema,
  defaultValues,
  onSubmit = () => {},
  children,
  actions,
}) => {
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  useEffect(() => {
    fields?.map((item) => {
      const parse = schemaValidation.safeParse(item);
      if (!parse?.success) {
        console.error(parse.error);
      }
      return item;
    });
  }, [fields]);

  useEffect(() => {
    methods.reset(defaultValues);
    // eslint-disable-next-line
  }, [defaultValues]);

  return (
    <FormProvider {...methods}>
      <FormComponent onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container spacing={1}>
          {actions && (
            <Grid item xs={12}>
              {actions}
            </Grid>
          )}
          {fields.map(({ xs, sm, md, lg, ...d }, i) => (
            <Grid item xs={xs} sm={sm} md={md} lg={lg} key={i}>
              <DynamicControl {...d} />
            </Grid>
          ))}
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
        {process.env.REACT_APP_FORM_DEV_MODE === true && (
          <div>
            <pre>{JSON.stringify(methods.getValues(), null, 1)}</pre>
          </div>
        )}
      </FormComponent>
    </FormProvider>
  );
};

Form.propTypes = {
  fields: PropTypes.arrayOf(DynamicFieldData),
  schema: PropTypes.object,
};
