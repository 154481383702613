import * as React from 'react';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import PropTypes from 'prop-types';

const SearchBar = ({
  placeholder = '',
  onChange = () => {},
  onClick = () => {},
  value,
}) => {
  return (
    <Box
      display='flex'
      border={1}
      paddingLeft={1}
      paddingRight={1}
      borderColor='grey.500'
      borderRadius={1}
      width={{ xs: 1, sm: 1 / 3 }}
      marginBottom={{ xs: 1, sm: 0 }}
    >
      <InputBase
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        inputProps={{ 'aria-label': 'search' }}
        fullWidth={true}
      />
      <IconButton sx={{ ml: 1 }} onClick={onClick}>
        <SearchIcon />
      </IconButton>
    </Box>
  );
};

export default SearchBar;

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  value: PropTypes.string,
};
