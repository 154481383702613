export const SearchConf = {
  query: {
    keys: [
      {
        key: 'id_yacht.yacht_name',
        operator: '$containsi',
      },
      {
        key: 'operation_type',
        operator: '$containsi',
      },
      {
        key: 'id_department.name_department',
        operator: '$containsi',
      },
      {
        key: 'id_location.marina',
        operator: '$containsi',
      },
    ],
  },
  placeholder: 'Search by department or yacht name',
  initialState: {
    value: '',
  },
};
