import React, { useEffect, useCallback } from 'react';
import { Typography, Box, Container, Paper } from '@mui/material';
import SegmentedControls from '../../components/common/SegmentedControls.component';
import Loading from '../../components/common/Loading.component';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CalendarView from '../../views/Calendar.view';
import ListView from '../../views/List.view';
import ToolbarComponent from '../../components/toolbar/Toolbar.component';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import _ from 'lodash';
import dayjs from 'dayjs';
import { useState } from 'react';

const BookingsPage = ({module:{query,tag,views,search,list,calendar,filter}}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { jwt } = useSelector((state) => state.login);
  const {
    data: { loading },
    view: { value: view },
    filter: { value },
  } = useSelector((state) => state[tag]);
  const _query = useSelector(query.all.selector);
  const { height } = useWindowDimensions();
  const userAuth = useSelector((state) => state.login?.user);
  const { from } = useSelector((state) => state.year);
  const [backDate, setBackDate] = useState(null);

  useEffect(() => {
    if (userAuth.permissions[_.toUpper(tag)]?.READ === 'true') {
      dispatch(
        query.all.action({
          settings: query.all.settings,
          token: jwt,
          query: { ..._query, populate: query.all.populate },
        })
      );
    }
    // eslint-disable-next-line
  }, [_query, jwt, userAuth, from, view]);

  const onClick = useCallback(
    (item) =>
      navigate(
        `${item.id}`,
        { state: { from: tag } },
        localStorage.setItem('selectedDate', item.start)
      ),
    [navigate, tag]
  );

  useEffect(() => {
    const fromYear = dayjs(from).year();
    const selectDate = localStorage.getItem('selectedDate');
    const selectedYear = selectDate && dayjs(selectDate).year();
    if (selectedYear) {
      if (selectedYear === fromYear) {
        setBackDate(selectDate);
      } else {
        setBackDate(null);
      }
      localStorage.removeItem('selectedDate');
    } else {
      setBackDate(null);
    }
  }, [from]);

  return (
    <Container disableGutters={true} maxWidth={false} sx={{ height: '100%' }}>
      <Box display={'flex'} justifyContent='space-between' padding={3}>
        <Typography variant='h4' sx={{ fontWeight: 'bold' }}>
          Bookings
        </Typography>
        {userAuth.permissions[_.toUpper(tag)]?.READ_LIST === 'true' &&
          userAuth.permissions[_.toUpper(tag)]?.READ_CALENDAR === 'true' && (
            <SegmentedControls tag='booking' views={views} />
          )}
      </Box>
      <Paper variant='outlined'>
        <ToolbarComponent
          tag='booking'
          search={search}
          add={list.add}
          filter={filter}
        />
        {loading && <Loading />}
        {userAuth.permissions[_.toUpper(tag)]?.READ === 'true' && (
          <Box padding={2}>
            {userAuth.permissions[_.toUpper(tag)]?.READ_CALENDAR === 'true' &&
              view.value === 'calendar' && (
                <CalendarView
                  calendar={calendar}
                  height={height}
                  range={{
                    startDate: value.start_date,
                    endDate: value.end_date,
                  }}
                  onClick={onClick}
                  defaultValue={backDate}
                />
              )}
            {userAuth.permissions[_.toUpper(tag)]?.READ_LIST === 'true' &&
              (view.value === 'list' ||
                userAuth.permissions[_.toUpper(tag)]?.READ_CALENDAR ===
                  'false') && (
                <ListView list={list} onClick={onClick} />
              )}
          </Box>
        )}
      </Paper>
    </Container>
  );
};

export default BookingsPage;
