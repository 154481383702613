import { Autocomplete, Stack, TextField } from '@mui/material';
import React from 'react';
import Divider from '../form/components/Divider.component';

const InvoicingEntityForm = ({ invoiceType, setInvoiceType = () => {} }) => {
  const handleChange = (event, newValue) => {
    if (newValue !== null) {
      setInvoiceType(newValue.value);
    }
  };

  return (
    <Stack>
      <Divider label='Invoice' />
      {invoiceType && (
        <Autocomplete
          id='invoicing-entity'
          value={{ value: invoiceType, label: invoiceType }}
          onChange={handleChange}
          options={[
            { value: 'NATURAL PERSON', label: 'NATURAL PERSON' },
            { value: 'COMPANY', label: 'COMPANY' },
          ]}
          isOptionEqualToValue={(option, value) => option.label === value.label}
          renderInput={(params) => (
            <TextField {...params} label='Invoicing Entity' />
          )}
        />
      )}
    </Stack>
  );
};

export default InvoicingEntityForm;
