import { Grid, Stack } from '@mui/material';
import Text from '../../../components/detail/components/Text.component';
import _ from 'lodash';
import moment from 'moment/moment';
import DownlaodComponent from '../../../components/detail/components/Download.component';
import MuiTypography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { green } from '@mui/material/colors';
import dayjs from 'dayjs';
import { FeesType } from './Data.conf';
import Link from '../../../components/detail/components/Link.component';

export const CommonDetailConf = {
  id: 'yacht_section',
  type: 'section',
  label: 'Yacht Details',
  xs: 12,
  items: [
    {
      type: 'text',
      label: 'Yacht name',
      key: 'attributes.yacht',
      xs: 12,
    },
    {
      type: 'text',
      label: 'Yacht type',
      key: 'attributes.yacht_type',
      xs: 12,
      sm: 6,
      md: 3,
    },

    { type: 'text', label: 'Loa', key: 'attributes.loa', xs: 12, sm: 6, md: 3 },
    {
      type: 'text',
      label: 'Beam',
      key: 'attributes.beam',
      xs: 12,
      sm: 6,
      md: 3,
    },
    {
      type: 'text',
      label: 'draft',
      key: 'attributes.draft',
      xs: 12,
      sm: 6,
      md: 3,
    },
    {
      type: 'text',
      label: 'Gross Tonn',
      key: 'attributes.gross_tonn',
      xs: 12,
      sm: 6,
      md: 3,
    },
    {
      type: 'text',
      label: 'Flag',
      key: 'attributes.id_flag.data.attributes.locality_flag',
      xs: 12,
      sm: 6,
      md: 3,
    },
    {
      type: 'text',
      label: 'Registry',
      key: 'attributes.registry_type',
      xs: 12,
      sm: 6,
      md: 3,
    },
    {
      type: 'text',
      label: 'Port Of Registry',
      key: 'attributes.port_of_registry',
      xs: 12,
      sm: 6,
      md: 3,
    },
    {
      type: 'text',
      label: 'Note',
      key: 'attributes.note',
      xs: 12,
    },
  ],
};

export const DetailConf = {
  initialState: {
    loading: false,
    error: null,
    data: null,
    meta: null,
  },
  fields: [
    { ...CommonDetailConf },
    {
      id: 'yacht_contacts',
      type: 'section',
      label: 'Contacts',
      xs: 12,
      items: [
        { type: 'text', label: '', key: 'attributes.id_user_crew', xs: 12 },
      ],
    },
    {
      id: 'invoicing_entity_section',
      type: 'section',
      label: 'Invoicing Details',
      xs: 12,
      items: [{ type: 'text', label: '', key: 'attributes.invoicing', xs: 12 }],
    },
    {
      id: 'terms_of_payment_section',
      type: 'section',
      label: 'Berth payments',
      xs: 12,
      items: [
        {
          type: 'text',
          label: '',
          key: 'attributes.payment_preferences',
          xs: 12,
          sm: 6,
        },
        {
          type: 'text',
          label: 'Fees Type',
          key: 'attributes.fees_type',
          xs: 12,
          sm: 6,
        },
      ],
    },
    {
      id: 'terms_and_conditions_section',
      type: 'section',
      label: 'Terms and conditions',
      xs: 12,
      items: [
        {
          type: 'boolean',
          label: 'Terms and condition',
          key: 'attributes.terms_and_condition',
          xs: 12,
          sm: 4,
        },
        {
          type: 'boolean',
          label: 'Marketing',
          key: 'attributes.feedback',
          xs: 12,
          sm: 4,
        },
        {
          type: 'boolean',
          label: 'Newsletter',
          key: 'attributes.commercial',
          xs: 12,
          sm: 4,
        },
      ],
    },

    {
      id: 'signature',
      type: 'section',
      label: 'Registration',
      xs: 12,
      items: [
        {
          type: 'text',
          label: 'Client Of Signature',
          key: 'attributes.client_of_signature',
          xs: 12,
          sm: 6,
        },
        {
          type: 'text',
          label: 'Date',
          key: 'attributes.date_submit_signature',
          xs: 12,
          sm: 6,
        },
        {
          type: 'text',
          label: 'Nautica know',
          key: 'attributes.id_nautica_known.data.attributes.description',
          xs: 12,
        },
        {
          type: 'text',
          label: 'Nautica know note',
          key: 'attributes.nautica_known_note',
          xs: 12,
        },
      ],
    },
    {
      id: 'documents_section',
      type: 'section',
      label: 'Documents',
      items: [
        {
          type: 'documents',
          label: 'Certificate of registry',
          key: 'attributes.yacht_document.data',
          xs: 12,
        },
      ],
    },
  ],
  formatValue: (key, value) => {
    switch (key) {
      case 'attributes.id_yacht.data':
        return (
          <Text label='Yacht name' value={value?.attributes?.yacht_name} />
        );
      case 'attributes.yacht_document.data':
        return (
          <Grid container spacing={{ xs: 2, sm: 4 }}>
            {value?.map((item) => (
              <Grid item key={item.id} xs={12} sm={6} md={4}>
                <DownlaodComponent
                  value={{
                    subLabel: _.get(
                      item,
                      'attributes.id_document_type.data.attributes.document_type'
                    ),
                    title: _.get(item, 'attributes.file.data.attributes.name'),
                    url: _.get(item, 'attributes.file.data.attributes.url'),
                  }}
                  tag='yachts'
                />
              </Grid>
            ))}
          </Grid>
        );

      case 'attributes.id_user_crew':
        if (value && value.data) {
          return (
            <Grid container spacing={2}>
              {value.data.map((user) => (
                <Grid
                  item
                  container
                  xs={12}
                  md={6}
                  key={user.id}
                  sx={{ boxShadow: 0.5 }}
                >
                  <Grid item xs={12}>
                    {!!user.attributes.is_delivery_manager_on_board ? (
                      <MuiTypography
                        fontWeight='bold'
                        alignItems='center'
                        display='flex'
                        justifyContent='left'
                      >
                        {`${user.attributes.crew_responsability} -  Delivery manager on board`}
                        <CheckCircleIcon
                          sx={{
                            color: green[500],
                            width: 15,
                            height: 15,
                            margin: 0.5,
                          }}
                        />
                      </MuiTypography>
                    ) : (
                      <Text
                        label=''
                        sx={{ fontWeight: 'bold' }}
                        value={user.attributes.crew_responsability}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Text
                      label='Name'
                      value={
                        user.attributes.id_users_permissions_user?.data
                          .attributes?.name
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Text
                      label='Surname'
                      value={
                        user.attributes.id_users_permissions_user?.data
                          .attributes?.surname
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Text
                      label='Email'
                      value={
                        user.attributes.id_users_permissions_user?.data
                          ?.attributes?.email
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Text
                      label='Phone number'
                      value={
                        user.attributes.phone_number
                          ? user.attributes.phone_number
                          : '-'
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    {_.lowerCase(user.attributes.crew_responsability) ===
                      'captain' && (
                      <Text
                        label='Birth date'
                        value={
                          user.attributes.birthdate
                            ? dayjs(user.attributes.birthdate).format(
                                'DD-MM-YYYY'
                              )
                            : '-'
                        }
                      />
                    )}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          );
        }
        break;
      case 'attributes.invoicing':
        if (value?.invoicing_entity === 'COMPANY') {
          return (
            <Stack spacing={1}>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <Text
                    label='Invoice Entity'
                    value={value?.invoicing_entity}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Text
                    label='Company name'
                    value={value?.invoice_company_name}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Text
                    label='Street address'
                    value={value?.invoice_street_address}
                  />
                </Grid>
                {value?.invoice_street_address2 && (
                  <Grid item xs={12} sm={6}>
                    <Text
                      label='Street address 2'
                      value={value.invoice_street_address2}
                    />
                  </Grid>
                )}

                <Grid item xs={12} sm={12}>
                  <Text
                    label='VAT number'
                    value={value?.vat_number_company_invoicing}
                  />
                </Grid>
              </Grid>

              <Grid container>
                {value?.invoice_person_of_payment && (
                  <Grid item xs={12} sm={6}>
                    <Text
                      label='Person in charge of payment'
                      value={value?.invoice_person_of_payment}
                    />
                  </Grid>
                )}
                {value?.invoice_position && (
                  <Grid item xs={12} sm={6}>
                    <Text label='Position' value={value?.invoice_position} />
                  </Grid>
                )}

                {value?.invoice_email && (
                  <Grid item xs={12} sm={6}>
                    <Text label='Email' value={value?.invoice_email} />
                  </Grid>
                )}
                {value?.invoice_phone_number && (
                  <Grid item xs={12} sm={6}>
                    <Text
                      label='Phone number'
                      value={value.invoice_phone_number}
                    />
                  </Grid>
                )}
              </Grid>
            </Stack>
          );
        } else if (value?.invoicing_entity === 'NATURAL PERSON') {
          return (
            <Stack spacing={1}>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <Text
                    label='Invoice Entity'
                    value={value?.invoicing_entity}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Text label='First Name' value={value?.invoice_person_name} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Text
                    label='Last Name'
                    value={value?.invoice_person_lastname}
                  />
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Text
                    label='Place of birth'
                    value={value?.invoice_person_place_of_birth}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Text
                    label='Birth date'
                    value={moment(value?.invoice_person_birthdate).format(
                      'DD-MM-YYYY'
                    )}
                  />
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Text
                    label='Street Address'
                    value={value?.invoice_street_address}
                  />
                </Grid>
                {value?.invoice_street_address2 && (
                  <Grid item xs={12} sm={6}>
                    <Text
                      label='Street Address 2'
                      value={value.invoice_street_address2}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6}>
                  <Text label='City' value={value?.invoice_city_address} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Text
                    label='Country'
                    value={value?.invoice_country_address}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Text
                    label='Postal / Zip Code'
                    value={value?.invoice_zipcode_addess}
                  />
                </Grid>
              </Grid>
            </Stack>
          );
        }
        return <Text label='Invoice Entity' value={'not set'} />;
      case 'attributes.draft':
        return (
          <Text label='Draft' value={_.isEmpty(value) ? `${value} m` : '-'} />
        );

      case 'attributes.loa':
        return (
          <Text label='Loa' value={_.isEmpty(value) ? `${value} m` : '-'} />
        );

      case 'attributes.beam':
        return (
          <Text label='Beam' value={_.isEmpty(value) ? `${value} m` : '-'} />
        );

      case 'attributes.payment_preferences':
        if (value && value.data) {
          const paymentPreferences = value.data.map(
            (payment) => payment.attributes.payment_type
          );
          const paymentPreferencesText = _.join(paymentPreferences, ', ');

          return (
            <Text
              label={'Payment preferences'}
              value={
                !_.isEmpty(paymentPreferences) ? paymentPreferencesText : '-'
              }
            />
          );
        }
        break;
      case 'attributes.date_submit_signature':
        return (
          <Text
            label='Date'
            value={value ? moment(value).format('DD-MM-YYYY') : '-'}
          />
        );

      case 'attributes.fees_type':
        return (
          <Text
            label='Fees Type'
            value={
              _.find(FeesType, function (o) {
                return o.id === value;
              }).value
            }
          />
        );

      case 'attributes.yacht':
        return (
          <Grid container>
            <Grid item xs={12} sm={6} md={3}>
              <Text label='Yacht Name' value={value?.yacht_name || '-'} />
            </Grid>
            {value?.url_web && (
              <Grid item xs={12} sm={6} md={3}>
                <Link label='Web' value={value?.url_web} />
              </Grid>
            )}
          </Grid>
        );

      default:
        return null;
    }
  },
};
