import React, { useCallback } from 'react';

import Button from '../../common/Button.component';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router';

const EditButton = ({ to, tag }) => {
  const navigate = useNavigate();

  const onClick = useCallback(
    (e) => {
      if (to) navigate(`${to}`, { state: { from: tag } });
    },
    [navigate, to, tag]
  );

  return (
    <Button variant='outlined' startIcon={<EditIcon />} onClick={onClick}>
      EDIT
    </Button>
  );
};

export default EditButton;
