import { useCallback } from "react"
import { useDispatch } from "react-redux"
import { useLocation } from "react-router-dom"

export const useSearch = (searchChange) => {

    const dispatch = useDispatch()
    const location= useLocation()
    const handleChangeSearch = useCallback((value) => {
        dispatch(searchChange(value))
        // eslint-disable-next-line
    }, [dispatch,location])

    return {
        handleChangeSearch,
    }
}