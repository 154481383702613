import React from 'react';
import MuiDivider from '@mui/material/Divider';
import MuiTypography from '@mui/material/Typography'

import PropTypes from 'prop-types';
import { Stack } from '@mui/material';

const Divider = ({
    label
}) => {

    return (
        <Stack>
            <MuiTypography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                {label}
            </MuiTypography>
            <MuiDivider sx={{ mt: 1, mb: 1 }} />
        </Stack>        
    );
};

Divider.propTypes = {
    label: PropTypes.string,
};

export default Divider;
