// CONF
import { CreateConf } from './conf/Create.conf';
import { UpdateConf } from './conf/Update.conf';
// REDUX
import { reducer, actions, editSelector } from './slice';

// SELECTOR
import { tag } from './slice';

// SAGA
import { saga } from './saga';

// API
import { API } from './api';
import { DetailConf } from './conf/Detail.conf';

export { reducer, saga, tag };

const technicalWork = {
  tag,
  query: {
    detail: {
      action: actions.fetchDetailAction,
      settings: API.technicalWork,
      populate: '*',
    },
    create: {
      settings: API.create,
    },
    delete: {
      settings: API.delete,
    },

  },
  edit: {
    conf: UpdateConf,
    selector: {
      data: editSelector,
    },
  },
  create: {
    conf: CreateConf,
  },
  detail: {
    conf: DetailConf,
    edit: {
      to: 'edit',
    },
  },
  remove: {
    // selector: {
    //   data: detailSelector,
    // },
    navigate: '/department/technical',
  },

};

export default technicalWork;
