import React, { useCallback, useEffect, useState } from 'react';
import {
  Container,
  Stack,
  Paper,
  Box,
  Autocomplete,
  TextField,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Back from '../../../components/common/Back.component';
import EditView from '../../../views/Edit.view';
import Loading from '../../../components/common/Loading.component';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchAPI } from '../../../api/request';
import Alert from '../../../components/common/Alert.component';
import _ from 'lodash';
import { ConciergeOptions } from '../../../module/concierge/conf/Data.conf';

const ConciergeEditPage = ({module:{edit, query,tag}}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { jwt, user } = useSelector((state) => state.login);
  const {
    loading: loadingGet,
    data,
    error: errorGET,
  } = useSelector(edit.selector.data);
  const [loading, setLoading] = useState(false);
  const [reservationType, setReservationType] = useState(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const onError = useCallback(() => {
    setSuccess(false);
    setLoading(false);
    setError(true);
    // eslint-disable-next-line
  }, []);

  const onSuccess = useCallback(
    (values) => {
      const data = {
        ...values,
        reservation_type: reservationType?.id,
        id_operation_list: id,
      };

      setLoading(false);
      fetchAPI(query.edit.settings2, { id: values.id_reservation.id }, '', data, jwt)
        .then(() => {
          setSuccess(true);
          setError(false);
        })
        .catch(onError);
    },
    [setLoading, setSuccess, jwt, onError, reservationType, id, query]
  );

  const onSubmit = useCallback(
    (values) => {
      const data = {
        ...values,
        id_user_last_updated: user?.id_users_nautica?.id || null,
      };
      setError(false);
      setSuccess(false);
      setLoading(true);
      fetchAPI(query.edit.settings, { id }, '', data, jwt)
        .then(() =>
          onSuccess({
            id_reservation: values?.id_reservation?.id,
            ...values,
          })
        )
        .catch(onError);
    },
    [setLoading, id, jwt, onError, onSuccess, query, user]
  );

  useEffect(() => {
    if (data && data?.id_reservation?.name) {
      setReservationType({
        id: data?.id_reservation?.name,
        label: data?.id_reservation?.name,
      });
      if (
        !_.find(ConciergeOptions, function (o) {
          return o.id === data?.id_reservation?.name;
        })
      ) {
        navigate('/404');
      }
    }
  }, [data, navigate]);

  useEffect(() => {
    dispatch(
      query.detail.action({
        settings: query.detail.settings,
        token: jwt,
        params: { id },
        query: { populate: query.detail.populate },
      })
    );

    return () => {
      dispatch(
        query.detail.action({
          settings: null,
          token: null,
          params: null,
          query: null,
        })
      );
    };
  }, [id, dispatch, jwt,query]);

  useEffect(() => {
    if (
      (errorGET &&
        (errorGET?.response?.status === 404 ||
          errorGET?.response?.status === 500)) ||
      data?.id_reservation === null
    )
      navigate('/404');
  }, [errorGET, navigate, data]);

  return (
    <Container disableGutters={true} maxWidth={false} sx={{ height: '100%' }}>
      <Stack
        spacing={2}
        sx={{ padding: 2 }}
        justifyContent='center'
        alignItems='center'
      >
        <Back title={tag} />
        <Container>
          {loading && <Loading />}
          {success && <Alert severity='success'>{'Succes: Updated'}</Alert>}
          {error && <Alert severity='error'>{'An error has occurred'}</Alert>}
          {loadingGet ? (
            <Loading />
          ) : (
            <Paper variant='outlined'>
              <Box sx={{ padding: 2 }}>
                <Stack spacing={1}>
                  <Autocomplete
                    disablePortal
                    disableClearable
                    options={ConciergeOptions}
                    isOptionEqualToValue={(option, value) =>
                      option.label === value.label
                    }
                    value={reservationType || null}
                    getOptionLabel={(option) => option?.label || ''}
                    onChange={(_, newValue) => {
                      setReservationType(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='Reservation Type'
                        required={true}
                      />
                    )}
                    disabled={!!data?.id_reservation?.name}
                  />

                  {reservationType && reservationType?.id && (
                    <EditView
                      data={data}
                      id={id}
                      conf={edit.conf[reservationType.id]}
                      onEditSubmit={onSubmit}
                      onEditSuccess={onSuccess}
                      onEditError={onError}
                    />
                  )}
                </Stack>
              </Box>
            </Paper>
          )}
        </Container>
      </Stack>
    </Container>
  );
};

export default ConciergeEditPage;
