import React from 'react';
import Typography from './Typography.component';
import { Paper } from '@mui/material';

const PaperTypography = ({
  title,
  variantPaper = 'outlined',
  variantTypography = 'h5',
  sx,
}) => {
  return (
    <Paper variant={variantPaper}>
      <Typography
        variant={variantTypography}
        sx={{
          fontWeight: 'bold',
          flexGrow: 1,
          textAlign: 'center',
          ...sx,
        }}
        m={1}
      >
        {title}
      </Typography>
    </Paper>
  );
};

export default PaperTypography;
