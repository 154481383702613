import React, { useState } from 'react';
import MUIAutocomplete from '@mui/material/Autocomplete';
import MUITextField from '@mui/material/TextField';
import Error from './Error.component';
import { useController, useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';

import PropTypes from 'prop-types';
import _ from 'lodash';

const Autocomplete = ({
  name,
  required,
  options,
  label,
  disabled,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const {
    field: { ref, onChange, value, ...inputProps },
  } = useController({
    name: name,
    control: control,
    rules: { required },
  });

  return (
    <Box display='flex' flexDirection={'column'}>
      <MUIAutocomplete
        {...inputProps}
        ref={ref}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        options={options}
        value={value || null}
        getOptionLabel={(option) => option?.label || ''}
        isOptionEqualToValue={(option, value) => option?.label === value?.label}
        filterOptions={(options, state) => {
          // Filter out any options that have the same name as the user's input value
          const inputValue = state.inputValue.toLowerCase();
          return options.filter((option) =>
            _.includes(option.label.toLowerCase(), inputValue.toLowerCase())
          );
        }}
        // renderOption={(_, option) => (
        //   <Box
        //     key={uuidv4()}
        //     onClick={() => {
        //       setOpen(false);
        //       onChange(option);
        //     }}
        //     pl={2}
        //     pr={2}
        //     pt={0.5}
        //     pb={0.5}
        //     sx={{
        //       '&:hover': {
        //         backgroundColor: grey[200],
        //       },
        //     }}
        //   >
        //     {option?.label}
        //   </Box>
        // )}
        renderInput={(params) => (
          <MUITextField
            {...params}
            label={label}
            required={required}
            error={!!errors[name]?.message}
          />
        )}
        onChange={(e, data) => onChange(data)}
        disabled={disabled}
        {...props}
      />
      <Error error={errors[name]?.message} />
    </Box>
  );
};

Autocomplete.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
};

export default Autocomplete;
