import * as yup from 'yup';
import { RegistrationStatus, RegistryTypes } from './Data.conf';
import dayjs from 'dayjs';

export const FilterConf = {
  schema: yup.object({
    registry_type: yup.object().nullable(),
    registration_status: yup.array().nullable(),
    start_date: yup
      .date()
      .nullable()
      .test('is-valid-date', 'Start date is not valid', function (value) {
        return value ? dayjs(value).isValid() : true;
      })
      //  .required('Check In is required')
      .transform((value) => {
        if (value && dayjs(value).isValid()) {
          const formattedDate = dayjs(value).format('YYYY-MM-DD');
          return new Date(formattedDate);
        }
        return null;
      }),
    // .transform((value, originalValue) => {
    //   const parsedDate = dayjs(originalValue, 'YYYY-MM-DD', true);
    //   const newDate = parsedDate.add(1, 'hour');

    //   return dayjs(originalValue).isValid()
    //     ? newDate?.isValid()
    //       ? newDate.toDate()
    //       : value
    //     : null;
    // }),
    end_date: yup
      .date()
      .nullable()
      .test('is-valid-date', 'End date is not valid', function (value) {
        return value ? dayjs(value)?.isValid() : true;
      })
      .when('start_date', (start_date, schema) =>
        start_date
          ? schema.min(start_date, "End date can't be before Start date")
          : schema
      )
      .transform((value) => {
        if (value && dayjs(value).isValid()) {
          const formattedDate = dayjs(value).format('YYYY-MM-DD');
          return new Date(formattedDate);
        }
        return null;
      }),
    // .transform((value, originalValue) => {
    //   const parsedDate = dayjs(originalValue, 'YYYY-MM-DD', true);
    //   const newDate = parsedDate.add(1, 'hour');

    //   return dayjs(originalValue).isValid()
    //     ? newDate?.isValid()
    //       ? newDate.toDate()
    //       : value
    //     : null;
    // }),
  }),
  initialState: {
    value: {
      registry_type: null,
      registration_status: [],
      start_date: dayjs().startOf('year').format('YYYY-MM-DD').toString(),
      end_date: dayjs().endOf('year').format('YYYY-MM-DD').toString(),
    },
  },
  fields: [
    {
      id: 'registry_type',
      name: 'registry_type',
      type: 'autocomplete',
      label: 'Registry Type',
      options: RegistryTypes,
      required: false,
      xs: 12,
      sm: 6,
      md: 3,
    },
    {
      id: 'registration_status',
      name: 'registration_status',
      type: 'autocomplete_multiple',
      label: 'Registration Status',
      options: RegistrationStatus,
      required: false,
      xs: 12,
      sm: 6,
      md: 3,
    },
    {
      id: 'start_date',
      name: 'start_date',
      type: 'date',
      label: 'Start Date',
      required: false,
      xs: 12,
      sm: 6,
      md: 3,
    },
    {
      id: 'end_date',
      name: 'end_date',
      type: 'date',
      label: 'End Date',
      required: false,
      xs: 12,
      sm: 6,
      md: 3,
    },
  ],
  query: {
    keys: [
      {
        key: 'registry_type',
        value: 'registry_type.label',
        operator: '$eq',
      },
      {
        key: 'registration_status',
        value: 'registration_status.label',
        operator: '$eq',
      },
      {
        key: 'date_submit_signature',
        value: 'start_date',
        operator: '$gte',
      },
      {
        key: 'date_submit_signature',
        value: 'end_date',
        operator: '$lte',
      },
    ],
  },
};
