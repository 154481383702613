import React, { Fragment } from 'react';
import { Stack, TableCell, Box } from '@mui/material';
import Info from '../../../../listItem/table/components/Info.component';
import Detail from '../../../../listItem/table/components/Detail.component';
import ControlAutocomplete from '../../../../listItem/table/components/ControlAutocomplete.component';
import ControlTextField from '../../../../listItem/table/components/ControlTextField.component';
import SliderComponent from '../../../../common/Slider.component';
import CardMedia from '../../../../common/CardMedia.component';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { AvailableType } from '../../../../../module/listItem/conf/Data.conf';
import IconsCrud from '../../../../common/IconsCrud.component';

const TableRow = ({
  item,
  onChange = () => {},
  editable,
  currentState,
  handleEditable = () => {},
  onSaveAction = () => {},
  handleOpen = () => {},
  crudDisabled,
}) => {
  return (
    <Fragment>
      <TableCell>
        <Stack spacing={1}>
          <Detail item={item} />
          <Info
            onChange={onChange}
            item={item}
            currentState={currentState}
            editable={editable}
          />
        </Stack>
      </TableCell>

      <TableCell width={250}>
        {item?.attributes?.attachment?.data ? (
          <Box mb={1}>
            <SliderComponent>
              {item?.attributes?.attachment?.data?.map((attachment, index) => {
                const base_url =
                  process.env.REACT_APP_BASE_URL.split('/api')[0];
                const url = _.get(attachment?.attributes, 'url');
                return (
                  <Box pr={1} key={uuidv4()}>
                    <CardMedia image={`${base_url}${url}`} />
                  </Box>
                );
              })}
            </SliderComponent>
          </Box>
        ) : (
          '-'
        )}
      </TableCell>

      <TableCell>
        <ControlTextField
          item={item}
          editable={editable}
          currentState={currentState}
          onChange={onChange}
          name='quantity'
          label='Quantity'
          visibleText={true}
        />
      </TableCell>

      <TableCell>
        <ControlAutocomplete
          item={item}
          editable={editable}
          options={AvailableType}
          currentState={currentState}
          onChange={onChange}
          name='available'
          label='Available'
          required={true}
          disableClearable={true}
        />
      </TableCell>

      <TableCell>
        <IconsCrud
          editable={editable?.[item?.id]}
          onSaveAction={() => onSaveAction(item)}
          handleEditable={() => handleEditable(item)}
          disabledEdit={
            crudDisabled ? crudDisabled : item?.disabledEdit || false
          }
          disabledDelete={
            crudDisabled ? crudDisabled : item?.disabledDelete || false
          }
          handleOpen={() => handleOpen(item)}
        />
      </TableCell>
    </Fragment>
  );
};

export default TableRow;
