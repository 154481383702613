import * as yup from 'yup';

import { API as suppliers } from '../../supplier/api';
import { API as marina } from '../../marina/api';
import dayjs from 'dayjs';

export const SimpleCreateConf = {
  schema: yup.object({
    id_supplier: yup.object().nullable().required('Supplier is required'),
    collection_delivery_date: yup
      .date()
      .nullable()
      .test('is-valid-date', 'Date is not valid', function (value) {
        return value ? dayjs(value)?.isValid() : true;
      })
      .transform((value) => {
        if (value && dayjs(value).isValid()) {
          const formattedDate = dayjs(value).format('YYYY-MM-DD');
          return new Date(formattedDate);
        }
        return null;
      })
      .required('Date is required'),
    collection_delivery_time: yup
      .date()
      .nullable()
      .required('Hour is required'),
    id_location: yup.object().nullable().required('Location in required'),
    address: yup.string().nullable(),
    time_notes: yup.string().nullable(),
    collection_delivery_notes: yup.string().nullable(),
  }),
  defaultValues: {
    id_location: null,
    collection_delivery_date: null,
    collection_delivery_time: null,
    id_supplier: null,
    address: null,
    time_notes: null,
    collection_delivery_notes: null,
  },
  fields: [
    {
      id: 'logbook_divider',
      type: 'divider',
      label: 'Logbook',
      xs: 12,
    },
    {
      id: 'collection_delivery_date',
      name: 'collection_delivery_date',
      type: 'date',
      label: 'Date',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'collection_delivery_time',
      name: 'collection_delivery_time',
      type: 'time',
      label: 'Time',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'id_supplier',
      name: 'id_supplier',
      type: 'async_autocomplete',
      label: 'Supplier',
      api: suppliers.select,
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'id_location',
      name: 'id_location',
      type: 'async_autocomplete',
      label: 'Location',
      api: marina.select,
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'address',
      name: 'address',
      type: 'text',
      label: 'Address',
      required: false,
      xs: 12,
    },
    {
      id: 'time_notes',
      name: 'time_notes',
      type: 'text',
      label: "Time's notes",
      required: false,
      xs: 12,
    },
    {
      id: 'collection_delivery_notes',
      name: 'collection_delivery_notes',
      type: 'text',
      label: 'Pick-Up Notes',
      required: false,
      xs: 12,
    },
  ],
};
