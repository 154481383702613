import React, { useEffect, useState, Fragment } from 'react';
import MUIAutocomplete from '@mui/material/Autocomplete';
import MUITextField from '@mui/material/TextField';
import MUICircularProgress from '@mui/material/CircularProgress';
import Error from './Error.component';
import { useController, useFormContext } from 'react-hook-form';
import MuiBox from '@mui/material/Box';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { fetchAPI } from '../../../api/request';
import _ from 'lodash';

const AsyncAutocomplete = ({
  name,
  required,
  url,
  api,
  label,
  disabled,
  autoFocus,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const { jwt } = useSelector((state) => state.login);
  const [loading, setLoading] = useState(false);
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const {
    field: { ref, onChange, value, ...inputProps },
  } = useController({ name: name, control: control, rules: { required } });
  const { from } = useSelector((state) => state.year);

  useEffect(() => {
    const path = _.head(_.split(api?.path, '?'));
    switch (path) {
      case '/yachts':
        break;
      default:
        setLoading(true);
        //onChange(null)
        fetchAPI(api, {}, {}, {}, jwt)
          .then((response) => {
            setLoading(false);
            setOptions(response);
          })
          .catch(() => {
            setLoading(false);
            setOptions([]);
          });
        break;
    }
  }, [jwt, api]);

  useEffect(() => {
    const path = _.head(_.split(api?.path, '?'));

    switch (path) {
      case '/yachts':
        setLoading(true);
        fetchAPI(api, {}, {}, {}, jwt)
          .then((response) => {
            setLoading(false);
            setOptions(response);
          })
          .catch(() => {
            setLoading(false);
            setOptions([]);
          });
        break;
      default:
        return;
    }
  }, [from, api, jwt,value]);

  useEffect(() => {
    if (value && !_.isEmpty(options)) {
      if (_.find(options, (item) => item.id === value.id) === undefined) {
        onChange(null);
      }
    }
  }, [value, options, onChange]);

  return (
    <MuiBox display='flex' flexDirection={'column'}>
      <MUIAutocomplete
        {...inputProps}
        ref={ref}
        open={open}
        value={value || null}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        options={options}
        getOptionLabel={(option) => option?.name || ''}
        isOptionEqualToValue={(option, value) =>
          option.name === value.name && option.id === value.id
        }
        onChange={(e, data) => onChange(data)}
        filterOptions={(options, state) => {
          // Filter out any options that have the same name as the user's input value
          const inputValue = state.inputValue.toLowerCase();
          return options.filter((option) =>
            _.includes(option.name?.toLowerCase(), inputValue.toLowerCase())
          );
        }}
        // renderOption={(_, option) => (
        //   <MuiBox
        //     key={uuidv4()}
        //     onClick={() => {
        //       setOpen(false);
        //       onChange(option);
        //     }}
        //     pl={2}
        //     pr={2}
        //     pt={1}
        //     pb={1}
        //     sx={{
        //       '&:hover': {
        //         backgroundColor: grey[200],
        //       },
        //     }}
        //   >
        //     {option?.name}
        //   </MuiBox>
        // )}
        loading={loading}
        renderInput={(params) => (
          <MUITextField
            {...params}
            label={label}
            required={required}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {loading ? (
                    <MUICircularProgress color='inherit' size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </Fragment>
              ),
            }}
            error={!!errors[name]?.message}
            autoFocus={autoFocus || false}
          />
        )}
        disabled={disabled}
        {...props}
      />
      <Error error={errors[name]?.message} />
    </MuiBox>
  );
};

AsyncAutocomplete.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
};

export default AsyncAutocomplete;
