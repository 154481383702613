export const API = {
  create: {
    path: '/provenence-lists',
    method: 'POST',
    get: null,
    auth: true,
    fallback: [],
  },
  edit: {
    path: '/provenence-lists/{{id}}',
    method: 'PUT',
    get: null,
    auth: true,
    fallback: [],
  },
  select: {
    path: '/provenence-lists?populate=%2A?pagination[page]=1&pagination[pageSize]=1000',
    method: 'GET',
    get: null,
    auth: true,
    fallback: [],
    transform: (data) =>
      data?.data?.map((item) => ({
        name: item.attributes.first_contact,
        id: item.id,
      })),
  },
  group: {
    path: '/provenence-groups?pagination[page]=1&pagination[pageSize]=1000&sort[group]=asc',
    method: 'GET',
    get: null,
    auth: true,
    fallback: [],
    transform: (data) =>
      data?.data?.map((item) => ({
        name: item.attributes.group,
        id: item.id,
      })),
  },
  sales_channel: {
    path: '/provenence-sales-channels?pagination[page]=1&pagination[pageSize]=1000&sort[sales_channel]=asc&',
    method: 'GET',
    get: null,
    auth: true,
    fallback: [],
    transform: (data) =>
      data?.data?.map((item) => ({
        name: item.attributes.sales_channel,
        id: item.id,
      })),
  },
  provenences: {
    path: '/provenences?pagination[page]=1&pagination[pageSize]=1000&sort[provenence]=asc&',
    method: 'GET',
    get: null,
    auth: true,
    fallback: [],
    transform: (data) =>
      data?.data?.map((item) => ({
        name: item.attributes.provenence,
        id: item.id,
      })),
  },
  provenences_list: {
    path: '/provenences',
    method: 'GET',
    get: null,
    auth: true,
    fallback: [],
    transform: (data) =>
      data?.data?.map((item) => ({
        name: item.attributes.provenence,
        id: item.id,
      })),
  },
};
