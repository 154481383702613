import * as React from 'react';
import MuiTableBody from '@mui/material/TableBody';
import MuiTableCell from '@mui/material/TableCell';
import MuiTableRow from '@mui/material/TableRow';
import _get from 'lodash/get';
import { grey } from '@mui/material/colors';
import { useTheme } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

const TableBody = ({
  rows,
  columns,
  onClick = () => { },
  formatValue = () => { },
  onSelectItem = () => { },
}) => {
  const theme = useTheme();
  return (
    <MuiTableBody>
      {rows.map((row, i) => {
        if (row.disabled) {
          return (
            <MuiTableRow
              role='checkbox'
              tabIndex={-1}
              key={uuidv4()}
              sx={{ backgroundColor: grey[300] }}
            >
              {columns.map(({ key, align, id, checkbox = false }, j) => (
                <MuiTableCell
                  key={uuidv4()}
                  align={align}
                  sx={{ color: grey[600] }}
                >                  
                  {formatValue(
                    key,
                    _get(row, id),
                    checkbox ? null : () => onSelectItem(row)
                  )}
                </MuiTableCell>
              ))}
            </MuiTableRow>
          );
        }

        return (
          <MuiTableRow
            hover
            role='checkbox'
            tabIndex={-1}
            key={uuidv4()}
            sx={{
              '&:nth-of-type(even)': {
                backgroundColor: theme.palette.secondary.medium_20,
                '&:hover': {
                  backgroundColor: theme.palette.secondary.medium_30,
                },
              },
              '&:nth-of-type(odd)': {
                '&:hover': {
                  backgroundColor: theme.palette.secondary.medium_30,
                },
              },
              // hide last border
              '&:last-child td, &:last-child th': {
                border: 0,
              },
            }}
          >
            {columns.map(({ key, align, id, checkbox = false }, j) => (
              <MuiTableCell
                key={uuidv4()}
                align={align}
                sx={{
                  cursor: checkbox ? row.selectable ? 'pointer' : 'default' : 'pointer',
                }}
                onClick={checkbox ? row.selectable ? () => onSelectItem(row) : null : () => onClick(row)}
              >                
                {formatValue(key, _get(row, id), () => {})}
              </MuiTableCell>
            ))}
          </MuiTableRow>
        );
      })}
    </MuiTableBody>
  );
};

export default TableBody;
