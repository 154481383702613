import { dataSlice as createDataSLice } from '../../../core/data';
import {
  combineReducers,
  createAction,
  createSelector,
} from '@reduxjs/toolkit';

import { DataConf } from '../conf/Data.conf';
import { detailSlice as createDetailSlice } from '../../../core/detail';

export const tag = 'itemsDepot';

// REDUCERS
const dataSlice = createDataSLice(`${tag}/data`, DataConf.initialState);
const detailSlice = createDetailSlice(`${tag}/detail`, {
  initialState: {
    loading: false,
    error: null,
    data: null,
    meta: null,
  },
});

export const reducer = combineReducers({
  data: dataSlice.reducer,
  detail: detailSlice.reducer,
});

// ACTIONS
export const actions = {
  fetchAllAction: createAction(`${tag}/fetchAll`),
  fetchDetailAction: createAction(`${tag}/fetchOne`),
  ...dataSlice.actions,
  ...detailSlice.actions,
};

// QUERY
export const querySelector = createSelector(
  (state) => state[tag].list,
  (list) => list
);

// DETAIL
export const detailSelector = createSelector(
  (state) => state[tag].detail,
  (detail) => detail,
);

