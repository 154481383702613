import { indigo } from '@mui/material/colors';
import MuiTypography from '@mui/material/Typography';
import { Box } from '@mui/system';

export const DetailConf = {
  fields: [
    {
      label: 'PRODUCT',
      key: 'product',
      id: 'attributes',
    },
    {
      label: 'Note',
      key: 'note',
      id: 'attributes.note',
    },
  ],

  formatValue: (key, value) => {
    switch (key) {
      case 'product':
        const product = value?.id_item?.data?.attributes;

        return (
          <Box
            sx={{
              whiteSpace: 'nowrap',
            }}
          >
            {product?.name_ITA || product?.name_ENG}
            {value?.quantity && (
              <span style={{ fontWeight: 600 }}>{` (${
                value?.quantity ? value?.quantity : '-'
              })`}</span>
            )}
          </Box>
        );
      case 'note':
        return (
          value && (
            <Box ml={1.2}>
              <MuiTypography
                variant='body2'
                sx={{
                  display: 'inline',
                  fontWeight: 'bold',
                  backgroundColor: indigo[50],
                  color: indigo[500],
                }}
              >{`Note: `}</MuiTypography>
              <MuiTypography
                variant='body2'
                sx={{
                  display: 'inline',
                  backgroundColor: indigo[50],
                  color: indigo[500],
                }}
              >
                {value}
              </MuiTypography>
            </Box>
          )
        );

      default:
        return <></>;
    }
  },
};
