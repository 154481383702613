import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MuiModal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Typography } from '@mui/material';

const Modal = ({
  label,
  sx,
  children,
  width = 900,
  variant,
  startIcon,
  openModal = false,
  setOpenModal = () => {},
}) => {
  const [open, setOpen] = useState(openModal);
  const modalContentRef = useRef(null);

  const handleOpen = useCallback(
    (event) => {
      event.stopPropagation();
      setOpen(true);
      setOpenModal(true);
    },
    [setOpenModal]
  );

  const handleClose = useCallback(
    (event) => {
      if (event.nativeEvent.type === 'click') {
        event.stopPropagation();
        setOpen(false);
        setOpenModal(false);
      }
    },
    [setOpenModal]
  );

  const handleModalContentClick = useCallback((event) => {
    event.stopPropagation();
  }, []);

  useEffect(() => {
    setOpen(openModal);
  }, [openModal]);

  return (
    <Fragment>
      {label ? (
        <Button onClick={handleOpen} variant={variant} startIcon={startIcon}>
          <Typography sx={{ ...sx }}>{label}</Typography>
        </Button>
      ) : (
        <IconButton onClick={handleOpen} sx={{ ...sx }} color='primary'>
          {startIcon}
        </IconButton>
      )}
      <MuiModal open={open} onClose={handleClose}>
        <Box
          sx={style}
          ref={modalContentRef}
          onClick={handleModalContentClick}
          width={width}
        >
          <Box display={'flex'} justifyContent={'right'}>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box p={3}>{children}</Box>
        </Box>
      </MuiModal>
    </Fragment>
  );
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 2,
};
export default Modal;
