import * as yup from 'yup';

import { API as users_nautica } from '../../users_nautica/api';
import { API as departments } from '../../departments/api'

export const CreateConf = {
  schema: {
    id_users_role: yup.object().nullable().required('Role is required'),
    extension_number: yup.string().nullable(),
    id_site: yup.object().nullable(),
    id_department: yup.object().nullable().required('Department is required'),
    subrole: yup.string().nullable(),
  },
  defaultValues: {
    id_users_role: '',
    extension_number: '',
    id_site: '',
    id_department: '',
    subrole: '',
  },
  fields: [
    {
      id: 'extension_number',
      name: 'extension_number',
      type: 'text',
      label: 'Extension Number',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: "id_users_role",
      name: "id_users_role",
      type: "async_autocomplete",
      label: "Role",
      api: users_nautica.selectUsersRole,
      required: true,
      xs: 12,
      sm: 6
    },
    {
      id: "id_site",
      name: "id_site",
      type: "async_autocomplete",
      label: "Site",
      api: users_nautica.selectUsersSite,
      required: false,
      xs: 12,
      sm: 6
    },
    {
      id: "id_department",
      name: "id_department",
      type: "async_autocomplete",
      label: "Department",
      api: departments.all,
      required: true,
      xs: 12,
      sm: 6
    },
    {
      id: "subrole",
      name: "subrole",
      type: "text",
      label: "Subrole",
      required: false,
      xs: 12,
      sm: 6
    },
  ],
};
