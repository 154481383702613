export const API = {
  select: {
    path: '/suppliers?pagination[page]=1&pagination[pageSize]=10000&sort[label]=asc',
    method: 'GET',
    get: null,
    auth: true,
    fallback: [],
    transform: (data) =>
      data?.data.map((item) => ({
        name: `${item?.attributes?.mexal_code} - ${item.attributes.label}`,
        id: item.id,
      })),
  },
};
