import React from 'react';

import { Box, Button, CardContent, Grid, Typography } from '@mui/material';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CardContent>
        <Grid container justifyContent='center' spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                maxWidth: 350,
                margin: '0 auto',
                textAlign: 'center',
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='h1' component='div'>
                    Something is wrong
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body2'>
                    The page you are looking was moved, removed, renamed, or
                    might never exist!{' '}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant='contained'
                    size='large'
                    component={Link}
                    to={'/'}
                  >
                    <HomeTwoToneIcon sx={{ fontSize: '1.3rem', mr: 0.75 }} />{' '}
                    Home
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Box>
  );
};

export default NotFoundPage;
