import React from "react";
import Table from "../table/Table.component";

const Read = ({
    data = [],
    total = 0,
    pagination,
    onChangePagination = () => { },
    sort,
    onChangeSort = () => { },
    onClick = () => { },
    columns = [],
    rowsPerPageOptions = [],
    render,
    onSelectItem = () => { },
    selected,
    toolbar,
    onClickToolbar = () => { },
}) => {

    return (
        <Table
            render={render}
            rows={data}
            columns={columns}
            total={total}
            rowsPerPageOptions={rowsPerPageOptions}
            pagination={pagination}
            onChangePagination={onChangePagination}
            sort={sort}
            onChangeSort={onChangeSort}
            onClick={onClick}
            onSelectItem={onSelectItem}            
            selected={selected}
            toolbar={toolbar}
            onClickToolbar={onClickToolbar}
        />
    )
}

export default Read