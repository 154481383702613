import React, { Fragment, useCallback, useState } from 'react';
import ConfirmDialog from '../common/Confirm.component';
import Button from '../form/components/Button.component';
import DeleteIcon from '@mui/icons-material/Delete';

const Delete = ({ disabled = false, onDelete = () => {}, children }) => {
  const [openDelete, setOpenDelete] = useState(false);

  const onClose = useCallback(() => {
    setOpenDelete(false);
  }, [setOpenDelete]);

  const onOpen = useCallback(() => {
    setOpenDelete(true);
  }, [setOpenDelete]);

  return (
    <Fragment>
      <Button
        onClick={onOpen}
        variant='outlined'
        startIcon={<DeleteIcon />}
        color='error'
        disabled={disabled}
      >
        Delete
      </Button>
      <ConfirmDialog
        open={openDelete}
        onClose={onClose}
        title='Are you sure you want to delete this?'
        description='It will be deleted permanently. You cannot undo this action.'
        cancelAction={onClose}
        confirmAction={onDelete}
      >
        {children}
      </ConfirmDialog>
    </Fragment>
  );
};

export default Delete;
