import dayjs from 'dayjs';
import Text from '../../../components/detail/components/Text.component';
import _ from 'lodash';
import { CommonDetailConf } from '../../yachts/conf/Detail.conf';
import { Grid } from '@mui/material';
import Link from '../../../components/detail/components/Link.component';
import LinkPermission from '../../../components/detail/components/LinkPermission.component';

export const DetailConf = {
  initialState: {
    loading: false,
    error: null,
    data: null,
    meta: null,
  },
  fields: [
    {
      ...CommonDetailConf,
      items: [
        {
          type: 'text',
          label: 'Yacht name',
          key: 'attributes.yacht',
          xs: 12,
        },
        {
          type: 'link',
          label: 'Web',
          key: 'attributes.url_web',
          xs: 12,
        },
        ...CommonDetailConf.items.map(
          (item) =>
            item.key !== 'attributes.yacht_name' && {
              ...item,
              key: `attributes.id_yacht.data.${item.key}`,
            }
        ),
      ],
    },
    {
      id: 'na_agent',
      type: 'section',
      label: 'NA Agent',
      items: [
        {
          type: 'text',
          label: '',
          key: 'attributes.id_agent_NA.data.attributes.id_users_permissions_user.data',
          xs: 12,
        },
      ],
    },

    {
      id: 'user_provisions',
      type: 'section',
      label: 'User Provisions',
      items: [
        {
          type: 'text',
          label: '',
          key: 'attributes.id_user_provision.data.attributes.id_users_permissions_user.data',
          xs: 12,
        },
      ],
    },

    {
      id: 'user_tech',
      type: 'section',
      label: 'User Tech',
      items: [
        {
          type: 'text',
          label: '',
          key: 'attributes.id_user_tech.data.attributes.id_users_permissions_user.data',
          xs: 12,
        },
      ],
    },
    {
      id: 'attendance',
      type: 'section',
      label: 'Attendance',
      items: [
        {
          type: 'boolean',
          label: 'Mexal validation',
          key: 'attributes.mexal_validation',
          xs: 12,
          sm: 6,
          md: 4,
        },
        {
          type: 'text',
          label: 'Mexal Validation Date',
          key: 'attributes.registration_date',
          xs: 12,
          sm: 6,
          md: 4,
        },
        {
          type: 'boolean',
          label: 'Central agent',
          key: 'attributes.is_central_agent',
          xs: 12,
          sm: 6,
          md: 4,
        },
        {
          type: 'text',
          label: 'Note',
          key: 'attributes.note',
          xs: 12,
        },
      ],
    },
    {
      id: 'procedures',
      type: 'section',
      label: 'Procedures',
      items: [
        {
          type: 'text',
          label: 'Formalities',
          key: 'attributes.formalities',
          xs: 12,
          sm: 6,
          md: 4,
        },
        {
          type: 'text',
          label: 'ISPS',
          key: 'attributes.isps',
          xs: 12,
          sm: 6,
          md: 4,
        },
        {
          type: 'text',
          label: 'Garbage',
          key: 'attributes.garbage',
          xs: 12,
          sm: 6,
          md: 4,
        },
      ],
    },
    {
      id: 'id_provenence_list',
      type: 'section',
      label: 'Provenance',
      items: [
        {
          type: 'text',
          label: 'Category',
          key: 'attributes.id_provenence_list.data.attributes.provenence_group.data.attributes.group',
          xs: 12,
          sm: 6,
          md: 4,
        },
        {
          type: 'text',
          label: 'Sales channel',
          key: 'attributes.id_provenence_list.data.attributes.provenence_sales_channel.data.attributes.sales_channel',
          xs: 12,
          sm: 6,
          md: 4,
        },
        {
          type: 'text',
          label: 'Provenance',
          key: 'attributes.id_provenence_list.data.attributes.provenence.data.attributes.provenence',
          xs: 12,
          sm: 6,
          md: 4,
        },
        {
          type: 'text',
          label: '',
          key: 'attributes.id_provenence_list.data.attributes',
          xs: 12,
        },
        {
          type: 'text',
          label: 'Note',
          key: 'attributes.id_provenence_list.data.attributes.note',
          xs: 12,
          sm: 6,
          md: 4,
        },
      ],
    },
  ],
  formatValue: (key, value) => {
    switch (key) {
      case 'attributes.yacht':
        return (
          <Grid container>
            <Grid item xs={12} sm={6} md={3}>
              <Text
                label='Yacht Name'
                value={
                  <LinkPermission
                    to={`/yachts/${value?.id}`}
                    value={value?.yacht_name}
                    page='YACHTS'
                  />
                }
              />
            </Grid>
            {value?.url_web && (
              <Grid item xs={12} sm={6} md={3}>
                <Link label='Web' value={value?.url_web} />
              </Grid>
            )}
          </Grid>
        );

      case 'attributes.registration_date':
        return (
          <Text
            label='Mexal Validation Date'
            value={dayjs(value).format('DD-MM-YYYY')}
          />
        );

      case 'attributes.id_yacht.data.attributes.draft':
        return (
          <Text label='Draft' value={_.isEmpty(value) ? `${value} m` : '-'} />
        );

      case 'attributes.id_yacht.data.attributes.loa':
        return (
          <Text label='Loa' value={_.isEmpty(value) ? `${value} m` : '-'} />
        );

      case 'attributes.id_yacht.data.attributes.beam':
        return (
          <Text label='Beam' value={_.isEmpty(value) ? `${value} m` : '-'} />
        );

      case 'attributes.id_user_tech.data.attributes.id_users_permissions_user.data':
      case 'attributes.id_user_provision.data.attributes.id_users_permissions_user.data':
      case 'attributes.id_agent_NA.data.attributes.id_users_permissions_user.data':
        return (
          <Text
            label='Name'
            value={
              <LinkPermission
                to={`/contacts/${value?.id}`}
                value={`${value?.attributes?.name} ${value?.attributes?.surname}`}
                page='CONTACTS_NAUTICA'
              />
            }
          />
        );

      case 'attributes.id_provenence_list.data.attributes':
        const user = !_.isEmpty(value.users_nauticas.data)
          ? value.users_nauticas.data?.attributes?.id_users_permissions_user
            ?.data?.attributes
          : null;

        return (
          <Grid container>
            <Grid item xs={12} sm={6} md={4}>
              <Text label='First Contact' value={value.first_contact} />
            </Grid>
            {value.first_contact === 'USER' && (
              <Grid item xs={12} sm={6}>
                <Text
                  label='User'
                  value={user ? `${user?.name} ${user?.surname}` : '-'}
                />
              </Grid>
            )}
          </Grid>
        );
      default:
        return null;
    }
  },
};
