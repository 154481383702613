import { useCallback } from "react"
import { useDispatch } from "react-redux"

export const useSegmentedControls = (valueChange) => {

    const dispatch = useDispatch()

    const handleChange = useCallback((value) => {
        dispatch(valueChange(value))
    }, [dispatch, valueChange])

    return {
        handleChange,        
    }
}