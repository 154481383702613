import React, { useCallback, useEffect, useState } from 'react';
import { Container, Stack, Paper } from '@mui/material';
import Back from '../../components/common/Back.component';
import Loading from '../../components/common/Loading.component';
import AddView from '../../views/Add.view';
import { useSelector } from 'react-redux';
import Alert from '../../components/common/Alert.component';
import { fetchAPI } from '../../api/request';
import { API as departments } from '../../module/departments/api';
import _ from 'lodash';

const CreateTemplate = ({
  module: { tag, create, query, code },
  children,
  actionsView,
  childrenView,
}) => {
  const { jwt } = useSelector((state) => state.login);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [idDepartment, setIdDepartment] = useState(null);
  const [error, setError] = useState(false);

  const onError = useCallback(() => {
    setSuccess(false);
    setLoading(false);
    setError(true);
    // eslint-disable-next-line
  }, []);

  const onSuccess = useCallback(() => {
    setSuccess(true);
    setLoading(false);
    setError(false);
  }, [setLoading, setSuccess]);

  const onSubmit = useCallback(
    (values) => {
      setError(false);
      setSuccess(false);
      setLoading(true);
      fetchAPI(
        query.create.settings,
        {},
        '',
        code ? { ...values, id_department: idDepartment } : { ...values },
        jwt
      )
        .then(onSuccess)
        .catch(onError);
    },
    [setLoading, jwt, onError, onSuccess, query, idDepartment, code]
  );

  useEffect(() => {
    if (code)
      fetchAPI(departments.select, { code: code }, '', null, jwt)
        .then((response) => {
          setIdDepartment(_.head(response)?.id);
        })
        .catch(onError);
    // eslint-disable-next-line
  }, [code]);

  return (
    <Container disableGutters={true} maxWidth={false} sx={{ height: '100%' }}>
      <Stack spacing={2} justifyContent='center' alignItems='center' width={1}>
        <Back title={tag} />
        {loading && <Loading />}
        {success && <Alert severity='success'>{'Success: Add'}</Alert>}
        {error && <Alert severity='error'>{'An error has occurred'}</Alert>}

        <Paper variant='outlined' sx={{ padding: 2, width: '100%' }}>
          <AddView
            conf={create.conf}
            onAddSubmit={onSubmit}
            onAddError={onError}
            onAddSuccess={onSuccess}
            disabled={loading || success}
            actions={actionsView}
          >
            {childrenView}
          </AddView>
        </Paper>
        {children}
      </Stack>
    </Container>
  );
};

export default CreateTemplate;
