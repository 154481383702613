import { Box, Container, Paper, Stack } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchAPI } from '../../../api/request';
import Alert from '../../../components/common/Alert.component';
import Back from '../../../components/common/Back.component';
import Loading from '../../../components/common/Loading.component';
import AddView from '../../../views/Add.view';
import provisions from '../../../module/provisions';
import { API as departments } from '../../../module/departments/api';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import YachtForm from '../../../components/yacht/YachtForm.component';
import AgentNAForm from '../../../components/agentNA/AgentNAForm.component';

const ProvisionsAddPage = () => {
  const navigate = useNavigate();
  const { code } = provisions;
  const { jwt, user } = useSelector((state) => state.login);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [idDepartment, setIdDepartment] = useState(null);
  const [error, setError] = useState(false);

  const onError = useCallback(() => {
    setSuccess(false);
    setLoading(false);
    setError(true);
    // eslint-disable-next-line
  }, []);

  const onSuccess = useCallback(
    (provision) => {
      fetchAPI(
        provisions.query.create.list,
        {},
        '',
        {
          id_operation_list: provision.data.id,
        },
        jwt
      )
        .then((list) => {
          setError(false);
          setSuccess(true);
          setLoading(false);
          navigate(
            `/department/provisions/${provision.data.id}/list/${list.data.id}`,
            { replace: true, state: { id_operation: `${provision.data.id}` } }
          );
        })
        .catch(onError);
    },
    [setSuccess, setLoading, jwt, onError, navigate]
  );

  const onSubmit = useCallback(
    (values) => {
      setSuccess(false);
      setLoading(true);
      setError(false);

      fetchAPI(
        provisions.query.create.settings,
        {},
        '',
        { ...values, id_department: idDepartment, id_user_last_updated: user?.id_users_nautica?.id || null },
        jwt
      )
        .then((response) => onSuccess(response))
        .catch(onError);
    },
    [setLoading, jwt, onError, onSuccess, idDepartment, user]
  );

  useEffect(() => {
    fetchAPI(departments.select, { code: code }, '', null, jwt)
      .then((response) => {
        setIdDepartment(_.head(response)?.id);
      })
      .catch(onError);
  }, [code, jwt, onError]);

  return (
    <Container disableGutters={true} maxWidth={false} sx={{ height: '100%' }}>
      <Stack
        spacing={2}
        sx={{ padding: 2 }}
        justifyContent='center'
        alignItems='center'
      >
        <Back title={provisions.tag} />
        <Container>
          {loading && <Loading />}
          {success && <Alert severity='success'>{'Success: Add'}</Alert>}
          {error && <Alert severity='error'>{'An error has occurred'}</Alert>}

          <Paper variant='outlined'>
            <Box sx={{ padding: 2 }}>
              {idDepartment && (
                <AddView
                  conf={provisions.create.conf}
                  disabled={loading}
                  onAddSubmit={onSubmit}
                  onAddError={onError}
                  onAddSuccess={onSuccess}
                  actions={
                    <Stack spacing={1}>
                      <YachtForm />
                      <AgentNAForm />
                    </Stack>
                  }
                />
              )}
            </Box>
          </Paper>
        </Container>
      </Stack>
    </Container>
  );
};

export default ProvisionsAddPage;
