export const DetailConf = {
  initialState: {
    loading: false,
    error: null,
    data: null,
    meta: null,
  },
  fields: [

    {
      id: 'item',
      type: 'section',
      label: 'Product',
      items: [
        {
          type: 'text',
          label: 'Category',
          key: 'attributes.category',
          xs: 12,
        },
        {
          type: 'text',
          label: 'category',
          key: 'attributes.name_ITA',
          xs: 12,
          sm: 6
        },
        {
          type: 'text',
          label: 'category',
          key: 'attributes.item_type_ITA',
          xs: 12,
          sm: 6
        },
        {
          type: 'text',
          label: 'category',
          key: 'attributes.name_ENG',
          xs: 12,
          sm: 6
        },
        {
          type: 'text',
          label: 'category',
          key: 'attributes.item_type_ENG',
          xs: 12,
          sm: 6
        },

      ],
    },

  ],
  formatValue: (key, value) => null
};
