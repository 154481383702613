// CONF
import { CreateConf } from './conf/Create.conf';

// API
import { API } from './api';
// SELECTOR
import { detailSelector, tag } from './slice';
import { DetailConf } from './conf/Detail.conf';

const users_nautica = {
	tag,
	query: {
		create: {
			settings: API.create,
		},
		delete: {
			settings: API.delete,
		},
	},

	create: {
		conf: CreateConf,
	},
	detail: {
		conf: DetailConf,
		selector: {
			data: detailSelector,
		},
		edit: {
			to: 'edit',
		},
	},
	remove: {
		selector: {
			data: detailSelector,
		},
	},
};

export default users_nautica;

export { API };
