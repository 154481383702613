import React from 'react';
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  useTheme,
  Typography,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import _get from 'lodash/get';

const TableItems = ({ data, minWidth = 700, module }) => {
  const theme = useTheme();
  const {
    list: {
      conf: { columns, render },
    },
  } = module;

  return (
    <TableContainer component={Paper} sx={{ maxHeight: 450, marginTop: -2 }}>
      <Table sx={{ minWidth: minWidth }} stickyHeader>
        <TableHead>
          <TableRow>
            {columns?.map((item) => (
              <TableCell
                key={uuidv4()}
                sx={{
                  backgroundColor: theme.palette.secondary.medium,
                  padding: 3,
                }}
              >
                <Typography color='white' fontWeight={'bold'}>
                  {item?.label}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((item) => {
            return (
              <TableRow
                key={uuidv4()}
                sx={{
                  '&:nth-of-type(even)': {
                    backgroundColor: theme.palette.secondary.medium_20,
                  },
                }}
              >
                {columns?.map(({ key, id, width }) => (
                  <TableCell key={uuidv4()} width={width || 'auto'}>
                    {render?.formatValue(key, _get(item, id), () => {})}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableItems;
