import {
    Box,
    TableCell,
    TableRow,
    TableSortLabel,
    Typography,
  } from '@mui/material';
  import React from 'react';
  import { v4 as uuidv4 } from 'uuid';
  import { visuallyHidden } from '@mui/utils';
  import { useTheme } from '@emotion/react';
  
  const TableRowSort = ({
    columns,
    onSort = () => {},
    order,
    orderBy,
    backgroundColor,
    colorText = '#fff',
    sx,
  }) => {
    const theme = useTheme();
  
    return (
      <TableRow>
        {columns?.map((column) => (
          <TableCell
            key={uuidv4()}
            variant='head'
            align={'left'}
            sx={{
              backgroundColor: backgroundColor || theme.palette.secondary.medium,
              width: column.width,
              ...sx,
            }}
            sortDirection={
              column?.sortable && orderBy === column?.key ? order : false
            }
          >
            {column?.sortable ? (
              <TableSortLabel
                active={orderBy === column?.key}
                direction={orderBy === column?.key ? order : 'asc'}
                onClick={() => {
                  onSort(column?.key);
                }}
              >
                <Typography
                  variant='body1'
                  color={colorText}
                  textTransform={'uppercase'}
                  fontWeight='bold'
                >
                  {column.label}
                </Typography>
                {orderBy === column?.key ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <Typography
                variant='body1'
                color={colorText}
                fontWeight='bold'
                textTransform={'uppercase'}
              >
                {column?.label}
              </Typography>
            )}
          </TableCell>
        ))}
      </TableRow>
    );
  };
  
  export default TableRowSort;
  