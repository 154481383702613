import { dataSlice as createDataSLice } from '../../../core/data';
import {
  combineReducers,
  createAction,
  createSelector,
} from '@reduxjs/toolkit';
import _ from 'lodash';
import { DataConf } from '../conf/Data.conf';
import { DetailConf } from '../conf/Detail.conf';
import { detailSlice as createDetailSlice } from '../../../core/detail';

import dayjs from 'dayjs';

export const tag = 'technical_work';

// REDUCERS
const dataSlice = createDataSLice(`${tag}/data`, DataConf.initialState);
const detailSlice = createDetailSlice(`${tag}/detail`, DetailConf.initialState);

export const reducer = combineReducers({
  data: dataSlice.reducer,
  detail: detailSlice.reducer,
});

// ACTIONS
export const actions = {
  fetchDetailAction: createAction(`${tag}/fetchOne`),
  ...dataSlice.actions,
  ...detailSlice.actions,
};

// EDIT
export const editSelector = createSelector(
  (state) => state[tag].detail,
  ({ data, loading, error }) => {
    const work_note = _.get(data, 'attributes.work_note');
    const time_in = _.get(data, 'attributes.time_in');
    const time_out = _.get(data, 'attributes.time_out');
    const work_date = _.get(data, 'attributes.work_date');

    return {
      loading,
      error,
      data: data
        ? {
            work_date: work_date ? work_date : dayjs(),
            yacht_contact: _.get(data, 'attributes.yacht_contact'),
            location: _.get(data, 'attributes.location'),
            work_description: _.get(data, 'attributes.work_description'),
            work_note: work_note ? work_note : '',
            time_in: time_in ? dayjs(`${work_date}T${time_in}`) : null,
            time_out: time_out ? dayjs(`${work_date}T${time_out}`) : null,
            users_technicians: _.get(data, 'attributes.users_technicians'),
            report_number: _.get(data, 'attributes.report_number'),
            na_tech_signature: _.get(data, 'attributes.na_tech_signature.data'),
            yacht_crew_signature: _.get(
              data,
              'attributes.yacht_crew_signature.data'
            ),
          }
        : null,
    };
  }
);
