import MuiTypography from '@mui/material/Typography';
import moment from 'moment/moment';
import CalendarIcon from '@mui/icons-material/CalendarMonth';
import { Box, Stack } from '@mui/material';
import MuiChip from '@mui/material/Chip';
import _ from 'lodash';
import { RegistrationStatus } from './Data.conf';

export const ListConf = {
  initialState: {
    pagination: {
      page: 1,
      pageSize: 100,
    },
    sort: {
      by: 'yacht_name',
      direction: 'asc',
    },
  },
  rowsPerPageOptions: [25, 50, 100],
  columns: [
    {
      id: 'attributes.yacht_name',
      key: 'yacht_name',
      sort_key: 'yacht_name',
      sortable: true,
      label: 'Yacht Name',
    },
    {
      id: 'attributes.yacht_type',
      key: 'yacht_type',
      sort_key: 'yacht_type',
      sortable: true,
      label: 'Yacht Type',
    },
    {
      id: 'attributes.registry_type',
      key: 'registry_type',
      sort_key: 'registry_type',
      sortable: true,
      label: 'Registry',
    },
    {
      id: 'attributes.port_of_registry',
      key: 'attributes.port_of_registry',
      sort_key: 'port_of_registry',
      sortable: true,
      label: 'Port of Registry',
    },
    {
      id: 'attributes.id_flag.data.attributes.locality_flag',
      key: 'locality_flag',
      sort_key: 'id_flag.locality_flag',
      sortable: true,
      label: 'Flag',
    },
    {
      id: 'attributes.date_submit_signature',
      key: 'date_submit_signature',
      sort_key: 'date_submit_signature',
      sortable: true,
      label: 'Registration date',
    },
    {
      id: 'attributes.registration_status',
      key: 'registration_status',
      sort_key: 'registration_status',
      sortable: true,
      label: 'REGISTRATION STATUS',
    },
  ],
  render: {
    formatHead: (key, value) => {
      switch (key) {
        default:
          return (
            <MuiTypography
              variant='body1'
              sx={{ fontWeight: 'bold', color: 'white' }}
            >
              {value.toUpperCase()}
            </MuiTypography>
          );
      }
    },
    formatValue: (key, value, onClick) => {
      switch (key) {
        case 'date_submit_signature':
          return (
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <CalendarIcon color='action' />
              <MuiTypography
                sx={{ fontWeight: 'bold', height: 1 }}
                variant='body1'
                onClick={onClick}
              >
                {value ? moment(value).format('DD/MM/YYYY') : '-'}
              </MuiTypography>
            </Stack>
          );
        case 'yacht_name': {
          return (
            <MuiTypography
              sx={{ fontWeight: 'bold', height: 1 }}
              variant='body1'
              onClick={onClick}
            >
              {value || '-'}
            </MuiTypography>
          );
        }
        case 'registration_status': {
          const color = _.find(
            RegistrationStatus,
            (item) => item.label.toUpperCase() === value.toUpperCase()
          );

          return (
            <Box onClick={onClick}>
              {value && (
                <MuiChip
                  label={value}
                  color={color?.color}
                  sx={{
                    color: color?.color ? '#fff' : color?.textHex,
                    backgroundColor: color?.hex,
                    opacity: 0.8,
                    fontWeight: 500,
                  }}
                />
                // <MuiChip
                //   label={value}
                //   color={
                //     _.find(
                //       RegistrationStatus,
                //       (item) => item.label.toUpperCase() === value.toUpperCase()
                //     ).color
                //   }
                //   sx={{ opacity: 0.8 }}
                // />
              )}
            </Box>
          );
        }
        default:
          return (
            <MuiTypography variant='body1' onClick={onClick}>
              {value ? value : '-'}
            </MuiTypography>
          );
      }
    },
  },
};
