import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AuthRoute from './AuthRoute';
import TemplateLogin from '../pages/templates/Login.template';
import TemplateList from '../pages/templates/List.template';
import TemplateCreate from '../pages/templates/Create.template';
import TemplateEdit from '../pages/templates/Edit.template';
import TemplateDelete from '../pages/templates/Delete.template';
import TemplateDetail from '../pages/templates/Detail.template';
import NotFoundPage from '../pages/NotFound.page';
import booking from '../module/booking';
import yacht from '../module/yachts';
import attendances from '../module/attendances';
import BookingsPage from '../pages/booking/Bookings.page';
import user from '../module/login';
import operations from '../module/operations';
import concierge from '../module/concierge';
import technical from '../module/technical';
import me from '../module/me';

import provisions from '../module/provisions';
import depot from '../module/depot';
import shipping from '../module/shipping';
import interiors from '../module/interiors';
import logbook from '../module/logbook';
import agency from '../module/agency';

import ConciergeAddPage from '../pages/concierge/add/ConciergeAdd.page';
import AgencyAddPage from '../pages/agency/add/AgencyAdd.page';
import ConciergeEditPage from '../pages/concierge/edit/ConciergeEdit.page';
import AgencyEditPage from '../pages/agency/edit/AgencyEdit.page';
import TechnicalAddPage from '../pages/technical/add/TechnicalAdd.page';
import ProvisionsAddPage from '../pages/provisions/add/ProvisionsAdd.page';
import itemsProvisions from '../module/listItemsProvisions';
import MainLayout from '../layout/mainLayout';
import AttendancesAddPage from '../pages/attendances/add/AttendancesAdd.page';
import AttendacesEditPage from '../pages/attendances/edit/AttendancesEdit.page';
import YachtEditPage from '../pages/yachts/edit/YachtEditPage';
import ContactsDetailPage from '../pages/contacts/detail/ContactsDetail.page';
import ItemsProvisionsListPage from '../pages/provisions/itemsList/ItemsProvisionsList.page';
import ContactsListPage from '../pages/contacts/list/ContactsList.page';
import YachtDetailPage from '../pages/yachts/detail/YachtDetail.page';
import TechnicalWorkAddPage from '../pages/technicalWork/add/TechnicalWorkAdd.page';
import TechnicalWorkEditPage from '../pages/technicalWork/edit/TechnicalWorkEdit.page';
import YachtForm from '../components/yacht/YachtForm.component';
import AgentNAForm from '../components/agentNA/AgentNAForm.component';
import { Stack } from '@mui/system';
import { API as yachts } from '../module/yachts/api';
import OperationsList from '../pages/operationsList/OperationsList.page';
// import ChangePasswordPage from '../pages/changePassword/ChangePassword.page';
import DetailMePage from '../pages/me/DetailMe.page';
// import changePassword from '../module/changePassword';
// import forgotPassword from '../module/forgotPassword';
// import GuestTemplate from '../pages/templates/Guest.template';
// import resetPassword from '../module/resetPassword';
import AgencyDetailPage from '../pages/agency/detail/AgencyDetail.page';
import ConciergeDetailPage from '../pages/concierge/detail/ConciergeDetail.page';
import TechnicalDetailPage from '../pages/technical/detail/TechnicalDetail.page';
import TechnicalWorkDetailPage from '../pages/technicalWork/detail/TechnicalWorkDetail.page';
import technicalWork from '../module/technicalWork';
import LogbookEditPage from '../pages/logbook/edit/LogbookEdit.page';
import { Box } from '@mui/material';
import DepotAddPage from '../pages/depot/add/DepotAdd.page';
import ItemsDepotListPage from '../pages/depot/itemsList/ItemsDepotList.page';
import itemsDepot from '../module/listItemsDepot';
import InteriorsAddPage from '../pages/interiors/add/InteriorsAdd.page';
import ItemsInteriorsListPage from '../pages/interiors/itemsList/ItemsInteriorsList.page';
import itemsInteriors from '../module/listItemsInteriors';
import ShippingAddPage from '../pages/shipping/add/ShippingAdd.page';
import ShippingEditPage from '../pages/shipping/edit/ShippingEdit.page';
import ShippingDetailPage from '../pages/shipping/detail/ShippingDetail.page';
import BookingEditPage from '../pages/booking/edit/BookingsEdit.page';
import LogbookAddPage from '../pages/logbook/add/LogbookAdd.page';
import ContactsAddPage from '../pages/contacts/add/ContactsAdd.page';
import ContactsEditPage from '../pages/contacts/edit/ContactsEdit.page';
import UserNauticaForm from '../components/logbook/UserNauticaForm.component';
import DepartmentForm from '../components/logbook/DepartmentForm.component';
import contacts from '../module/contacts';

const Router = () => {
  return (
    <Routes>
      <Route
        path='/'
        element={
          <TemplateLogin
            tag={user.tag}
            login={user.login}
            query={user.query}
            title='Hi, Welcome Back'
            subTitle='Log in to your account'
          >
            {/* <Link to='forgot-password' style={{ fontSize: 12 }}>
              Forgot Password?
            </Link> */}
          </TemplateLogin>
        }
      />
      {/* Forgot Password */}
      {/* <Route
        path='/forgot-password'
        element={
          <GuestTemplate
            module={forgotPassword}
            title='Forgot Password'
            subTitle='Enter the email to receive the password reset link.'
            labelButton='Send'
          />
        }
      /> */}

      {/* Reset Password */}
      {/* <Route
        path='/reset-password?'
        element={
          <GuestTemplate
            module={resetPassword}
            title='Reset Password'
            subTitle='Enter the new password for your account.'
            labelButton='Reset'
          />
        }
      /> */}

      <Route
        element={
          <AuthRoute>
            <MainLayout />
          </AuthRoute>
        }
      >
        {/* Yacht */}
        <Route path='/yachts' element={<TemplateList module={yacht} />} />
        <Route path='/yachts/add' element={<TemplateCreate module={yacht} />} />
        <Route path='/yachts/:id' element={<YachtDetailPage module={yacht} />} />

        <Route
          path='/yachts/:id/edit'
          element={
            <YachtEditPage
              tag={yacht.tag}
              edit={yacht.edit}
              query={yacht.query}
            />
          }
        />

        {/* Booking */}
        <Route path='/booking' element={<BookingsPage module={booking} />} />
        <Route
          path='/booking/add'
          element={
            <TemplateCreate
              module={booking}
              actionsView={
                <Stack spacing={1}>
                  <YachtForm api={yachts.selectWithAttendances} />
                  <AgentNAForm />
                </Stack>
              }
            />
          }
        />
        <Route
          path='/booking/:id'
          element={
            <TemplateDetail
              module={booking}
              deleteView={<TemplateDelete module={booking} />}
            />
          }
        />
        <Route
          path='/booking/:id/edit'
          element={
            <BookingEditPage
              module={booking}
              actions={
                <Stack spacing={1}>
                  <YachtForm api={yachts.selectWithAttendances} />
                  <AgentNAForm />
                </Stack>
              }
            />
          }
        />


        {/* Logbook */}
        <Route path='/logbook' element={<TemplateList module={logbook} />} />
        <Route
          path='/logbook/:id'
          element={
            <TemplateDetail
              module={logbook}
              deleteView={
                <TemplateDelete module={logbook}>
                  <Box mt={1}>
                    Notice! Items list potentially may not be deleted.{' '}
                  </Box>
                </TemplateDelete>
              }
            />
          }
        />
        <Route
          path='/logbook/add'
          element={
            <LogbookAddPage
              module={logbook}
              actionsView={
                <Stack spacing={1}>
                  <YachtForm api={yachts.selectWithAttendances} />
                  <AgentNAForm />
                  <UserNauticaForm />
                  <DepartmentForm />
                </Stack>
              }
            />
          }
        />
        <Route
          path='/logbook/:id/edit'
          element={<LogbookEditPage module={logbook} />}
        />

        {/* Logbook */}
        <Route path='/logbook' element={<TemplateList module={logbook} />} />
        <Route
          path='/logbook/:id'
          element={
            <TemplateDetail
              module={logbook}
              deleteView={
                <TemplateDelete module={logbook}>
                  <Box mt={1}>
                    Notice! Items list potentially may not be deleted.{' '}
                  </Box>
                </TemplateDelete>
              }
            />
          }
        />
        <Route
          path='/logbook/add'
          element={
            <TemplateCreate
              module={logbook}
              actionsView={
                <Stack spacing={1}>
                  <YachtForm api={yachts.selectWithAttendances} />
                  <AgentNAForm />
                </Stack>
              }
            />
          }
        />
        <Route
          path='/logbook/:id/edit'
          element={<LogbookEditPage module={logbook} />}
        />

        {/* Operation List */}
        <Route
          path='department/operations-list'
          element={<OperationsList module={operations} />}
        />
        {/* <Route
          path='/department/operations-list/:id'
          element={<TemplateDetail module={operations} />}
        /> */}

        {/* Agency */}
        <Route
          path='/department/agency'
          element={<TemplateList module={agency} />}
        />
        <Route path='/department/agency/:id' element={<AgencyDetailPage module={agency} />} />
        <Route path='/department/agency/add' element={<AgencyAddPage module={agency}/>} />
        <Route
          path='/department/agency/:id/edit'
          element={<AgencyEditPage module={agency} />}
        />

        {/* Concierge */}
        <Route
          path='/department/concierge'
          element={<TemplateList module={concierge} />}
        />
        <Route
          path='/department/concierge/:id'
          element={<ConciergeDetailPage module={concierge} />}
        />
        <Route
          path='/department/concierge/add'
          element={<ConciergeAddPage module={concierge}/>}
        />
        <Route
          path='/department/concierge/:id/edit'
          element={<ConciergeEditPage module={concierge}/>}
        />

        {/* Depot */}
        <Route
          path='/department/depot'
          element={<TemplateList module={depot} />}
        />
        <Route
          path='/department/depot/:id'
          element={
            <TemplateDetail
              module={depot}
              deleteView={
                <TemplateDelete module={depot}>
                  <Box mt={1}>
                    The list of items and linked logbooks will be deleted.
                  </Box>
                </TemplateDelete>
              }
            />
          }
        />
        <Route
          path='/department/depot/add'
          element={<DepotAddPage module={depot} />}
        />
        <Route
          path='/department/depot/:id/edit'
          element={<TemplateEdit module={depot} />}
        />

        <Route
          path='/department/depot/:id/edit'
          element={<TemplateEdit module={depot} />}
        />
        <Route
          path='/department/depot/:id_operation/list/:id'
          element={<ItemsDepotListPage module={itemsDepot} />}
        />

        {/* Interiors */}
        <Route
          path='/department/interiors'
          element={<TemplateList module={interiors} />}
        />
        <Route
          path='/department/interiors/:id'
          element={
            <TemplateDetail
              module={interiors}
              deleteView={
                <TemplateDelete module={interiors}>
                  <Box mt={1}>
                    The list of items and linked logbooks will be deleted.
                  </Box>
                </TemplateDelete>
              }
            />
          }
        />
        <Route
          path='/department/interiors/add'
          element={<InteriorsAddPage module={interiors} />}
        />
        <Route
          path='/department/interiors/:id/edit'
          element={<TemplateEdit module={interiors} />}
        />

        <Route
          path='/department/interiors/:id_operation/list/:id'
          element={<ItemsInteriorsListPage module={itemsInteriors} />}
        />

        {/* Provisions */}
        <Route
          path='/department/provisions'
          element={<TemplateList module={provisions} />}
        />
        <Route
          path='/department/provisions/:id'
          element={
            <TemplateDetail
              module={provisions}
              deleteView={
                <TemplateDelete module={provisions}>
                  <Box mt={1}>
                    The list of items and linked logbooks will be deleted.
                  </Box>
                </TemplateDelete>
              }
            />
          }
        />
        <Route
          path='/department/provisions/add'
          element={<ProvisionsAddPage module={provisions} />}
        />
        <Route
          path='/department/provisions/:id/edit'
          element={<TemplateEdit module={provisions} />}
        />
        <Route
          path='/department/provisions/:id_operation/list/:id'
          element={<ItemsProvisionsListPage module={itemsProvisions} />}
        />

        {/* Shipping */}
        <Route
          path='/department/shipping'
          element={<TemplateList module={shipping} />}
        />
        <Route path='/department/shipping/add' element={<ShippingAddPage module={shipping}/>} />
        <Route
          path='/department/shipping/:id'
          element={
            <ShippingDetailPage module={shipping} />
          }
        />

        <Route
          path='/department/shipping/:id/edit'
          element={<ShippingEditPage module={shipping}/>}
        />

        {/* Technical */}
        <Route
          path='/department/technical'
          element={<TemplateList module={technical} />}
        />
        <Route
          path='/department/technical/:id'
          element={
            <TechnicalDetailPage module={technical}>
              <TechnicalWorkDetailPage
                module={technicalWork}
                technicalModule={technical}
              />
            </TechnicalDetailPage>
          }
        />
        <Route
          path='/department/technical/add'
          element={<TechnicalAddPage module={technical} />}
        />
        <Route
          path='/department/technical/:id/edit'
          element={<TemplateEdit module={technical} />}
        />
        <Route
          path='/department/technical/:id/technicalWork/add'
          element={<TechnicalWorkAddPage />}
        />
        <Route
          path='/department/technical/:id_operation/technicalWork/:id/edit'
          element={<TechnicalWorkEditPage />}
        />

        {/* Contacts */}
        <Route path='/contacts' element={<ContactsListPage module={contacts}/>} />
        <Route path='/contacts/add' element={<ContactsAddPage module={contacts} />} />
        <Route path='/contacts/:id' element={<ContactsDetailPage module={contacts} />} />
        <Route path='/contacts/:id/edit' element={<ContactsEditPage  module={contacts}/>} />

        {/* Attendances */}
        <Route
          path='/attendances'
          element={<TemplateList module={attendances} />}
        />
        <Route path='/attendances/add' element={<AttendancesAddPage module={attendances}/>} />
        <Route
          path='/attendances/:id'
          element={<TemplateDetail module={attendances} />}
        />
        <Route
          path='/attendances/:id/edit'
          element={<AttendacesEditPage module={attendances} />}
        />

        {/* NotFound */}
        <Route path='*' element={<NotFoundPage />} />

        {/* Change Password */}
        {/* <Route
          path='/change-password'
          element={<ChangePasswordPage module={changePassword} />}
        /> */}

        {/* Profile */}
        <Route path='/profile' element={<DetailMePage module={me} />} />
        <Route path='/404' element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};

export default Router;
