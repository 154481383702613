export const API = {
	contacts: {
		path: '/users',
		method: 'GET',
		get: null,
		auth: true,
		fallback: null,
	},
	contactsNotBlocked: {
		path: '/users?filters[blocked]=false',
		method: 'GET',
		get: null,
		auth: true,
		fallback: null,
	},

	contact: {
		path: '/users/{{id}}',
		method: 'GET',
		get: null,
		auth: true,
		fallback: null,
		transform: (data) => {
			return { data: data };
		},
	},
	create: {
		path: '/users-nauticas',
		method: 'POST',
		get: null,
		auth: true,
		fallback: null,
		adapter: (body) => {
			return {
				data: {
					username: body?.username,
					email: body?.email,
					surname: body?.surname,
					name: body?.name,
					phone_number: body?.phone_number,
					extension_number: body?.extension_number,
					id_users_role: body?.id_users_role,
					id_site: body?.id_site,
				},
			};
		},
	},

	update: {
		path: '/users/{{id}}',
		method: 'PUT',
		get: null,
		auth: true,
		fallback: null,
		adapter: (body) => {
			return {
				data: {
					username: body?.username,
					email: body?.email,
					surname: body?.surname,
					name: body?.name,
					phone_number: body?.phone_number,
					extension_number: body?.extension_number,
					id_users_role: body?.id_users_role,
					id_site: body?.id_site,
					password: body?.password,
				},
			};
		},
	},
	delete: {
		path: '/users/{{id}}',
		method: 'DELETE',
		get: null,
		auth: true,
		fallback: null,
	},
	yachts: {
		path: '/yachts',
		method: 'GET',
		get: null,
		auth: true,
		fallback: null,
		transform: (data) =>
			data?.data?.map((item) => ({
				name: item.attributes.yacht_name,
				id: item.id,
			})),
	},
	usersCSV: {
		path: '/users-csv',
		method: 'GET',
		get: null,
		auth: true,
		fallback: null,
	},
};
