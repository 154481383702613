import MuiTypography from '@mui/material/Typography';
import { Stack } from '@mui/material';
import { EmailRounded } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { green, red } from '@mui/material/colors';
import Text from '../../../components/detail/components/Text.component';

export const ListConf = {
	initialState: {
		pagination: {
			page: 1,
			pageSize: 25,
		},
		sort: {
			by: 'createdAt',
			direction: 'desc',
		},
	},
	rowsPerPageOptions: [25, 50, 100],
	columns: [
		{
			id: 'name',
			key: 'name',
			sort_key: 'name',
			sortable: true,
			label: 'Name',
		},
		{
			id: 'surname',
			key: 'surname',
			sort_key: 'surname',
			sortable: true,
			label: 'Surname',
		},
		{
			id: 'id_yacht.yacht_name',
			key: 'yacht_name',
			sort_key: 'id_yacht.yacht_name',
			sortable: true,
			label: 'Yacht',
		},
		{
			id: 'email',
			key: 'email',
			sort_key: 'email',
			sortable: true,
			label: 'Email',
		},
		{
			id: 'phone_number',
			key: 'phone_number',
			sort_key: 'phone_number',
			sortable: true,
			label: 'Phone',
		},
		{
			id: 'blocked',
			key: 'blocked',
			sort_key: 'blocked',
			sortable: true,
			label: 'Status',
		},

],
	render: {
		formatHead: (key, value) => {
			switch (key) {
				default:
					return (
						<MuiTypography
							variant='body1'
							sx={{ fontWeight: 'bold', color: 'white' }}>
							{value.toUpperCase()}
						</MuiTypography>
					);
			}
		},
		formatValue: (key, value, onClick) => {
			switch (key) {
				case 'email':
					return (
						<Stack direction={'row'} spacing={1} alignItems={'center'}>
							<EmailRounded color='action' />
							<MuiTypography sx={{ fontWeight: 'bold', height: 1 }} variant='body1' onClick={onClick}>
								{value}
							</MuiTypography>
						</Stack>
					);
				case 'crew_responsability':
				case 'yacht_name':
					return (
						<MuiTypography sx={{ fontWeight: 'bold', height: 1 }} variant='body1' onClick={onClick}>
							{value ? value : '-'}
						</MuiTypography>
					);		
				case 'blocked':
					return (
						<Text
							label=''
							value={value ? <CancelIcon sx={{ color: red[500] }} /> : <CheckCircleIcon sx={{ color: green[500] }} />}
						/>
					);		
				default:
					return (
						<MuiTypography variant='body1' onClick={onClick}>
							{value ? value : '-'}
						</MuiTypography>
					);
			}
		},
	},
};
