import * as yup from 'yup';
import { CovidTestType } from '../Data.conf';
import { API as marina } from '../../../marina/api';

export const CovidTestConf = {
  schema: {
    service_date: yup.date().nullable().required('Service date is required'),
    service_time: yup.date().nullable().required('Service time is required'),
    patient: yup.string().nullable(),
    id_marina: yup.object().nullable(),
    covid_test_type: yup.object().nullable().required('Test type is required'),
    covid_test_number_of_crew: yup
      .string()
      .nullable()
      .required('Crew - Number of tests is required'),
    covid_test_number_of_guest: yup
      .string()
      .nullable()
      .required('Guest - Number of tests is required'),
  },
  defaultValues: {
    service_date: null,
    service_time: null,
    covid_test_type: null,
    covid_test_number_of_crew: null,
    covid_test_number_of_guest: null,
    patient: null,
    id_marina: null,
  },
  fields: [
    {
      id: 'reservation_divider',
      type: 'divider',
      label: 'Reservation',
      xs: 12,
    },
    {
      id: 'service_date',
      name: 'service_date',
      type: 'date',
      label: 'Service date',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'service_time',
      name: 'service_time',
      type: 'time',
      label: 'Service time',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'covid_test_type',
      name: 'covid_test_type',
      type: 'autocomplete',
      label: 'Test type',
      required: true,
      options: CovidTestType,
      xs: 12,
      sm: 6,
    },

    {
      id: 'patient',
      name: 'patient',
      type: 'text',
      label: 'Patient',
      required: false,
      xs: 12,
      sm: 6,
    },

    {
      id: 'covid_test_number_of_crew',
      name: 'covid_test_number_of_crew',
      type: 'number',
      label: 'Crew - Number of tests',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'covid_test_number_of_guest',
      name: 'covid_test_number_of_guest',
      type: 'number',
      label: 'Guest - Number of tests',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'id_marina',
      name: 'id_marina',
      type: 'async_autocomplete',
      label: 'Location',
      api: marina.select,
      required: false,
      xs: 12,
      sm: 6,
    },
  ],
};
