import React, { Fragment, useEffect, useRef, useState } from 'react';

import {
  TableBody as MuiTableBody,
  TableRow as MuiTableRow,
} from '@mui/material';

import { StatusLogbook } from '../../../../../module/logbook/conf/Data.conf';
import _ from 'lodash';

import TableRowLogbook from './TableRowLogbook.component';
import { v4 as uuidv4 } from 'uuid';
import TableCollapse from './TableCollapse.component';
import { useLocation } from 'react-router-dom';
import { grey } from '@mui/material/colors';

const TableBody = ({ data, idAccordion, setSuccessUpdate = () => {} }) => {
  const { state } = useLocation();
  const [expanded, setExpanded] = useState(null);
  const tableRef = useRef(null);

  const handleChange = (id) => {
    setExpanded({ ...expanded, [id]: !expanded[id] });
  };

  useEffect(() => {
    if (data) {
      setExpanded(
        _.mapValues(
          _.keyBy(data, (item) => _.get(item, idAccordion)),
          (value) =>
            state?.id_logbook && _.get(value, idAccordion) === state?.id_logbook
              ? true
              : false
        )
      );
    }
  }, [data, idAccordion, state]);

  useEffect(() => {
    if (state?.id_logbook && tableRef?.current) {
      const id_logbook = state?.id_logbook;
      const row = tableRef.current.querySelector(`#row-${id_logbook}`);
      if (row) {
        row.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [state, tableRef]);

  return (
    <MuiTableBody ref={tableRef}>
      {data.map((item) => {
        const logbook = _.get(item, '[0].attributes.id_logbook.data');
        const id = _.get(item, idAccordion); //id accordion

        const color = _.find(
          StatusLogbook,
          (element) =>
            element.label.toUpperCase() ===
            logbook?.attributes?.status_logbook?.toUpperCase()
        );
        return (
          <Fragment key={uuidv4()}>
            <TableRowLogbook
              open={expanded?.[id] || false}
              onChangeExpanded={() => handleChange(id)}
              status_logbook={logbook?.attributes?.status_logbook}
              id={id}
              color={color}
              label={logbook?.attributes?.id_supplier?.data?.attributes?.label}
              mexal_code={
                logbook?.attributes?.id_supplier?.data?.attributes?.mexal_code
              }
            />
            <MuiTableRow sx={{ backgroundColor: expanded?.[id] && grey[50] }}>
              <TableCollapse
                open={expanded?.[id]}
                data={item}
                idLogbook={id}
                setSuccessUpdate={setSuccessUpdate}
                status_logbook={logbook?.attributes?.status_logbook}
              />
            </MuiTableRow>
          </Fragment>
        );
      })}
    </MuiTableBody>
  );
};

export default TableBody;
