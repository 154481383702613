export const ConciergeOptions = [
  { id: 'PARK PERMIT', label: 'PARK PERMIT', color: '#009ab6', },
  {
    id: 'TRANSFER RESERVATION',
    label: 'TRANSFER RESERVATION',
    color: '#009ab6',
  },
  {
    id: 'RESTAURANT RESERVATION',
    label: 'RESTAURANT RESERVATION',
    color: '#009ab6',
  },
  {
    id: 'BEACH CLUB RESERVATION',
    label: 'BEACH CLUB RESERVATION',
    color: '#009ab6',
  },
  {
    id: 'CLUB RESERVATION',
    label: 'CLUB RESERVATION',
    color: '#009ab6',
  },
  {
    id: 'HOTEL RESERVATION',
    label: 'HOTEL RESERVATION',
    color: '#009ab6',
  },

  {
    id: 'MEDICAL SERVICES',
    label: 'MEDICAL SERVICES',
    color: '#009ab6',
  },
  {
    id: 'CAR & SCOOTER RENTALS',
    label: 'CAR & SCOOTER RENTALS',
    color: '#009ab6',
  },
  {
    id: 'TENDER & WATER TOYS RENTALS',
    label: 'TENDER & WATER TOYS RENTALS',
    color: '#009ab6',
  },

  {
    id: 'TICKETS',
    label: 'TICKETS',
    color: '#009ab6',
  },
  {
    id: 'HELICOPTER & PRIVATE JET',
    label: 'HELICOPTER & PRIVATE JET',
    color: '#009ab6',
  },
  {
    id: 'HAIR & BEAUTY',
    label: 'HAIR & BEAUTY',
    color: '#009ab6',
  },
  {
    id: 'SPA RESERVATION',
    label: 'SPA RESERVATION',
    color: '#009ab6',
  },
  {
    id: 'GENERIC SERVICE',
    label: 'GENERIC SERVICE',
    color: '#009ab6',
  },
  {
    id: 'INTERNAL & EXTERNAL CLEANING',
    label: 'INTERNAL & EXTERNAL CLEANING',
    color: '#009ab6',
  },
  {
    id: 'COVID TEST',
    label: 'COVID TEST',
    color: '#009ab6',
  },
  {
    id: 'PARTY & EVENTS',
    label: 'PARTY & EVENTS',
    color: '#009ab6',
  },
  {
    id: 'MUSICIANS ON BOARD',
    label: 'MUSICIANS ON BOARD',
    color: '#009ab6',
  },
  {
    id: 'TOURS',
    label: 'TOURS',
    color: '#009ab6',
  },
  {
    id: 'SPORT & ACTIVITIES',
    label: 'SPORT & ACTIVITIES',
    color: '#009ab6',
  },
  {
    id: 'LOST & FOUND',
    label: 'LOST & FOUND',
    color: '#009ab6',
  },
];

export const PermitType = [
  { label: 'Cruising', id: 'Cruising' },
  { label: 'Diving', id: 'Diving' },
  { label: 'Fishing', id: 'Fishing' },
  { label: 'Other', id: 'Other' },
];

export const PaymentType = [
  {
    id: 'None',
    label: 'None',
  },
  {
    id: 'NAUTICA ASSISTANCE',
    label: 'NAUTICA ASSISTANCE',
  },
  {
    id: 'DIRECTLY (CLIENT)',
    label: 'DIRECTLY (CLIENT)',
  },
];

export const CovidTestType = [
  {
    id: 'PCR',
    label: 'PCR',
  },
  {
    id: 'RAPID',
    label: 'RAPID',
  },
  {
    id: 'OTHER',
    label: 'OTHER',
  },
];

export const CrewGuestType = [
  {
    id: 'CREW',
    label: 'CREW',
  },
  {
    id: 'GUEST',
    label: 'GUEST',
  },
];

export const ModeBeautyType = [
  {
    id: 'ON BOARD',
    label: 'ON BOARD',
  },
  {
    id: 'SALON',
    label: 'SALON',
  },
  {
    id: 'SPA',
    label: 'SPA',
  },
];

export const RequestedServiceBeautyType = [
  {
    id: 'BEAUTICIAN',
    label: 'BEAUTICIAN',
  },
  {
    id: 'MASSEUSE',
    label: 'MASSEUSE',
  },
  {
    id: 'HAIR STYLIST',
    label: 'HAIR STYLIST',
  },
  {
    id: 'SPA',
    label: 'SPA',
  },
];

export const RequestedServiceCleanType = [
  {
    id: 'INTERNAL CLEANING',
    label: 'INTERNAL CLEANING',
  },
  {
    id: 'EXTERNAL CLEANING',
    label: 'EXTERNAL CLEANING',
  },
  {
    id: 'CARPET CLEANING',
    label: 'CARPET CLEANING',
  },
  {
    id: 'MATTRESS CLEANING',
    label: 'MATTRESS CLEANING',
  },
  {
    id: 'SANIFICATION ON BOARD',
    label: 'SANIFICATION ON BOARD',
  },
  {
    id: 'OTHER',
    label: 'OTHER',
  },
];

export const PlaceMedicalType = [
  {
    id: 'STUDIO',
    label: 'STUDIO',
  },
  {
    id: 'ON BOARD',
    label: 'ON BOARD',
  },
  {
    id: 'HOSPITAL',
    label: 'HOSPITAL',
  },
];

export const RequiredVehicleType = [
  {
    id: 'V-CLASS',
    label: 'V-CLASS',
  },
  {
    id: 'S-CLASS',
    label: 'S-CLASS',
  },
  {
    id: 'E-CLASS',
    label: 'E-CLASS',
  },
  {
    id: 'STANDARD',
    label: 'STANDARD',
  },
  {
    id: 'OTHER',
    label: 'OTHER',
  },
];

export const TypeOfServiceRental = [
  {
    id: 'RENTAL CAR',
    label: 'RENTAL CAR',
  },
  {
    id: 'RENTAL SCOOTER',
    label: 'RENTAL SCOOTER',
  },
  {
    id: 'BIKES',
    label: 'BIKES',
  },
  {
    id: 'TENDER',
    label: 'TENDER',
  },
  {
    id: 'WATER TOYS',
    label: 'WATER TOYS',
  },
  {
    id: 'OTHER',
    label: 'OTHER',
  },
];

export const RequestedServiceTicketType = [
  {
    id: 'BY PLANE',
    label: 'BY PLANE',
  },
  {
    id: 'BY FERRY',
    label: 'BY FERRY',
  },
  {
    id: 'BY HELICOPTER',
    label: 'BY HELICOPTER',
  },
  {
    id: 'NOT SPECIFIED',
    label: 'NOT SPECIFIED',
  },
];
