// REDUX
import { FilterConf } from './conf/Filter.conf';
import { reducer, actions } from './slice';

// SELECTOR
import { tag } from './slice';

export { reducer, tag };

const year = {
  tag,
  filter: {
    conf: FilterConf,
    actions: {
      changeYear: actions.yearSave,
    },
  },
};

export default year;
