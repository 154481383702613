import { dataSlice as createDataSLice } from '../../../core/data';
import { sliceList as createListSlice } from '../../../core/list';
import { sliceFilter as createFilterSlice } from '../../../core/filter';
import { detailSlice as createDetailSlice } from '../../../core/detail';
import { sliceSearch as createSearchSlice } from '../../../core/search';
import {
  combineReducers,
  createAction,
  createSelector,
} from '@reduxjs/toolkit';
import _ from 'lodash';

import { ListConf } from '../conf/List.conf';
import { SearchConf } from '../conf/Search.conf';
import { FilterConf } from '../conf/Filter.conf';
import { DataConf } from '../conf/Data.conf';
import { QueryConf } from '../conf/Query.conf';
import { DetailConf } from '../conf/Detail.conf';
import dayjs from 'dayjs';

export const tag = 'logbook';

// REDUCERS
const dataSlice = createDataSLice(`${tag}/data`, DataConf.initialState);
const listSlice = createListSlice(`${tag}/list`, ListConf.initialState);
const filterSlice = createFilterSlice(`${tag}/filter`, FilterConf.initialState);
const searchSlice = createSearchSlice(`${tag}/search`, SearchConf.initialState);
const detailSlice = createDetailSlice(`${tag}/detail`, DetailConf.initialState);

export const reducer = combineReducers({
  search: searchSlice.reducer,
  data: dataSlice.reducer,
  list: listSlice.reducer,
  filter: filterSlice.reducer,
  detail: detailSlice.reducer,
});

// ACTIONS
export const actions = {
  fetchAllAction: createAction(`${tag}/fetchAll`),
  fetchDetailAction: createAction(`${tag}/fetchOne`),
  ...searchSlice.actions,
  ...dataSlice.actions,
  ...listSlice.actions,
  ...filterSlice.actions,
  ...detailSlice.actions,
};

// QUERY
export const querySelector = createSelector(
  (state) => state[tag].list,
  (state) => state[tag].filter,
  (state) => state[tag].search,
  (list, filter, search) => QueryConf.get(list, filter, search)
);

// LIST
export const listSelector = createSelector(
  (state) => state[tag].data,
  (state) => state[tag].list,
  ({ data, meta }, { pagination, sort }) => ({
    data,
    meta,
    pagination,
    sort,
  })
);

// DETAIL
export const detailSelector = createSelector(
  (state) => state[tag].detail,
  (detail) => detail
);

// EDIT
export const editSelector = createSelector(
  (state) => state[tag].detail,
  ({ data, loading, error }) => {
    const yacht = _.get(data, 'attributes.id_yacht.data');
    const department = _.get(data, 'attributes.id_department.data');
    const location = _.get(data, 'attributes.id_location.data');
    const status_logbook = _.get(data, 'attributes.status_logbook');
    const operation_type = _.get(data, 'attributes.operation_type');
    const priority = _.get(data, 'attributes.priority');
    const id_supplier = _.get(data, 'attributes.id_supplier.data');
    const id_driver_in_charge = _.get(
      data,
      'attributes.id_driver_in_charge.data'
    );
    const id_operator = _.get(data, 'attributes.id_operator.data');
    const manager = _.get(data, 'attributes.manager.data');
    const id_agent_NA = _.get(
      data,
      'attributes.id_yacht.data.attributes.id_attendance.data[0].attributes.id_agent_NA.data.attributes.id_users_permissions_user.data.attributes'
    );
    return {
      loading,
      error,
      data: data
        ? {
            id_yacht: yacht
              ? { name: yacht.attributes.yacht_name, id: yacht.id }
              : null,
            id_department: department
              ? {
                  name: department.attributes.name_department,
                  id: department.id,
                }
              : null,
            id_location: location
              ? {
                name: `${location.attributes.location} - ${location.attributes.marina}`,
                  id: location.id,
                }
            : null,
          status_logbook: status_logbook,

          operation_type: operation_type,

            priority: priority ? { label: priority, id: priority.id } : null,

            id_supplier: id_supplier
              ? {
                  name: `${id_supplier.attributes.mexal_code} - ${id_supplier.attributes.label}`,
                  id: id_supplier.id,
                }
              : null,

            id_driver_in_charge: id_driver_in_charge
              ? {
                  name: `${id_driver_in_charge.attributes.id_users_permissions_user.data.attributes.name} ${id_driver_in_charge.attributes.id_users_permissions_user.data.attributes.surname}`,
                  id: id_driver_in_charge.id,
                }
              : null,
            id_operator: id_operator
              ? {
                  name: `${id_operator.attributes.id_users_permissions_user.data.attributes.name} ${id_operator.attributes.id_users_permissions_user.data.attributes.surname}`,
                  id: id_operator.id,
                }
              : null,
            manager: manager
              ? {
                  name: `${manager.attributes.id_users_permissions_user.data.attributes.name} ${manager.attributes.id_users_permissions_user.data.attributes.surname}`,
                  id: manager.id,
                }
              : null,

            // collection_delivery_time: collection_delivery_time
            //   ? { label: collection_delivery_time, id: collection_delivery_time.id } : null,

          // time_notes: _.get(data, 'attributes.time_notes'),
          // BC_number_mexal: _.get(data, 'attributes.BC_number_mexal'),
            delivery_cost: _.get(data, 'attributes.delivery_cost'),
            delivery_notes: _.get(data, 'attributes.delivery_notes'),
            address: _.get(data, 'attributes.address'),
            collection_delivery_notes: _.get(
              data,
              'attributes.collection_delivery_notes'
            ),
            assigned_transport: _.get(data, 'attributes.assigned_transport'),
            contact_on_board_for_delivery: _.get(
              data,
              'attributes.contact_on_board_for_delivery'
            ),
            collection_delivery_date: _.get(
              data,
              'attributes.collection_delivery_date'
            ),
            collection_cost: _.get(data, 'attributes.collection_cost'),
            collection_delivery_time: dayjs(
              `${_.get(data, 'attributes.collection_delivery_date')}T${_.get(
                data,
                'attributes.collection_delivery_time'
              )}`
            ),
          logbook_date: _.get(data, 'attributes.logbook_date'),
            id_list_items: _.get(data, 'attributes.id_list_items.data'),
            yacht_name: yacht ? yacht.attributes.yacht_name : '',
            agent_NA_name: id_agent_NA
              ? `${id_agent_NA.name} ${id_agent_NA.surname}`
              : '',
          }
        : null,
    };
  }
);
