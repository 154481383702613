import React from 'react';

import { IconButton } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { useFormContext } from 'react-hook-form';

const SaveIconButton = ({ disabled, name, defaultValue }) => {
  const { watch } = useFormContext();
  const watchName = watch(name);

  return (
    <IconButton
      //onClick={onSubmit}
      type='submit'
      disabled={disabled || defaultValue === watchName}
      color='info'
    >
      <SaveIcon />
    </IconButton>
  );
};

export default SaveIconButton;
