import dayjs from 'dayjs';
import _ from 'lodash';

export const API = {
  provisions: {
    path: '/operation-lists?filters[id_department][short_description][$eq]=PRV&populate[id_yacht][populate][id_attendance][populate][id_agent_NA][populate][id_users_permissions_user]=%2A&populate[id_department]=%2A&populate[id_marina]=%2A&populate[id_service]=%2A&populate[id_user_in_charge][populate][id_users_permissions_user][fields]=name,surname&populate[id_list][populate][id_list_items][populate][id_item]=%2A&populate[id_list][populate][id_list_items][populate][id_logbook][populate][id_supplier]=%2A',
    method: 'GET',
    get: null,
    auth: true,
    fallback: null,
    transform: (data) => ({
      meta: data?.meta,
      data: data?.data.map((item) => ({
        id: item?.id,
        attributes: {
          ...item?.attributes,
          ft_id: {
            ft: item?.attributes.ft,
            id: item?.id,
          },
          date_out: `${item?.attributes?.requested_date
              ? dayjs(item?.attributes?.requested_date).format('DD-MM-YYYY')
              : '-'
            } / ${item?.attributes?.check_out_date
              ? dayjs(item?.attributes?.check_out_date).format('DD-MM-YYYY')
              : '-'
            }`,
        },
      })),
    }),
  },
  create: {
    path: '/operation-lists',
    method: 'POST',
    get: null,
    auth: true,
    fallback: null,
    adapter: (body) => {
      return {
        data: {
          ...body,
          id_mexal_person_in_charge: body?.id_mexal_person_in_charge?.id,
          id_technical_work: body?.id_technical_work?.id,
          status_operation_BF: body?.status_operation_BF?.id,
          status_operation_BC: body?.status_operation_BC?.id,
          status_operation_booking: body?.status_operation_booking,
          check_out_date: body?.check_out_date
            ? dayjs(body?.check_out_date).format('YYYY-MM-DD')
            : null,
          operation_date: body?.operation_date
            ? dayjs(body?.operation_date).format('YYYY-MM-DD')
            : null,
          requested_date: body?.requested_date
            ? dayjs(body?.requested_date).format('YYYY-MM-DD')
            : dayjs().format('YYYY-MM-DD'),
          requested_hour: body?.requested_hour
            ? dayjs(body?.requested_hour).format('HH:mm:ss')
            : null,
          account_type: body?.account_type?.id,
          OC_fees: _.isEmpty(body?.OC_fees) ? null : body?.OC_fees,
          selling_price: _.isEmpty(body?.selling_price)
            ? null
            : body?.selling_price,
          purchase_price: _.isEmpty(body?.purchase_price)
            ? null
            : body?.purchase_price,
          type_of_contact: body?.type_of_contact?.id,
          id_service: body?.id_service?.id,
          hour_type: body?.hour_type ? body?.hour_type?.id : null,
        },
      };
    },
  },
  createList: {
    path: '/lists',
    method: 'POST',
    get: null,
    auth: true,
    fallback: null,
    adapter: (body) => ({
      data: {
        ...body,
      },
    }),
  },

  servicesOperationList: {
    path: '/service-operation-lists?populate[id_department]=%2A&filters[id_department][short_description]=PRV&sort[service]=asc',
    method: 'GET',
    get: null,
    auth: true,
    fallback: [],
    transform: (data) =>
      data?.data.map((item) => ({
        name: item.attributes.service,
        id: item.id,
      })),
  },

  update: {
    path: '/operation-lists/{{id}}',
    method: 'PUT',
    get: null,
    auth: true,
    fallback: null,
    adapter: (body) => ({
      data: {
        ...body,
        id_mexal_person_in_charge: body?.id_mexal_person_in_charge?.id,
        id_technical_work: body?.id_technical_work?.id,
        status_operation_BF: body?.status_operation_BF?.id,
        status_operation_BC: body?.status_operation_BC?.id,
        status_operation_booking: body?.status_operation_booking,
        check_out_date: body?.check_out_date
          ? dayjs(body?.check_out_date).format('YYYY-MM-DD')
          : null,
        operation_date: body?.operation_date
          ? dayjs(body?.operation_date).format('YYYY-MM-DD')
          : null,
        requested_date: body?.requested_date
          ? dayjs(body?.requested_date).format('YYYY-MM-DD')
          : dayjs().format('YYYY-MM-DD'),
        requested_hour: body?.requested_hour
          ? dayjs(body?.requested_hour).format('HH:mm:ss')
          : null,
        account_type: body?.account_type?.id,
        OC_fees: _.isEmpty(body?.OC_fees) ? null : body?.OC_fees,
        selling_price: _.isEmpty(body?.selling_price)
          ? null
          : body?.selling_price,
        purchase_price: _.isEmpty(body?.purchase_price)
          ? null
          : body?.purchase_price,
        type_of_contact: body?.type_of_contact?.id,
        id_service: body?.id_service?.id,
        hour_type: body?.hour_type ? body?.hour_type?.id : null,
      },
    }),
  },

  items: {
    path: '/items?pagination[page]=1&pagination[pageSize]=10000',
    method: 'GET',
    get: null,
    auth: true,
    fallback: [],
    transform: (data) =>
      data?.data?.map((item) => ({
        ...item,
        label: `${item.attributes.name_ITA} (${item.attributes.name_ENG})`,
        id: item.id,
      })),
  },
  operation: {
    path: '/operation-lists/{{id}}?populate[id_list]=%2A&populate[id_yacht][populate][id_attendance][populate][id_agent_NA][populate][id_users_permissions_user][fields][0][1]=name, surname&populate[id_technical_work][populate][id_user_technicians][populate][id_users_permissions_user][fields]=name,surname&populate[id_mexal_person_in_charge][populate][id_users_permissions_user][fields]=name,surname&populate[id_department]=%2A&populate[id_marina]=%2A&populate[id_yacht]=%2A&populate[id_reservation][populate][id_arrival_departure_formality]=%2A&populate[id_user_in_charge][populate][id_users_permissions_user][fields]=name,surname&populate[id_service]=%2A&populate[id_supplier_operation]=%2A&populate[id_reservation_piloti_in][populate][id_yacht]=%2A&populate[id_yacht][populate][id_flag]=%2A&populate[id_technical_works]=%2A&populate[id_user_last_updated][populate][id_users_permissions_user]=%2A',
    method: 'GET',
    get: null,
    auth: true,
    fallback: null,
    transform: (data) => {
      return {
        data: {
          ...data?.data,
          attributes: {
            ...data?.data?.attributes,
            id_list_id: {
              id_list: data?.data?.attributes?.id_list?.data?.id,
              id_department:
                data?.data?.attributes?.id_department?.data?.attributes
                  ?.short_description,
            },
            delivery_date: data?.data?.attributes?.requested_date
              ? dayjs(data?.data?.attributes?.requested_date).format(
                'DD-MM-YYYY'
              )
              : '',
            delivery_hour: data?.data?.attributes?.requested_hour
              ? dayjs(
                data?.data?.attributes?.requested_hour,
                'HH:mm:ss'
              ).format('HH:mm')
              : '',
          },
        },
        meta: data?.meta,
      };
    },
  },

  updateList: {
    path: '/lists/{{id}}',
    method: 'PUT',
    get: null,
    auth: true,
    fallback: null,
    adapter: (body) => ({
      data: {
        ...body,
        id_department: body?.id_department?.id,
        id_agent_NA: body?.id_agent_NA?.id,
        id_mexal_person_in_charge: body?.id_mexal_person_in_charge?.id,
        id_supplier_operation: body?.id_supplier_operation?.id,
        id_yacht: body?.id_yacht?.id,
        id_marina: body?.id_marina?.id,
        id_service: body?.id_service?.id,
        id_technical_work: body?.id_technical_work?.id,
        status_operation_BF: body?.status_operation_BF?.id,
        status_operation_BC: body?.status_operation_BC?.id,
        status_operation_booking: body?.status_operation_booking?.id,
        check_out_date: body?.check_out_date
          ? dayjs(body?.check_out_date).format('YYYY-MM-DD')
          : null,
        operation_date: body?.operation_date
          ? dayjs(body?.operation_date).format('YYYY-MM-DD')
          : null,
        requested_date: body?.requested_date
          ? dayjs(body?.requested_date).format('YYYY-MM-DD')
          : null,
        requested_hour: body?.requested_hour
          ? dayjs(body?.requested_hour).format('HH:mm:ss')
          : null,
        account_type: body?.account_type?.label,
      },
    }),
  },
  delete: {
    path: '/operation-lists/{{id}}',
    method: 'DELETE',
    get: null,
    auth: true,
    fallback: null,
  },
};
