import React, { Fragment, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchAPI } from '../../api/request';
import Loading from '../../components/common/Loading.component';
import DeleteView from '../../views/Delete.view';
import Alert from '../../components/common/Alert.component';
import _ from 'lodash';

const DeleteTemplate = ({ module: { tag, remove, query }, children }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { loading: loadingGet } = useSelector(remove.selector.data);
  const [loading, setLoading] = useState(false);
  const { jwt } = useSelector((state) => state.login);
  const [error, setError] = useState(null);
  const userAuth = useSelector((state) => state.login?.user);

  const onSuccess = useCallback(() => {
    setLoading(true);
    setError(null);
    navigate(remove?.navigate ? remove?.navigate : -2);
  }, [navigate, setLoading, remove]);

  const onError = useCallback((error) => {
    setLoading(false);
    setError(error);
    // eslint-disable-next-line
  }, []);

  const onSubmit = useCallback(() => {
    if (userAuth.permissions[_.toUpper(tag)]?.DELETE === 'true') {
      setError(false);
      setLoading(false);
      fetchAPI(query.delete.settings, { id }, '', {}, jwt)
        .then(onSuccess)
        .catch((error) => onError(error));
    }
  }, [id, jwt, onError, onSuccess, query, userAuth, tag]);

  return (
    <Fragment>
      {(loading || loadingGet) && <Loading />}
      {error && (
        <Alert severity='error'>
          {error?.response?.data?.error?.message
            ? error?.response?.data?.error?.message
            : 'An error has occurred'}
        </Alert>
      )}

      {!loadingGet && (
        <DeleteView
          tag={tag}
          id={id}
          api={query.delete}
          onDeleteSubmit={onSubmit}
          onDeleteSuccess={onSuccess}
          onEditError={onError}
          disabled={loading || loadingGet}
        >
          {children}
        </DeleteView>
      )}
    </Fragment>
  );
};

export default DeleteTemplate;
