import React, { Fragment, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchAPI } from '../../../api/request';
import Loading from '../../../components/common/Loading.component';
import DeleteView from '../../../views/Delete.view';
import Alert from '../../../components/common/Alert.component';
import _ from 'lodash';

const ContactsDeletePage = ({
  module: { tag, remove, query },
  children,
  id,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { jwt } = useSelector((state) => state.login);
  const [error, setError] = useState(null);
  const userAuth = useSelector((state) => state.login?.user);

  const onSuccess = useCallback(() => {
    setLoading(true);
    setError(null);
    navigate(remove?.navigate ? remove?.navigate : -1);
  }, [navigate, setLoading, remove]);

  const onError = useCallback(
    (error) => {
      setLoading(false);
      setError(error);
    },
    [setLoading, setError]
  );

  const onSubmit = useCallback(() => {
    if (userAuth.permissions[_.toUpper(tag)]?.DELETE === 'true') {
      setError(false);
      setLoading(false);
      fetchAPI(query.delete.settings, { id: id }, '', {}, jwt)
        .then(onSuccess)
        .catch(onError);
    }
  }, [
    userAuth.permissions,
    tag,
    query.delete.settings,
    id,
    jwt,
    onSuccess,
    onError,
  ]);

  return (
    <Fragment>
      {loading && <Loading />}
      {error && (
        <Alert severity='error'>
          {error?.response?.data?.error?.message
            ? error?.response?.data?.error?.message
            : 'An error has occurred'}
        </Alert>
      )}

      {id && (
        <DeleteView onDeleteSubmit={onSubmit} disabled={loading}>
          {children}
        </DeleteView>
      )}
    </Fragment>
  );
};

export default ContactsDeletePage;
