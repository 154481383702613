import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Skeleton, Grid } from '@mui/material';
import CardIcon from '../common/CardIcon.component';
import AvatarText from '../common/AvatarText.component';
import { v4 as uuidv4 } from 'uuid';

const OrderInfo = ({
  isLoading,
  data,
  backgroundColor,
  height,
  icon,
  children,
}) => {
  return (
    <Fragment>
      {isLoading ? (
        <Skeleton variant='rectangular' width={'100%'} height={height} />
      ) : (
        <CardIcon
          backgroundColor={backgroundColor}
          minHeight={{ xs: '100%', md: height }}
          icon={icon}
        >
          {data && (
            <Grid container spacing={{ xs: 1, md: 2, width: '100%' }}>
              {data?.map((item) => {
                const iconAvatar = item?.icon;
                const value = item?.value;
                return (
                  value && (
                    <Grid
                      item
                      xs={12}
                      sm={icon ? 12 : 6}
                      md={icon ? 12 : 4}
                      lg={icon ? 12 : 4}
                    >
                      <AvatarText
                        key={uuidv4()}
                        icon={iconAvatar}
                        value={value}
                        sxTypography={{
                          fontSize: 15,
                          color: '#fff',
                          ...item?.sxTypography,
                        }}
                        sx={{ fontSize: 15, ...item?.avatar?.sx }}
                      />
                    </Grid>
                  )
                );
              })}
            </Grid>
          )}
          {children}
        </CardIcon>
      )}
    </Fragment>
  );
};

OrderInfo.propTypes = {
  isLoading: PropTypes.bool,
  data: PropTypes.array,
};

export default OrderInfo;
