import * as yup from 'yup';
import { CategoryType } from './Data.conf';

export const UpdateConf = {
    schema: yup.object({
        name_ITA: yup.string().nullable(),
        name_ENG: yup.string().nullable(),
        item_type_ITA: yup.string().nullable(),
        item_type_ENG: yup.string().nullable(),
        category: yup.object().nullable(),
    }),
    defaultValues: {
        name_ITA: null,
        name_ENG: null,
        item_type_ITA: null,
        item_type_ENG: null,
        category: null,
    },
    fields: [
        {
            id: 'name_ITA',
            name: 'name_ITA',
            type: 'text',
            label: 'Name ITA',
            required: false,
            xs: 12,
            sm: 6,
        },
        {
            id: 'name_ENG',
            name: 'name_ENG',
            type: 'text',
            label: 'Name ENG',
            required: false,
            xs: 12,
            sm: 6,
        },
        {
            id: 'item_type_ITA',
            name: 'item_type_ITA',
            type: 'text',
            label: 'Type ITA',
            required: false,
            xs: 12,
            sm: 6,
        },
        {
            id: 'item_type_ENG',
            name: 'item_type_ENG',
            type: 'text',
            label: 'Type ENG',
            required: false,
            xs: 12,
            sm: 6,
        },
        {
            id: 'category',
            name: 'category',
            label: 'Category',
            type: 'autocomplete',
            options: CategoryType,
            xs: 12,
            sm: 6,
        },
    ],
};
