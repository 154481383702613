import React from "react";
import { Button, ButtonGroup } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


const ActionsComponent = ({    
    goToBack = () => { },
    goToNext = () => { },
    disabledBack = false,
    disabledForward = false,
}) => {




    return (
        <ButtonGroup>
            <Button variant="outlined" disabled={disabledBack} aria-label="prev" onClick={goToBack} startIcon={<ArrowBackIosIcon />}>
                Prev
            </Button>            
            <Button variant="outlined" disabled={disabledForward} aria-label="next" onClick={goToNext} endIcon={<ArrowForwardIosIcon />}>
                Next
            </Button>
        </ButtonGroup>
    )
}

export default ActionsComponent