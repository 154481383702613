import React, { Fragment, useEffect, useState } from 'react';
import { Box, Stack, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../../components/common/Loading.component';
import DetailView from '../../../views/Detail.view';
import Section from '../../../components/detail/components/Section.component';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import ShippingDocumentsDeletePage from '../delete/ShippingDocumentsDelete.page';
import ShippingDocumentsEditPage from '../edit/ShippingDocumentsEdit.page';

const ShippingDocumentsDetailPage = ({
  module,
  shippingModule,
  setSuccessUpdate = () => {},
  successUpdate,
}) => {
  const { detail, tag } = module;
  const {
    loading,
    data: dataShipping,
    error,
  } = useSelector(shippingModule.detail.selector.data);
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const userAuth = useSelector((state) => state.login?.user);

  useEffect(() => {
    if (
      dataShipping?.attributes?.id_shipping?.data?.attributes
        ?.id_shipping_document?.data
    )
      setData(
        dataShipping?.attributes?.id_shipping?.data?.attributes
          ?.id_shipping_document?.data
      );
  }, [dataShipping]);

  useEffect(() => {
    if (
      error &&
      (error?.response?.status === 404 || error?.response?.status === 500)
    )
      navigate('/404');
  }, [error, navigate]);

  return (
    <Fragment>
      {loading && <Loading />}
      {userAuth.permissions[_.toUpper(tag)]?.READ === 'true' &&
        !_.isEmpty(data) && (
          <Box mt={2}>
            <Section label='Shipping Documents'>
              <Grid container spacing={4}>
                {data?.map((document) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} key={uuidv4()}>
                      <Box sx={{ position: 'relative' }}>
                        <Stack
                          direction={'row'}
                          mt={1.7}
                          sx={{
                            position: 'absolute',
                            // top: 0,
                            right: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: 40,
                          }}
                        >
                          {detail?.edit && (
                            <ShippingDocumentsEditPage
                              id={document?.id}
                              module={module}
                              setSuccessUpdate={setSuccessUpdate}
                            />
                          )}

                          <ShippingDocumentsDeletePage
                            module={{
                              ...module,
                              remove: {
                                navigate: `/department/shipping/${id}`,
                              },
                            }}
                            id={document?.id}
                            setSuccessUpdate={setSuccessUpdate}
                            successUpdate={successUpdate}
                          />
                        </Stack>

                        <DetailView
                          data={document}
                          conf={{
                            ...detail.conf,
                          }}
                        />
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Section>
          </Box>
        )}
    </Fragment>
  );
};

export default ShippingDocumentsDetailPage;
