import * as yup from 'yup';
import { API as users_nautica } from '../../users_nautica';
import { API as yachts } from '../../yachts/api';
import {
	DefaultStatusOpBooking,
	StatusOperationBC,
	StatusOperationBF,
	StatusOperationBookingType,
} from '../../operations/conf/Data.conf';
import dayjs from 'dayjs';

export const FilterConf = {
	schema: yup.object({
		id_yacht: yup.object().nullable(),
		operation_date: yup
			.date()
			.nullable()
			.test('is-valid-date', 'Date is not valid', function (value) {
				return value ? dayjs(value)?.isValid() : true;
			})
			.transform((value) => {
				if (value && dayjs(value).isValid()) {
					const formattedDate = dayjs(value).format('YYYY-MM-DD');
					return new Date(formattedDate);
				}
				return null;
			}),
		start_date: yup
			.date()
			.nullable()
			.test('is-valid-date', 'Start date is not valid', function (value) {
				return value ? dayjs(value)?.isValid() : true;
			})
			.transform((value) => {
				if (value && dayjs(value).isValid()) {
					const formattedDate = dayjs(value).format('YYYY-MM-DD');
					return new Date(formattedDate);
				}
				return null;
			}),

		end_date: yup
			.date()
			.nullable()
			.test('is-valid-date', 'End date is not valid', function (value) {
				return value ? dayjs(value)?.isValid() : true;
			})
			.transform((value) => {
				if (value && dayjs(value).isValid()) {
					const formattedDate = dayjs(value).format('YYYY-MM-DD');
					return new Date(formattedDate);
				}
				return null;
			}),
	}),
	initialState: {
		value: {
			id_yacht: null,
			id_mexal_person_in_charge: [],
			id_user_in_charge: [],
			start_date: null,
			end_date: null,
			operation_date: null,
			status_operation_booking: DefaultStatusOpBooking,
			status_operation_BC: null,
			status_operation_BF: null,
		},
	},
	fields: [
		{
			id: 'id_yacht',
			name: 'id_yacht',
			type: 'async_autocomplete',
			label: 'Yacht',
			api: yachts.selectNoDRAFT,
			required: false,
			xs: 12,
			sm: 6,
			md: 4,
		},
		{
			id: 'id_user_in_charge',
			name: 'id_user_in_charge',
			type: 'async_autocomplete_multiple',
			label: 'User in Charge',
			api: users_nautica.selectName,
			required: false,
			xs: 12,
			sm: 6,
			md: 4,
		},
		{
			id: 'status_operation_booking',
			name: 'status_operation_booking',
			type: 'autocomplete_multiple',
			label: 'Status Operation Booking',
			options: StatusOperationBookingType,
			required: false,
			xs: 12,
			sm: 6,
			md: 4,
		},
		{
			id: 'start_date',
			name: 'start_date',
			type: 'date',
			label: 'Start Date',
			required: false,
			xs: 12,
			sm: 6,
			md: 4,
		},
		{
			id: 'end_date',
			name: 'end_date',
			type: 'date',
			label: 'End Date',
			required: false,
			xs: 12,
			sm: 6,
			md: 4,
		},
		{
			id: 'operation_date',
			name: 'operation_date',
			type: 'date',
			label: 'Operation Date',
			required: false,
			xs: 12,
			sm: 6,
			md: 4,
		},
		{
			id: 'status_operation_BC',
			name: 'status_operation_BC',
			type: 'autocomplete',
			label: 'Status Operation BC',
			options: StatusOperationBC,
			required: false,
			xs: 12,
			sm: 6,
			md: 4,
		},
		{
			id: 'status_operation_BF',
			name: 'status_operation_BF',
			type: 'autocomplete',
			label: 'Status Operation BF',
			options: StatusOperationBF,
			required: false,
			xs: 12,
			sm: 6,
			md: 4,
		},
	],
	query: {
		keys: [
			{
				key: 'id_yacht.id',
				value: 'id_yacht.id',
				operator: '$eq',
			},
			{
				key: 'id_reservation.reservation_type',
				value: 'id_reservation.id',
				operator: '$eq',
			},
			{
				key: 'id_user_in_charge.id',
				value: 'id_user_in_charge.id',
				operator: '$eq',
			},
			{
				key: 'requested_date',
				value: 'start_date',
				operator: '$gte',
			},
			{
				key: 'requested_date',
				value: 'end_date',
				operator: '$lte',
			},
			{
				key: 'operation_date',
				value: 'operation_date',
				operator: '$eq',
			},
			{
				key: 'status_operation_booking',
				value: 'status_operation_booking.label',
				operator: '$eq',
			},
			{
				key: 'status_operation_BC',
				value: 'status_operation_BC.label',
				operator: '$eq',
			},
			{
				key: 'status_operation_BF',
				value: 'status_operation_BF.label',
				operator: '$eq',
			},
		],
	},
};
