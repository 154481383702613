import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

export const useYear = (yearChange) => {
  const dispatch = useDispatch();

  const handleChangeYear = useCallback(
    (year) => {
      dispatch(yearChange(year));
    },
    [dispatch, yearChange]
  );

  return {
    handleChangeYear,
  };
};
