import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { Link } from '@mui/material';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <Link component={RouterLink} to={'/yachts'}>
        <img alt='logo' width={65} height={40} src='/logo.png' />
    </Link>
);

export default LogoSection;
