import React, { Fragment } from 'react';
import { Autocomplete, TextField, Typography } from '@mui/material';

import _ from 'lodash';

const ControlAutocomplete = ({
  item,
  editable,
  onChange = () => {},
  options = [],
  name,
  label,
  currentState,
  required = false,
  disableClearable = false,
  visibleText = true,
}) => {
  return (
    <Fragment>
      {!editable?.[item?.id] ? (
        visibleText && (
          <Typography>
            {_.has(currentState, `${name}.${item?.id}`)
              ? currentState?.[name]?.[item?.id] || '-'
              : item?.attributes?.[name] || '-'}
          </Typography>
        )
      ) : (
        <Autocomplete
          disablePortal
          disableClearable={disableClearable}
          value={
            _.has(currentState, `${name}.${item?.id}`)
              ? currentState?.[name]?.[item?.id] || null
              : item?.attributes?.[name] || null
          }
          options={options}
          sx={{
            '& input': {
              height: '17px',
            },
            paddingTop: 1,
            paddingBottom: 1,
          }}
          size='small'
          onChange={(event, newValue) => onChange(newValue?.id, name, item?.id)}
          isOptionEqualToValue={(option, value) => option?.id === value}
          renderInput={(params) => (
            <TextField {...params} label={label} required={required} />
          )}
          ListboxProps={{
            style: {
              height: 'auto',
            },
          }}
        />
      )}
    </Fragment>
  );
};
export default ControlAutocomplete;
