// CONF
import { ListConf } from './conf/List.conf';
import { FilterConf } from './conf/Filter.conf';
import { SearchConf } from './conf/Search.conf';
import { DetailConf } from '../operations/conf/Detail.conf';

// REDUX
import { reducer, actions, detailSelector } from './slice';

// SELECTOR
import { tag, querySelector, listSelector } from './slice';

// SAGA
import { saga } from './saga';

// API
import { API } from './api';

export { reducer, saga, tag };

const operations = {
  tag,
  query: {
    all: {
      selector: querySelector,
      action: actions.fetchAllAction,
      settings: API.operations,
      populate: '*',
    },
    detail: {
      action: actions.fetchDetailAction,
      settings: API.operation,
      populate: '*',
    },
  },
  search: {
    conf: SearchConf,
    actions: {
      search: actions.searchChange,
    },
  },
  filter: {
    conf: FilterConf,
    actions: {
      filter: actions.filterChange,
    },
  },
  list: {
    conf: ListConf,
    actions: {
      pagination: actions.paginationChange,
      sort: actions.sortChange,
    },
    selector: {
      data: listSelector,
    },
  },
  detail: {
    conf: DetailConf,
    selector: {
      data: detailSelector,
    },
  },
};

export default operations;
