import * as yup from 'yup';

import _ from 'lodash';
import { ParkPermitConf } from './common/ParkPermit.conf';
import { TransferReservationConf } from './common/TransferReservation.conf';
import { RestaurantBeachClubConf } from './common/RestaurantBeachClub.conf';
import { HotelConf } from './common/Hotel.conf';
import { MedicalConf } from './common/Medical.conf';
import { RentalConf } from './common/Rental.conf';
import { TicketHelicopterTransferConf } from './common/TicketHelicopterTransfer.conf';
import { BeautyConf } from './common/Beauty.conf';
import { CleaningConf } from './common/Cleaning.conf';
import { CovidTestConf } from './common/CovidTest.conf';
import { GenericConf } from './common/Generic.conf';
import { OperationConf as Operation } from '../../operations/conf/Create.conf';

const OperationConf = {
  schema: _.omit(Operation.schema, 'requested_date', 'id_marina'),
  defaultValues: _.omit(Operation.defaultValues, 'requested_date', 'id_marina'),
  fields: _.concat(Operation.fields.slice(0, 1), Operation.fields.slice(2, 4), Operation.fields.slice(5)),
};

export const CreateConf = {
  'PARK PERMIT': {
    schema: yup.object({
      ...ParkPermitConf.schema,
      ...OperationConf.schema,
    }),
    defaultValues: {
      ...ParkPermitConf.defaultValues,
      ...OperationConf.defaultValues,
    },
    fields: _.concat(ParkPermitConf.fields, OperationConf.fields),
  },
  'TRANSFER RESERVATION': {
    schema: yup.object({
      ...TransferReservationConf.schema,
      ...OperationConf.schema,
    }),
    defaultValues: {
      ...TransferReservationConf.defaultValues,
      ...OperationConf.defaultValues,
    },
    fields: _.concat(TransferReservationConf.fields, OperationConf.fields),
  },
  'HOTEL RESERVATION': {
    schema: yup.object({
      ...HotelConf.schema,
      ...OperationConf.schema,
    }),
    defaultValues: {
      ...HotelConf.defaultValues,
      ...OperationConf.defaultValues,
    },
    fields: _.concat(HotelConf.fields, OperationConf.fields),
  },
  'MEDICAL SERVICES': {
    schema: yup.object({
      ...MedicalConf.schema,
      ...OperationConf.schema,
    }),
    defaultValues: {
      ...MedicalConf.defaultValues,
      ...OperationConf.defaultValues,
    },
    fields: _.concat(MedicalConf.fields, OperationConf.fields),
  },
  'INTERNAL & EXTERNAL CLEANING': {
    schema: yup.object({
      ...CleaningConf.schema,
      ...OperationConf.schema,
    }),
    defaultValues: {
      ...CleaningConf.defaultValues,
      ...OperationConf.defaultValues,
    },
    fields: _.concat(CleaningConf.fields, OperationConf.fields),
  },
  'COVID TEST': {
    schema: yup.object({
      ...CovidTestConf.schema,
      ...OperationConf.schema,
    }),
    defaultValues: {
      ...CovidTestConf.defaultValues,
      ...OperationConf.defaultValues,
    },
    fields: _.concat(CovidTestConf.fields, OperationConf.fields),
  },
};

const genericServiceKeys = ['GENERIC SERVICE', 'PARTY & EVENTS', 'MUSICIANS ON BOARD', 'TOURS', 'SPORT & ACTIVITIES', 'LOST & FOUND'];

genericServiceKeys.forEach(key => {
  CreateConf[key] = {
    schema: yup.object({
      ...GenericConf.schema,
      ...OperationConf.schema,
    }),
    defaultValues: {
      ...GenericConf.defaultValues,
      ...OperationConf.defaultValues,
    },
    fields: _.concat(GenericConf.fields, OperationConf.fields),
  };
})


const restaurantBeachClubKeys = [
  'RESTAURANT RESERVATION',
  'BEACH CLUB RESERVATION',
  'CLUB RESERVATION'
];

restaurantBeachClubKeys.forEach(key => {
  CreateConf[key] = {
    schema: yup.object({
      ...RestaurantBeachClubConf.schema,
      ...OperationConf.schema,
    }),
    defaultValues: {
      ...RestaurantBeachClubConf.defaultValues,
      ...OperationConf.defaultValues,
    },
    fields: _.concat(RestaurantBeachClubConf.fields, OperationConf.fields),
  };
});

const rentalKeys = ['CAR & SCOOTER RENTALS', 'TENDER & WATER TOYS RENTALS']

rentalKeys.forEach(key => {
  CreateConf[key] = {
    schema: yup.object({
      ...RentalConf.schema,
      ...OperationConf.schema,
    }),
    defaultValues: {
      ...RentalConf.defaultValues,
      ...OperationConf.defaultValues,
    },
    fields: _.concat(RentalConf.fields, OperationConf.fields),
  };
})

const beautyKeys = ['HAIR & BEAUTY', 'SPA RESERVATION'];

beautyKeys.forEach(key => {
  CreateConf[key] = {
    schema: yup.object({
      ...BeautyConf.schema,
      ...OperationConf.schema,
    }),
    defaultValues: {
      ...BeautyConf.defaultValues,
      ...OperationConf.defaultValues,
    },
    fields: _.concat(BeautyConf.fields, OperationConf.fields),
  };
})

const ticketsHelicopterTransferKeys = ['TICKETS', 'HELICOPTER & PRIVATE JET'];

ticketsHelicopterTransferKeys.forEach(key => {
  CreateConf[key] = {
    schema: yup.object({
      ...TicketHelicopterTransferConf.schema,
      ...OperationConf.schema,
    }),
    defaultValues: {
      ...TicketHelicopterTransferConf.defaultValues,
      ...OperationConf.defaultValues,
    },
    fields: _.concat(TicketHelicopterTransferConf.fields, OperationConf.fields),
  };
})