// CONF
import { CreateConf } from './conf/Create.conf';
import { UpdateConf } from './conf/Update.conf';
import { ListConf } from './conf/List.conf';
import { SearchConf } from '../operations/conf/Search.conf';
import { FilterConf } from './conf/Filter.conf';
import { DetailConf, InfoFields, NewInteriorsDepotProvisionFields, NewMexalFields } from '../operations/conf/Detail.conf';
// REDUX
import { reducer, actions, detailSelector, editSelector } from './slice';

// SELECTOR
import { tag, querySelector, listSelector } from './slice';

// SAGA
import { saga } from './saga';

// API
import { API } from './api';

export { reducer, saga, tag };

const depot = {
	code: 'DEP',
	tag,
	query: {
		all: {
			selector: querySelector,
			action: actions.fetchAllAction,
			settings: API.depot,
			populate: '*',
		},
		detail: {
			action: actions.fetchDetailAction,
			settings: API.operation,
			populate: '*',
		},
		edit: {
			settings: API.update,
		},
		create: {
			settings: API.create,
			list: API.createList,
		},
		delete: {
			settings: API.delete,
		},
	},
	search: {
		conf: SearchConf,
		actions: {
			search: actions.searchChange,
		},
	},
	filter: {
		conf: FilterConf,
		actions: {
			filter: actions.filterChange,
		},
	},
	list: {
		conf: ListConf,
		actions: {
			pagination: actions.paginationChange,
			sort: actions.sortChange,
		},
		selector: {
			data: listSelector,
		},
		add: {
			to: 'add',
		},
	},
	detail: {
		conf: {
			...DetailConf,
			fields: [
				{
					id: 'info_fields_sections',
					type: 'section',
					label: '',
					items: InfoFields
				},
				{
					id: 'new_interiors_service_fields',
					type: 'section',
					label: 'SERVICE',
					items: NewInteriorsDepotProvisionFields
				},
				{
					id: 'new_mexal_sections',
					type: 'section',
					label: 'MEXAL',
					items: NewMexalFields
				}
			],
		},
		selector: {
			data: detailSelector,
		},
		edit: {
			to: 'edit',
		},
	},
	edit: {
		conf: UpdateConf,
		selector: {
			data: editSelector,
		},
	},
	create: {
		conf: CreateConf,
	},
	remove: {
		selector: {
			data: detailSelector,
		},
		navigate: '/department/depot',
	},
};

export default depot;
