import * as yup from 'yup';
import { CrewGuestType, ModeBeautyType, RequestedServiceBeautyType } from '../Data.conf';
import { API as marina } from '../../../marina/api';

export const BeautyConf = {
  schema: {
    service_date: yup.date().nullable().required('Service date is required'),
    service_time: yup.date().nullable().required('Service time is required'),
    id_marina: yup.object().nullable(),
    requested_service_beauty: yup
      .object()
      .nullable()
      .required('Requested service is required'),
    mode_beauty: yup.object().nullable().required('Mode is required'),
    service_place: yup.string().nullable().required('Location is required'),
    pax_number: yup.string().nullable().required('Pax is required'),
    crew_guest: yup.object().nullable().required('Crew / Guest is required'),
  },
  defaultValues: {
    service_date: null,
    service_time: null,
    requested_service_beauty: null,
    mode_beauty: null,
    service_place: null,
    pax_number: null,
    crew_guest: null,
    id_marina: null,
  },
  fields: [
    {
      id: 'reservation_divider',
      type: 'divider',
      label: 'Reservation',
      xs: 12,
    },
    {
      id: 'service_date',
      name: 'service_date',
      type: 'date',
      label: 'Service date',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'service_time',
      name: 'service_time',
      type: 'time',
      label: 'Service time',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'requested_service_beauty',
      name: 'requested_service_beauty',
      type: 'autocomplete',
      label: 'Requested service',
      required:true,
      options:RequestedServiceBeautyType,
      xs: 12,
      sm: 6,
    },
    {
      id: 'mode_beauty',
      name: 'mode_beauty',
      type: 'autocomplete',
      label: 'Mode',
      required:true,
      options: ModeBeautyType,
      xs: 12,
      sm: 6,
    },
    {
      id: 'service_place',
      name: 'service_place',
      type: 'text',
      label: 'Service Place',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'id_marina',
      name: 'id_marina',
      type: 'async_autocomplete',
      label: 'Location',
      api: marina.select,
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'pax_number',
      name: 'pax_number',
      type: 'number',
      label: 'Pax',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'crew_guest',
      name: 'crew_guest',
      type: 'autocomplete',
      label: 'Crew / Guest',
      options: CrewGuestType,
      required: true,
      xs: 12,
      sm: 6,
    },
  ],
};
