import _ from 'lodash';
import { IconTruckDelivery, IconTruckLoading } from '@tabler/icons-react';
import { green, yellow } from '@mui/material/colors';

export const OperationTypes = [
  {
    id: 'PICKUP',
    label: 'PICKUP',
    backgroundColor: yellow[500],
    icon: <IconTruckLoading width={16} height={16} />, 
  },
  {
    id: 'DELIVERY',
    label: 'DELIVERY',
    color: '#fff',
    backgroundColor: green[500],
    icon: <IconTruckDelivery width={16} height={16} color='#fff' />, 
  },
];
export const Department = [
  {
    label: 'PROVISIONS',
    id: 'PROVISIONS',
  },
  {
    label: 'INTERIORS',
    id: 'INTERIORS',
  },
];

export const Status = [
  {
    id: 'None',
    label: 'None',
  },
  {
    id: 'OPEN',
    label: 'OPEN',
  },
  {
    id: 'CLOSED',
    label: 'CLOSED',
  },
];

export const DataConf = {
  initialState: {
    loading: false,
    error: null,
    data: [],
    meta: null,
  },
};

export const StatusRequest = [
  {
    id: 'None',
    label: 'None',
  },
  {
    id: 'OPEN',
    label: 'OPEN',
  },
  {
    id: 'CLOSED',
    label: 'CLOSED',
  },
];

export const StatusLogbookDefault = [
  {
    id: 'NEW REQUEST',
    label: 'NEW REQUEST',
    color: '#fff',
    backgroundColor: '#005488',
  },
  {
    id: 'CANCELLED',
    label: 'CANCELLED',
    color: '#fff',
    backgroundColor: '#ff001b',
  },
];

export const StatusLogbookDeliveries = [
  {
    id: 'NEW REQUEST',
    label: 'NEW REQUEST',
    color: '#fff',
    backgroundColor: '#005488',
  },
  {
    id: 'STAND-BY',
    label: 'STAND-BY',
    color: '#fff',
    backgroundColor: '#005488',
  },
  {
    id: 'SCHEDULED',
    label: 'SCHEDULED',
    color: '#fff',
    backgroundColor: '#ff6500',
  },
  {
    id: 'IN PROGRESS',
    label: 'IN PROGRESS',
    color: '#fff',
    backgroundColor: '#f6ca00',
  },
  {
    id: 'DELIVERED',
    label: 'DELIVERED',
    color: '#fff',
    backgroundColor: '#00c256',
  },
  {
    id: 'PICKED UP',
    label: 'PICKED UP',
    color: '#fff',
    backgroundColor: '#00c256',
  },
  {
    id: 'DECLINED',
    label: 'DECLINED',
    color: '#fff',
    backgroundColor: '#ff001b',
  },
  {
    id: 'CANCELLED',
    label: 'CANCELLED',
    color: '#fff',
    backgroundColor: '#ff001b',
  },
];

export const StatusPriority = [
  {
    id: 'LOW',
    label: 'LOW',
  },
  {
    id: 'MEDIUM',
    label: 'MEDIUM',
  },
  {
    id: 'HIGH',
    label: 'HIGH',
  },
];
export const StatusTime= [
  {
    id: 'INDICATIVE',
    label: 'INDICATIVE',
  },
  {
    id: 'SPECIFIC',
    label: 'SPECIFIC',
  },
]

export const StatusLogbook = _.concat(
  _.map(StatusLogbookDefault, (item) => {
    return _.mapKeys(item, (value, key) => {
      switch (key) {
        case 'backgroundColor':
          return 'hex';
        case 'color':
          return 'textHex';
        default:
          return key;
      }
    });
  }),
  _.map(StatusLogbookDeliveries, (item) => {
    return _.mapKeys(item, (value, key) => {
      switch (key) {
        case 'backgroundColor':
          return 'hex';
        case 'color':
          return 'textHex';
        default:
          return key;
      }
    });
  })
);
