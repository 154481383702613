import React from 'react';
// import MuiDivider from '@mui/material/Divider';
import MuiTypography from '@mui/material/Typography';
import MuiPaper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { Box } from '@mui/material';

const Section = ({ label, children, ...props }) => {
  const theme = useTheme();

  return (
    <MuiPaper
      sx={{
        backgroundColor: theme.palette.background.light_25,
        boxShadow: theme.customShadows.light,
        ...props?.sx,
      }}
      variant='outlined'
    >
      <MuiTypography
        variant='h4'
        pt={1}
        pb={1}
        pl={1.5}
        pr={1.5}
        sx={{
          fontWeight: 600,
          /* backgroundColor: theme.palette.background.light_30, */
          color: theme.palette.primary[800],
          ...props?.sxTypography,
        }}
      >
        {label}
      </MuiTypography>

      {/* <MuiDivider sx={{ mt: 1, mb: 1 }} /> */}
      <Box p={1}>{children}</Box>
    </MuiPaper>
  );
};

Section.propTypes = {
  label: PropTypes.string,
};

export default Section;
