import { Box, Stack } from '@mui/material';
import React, { useCallback, useEffect, useMemo } from 'react';
import Button from '../components/form/components/Button.component';
import { Form } from '../components/form/Form';
import { useFilter } from '../core/filter';
import FilterListIcon from '@mui/icons-material/FilterList';
import ClearIcon from '@mui/icons-material/Clear';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import { getItem } from '../utility/Storage.utility';

const SubmitButton = () => {
  return (
    <Button type='submit' variant='contained' startIcon={<FilterListIcon />}>
      Search
    </Button>
  );
};

const ResetButton = ({ handleResetFilter, initialState }) => {
  const { reset } = useFormContext();

  const onReset = useCallback(() => {
    handleResetFilter();
    reset(initialState);
  }, [handleResetFilter, reset, initialState]);

  return (
    <Button
      variant='outlined'
      color='error'
      startIcon={<ClearIcon />}
      onClick={onReset}
    >
      Reset
    </Button>
  );
};

const FilterView = ({ filter, tag, children }) => {
  const { value } = useSelector((state) => state[tag].filter);
  const {
    conf: { schema, initialState, fields },
    actions: { filter: filterAction },
  } = filter;
  const { handleChangeFilter } = useFilter(filterAction);
  const { from, to } = useSelector((state) => state.year);
  const { pathname } = useLocation();

  const defaultValues = useMemo(() => {
    return value || initialState.value;
  }, [value, initialState]);

  const onSubmit = useCallback(
    (values) => {
      handleChangeFilter(values);
    },
    [handleChangeFilter]
  );

  const updateDatesFilter = useCallback(
    ({ newInitialState }) => {
      const thisYear = dayjs().year();

      if (from && dayjs(from).year() !== thisYear) {
        let updatedState = { ...newInitialState };

        if (updatedState?.start_date)
          updatedState.start_date = _.head(_.split(from, 'T'));
        if (updatedState?.end_date)
          updatedState.end_date = _.head(_.split(to, 'T'));

        handleChangeFilter(updatedState);
      } else {
        handleChangeFilter(newInitialState);
      }
    },
    [from, to, handleChangeFilter]
  );

  const onReset = useCallback(() => {
    updateDatesFilter({
      newInitialState: { ...initialState.value, season: from },
    });
  }, [initialState, updateDatesFilter, from]);

  useEffect(() => {
    const filtersStorage = getItem(tag)?.filters;
    if (
      filtersStorage?.season &&
      from &&
      dayjs(filtersStorage?.season).year() !== dayjs(from).year()
    ) {
      updateDatesFilter({
        newInitialState: { ...initialState.value, season: from },
      });
    } else {
      const state = filtersStorage
        ? { ...filtersStorage }
        : { ...initialState.value };
      updateDatesFilter({ newInitialState: { ...state, season: from } });
    }
  }, [
    from,
    to,
    initialState,
    pathname,
    tag,
    handleChangeFilter,
    updateDatesFilter,
  ]);

  return (
    <Form
      fields={fields}
      schema={schema}
      defaultValues={defaultValues}
      onSubmit={onSubmit}
    >
      <Box width={1} display={'flex'} justifyContent='flex-end'>
        <Stack direction={'row'} spacing={1} sx={{ mt: 1, mb: 1 }}>
          <ResetButton
            handleResetFilter={onReset}
            initialState={initialState.value}
          />
          <SubmitButton />
        </Stack>
      </Box>
      {children}
    </Form>
  );
};

export default FilterView;
