import { Box } from '@mui/material';
import React, { useCallback, useEffect, useMemo } from 'react';
import Typography from '../../common/Typography.component';
import dayjs from 'dayjs';
import ViewsComponent from './Views.component';
import ActionsComponent from './Actions.component';

const Toolbar = ({
  label,
  date,
  onNavigate,
  onView,
  range,
  view,
  defaultValue,
  setEventsPopup = () => {},
}) => {
  const goToBack = useCallback(() => {
    onNavigate('prev', dayjs(date).subtract(1, view));
    setEventsPopup(false);
  }, [date, onNavigate, view, setEventsPopup]);

  const goToNext = useCallback(() => {
    onNavigate('next', dayjs(date).add(1, view));
    setEventsPopup(false);
  }, [date, onNavigate, view, setEventsPopup]);

  const handleChangeView = useCallback(
    (value) => {
      onView(value);
    },
    [onView]
  );

  const disabledBack = useMemo(() => {
    return !(
      range.startDate && dayjs(dayjs(date)).isAfter(dayjs(range.startDate))
    );
  }, [date, range]);

  const disabledForward = useMemo(() => {
    return !(
      range.endDate &&
      dayjs(dayjs(date).add(1, 'month')).isBefore(dayjs(range.endDate))
    );
  }, [date, range]);

  useEffect(() => {
    if (defaultValue) {
      onNavigate('date', dayjs(defaultValue));
    } else if (range.startDate) {
      onNavigate('date', dayjs(range.startDate));
    }
  }, [range, onNavigate, defaultValue]);

  return (
    <Box
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      marginBottom={1}
    >
      <ViewsComponent handleChangeView={handleChangeView} value={view} />

      <Typography variant='h4' sx={{ fontWeight: 'bold' }}>
        {label}
      </Typography>

      <ActionsComponent
        goToBack={goToBack}
        goToNext={goToNext}
        disabledBack={disabledBack}
        disabledForward={disabledForward}
      />
    </Box>
  );
};

export default Toolbar;
