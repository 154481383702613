export const CategoryType = [
    { id: 'DRY', label: 'DRY' },
    { id: 'FRESH', label: 'FRESH' },
    { id: 'FROZEN', label: 'FROZEN' },
]


export const DataConf = {
    initialState: {
        loading: false,
        error: null,
        data: [],
        meta: null
    }
}