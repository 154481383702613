import React, { useCallback, useState } from 'react';
import { Container, Stack, Paper, Box } from '@mui/material';
import Back from '../../../components/common/Back.component';
import Loading from '../../../components/common/Loading.component';
import AddView from '../../../views/Add.view';
import { useSelector } from 'react-redux';
import Alert from '../../../components/common/Alert.component';
import { fetchAPI } from '../../../api/request';
import { API as provenenceLists } from '../../../module/provenenceLists/api';
import AttendanceForm from '../../../components/attendance/AttendanceForm.component';
import dayjs from 'dayjs';

const AttendancesAddPage = ({ children, module:{query,tag,create} }) => {
  const { jwt } = useSelector((state) => state.login);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const onError = useCallback(() => {
    setSuccess(false);
    setLoading(false);
    setError(true);
    // eslint-disable-next-line
  }, []);

  const onSuccess = useCallback(() => {
    setSuccess(true);
    setLoading(false);
    setError(false);
  }, [setLoading, setSuccess]);

  const onSubmitAttendances = useCallback(
    (data) => {
      fetchAPI(
        query.create.settings,
        {},
        '',
        {
          ...data,
          note: data?.note_attendance,
        },
        jwt
      )
        .then(onSuccess)
        .catch(onError);
    },
    [jwt, onError, onSuccess,query]
  );

  const onSubmit = useCallback(
    (values) => {
      setError(false);
      setSuccess(false);
      setLoading(true);
      fetchAPI(
        provenenceLists.create,
        {},
        '',
        {
          data: {
            provenence_group: values.provenence_group?.id,
            first_contact: values.first_contact?.id,
            provenence_sales_channel: values.provenence_sales_channel?.id,
            provenence: values.provenence?.id,
            note: values.note,
            users_nauticas: values.users_nauticas?.id,
          },
        },
        jwt
      )
        .then((resp) =>
          onSubmitAttendances({
            ...values,
            id_provenence_list: resp?.data?.id,
            registration_date: dayjs(values?.registration_date).format(
              'YYYY-MM-DD'
            ),
            note: null,
          })
        )
        .catch(onError);
    },
    [setLoading, jwt, onError, onSubmitAttendances]
  );

  return (
    <Container disableGutters={true} maxWidth={false} sx={{ height: '100%' }}>
      <Stack
        spacing={2}
        sx={{ padding: 2 }}
        justifyContent='center'
        alignItems='center'
      >
        <Back title={tag} />
        <Container>
          {loading && <Loading />}
          {success && <Alert severity='success'>{'Success: Add'}</Alert>}
          {error && <Alert severity='error'>{'An error has occurred'}</Alert>}

          <Paper variant='outlined'>
            <Box sx={{ padding: 2 }}>
              <AddView
                conf={create.conf}
                onAddSubmit={onSubmit}
                onAddError={onError}
                onAddSuccess={onSuccess}
                disabled={loading || success}
              >
                <AttendanceForm />
              </AddView>
            </Box>
          </Paper>
        </Container>
        {children}
      </Stack>
    </Container>
  );
};

export default AttendancesAddPage;
