import * as yup from 'yup';

export const CommonConf = {
	schema: {
		username: yup.string().nullable().required('Username  is required'),
		email: yup.string().nullable().email('Please enter a valid email').required('Email is required'),
		surname: yup.string().nullable().required('Last name is required'),
		name: yup.string().nullable().required('Name is required'),
		phone_number: yup.string().nullable(),
	},
	defaultValues: {
		username: '',
		email: '',
		surname: '',
		name: '',
		phone_number: '',
	},
	fields: [
		{
			id: 'contact_divider',
			type: 'divider',
			label: 'Contact',
			xs: 12,
		},
		{
			id: 'username',
			name: 'username',
			type: 'text',
			label: 'Username',
			required: true,
			xs: 12,
			sm: 6,
		},
		{
			id: 'email',
			name: 'email',
			type: 'text',
			label: 'Email',
			required: true,
			xs: 12,
			sm: 6,
		},
		{
			id: 'name',
			name: 'name',
			type: 'text',
			label: 'First Name',
			required: true,
			xs: 12,
			sm: 6,
		},
		{
			id: 'surname',
			name: 'surname',
			type: 'text',
			label: 'Last Name',
			required: true,
			xs: 12,
			sm: 6,
		},
		{
			id: 'phone_number',
			name: 'phone_number',
			type: 'text',
			label: 'Phone Number',
			required: false,
			xs: 12,
			sm: 6,
		},
	],
};
