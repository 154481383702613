import PropTypes from 'prop-types';
import { memo, useCallback, useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, useMediaQuery } from '@mui/material';
import MenuList from './MenuList';
import LogoSection from '../../logoSection';
import menu from '../../../../module/menu';
import { useDispatch, useSelector } from 'react-redux';
import ReleaseVersionContainer from './MenuList/releaseVersion/ReleaseVersion.container';

// ==============================|| SIDEBAR DRAWER ||============================== //

const drawerWidthOpen = 260;
const drawerWidthClose = 70;

const Sidebar = ({ window }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const { drawerOpen } = useSelector((state) => state.menu);

  const logo = useMemo(
    () => (
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
          <LogoSection />
        </Box>
      </Box>
    ),
    []
  );

  const container =
    window !== undefined ? () => window.document.body : undefined;

  const openDrawer = useCallback(() => {
    dispatch(menu.query.open.action(!drawerOpen));
  }, [drawerOpen, dispatch]);

  return (
    <Box
      component='nav'
      sx={{
        flexShrink: { md: 0 },
        width: matchUpMd
          ? drawerOpen
            ? drawerWidthOpen
            : drawerWidthClose
          : 'auto',
      }}
      aria-label='mailbox folders'
    >
      <Drawer
        container={container}
        variant={matchUpMd ? 'persistent' : 'temporary'}
        anchor='left'
        open={matchUpMd ? true : drawerOpen}
        onClose={() => openDrawer()}
        sx={{
          '& .MuiDrawer-paper': {
            width: drawerOpen ? drawerWidthOpen : drawerWidthClose,
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            borderRight: 'none',
            [theme.breakpoints.up('md')]: {
              top: '88px',
            },
            height: { sx: '100%', md: '88%' },
          },
        }}
        ModalProps={{ keepMounted: true }}
        color='inherit'
      >
        {logo}
        <Box pl={1} pr={1} alignItems='center' justifyContent='center'>
          <MenuList />
        </Box>
        {!!drawerOpen && <ReleaseVersionContainer />}
      </Drawer>
    </Box>
  );
};

Sidebar.propTypes = {
  window: PropTypes.object,
};

export default memo(Sidebar);
