import MuiTypography from '@mui/material/Typography';
import CalendarIcon from '@mui/icons-material/CalendarMonth';
import PersonIcon from '@mui/icons-material/Person';
import { Box, Stack } from '@mui/material';
import moment from 'moment';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import dayjs from 'dayjs';
import { OperationTypes, StatusLogbook } from './Data.conf';
import _ from 'lodash';
import RectangleChip from '../../../components/common/RectangleChip.component';

export const ListConf = {
  initialState: {
    pagination: {
      page: 1,
      pageSize: 25,
    },
    sort: {
      by: 'collection_delivery_time',
      direction: 'asc',
    },
  },
  rowsPerPageOptions: [25, 50, 100],
  columns: [
    {
      id: 'attributes.status_logbook',
      key: 'status_logbook',
      sort_key: 'status_logbook',
      sortable: true,
      label: 'STATUS',
      width: 150,
    },
    {
      id: 'attributes.id_department.data.attributes.name_department',
      key: 'id_department',
      sort_key: 'id_marina.name_department',
      sortable: true,
      label: 'DEPARTMENT',
      width: 180,
    },
    {
      id: 'attributes.id_yacht.data.attributes.yacht_name',
      key: 'yacht_name',
      sort_key: 'id_yacht.yacht_name',
      sortable: true,
      label: 'YACHT NAME',
      width: 180,
    },
    {
      id: 'attributes.operation_type',
      key: 'operation_type',
      sort_key: 'operation_type',
      sortable: true,
      label: 'SERVICE',
      width: 180,
    },
    {
      id: 'attributes.collection_delivery_date',
      key: 'collection_delivery_date',
      sort_key: 'collection_delivery_date',
      sortable: true,
      label: 'DATE',
      width: 170,
    },
    {
      id: 'attributes.collection_delivery_time',
      key: 'collection_delivery_time',
      sort_key: 'collection_delivery_time',
      sortable: true,
      label: 'TIME',
      width: 150,
    },

    {
      id: 'attributes.id_supplier.data.attributes.label',
      key: 'id_supplier',
      sort_key: 'id_supplier',
      sortable: true,
      label: 'SUPPLIER',
      width: 180,
    },
    {
      id: 'attributes.id_location.data.attributes.marina',
      key: 'id_location',
      sort_key: 'id_location',
      sortable: true,
      label: 'LOCATION',
      width: 150,
    },
    {
      id: 'attributes.id_operator.data.attributes.id_users_permissions_user.data.attributes',
      key: 'id_operator',
      sort_key: 'id_operator',
      sortable: true,
      label: 'USER',
      width: 150,
    },
    {
      id: 'attributes.id_driver_in_charge.data.attributes.id_users_permissions_user.data.attributes',
      key: 'id_driver_in_charge',
      sort_key: 'id_driver_in_charge',
      sortable: true,
      label: 'DRIVER',
      width: 150,
    },
  ],
  render: {
    formatHead: (key, value) => {
      switch (key) {
        default:
          return (
            <MuiTypography
              variant='body1'
              sx={{ fontWeight: 'bold', color: 'white' }}
            >
              {value}
            </MuiTypography>
          );
      }
    },
    formatValue: (key, value, onClick) => {
      switch (key) {
        case 'collection_delivery_time': {
          return value ? (
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <AccessTimeIcon color='action' />
              <MuiTypography
                sx={{ fontWeight: 'bold', height: 1 }}
                variant='body1'
                onClick={onClick}
              >
                {moment(value, 'HH:mm:ss').format('HH:mm')}
              </MuiTypography>
            </Stack>
          ) : (
            <MuiTypography
              variant='body1'
              onClick={onClick}
              sx={{ height: '100%' }}
            >
              -
            </MuiTypography>
          );
        }
        case 'collection_delivery_date':
          return (
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <CalendarIcon color='action' />
              <MuiTypography
                sx={{ fontWeight: 'bold', height: 1 }}
                variant='body1'
                onClick={onClick}
              >
                {value ? dayjs(value).format('DD-MM-YYYY') : '-'}
              </MuiTypography>
            </Stack>
          );

        case 'id_operator':
          return value ? (
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <PersonIcon color='action' fontSize='small' />
              <MuiTypography
                sx={{ height: 1 }}
                variant='body1'
                onClick={onClick}
              >
                {`${value?.name} ${value?.surname}`}
              </MuiTypography>
            </Stack>
          ) : (
            '-'
          );
        case 'id_driver_in_charge':
          return value ? (
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <PersonIcon color='action' fontSize='small' />
              <MuiTypography
                sx={{ height: 1 }}
                variant='body1'
                onClick={onClick}
              >
                {`${value?.name} ${value?.surname}`}
              </MuiTypography>
            </Stack>
          ) : (
            '-'
          );
        case 'yacht_name': {
          return (
            <MuiTypography
              variant='body1'
              onClick={onClick}
              sx={{ height: '100%', fontWeight: 'bold' }}
            >
              {value || '-'}
            </MuiTypography>
          );
        }
        case 'status_logbook':
          const color = _.find(
            StatusLogbook,
            (item) => item.label.toUpperCase() === value.toUpperCase()
          );
          return (
            <Box onClick={onClick}>
              {value ? (
                <RectangleChip
                  value={value}
                  backgroundColor={color?.hex}
                  color='#fff'
                />
              ) : (
                '-'
              )}
            </Box>
          );

        case 'operation_type':
          return value ? (
            <RectangleChip
              width={102}
              onClick={onClick}
              value={value}
              color={value === 'DELIVERY' && '#fff'}
              backgroundColor={_.find(OperationTypes, ((item) => item.id === value))?.backgroundColor}
              icon={
                _.find(OperationTypes, ((item) => item.id === value))?.icon
              }
            />
          ) : (
            '-'
          );

        default:
          return (
            <MuiTypography
              variant='body1'
              onClick={onClick}
              sx={{ height: '100%' }}
            >
              {value || '-'}
            </MuiTypography>
          );
      }
    },
  },
};
