import Divider from './components/Divider.component';
import PropTypes from 'prop-types';
import Section from './components/Section.component';
import Text from './components/Text.component';
import MuiChip from '@mui/material/Chip';
import { Status } from '../../module/booking/conf/Data.conf';
import _ from 'lodash';
import { Box } from '@mui/material';
import MuiTypography from '@mui/material/Typography';
import DownlaodComponent from './components/Download.component';
import Link from './components/Link.component';

export const DynamicControl = ({ type, label, value, ...props }) => {
  switch (type) {
    case 'divider': {
      return <Divider label={label} />;
    }
    case 'section': {
      return <Section label={label} {...props} />;
    }
    case 'text': {
      return <Text label={label} value={value} {...props}/>;
    }
    case 'boolean':
      return <Text label={label} value={value ? 'true' : 'false'} />;

    case 'booking_status':
      const color = _.find(
        Status,
        (item) => item.label.toUpperCase() === value.toUpperCase()
      );
      return (
        <Box display={'flex'}>
          <MuiTypography variant='subtitle1'>{`${label}:`}</MuiTypography>

          <MuiChip
            label={value}
            color={color?.color}
            sx={{
              color: color?.color ? '#fff' : color?.textHex,
              backgroundColor: color?.hex,
              marginLeft: 1,
              marginRight: 1,
              fontWeight: 500,
            }}
            size='small'
          />
        </Box>
      );
    case 'download':
      return <DownlaodComponent label={label} value={value} {...props} />;

    case 'link':
      return <Link label={label} value={value} />;

    default:
      return null;
  }
};

DynamicControl.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  sx: PropTypes.object,
};
