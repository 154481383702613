import * as yup from 'yup';
import dayjs from 'dayjs';
import { PortOptions } from '../Data.conf';

export const PilotiOUTConf = {
  schema: {
    check_out_time: yup
      .date()
      .nullable()
      .required('Service time is required or invalid')
      .transform((value, originalValue) => {
        const parsedTime = dayjs(originalValue, 'HH:mm', true);
        return parsedTime?.isValid() ? parsedTime.toDate() : null;
      }),
    check_out_date_reservation: yup
    .date()
    .nullable()
    .required('Date is required or invalid')
    .transform((value, originalValue) => {
      const parsedDate = dayjs(originalValue, 'YYYY-MM-DD', true);
      return parsedDate?.isValid() ? parsedDate.toDate() : null;
    }),
    port: yup.object().nullable().required('Port is required'),
    id_reservation_piloti_in: yup
      .object()
      .nullable()
      .required('Pilot IN is required'),
  },
  defaultValues: {
    check_out_time: null,
    check_out_date_reservation: dayjs(),
    port: null,
  },
  fields: [
    {
      id: 'service_divider',
      type: 'divider',
      label: 'Service',
      xs: 12,
    },

    {
      id: 'check_out_date_reservation',
      name: 'check_out_date_reservation',
      type: 'date',
      label: 'Check out date reservation',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'check_out_time',
      name: 'check_out_time',
      type: 'time',
      label: 'Check out time',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'port',
      name: 'port',
      type: 'autocomplete',
      label: 'Port',
      options: PortOptions,
      required: true,
      xs: 12,
      sm: 6,
    },
  ],
};
