import { Box } from '@mui/material';
import React from 'react';
import Button from '../form/components/Button.component';
import { Form } from '../form/Form';
import EditIcon from '@mui/icons-material/Edit';

const Update = ({
  defaultValues,
  disabled = false,
  onSubmit = () => {},
  fields,
  schema,
  children,
  actions,
}) => {
  return (
    <Form
      actions={actions}
      fields={fields}
      schema={schema}
      defaultValues={defaultValues}
      onSubmit={onSubmit}
    >
      {children}
      <Box display={'flex'} justifyContent='flex-end'>
        <Button
          type='submit'
          variant='outlined'
          startIcon={<EditIcon />}
          sx={{ mt: 1, mb: 2 }}
          disabled={disabled}
        >
          Update
        </Button>
      </Box>
    </Form>
  );
};

export default Update;
