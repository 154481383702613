export const SearchConf = {
  query: {
    keys: [
      {
        key: 'email',
        operator: '$containsi',
      },

      {
        key: 'username',
        operator: '$containsi',
      },
      {
        key: 'name',
        operator: '$containsi',
      },

      {
        key: 'id_users_crew.crew_responsability',
        operator: '$containsi',
      },
      {
        key: 'id_yacht.yacht_name',
        value: 'id_yacht.name',
        operator: '$eq',
      },
    ],
  },
  placeholder: 'Search by email, username, name, role or yacht name',
  initialState: {
    value: '',
  },
};
