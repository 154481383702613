import * as yup from 'yup';
import { CrewGuestType } from '../Data.conf';

export const GenericConf = {
  schema: {
    service_time: yup.date().nullable(),
    service_place: yup
      .string()
      .nullable()
      .required('Service place is required'),
    requested_service_generic: yup
      .string()
      .nullable()
      .required('Request service generic is required'),
    crew_guest: yup.object().nullable(),
    pax_number: yup.string().nullable(),
  },
  defaultValues: {
    service_time: null,
    service_place: null,
    requested_service_generic: null,
    crew_guest: null,
    pax_number: null,
  },
  fields: [
    {
      id: 'service_divider',
      type: 'divider',
      label: 'Service',
      xs: 12,
    },
    {
      id: 'service_place',
      name: 'service_place',
      type: 'text',
      label: 'Service place',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'requested_service_generic',
      name: 'requested_service_generic',
      type: 'text',
      label: 'Requested service generic',
      required: true,
      xs: 12,
      sm: 6,
    },
  
    {
      id: 'service_time',
      name: 'service_time',
      type: 'time',
      label: 'Service time',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'crew_guest',
      name: 'crew_guest',
      type: 'autocomplete',
      label: 'Crew guest',
      options: CrewGuestType,
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'pax_number',
      name: 'pax_number',
      type: 'number',
      label: 'Pax number',
      required: false,
      xs: 12,
      sm: 6,
    },
   
  ],
};
