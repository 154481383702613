export const API = {
  items: {
    path: '/items',
    method: 'GET',
    get: null,
    auth: true,
    fallback: null,
  },
  item: {
    path: '/items/{{id}}',
    method: 'GET',
    get: null,
    auth: true,
    fallback: null,
  },
  delete: {
    path: '/items/{{id}}',
    method: 'DELETE',
    get: null,
    auth: true,
    fallback: null,
  },

  create: {
    path: '/items',
    method: 'POST',
    get: null,
    auth: true,
    fallback: null,
    adapter: (body) => {
      return {
        data: {
          ...body,
          category: body?.category?.id,
        },
      };
    },
  },
  update: {
    path: '/items/{{id}}',
    method: 'PUT',
    get: null,
    auth: true,
    fallback: null,
    adapter: (body) => ({
      data: {
        ...body,
        category: body?.category?.id,
        id_item: body?.id_item?.id,
      },
    }),
  },
  select: {
    path: '/items?filters[id_mexal][code][$eq]=PRV&populate[id_mexal][fields][0]=code&populate[id_mexal_code][fields][0]=mexal_code&pagination[page]=1&pagination[pageSize]=10000',
    method: 'GET',
    get: null,
    auth: true,
    fallback: [],
    transform: (data) =>
      data?.data.map((item) => ({
        name: `${
          item?.attributes?.id_mexal_code?.data?.attributes?.mexal_code
        } - ${item?.attributes?.name_ITA ? item?.attributes?.name_ITA : ''} ${
          item?.attributes?.name_ENG ? `(${item?.attributes?.name_ENG})` : ''
        }`,
        id: item.id,
      })),
  },
  selectDEP: {
    path: '/items?filters[id_mexal][code][$eq]=DEP&populate[id_mexal][fields][0]=code&populate[id_mexal_code][fields][0]=mexal_code&pagination[page]=1&pagination[pageSize]=10000',
    method: 'GET',
    get: null,
    auth: true,
    fallback: [],
    transform: (data) =>
      data?.data.map((item) => ({
        name: `${
          item?.attributes?.id_mexal_code?.data?.attributes?.mexal_code
        } - ${item?.attributes?.name_ITA ? item?.attributes?.name_ITA : ''} ${
          item?.attributes?.name_ENG ? `(${item?.attributes?.name_ENG})` : ''
        }`,
        id: item.id,
      })),
  },
};
