import _ from 'lodash';

export const getHasPermissions = ({ location, user }) => {
  let hasPermission = 'false';

  if (!_.isEmpty(user?.permissions)) {
    let page = _.toUpper(location?.pathname?.split('/')[1]);
    let idOrAction = _.toUpper(location?.pathname?.split('/')[2]);
    let action = _.toUpper(location?.pathname?.split('/')[3]);
    let idOrPage = _.toUpper(location?.pathname?.split('/')[4]);
    let id = _.toUpper(location?.pathname?.split('/')[5]);
    let actionLast = _.toUpper(location?.pathname?.split('/')[6]);

    switch (page) {
      case 'DEPARTMENT':
        page = idOrAction;
        idOrAction = action;
        action = idOrPage;
        idOrPage = id;
        break;

      case 'PROFILE':
        page = 'DASHBOARD';
        action = 'PROFILE';
        break;

      default:
        break;
    }

    if (page === 'OPERATIONS-LIST') page = 'OPERATIONS';

    if ((!idOrAction && !action) || (idOrAction === 'OPERATIONS' && !action)) {
      //LIST
      hasPermission = user.permissions[page]?.READ;
    }

    if (
      (action && action === 'EDIT') ||
      (actionLast && actionLast === 'EDIT')
    ) {
      //EDIT PAGE
      hasPermission = user.permissions[page]?.UPDATE;
    }

    if (
      (idOrAction && idOrAction === 'ADD') ||
      (idOrPage && idOrPage === 'ADD')
    ) {
      //ADD PAGE
      hasPermission = user.permissions[page]?.CREATE;
    }

    if (!action && idOrAction && Number.parseInt(idOrAction)) {
      //DETAIL PAGE
      hasPermission = user.permissions[page]?.READ;
    }

    if (action && action === 'PROFILE') {
      //PROFILE
      hasPermission = user.permissions[page]?.PROFILE;
    }

    //TO DO: add new DEPARTMENT if there is a PRODUCT_LIST
    if (
      (page === 'PROVISIONS' || page === 'DEPOT' || page==='INTERIORS') &&
      action &&
      action === 'LIST'
    ) {
      //PRODUCT_LIST
      hasPermission = user.permissions[page]?.PRODUCT_LIST;
    }
  }
  return hasPermission;
};
