import React from "react";
import Box from '@mui/material/Box';

import PropTypes from 'prop-types';

const FormComponent = ({
    onSubmit = () => { },
    children
}) => {


    return (
        <Box component="form" onSubmit={onSubmit} sx={{ display: 'flex', width: 1, flexDirection: 'column' }} noValidate>
            {children}
        </Box>
    )
}

FormComponent.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    children: PropTypes.node,
};

export default FormComponent