import React, { useEffect, useRef, useState } from 'react';

import {
  Avatar,
  Box,
  Card,
  Chip,
  ClickAwayListener,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { IconLogout } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import { v4 as uuidv4 } from 'uuid';

const Profile = ({ onLogout = () => {} }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { user } = useSelector((state) => state.login);

  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const anchorRef = useRef(null);
  const prevOpen = useRef(open);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListItemClick = (event, index, route = '') => {
    setSelectedIndex(index);
    handleClose(event);

    if (route && route !== '') {
      navigate(route);
    }
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const menuOptions = [
    user?.permissions?.DASHBOARD?.PROFILE === 'true'
      ? {
          id: 1,
          label: 'Profile',
          icon: (
            <PermIdentityOutlinedIcon
              stroke={1.8}
              sx={{ width: 22, height: 22 }}
            />
          ),
          onClick: (event) => handleListItemClick(event, 1, '/profile'),
        }
      : null,
    // {
    //   id: 2,
    //   label: 'Change Password',
    //   icon: <VpnKeyOutlinedIcon stroke={1} sx={{ width: 22, height: 22 }} />,
    //   onClick: (event) => handleListItemClick(event, 2, '/change-password'),
    // },
    {
      id: 3,
      label: 'Logout',
      icon: <IconLogout stroke={1.8} sx={{ width: 22, height: 22 }} />,
      onClick: onLogout,
    },
  ];

  return (
    <Box>
      <Chip
        ref={anchorRef}
        onClick={handleToggle}
        label={
          <Typography
            sx={{
              color: open ? 'white' : 'black',
              '&:hover': {
                color: 'white',
              },
              display: { xs: 'none', sm: 'block' },
            }}
          >
            {user?.name}
          </Typography>
        }
        sx={{
          height: 38,
          alignItems: 'center',
          borderRadius: 27,
          transition: 'all .2s ease-in-out',
          borderColor: theme.palette.primary.light,
          backgroundColor: open
            ? theme.palette.primary.main
            : theme.palette.primary.light,
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.primary.main,
            background: `${theme.palette.primary.main}!important`,
            color: theme.palette.primary.light,
          },
          '& .MuiChip-label': {
            lineHeight: 0,
          },
        }}
        avatar={<Avatar color='inherit' />}
        color='primary'
        id='menu'
      />
      <Popper
        id={'menu'}
        open={open}
        anchorEl={anchorRef.current}
        placement='bottom'
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 5],
              },
            },
          ],
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Paper sx={{ marginRight: 2.3 }}>
            <Card elevation={16} content={false} boxShadow>
              <Stack>
                <Stack p={2}>
                  <Stack direction='row' spacing={0.5} alignItems='center'>
                    <Typography variant='h4'>Welcome</Typography>
                    <Typography
                      component='span'
                      variant='h4'
                      sx={{
                        fontWeight: 400,
                      }}
                    >
                      {user?.name}
                    </Typography>
                  </Stack>
                </Stack>

                <Divider />
                <List
                  component='nav'
                  sx={{
                    width: '100%',
                    maxWidth: 350,
                    minWidth: 300,
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: 10,
                    padding: 0,
                    [theme.breakpoints.down('md')]: {
                      minWidth: '100%',
                    },
                    '& .MuiListItemButton-root': {
                      m: 1,
                    },
                  }}
                >
                  {menuOptions?.map(
                    (item) =>
                      item && (
                        <ListItemButton
                          key={uuidv4()}
                          sx={{ borderRadius: 2 }}
                          onClick={item?.onClick}
                          selected={selectedIndex === item?.id}
                        >
                          <ListItemIcon>{item?.icon}</ListItemIcon>
                          <ListItemText
                            primary={<Typography>{item?.label}</Typography>}
                          />
                        </ListItemButton>
                      )
                  )}
                </List>
              </Stack>
            </Card>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </Box>
  );
};

export default Profile;
