import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import NavItem from '../NavItem';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import _ from 'lodash';
import { useSelector } from 'react-redux';

// ==============================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||============================== //

const NavCollapse = ({ menu, level }) => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const { selectedItem } = useSelector((state) => state.menu);

  const handleClick = () => {
    setOpen(!open);
  };

  const checkOpenForParent = (child, id) => {
    child.forEach((item) => {
      if (item.to === pathname) {
        setOpen(true);
        setSelected(id);
      }
    });
  };

  // menu collapse for sub-levels
  useEffect(() => {
    const childrens = menu.children ? menu.children : [];
    childrens.forEach((item) => {
      if (item.children?.length) {
        checkOpenForParent(item.children, menu.id);
      }
    });

    if (
      _.find(childrens, function (item) {
        return selectedItem === item.id;
      })
    ) {
      setSelected(menu.id);
    } else {
      setSelected(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, menu, selectedItem]);

  return (
    <>
      <ListItemButton
        sx={{
          borderRadius: `5px`,
          mb: 0.5,
          alignItems: 'flex-start',
          justifyContent: 'center',
          backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
          py: level > 1 ? 1 : 1.25,
        }}
        selected={selected === menu.id}
        onClick={handleClick}
      >
        <ListItemIcon sx={{ my: 'auto', minWidth: !menu.icon ? 18 : 36 }}>
          {menu?.icon}
        </ListItemIcon>

        <ListItemText
          primary={
            <Typography
              variant={selected === menu.id ? 'h5' : 'body1'}
              color='inherit'
              sx={{ my: 'auto' }}
            >
              {menu.title}
            </Typography>
          }
          secondary={
            menu.caption && (
              <Typography
                variant='caption'
                sx={{ ...theme.typography.subMenuCaption }}
                display='block'
                gutterBottom
              >
                {menu.caption}
              </Typography>
            )
          }
        />
        {open ? (
          <IconChevronUp
            stroke={1.5}
            size='16px'
            style={{ marginTop: 'auto', marginBottom: 'auto' }}
          />
        ) : (
          <IconChevronDown
            stroke={1.5}
            size='16px'
            style={{ marginTop: 'auto', marginBottom: 'auto' }}
          />
        )}
      </ListItemButton>
      <Collapse in={open} timeout='auto' unmountOnExit>
        {open && (
          <List
            component='div'
            disablePadding
            sx={{
              position: 'relative',
              '&:after': {
                content: "''",
                position: 'absolute',
                left: '30px',
                top: 0,
                height: '100%',
                width: '1.5px',
                opacity: theme.palette.mode === 'dark' ? 0.2 : 1,
                background:
                  theme.palette.mode === 'dark'
                    ? theme.palette.dark.light
                    : theme.palette.primary.light,
              },
            }}
          >
            {menu?.children?.map((item, index) => {
              return item.to ? (
                <NavItem key={index} item={item} level={1} />
              ) : (
                <NavCollapse key={index} menu={item} level={1} />
              );
            })}
          </List>
        )}
      </Collapse>
    </>
  );
};

NavCollapse.propTypes = {
  menu: PropTypes.object,
  level: PropTypes.number,
};

export default NavCollapse;
