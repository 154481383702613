import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { saveItem } from '../../../utility/Storage.utility';

export const sliceFilter = (name, initialState) =>
  createSlice({
    name,
    initialState: { ...initialState },
    reducers: {
      filterChange: (state, action) => {
        const tag = _.head(_.split(name, '/'));
        state.value = { ...action.payload };
        saveItem(tag, { filters: { ...action.payload } });
      },
    },
  });
