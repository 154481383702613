import * as yup from 'yup';
import dayjs from 'dayjs';
import _ from 'lodash';
import { RegistryTypes, Types } from './Data.conf';
import { API as flags } from '../../flag/api';

const URL =
  /^(https?:\/\/)([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}(:[0-9]{1,5})?(\/.*)?$/i;

export const YachtDimensionConf = {
  schema: {
    yacht_name: yup
      .string()
      .nullable()
      .required('Yacht Name is a required field'),
    yacht_type: yup
      .object()
      .nullable()
      .required('Yacht Type is a required field'),
    loa: yup.number().nullable().required('LOA is a required field'),
    beam: yup.number().nullable().required('BEAM is a required field'),
    draft: yup.number().nullable().required('DRAFT is a required field'),
    gross_tonn: yup
      .number()
      .nullable()
      .required('GROSS TONN is a required field'),
    // flag: yup.object().nullable().required(),
    registry_type: yup.object().nullable().required(),
    note: yup.string().nullable(),
    id_flag: yup.object().nullable().required('Flag is a required field'),
    url_web: yup
      .string()
      .nullable()
      .test(
        'is-valid-url',
        'Enter a valid url (ex. http://example.com)',
        function (value) {
          if (value !== null && value !== '') {
            return URL.test(value);
            //return yup.string().matches(URL, 'Enter a valid url')
          }
          return true;
        }
      ),

    // .when([], {
    //   is: null,
    //   then: yup.string().nullable(),
    //   otherwise: yup.string().matches(URL, 'Enter a valid url'),
    // }),
  },
  defaultValues: {
    yacht_name: null,
    loa: null,
    beam: null,
    draft: null,
    gross_tonn: null,
    yacht_type: null,
    id_flag: null,
    registry_type: null,
    note: null,
    url_web: null,
  },
  fields: [
    {
      id: 'yacht_divider',
      type: 'divider',
      label: 'Yacht',
      xs: 12,
    },
    {
      id: 'yacht_name',
      name: 'yacht_name',
      type: 'text',
      label: 'Yacht name',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'yacht_type',
      name: 'yacht_type',
      type: 'autocomplete',
      label: 'Yacht type',
      options: Types,
      xs: 12,
      sm: 6,
    },
    {
      id: 'registry_type',
      name: 'registry_type',
      type: 'autocomplete',
      label: 'Registry',
      options: RegistryTypes,
      required: true,
      xs: 12,
      sm: 6,
    },

    {
      id: 'loa',
      name: 'loa',
      type: 'text',
      label: 'Loa',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'beam',
      name: 'beam',
      type: 'text',
      label: 'Beam',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'draft',
      name: 'draft',
      type: 'text',
      label: 'Draft',
      xs: 12,
      sm: 6,
    },
    {
      id: 'gross_tonn',
      name: 'gross_tonn',
      type: 'text',
      label: 'Gross tonn',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'id_flag',
      name: 'id_flag',
      type: 'async_autocomplete',
      label: 'Flag',
      api: flags.select,
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'url_web_divider',
      type: 'divider',
      label: 'Web Address',
      xs: 12,
    },
    {
      id: 'url_web',
      name: 'url_web',
      type: 'text',
      label: 'Url',
      placeholder: 'http://example.com',
      xs: 12,
    },
  ],
};

// export const SignatureConf = {
//   schema: {
//     client_of_signature: yup.string().nullable().required('Client is required'),
//     date_submit_signature: yup
//       .date()
//       .nullable()
//       .required('Date of submission is required'),
//     signature_draw: yup.mixed().nullable(),
//     yacht_stamp: yup.mixed().nullable(),
//   },
//   defaultValues: {
//     client_of_signature: null,
//     date_submit_signature: dayjs(),
//     signature_draw: null,
//     yacht_stamp: null,
//   },
//   fields: [
//     {
//       id: 'captain_divider',
//       type: 'divider',
//       label: 'Signature',
//       xs: 12,
//     },
//     {
//       id: 'client_of_signature',
//       name: 'client_of_signature',
//       type: 'text',
//       label: 'Client',
//       required: true,
//       xs: 12,
//       sm: 6,
//     },
//     {
//       id: 'date_submit_signature',
//       name: 'date_submit_signature',
//       type: 'date',
//       label: 'Date of Submission',
//       required: true,
//       xs: 12,
//       sm: 6,
//     },
//     {
//       id: 'signature',
//       type: 'typography',
//       label: 'Signature ( please draw your signature here )',
//       xs: 12,
//       sm: 12,
//       sx: { p: 1 },
//     },
//     {
//       id: 'signature_draw',
//       name: 'signature_draw',
//       type: 'draw',
//       required: false,
//       xs: 12,
//       sm: 12,
//     },
//     {
//       id: 'yacht_stamp',
//       name: 'yacht_stamp',
//       label: 'Certificate Of Registry',
//       type: 'upload',
//       required: false,
//       xs: 12,
//       sm: 6,
//     },
//   ],
// };

export const CaptainConf = {
  schema: {
    username: yup.string().nullable().required('Username  is required'),
    email: yup
      .string()
      .nullable()
      .email('Please enter a valid email')
      .required('Email is required'),
    name: yup.string().nullable().required('Name is required'),
    surname: yup.string().nullable().required('Last name is required'),
    country_code: yup.object().nullable(),
    phone_number: yup.string().required('Phone number is required'),
  },
  defaultValues: {
    username: null,
    email: null,
    phone_number: null,
    name: null,
    surname: null,
    country_code: null,
  },
  fields: [
    {
      id: 'captain_divider',
      type: 'divider',
      label: 'Captain',
      xs: 12,
    },
    {
      id: 'name',
      name: 'name',
      type: 'text',
      label: 'Name',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'surname',
      name: 'surname',
      type: 'text',
      label: 'Surname',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'username',
      name: 'username',
      type: 'text',
      label: 'Username',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'email',
      name: 'email',
      type: 'text',
      label: 'Email',
      required: true,
      xs: 12,
      sm: 6,
    },

    {
      id: 'country_code',
      name: 'country_code',
      type: 'country-autocomplete',
      label: 'Country Code',
      required: false,
      xs: 4,
      sm: 4,
    },
    {
      id: 'phone_number',
      name: 'phone_number',
      type: 'text',
      label: 'Phone Number',
      required: true,
      xs: 8,
      sm: 8,
    },
  ],
};

export const CaptainDisabledConf = {
  schema: {},
  defaultValues: {
    username: null,
    email: null,
    phone_number: null,
    name: null,
    surname: null,
  },
  fields: [
    {
      id: 'captain_divider',
      type: 'divider',
      label: 'Captain',
      xs: 12,
    },
    {
      id: 'name',
      name: 'name',
      type: 'text',
      label: 'Name',
      required: true,
      disabled: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'surname',
      name: 'surname',
      type: 'text',
      label: 'Surname',
      required: true,
      disabled: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'username',
      name: 'username',
      type: 'text',
      label: 'Username',
      required: true,
      disabled: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'email',
      name: 'email',
      type: 'text',
      label: 'Email',
      required: true,
      disabled: true,
      xs: 12,
      sm: 6,
    },

    {
      id: 'phone_number',
      name: 'phone_number',
      type: 'text',
      label: 'Phone number',
      required: true,
      disabled: true,
      xs: 12,
      sm: 6,
    },
  ],
};
export const CompanyConf = {
  schema: {
    invoice_company_name: yup
      .string()
      .nullable()
      .required('Company Name is required'),
    invoice_street_address: yup
      .string()
      .nullable()
      .required('Street Address is required'),
    invoice_street_address2: yup.string().nullable(),
    invoice_city_address: yup.string().nullable().required('City is required'),
    invoice_country_address: yup
      .string()
      .nullable()
      .required('Country is required'),
    invoice_zipcode_addess: yup.string().nullable(),
    vat_number_company_invoicing: yup
      .string()
      .nullable()
      .required('VAT number is required'),
    vat_document: yup.mixed().nullable(),
  },
  defaultValues: {
    invoice_company_name: null,
    invoice_street_address: null,
    invoice_street_address2: null,
    invoice_city_address: null,
    invoice_country_address: null,
    invoice_zipcode_addess: null,
    vat_number_company_invoicing: null,
    vat_document: null,
    terms_and_condition: true,
  },
  fields: [
    {
      id: 'invoice_company_name',
      name: 'invoice_company_name',
      type: 'text',
      label: 'Company Name',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'invoice_street_address',
      name: 'invoice_street_address',
      type: 'text',
      label: 'Street Address',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'invoice_street_address2',
      name: 'invoice_street_address2',
      type: 'text',
      label: 'Street Address 2',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'invoice_city_address',
      name: 'invoice_city_address',
      type: 'text',
      label: 'City',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'invoice_country_address',
      name: 'invoice_country_address',
      type: 'text',
      label: 'Country',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'invoice_zipcode_addess',
      name: 'invoice_zipcode_addess',
      type: 'text',
      label: 'Postal / Zip Code',
      required: false,
      xs: 12,
      sm: 6,
    },

    {
      id: 'vat_number_company_invoicing',
      name: 'vat_number_company_invoicing',
      type: 'text',
      label: 'Vat Number',
      required: true,
      xs: 12,
    },
    // {
    //   id: 'vat_document',
    //   name: 'vat_document',
    //   label: 'Certificate of VAT or Incorporation',
    //   type: 'upload',
    //   required: false,
    //   xs: 12,
    //   sm: 6,
    // },
  ],
};

export const NaturalPersonConf = {
  schema: {
    invoice_street_address: yup
      .string()
      .nullable()
      .required('Street Address is required'),
    invoice_street_address2: yup.string().nullable(),
    invoice_city_address: yup.string().nullable().required('City is required'),
    invoice_country_address: yup
      .string()
      .nullable()
      .required('Country is required'),
    invoice_zipcode_addess: yup.string().nullable(),
    invoice_position: yup.string().nullable(),

    invoice_person_name: yup.string().nullable().required('Name is required'),
    invoice_person_lastname: yup
      .string()
      .nullable()
      .required('Last Name is required'),
    invoice_person_place_of_birth: yup
      .string()
      .nullable()
      .required('Place of birth is required'),
    invoice_person_birthdate: yup
      .date()
      .nullable()
      .required('Birth date is required'),
    report_number: yup.number().nullable(),
    passaport: yup.mixed().nullable(),
  },
  defaultValues: {
    invoice_street_address: null,
    invoice_street_address2: null,
    invoice_city_address: null,
    invoice_country_address: null,
    invoice_zipcode_addess: null,
    invoice_position: null,
    invoice_person_name: null,
    invoice_person_lastname: null,
    invoice_person_place_of_birth: null,
    invoice_person_birthdate: dayjs(),
    report_number: null,
    passaport: null,
    terms_and_condition: true,
  },
  fields: [
    {
      id: 'invoice_person_name',
      name: 'invoice_person_name',
      type: 'text',
      label: 'Name',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'invoice_person_lastname',
      name: 'invoice_person_lastname',
      type: 'text',
      label: 'Last Name',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'invoice_person_place_of_birth',
      name: 'invoice_person_place_of_birth',
      type: 'text',
      label: 'Place of Birth',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'invoice_person_birthdate',
      name: 'invoice_person_birthdate',
      type: 'date',
      label: 'Birth date',
      required: true,
      disableFuture: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'invoice_street_address',
      name: 'invoice_street_address',
      type: 'text',
      label: 'Street Address',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'invoice_street_address2',
      name: 'invoice_street_address2',
      type: 'text',
      label: 'Street Address 2',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'invoice_city_address',
      name: 'invoice_city_address',
      type: 'text',
      label: 'City',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'invoice_country_address',
      name: 'invoice_country_address',
      type: 'text',
      label: 'Country',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'invoice_zipcode_addess',
      name: 'invoice_zipcode_addess',
      type: 'text',
      label: 'Postal / Zip Code',
      required: false,
      xs: 12,
      sm: 6,
    },
    // {
    //   id: 'passaport',
    //   name: 'passaport',
    //   label: 'Copy of Passport',
    //   type: 'upload',
    //   required: false,
    //   xs: 12,
    //   sm: 6,
    // },
  ],
};

export const UpdateConf = {
  'NATURAL PERSON': {
    schema: yup.object({
      ...YachtDimensionConf.schema,
      // ...CaptainConf.schema,
      ...NaturalPersonConf.schema,
      //...SignatureConf.schema,
    }),
    defaultValues: {
      ...YachtDimensionConf.defaultValues,
      // ...CaptainConf.defaultValues,
      ...NaturalPersonConf.defaultValues,
      //...SignatureConf.defaultValues,
    },
    fields: _.concat(
      // YachtDimensionConf.fields,
      NaturalPersonConf.fields
      // CaptainConf.fields
      // SignatureConf.fields
    ),
  },
  'NATURAL PERSON_captain': {
    schema: yup.object({
      ...YachtDimensionConf.schema,
      //...CaptainDisabledConf.schema,
      ...NaturalPersonConf.schema,
      //...SignatureConf.schema,
    }),
    defaultValues: {
      ...YachtDimensionConf.defaultValues,
      //...CaptainDisabledConf.defaultValues,
      ...NaturalPersonConf.defaultValues,
      //...SignatureConf.defaultValues,
    },
    fields: _.concat(
      // YachtDimensionConf.fields,
      NaturalPersonConf.fields
      // CaptainDisabledConf.fields,
      // SignatureConf.fields
    ),
  },
  COMPANY: {
    schema: yup.object({
      ...YachtDimensionConf.schema,
      // ...CaptainConf.schema,
      ...CompanyConf.schema,
      //...SignatureConf.schema,
    }),
    defaultValues: {
      ...YachtDimensionConf.defaultValues,
      //...CaptainConf.defaultValues,
      ...CompanyConf.defaultValues,
      // ...SignatureConf.defaultValues,
    },
    fields: _.concat(
      // YachtDimensionConf.fields,
      CompanyConf.fields
      //CaptainConf.fields,
      //SignatureConf.fields
    ),
  },
  COMPANY_captain: {
    schema: yup.object({
      ...YachtDimensionConf.schema,
      //...CaptainDisabledConf.schema,
      ...CompanyConf.schema,
      //...SignatureConf.schema,
    }),
    defaultValues: {
      ...YachtDimensionConf.defaultValues,
      //...CaptainDisabledConf.defaultValues,
      ...CompanyConf.defaultValues,
      //...SignatureConf.defaultValues,
    },
    fields: _.concat(
      // YachtDimensionConf.fields,
      CompanyConf.fields
      //CaptainDisabledConf.fields,
      //SignatureConf.fields
    ),
  },
};
