// CONF
import { ListConf } from './conf/List.conf';
import { FilterConf } from './conf/Filter.conf';
import { SearchConf } from './conf/Search.conf';
import { DetailConf } from './conf/Detail.conf';
import { UpdateConf } from './conf/Update.conf';

// REDUX
import { reducer, actions, editSelector } from './slice';

// SELECTOR
import { tag, querySelector, listSelector, detailSelector } from './slice';

// SAGA
import { saga } from './saga';

// API
import { API } from './api';
import { CreateConf } from './conf/Create.conf';
// import { UpdateConfCompany } from '../companyDetails/conf/Update.conf';
// import { UpdateConf } from '../naturalPersonDetails/conf/Update.conf';

export { reducer, saga, tag };

const yacht = {
  tag,
  query: {
    all: {
      selector: querySelector,
      action: actions.fetchAllAction,
      settings: API.yachts,
      populate: '*',
    },
    detail: {
      action: actions.fetchDetailAction,
      settings: API.yacht_crew,
      populate: '*',
    },
    edit: {
      settings: API.update,
    },
    create: {
      settings: API.create,
    },
  },
  search: {
    conf: SearchConf,
    actions: {
      search: actions.searchChange,
    },
  },
  filter: {
    conf: FilterConf,
    actions: {
      filter: actions.filterChange,
    },
  },
  list: {
    conf: ListConf,
    actions: {
      pagination: actions.paginationChange,
      sort: actions.sortChange,
    },
    selector: {
      data: listSelector,
    },
    add: {
      to: 'add',
    },
    link: {
      to: process.env.REACT_APP_USER_APPLICATION,
      label: 'ONLINE REGISTRATION',
      target: '_blank',
    },
  },
  detail: {
    conf: DetailConf,
    selector: {
      data: detailSelector,
    },
    edit: {
      to: 'edit',
    },
  },
  create: {
    conf: CreateConf,
  },
  edit: {
    conf: UpdateConf,
    selector: {
      data: editSelector,
    },
  },
};

export default yacht;
