import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const LinkPermission = ({ to, value, page, ...style }) => {
  const userAuth = useSelector((state) => state.login?.user);
  const hasPermissions = userAuth.permissions?.[page]?.READ === 'true';

  return (
    <Link
      to={to}
      style={{
        color: hasPermissions ? 'blue' : 'grey',
        pointerEvents: hasPermissions ? '': 'none',
        ...style,
      }}
    >
      {value}
    </Link>
  );
};

LinkPermission.propTypes = {
  label: PropTypes.string,
  variant: PropTypes.string,
};

export default LinkPermission;
