import React from 'react';
import MUISelect from '@mui/material/Select';
import MUIFormControl from '@mui/material/FormControl';
import MUIInputLabel from '@mui/material/InputLabel';
import MUIBox from '@mui/material/Box';
import Error from './Error.component';
import { useController, useFormContext } from 'react-hook-form';
import _ from 'lodash';
import RectangleChip from '../../common/RectangleChip.component';
import MuiMenuItem from '@mui/material/MenuItem';
import { v4 as uuidv4 } from 'uuid';

import PropTypes from 'prop-types';

const SelectCustom = ({ name, required, options, label, icon, ...props }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const {
    field: { ref, onChange, value },
  } = useController({
    name: name,
    control: control,
    rules: { required },
  });

  return (
    <MUIBox display='flex' flexDirection={'column'}>
      <MUIFormControl error={!!errors[name]?.message}>
        <MUIInputLabel required={required}> {label}</MUIInputLabel>
        <MUISelect
          ref={ref}
          value={value || ''}
          onChange={onChange}
          label={label}
          renderValue={(item) => {
            const selected = _.find(options, (option) => option?.id === item);
            return (
              selected && (
                <RectangleChip
                  value={selected?.label}
                  backgroundColor={selected?.backgroundColor}
                  color={selected?.color}
                  width={selected?.width}
                  icon={selected?.icon}

                />
              )
            );
          }}
          sx={{ height: 53 }}
          {...props}
        >
          {!required && (
            <MuiMenuItem value=''>
              <em>None</em>
            </MuiMenuItem>
          )}
          {options?.map((option) => (
            <MuiMenuItem key={uuidv4()} value={option?.id}>
              <RectangleChip
                value={option?.label}
                backgroundColor={option?.backgroundColor}
                color={option?.color}
                width={120}
                icon={option?.icon}
              />
            </MuiMenuItem>
          ))}
        </MUISelect>
      </MUIFormControl>

      <Error error={errors[name]?.message} />
    </MUIBox>

  );
};

SelectCustom.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  icon: PropTypes.node
};

export default SelectCustom;
