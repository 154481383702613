import * as yup from 'yup';
import { API as yachts } from '../../yachts/api';
import { API as users_nautica } from '../../users_nautica/api';
import dayjs from 'dayjs';

export const CreateConf = {
  schema: yup.object({
    id_yacht: yup.object().nullable().required('Yacht is required'),
    id_agent_NA: yup.object().nullable().required('Agent NA is required'),
    is_central_agent: yup.boolean().nullable(),
    id_user_provision: yup.object().nullable(),
    id_user_tech: yup.object().nullable(),
    registration_date: yup.date().nullable(),
    provenence_group: yup
      .object()
      .nullable()
      .required('Provenence group is required'),
    first_contact: yup
      .object()
      .nullable()
      .required('First contact is required'),
    provenence_sales_channel: yup.object().nullable(),
    provenence: yup.object().nullable(),
    note: yup.string().nullable(),
    // .test(
    // 	'provenenceRequired',
    // 	'note is required when provenence is CAMPO NOTE',
    // 	function (value) {
    // 		const provenence = this.parent.provenence?.name;
    // 		if (provenence && provenence === 'CAMPO NOTE') {
    // 			return value !== null && typeof value === 'object';
    // 		}
    // 		return true;
    // 	}
    // )
    users_nauticas: yup
      .object()
      .nullable()
      .test(
        'usersNauticasRequired',
        "User is required when 'First contact' is USER",
        function (value) {
          const firstContact = this.parent.first_contact?.id;
          if (firstContact && firstContact === 'USER') {
            return value !== null && typeof value === 'object';
          }
          return true;
        }
      ),
    note_attendance: yup.string().nullable(),
  }),
  defaultValues: {
    id_yacht: null,
    id_agent_NA: null,
    is_central_agent: false,
    id_user_provision: null,
    id_user_tech: null,
    note: '',
    registration_date: dayjs(),
    provenence_group: null,
    first_contact: null,
    provenence_sales_channel: null,
    provenence: null,
    users_nauticas: null,
    note_attendance: null,
  },
  fields: [
    {
      id: 'yacht_divider',
      type: 'divider',
      label: 'Yacht',
      xs: 12,
    },
    {
      id: 'id_yacht',
      name: 'id_yacht',
      type: 'async_autocomplete',
      label: 'Yacht',
      required: true,
      api: yachts.selectWithStatus,
      xs: 12,
    },
    {
      id: 'user_divider',
      type: 'divider',
      label: 'NA Agent',
      xs: 12,
    },
    {
      id: 'id_agent_NA',
      name: 'id_agent_NA',
      type: 'async_autocomplete',
      label: 'NA Agent',
      api: users_nautica.selectName,
      required: true,
      xs: 12,
    },
    {
      id: 'user_provision_divider',
      type: 'divider',
      label: 'User Provisions',
      xs: 12,
    },
    {
      id: 'id_user_provision',
      name: 'id_user_provision',
      type: 'async_autocomplete',
      label: 'User Provisions',
      required: false,
      api: users_nautica.selectName,
      xs: 12,
    },
    {
      id: 'user_tech_divider',
      type: 'divider',
      label: 'User Tech',
      xs: 12,
    },
    {
      id: 'id_user_tech',
      name: 'id_user_tech',
      type: 'async_autocomplete',
      label: 'User Tech',
      required: false,
      api: users_nautica.selectName,
      xs: 12,
    },
    {
      id: 'divider_0',
      type: 'divider',
      xs: 12,
    },
    {
      id: 'note_attendance',
      name: 'note_attendance',
      type: 'text',
      label: 'Note Attendance',
      xs: 12,
    },
    {
      id: 'is_central_agent',
      name: 'is_central_agent',
      type: 'switch',
      label: 'Central Agent',
      xs: 12,
    },
  ],
};
