import React, { useEffect } from 'react';

import { useFormContext } from 'react-hook-form';
import Autocomplete from '../form/components/Autocomplete.component';
import { useSelector } from 'react-redux';
import { fetchAPI } from '../../api/request';

const AgentNAForm = () => {
  const { watch, setValue } = useFormContext();
  const watchYacht = watch('id_yacht');
  const { jwt } = useSelector((state) => state.login);

  useEffect(() => {
    if (watchYacht) {
      fetchAPI(
        {
          path: `/yachts/${watchYacht.id}?populate[id_attendance][populate][id_agent_NA][populate][id_users_permissions_user][fields][0][1]=name, surname`,
          method: 'GET',
          auth: true,
        },
        {},
        '',
        {},
        jwt
      )
        .then((resp) => {
          const agent_NA =
            resp?.data?.attributes?.id_attendance?.data[0]?.attributes
              ?.id_agent_NA?.data?.attributes?.id_users_permissions_user?.data
              ?.attributes;

          setValue(
            'id_agent_NA',
            !!agent_NA
              ? {
                  label: `${agent_NA?.name} ${agent_NA?.surname}`,
                  id: `${agent_NA?.name} ${agent_NA?.surname}`,
                }
              : null
          );
        })
        .catch(() => setValue('id_agent_NA', null));
    } else {
      setValue('id_agent_NA', null);
    }
  }, [watchYacht, jwt, setValue]);

  return (
    <Autocomplete
      name={'id_agent_NA'}
      id={'id_agent_NA'}
      label={'NA Agent'}
      disabled={true}
      options={[]}
    />
  );
};

export default AgentNAForm;
