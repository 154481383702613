import * as yup from 'yup';
import { OperationConf } from '../../operations/conf/Create.conf';
import _ from 'lodash';
import { API as marina } from '../../marina/api';
import { API as servicesOperationList } from '../api/index'

export const CommonFiels = [
  {
    id: 'yacht_divider',
    type: 'divider',
    label: 'Yacht',
    xs: 12,
  },
  {
    id: 'yacht_name',
    name: 'yacht_name',
    type: 'text',
    label: 'Yacht',
    required: true,
    disabled: true,
    xs: 12,
  },
  {
    id: 'agent_NA_name',
    name: 'agent_NA_name',
    type: 'text',
    label: 'NA Agent',
    disabled: true,
    required: false,
    xs: 12,
  },
];

const operationService = yup.object().nullable().required('Service is required');

export const UpdateConf = {
  schema: yup.object({
    ...OperationConf.schema.fields,
    id_service: operationService,
  }),
  defaultValues: OperationConf.defaultValues,
  fields: _.concat(
    CommonFiels,
    {
      id: 'service_generic',
      type: 'divider',
      label: 'Service',
      xs: 12,
    },
    {
      id: 'id_service',
      name: 'id_service',
      type: 'async_autocomplete',
      api: servicesOperationList.servicesOperationList,
      label: 'Service',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'id_marina',
      name: 'id_marina',
      type: 'async_autocomplete',
      label: 'Location',
      api: marina.select,
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'requested_date',
      name: 'requested_date',
      type: 'date',
      label: 'Delivery Date',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'requested_hour',
      name: 'requested_hour',
      type: 'time',
      label: 'Delivery Time',
      required: false,
      xs: 12,
      sm: 4,
    },
    {
      id: 'hour_type',
      name: 'hour_type',
      type: 'autocomplete',
      label: 'Hour Type',
      required: false,
      options: [
        { id: 'AM', label: 'AM' },
        { id: 'PM', label: 'PM' },
      ],
      xs: 12,
      sm: 2,
    },
    OperationConf.fields.slice(5, 17),
    OperationConf.fields.slice(18)
  ),
};
