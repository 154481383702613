import { dataSlice as createDataSLice } from '../../../core/data';
import { sliceList as createListSlice } from '../../../core/list';
import { sliceFilter as createFilterSlice } from '../../../core/filter';
import { sliceSearch as createSearchSlice } from '../../../core/search';
import { detailSlice as createDetailSlice } from '../../../core/detail';
import {
  combineReducers,
  createAction,
  createSelector,
} from '@reduxjs/toolkit';

import { ListConf } from '../conf/List.conf';
import { SearchConf } from '../conf/Search.conf';
import { FilterConf } from '../conf/Filter.conf';
import { DataConf } from '../conf/Data.conf';
import { QueryConf } from '../conf/Query.conf';
import { DetailConf } from '../conf/Detail.conf';
import _ from 'lodash';

export const tag = 'yachts';

// REDUCERS
const dataSlice = createDataSLice(`${tag}/data`, DataConf.initialState);
const listSlice = createListSlice(`${tag}/list`, ListConf.initialState);
const filterSlice = createFilterSlice(`${tag}/filter`, FilterConf.initialState);
const searchSlice = createSearchSlice(`${tag}/search`, SearchConf.initialState);
const detailSlice = createDetailSlice(`${tag}/detail`, DetailConf.initialState);

export const reducer = combineReducers({
  search: searchSlice.reducer,
  data: dataSlice.reducer,
  list: listSlice.reducer,
  filter: filterSlice.reducer,
  detail: detailSlice.reducer,
});

// ACTIONS
export const actions = {
  fetchAllAction: createAction(`${tag}/fetchAll`),
  fetchDetailAction: createAction(`${tag}/fetchOne`),
  ...searchSlice.actions,
  ...dataSlice.actions,
  ...listSlice.actions,
  ...filterSlice.actions,
  ...detailSlice.actions,
};

// QUERY
export const querySelector = createSelector(
  (state) => state[tag].list,
  (state) => state[tag].filter,
  (state) => state[tag].search,
  (list, filter, search) => QueryConf.get(list, filter, search)
);

// LIST
export const listSelector = createSelector(
  (state) => state[tag].data,
  (state) => state[tag].list,
  ({ data, meta }, { pagination, sort }) => ({
    data,
    meta,
    pagination,
    sort,
  })
);

// DETAIL
export const detailSelector = createSelector(
  (state) => state[tag].detail,
  (detail) => detail
);

// EDIT
export const editSelector = createSelector(
  (state) => state[tag].detail,
  ({ data, loading, error }) => {
    const yacht_type = _.get(data, 'attributes.yacht_type');
    const invoicing_entity = _.get(data, 'attributes.invoicing_entity');
    const fees_type = _.get(data, 'attributes.fees_type');
    const registry_type = _.get(data, 'attributes.registry_type');

    const captain = _.get(
      data,
      'attributes.id_user_crew.data[0].attributes.id_users_permissions_user.data.attributes'
    );

    const flag = _.get(data, 'attributes.id_flag.data');
    const phone = _.get(
      data,
      'attributes.id_user_crew.data[0].attributes.phone_number'
    );
    const lastDashIndex = _.lastIndexOf(phone, '-');
    let phone_number = phone;
    let country_code = null;

    if (lastDashIndex > 0) {
      country_code = phone.slice(0, lastDashIndex);
      phone_number = phone.slice(lastDashIndex + 1);
    }

    return {
      loading,
      error,
      data: data
        ? {
            //CAPTAIN
            name: captain?.name,
            surname: captain?.surname,
            username: captain?.username,
            email: captain?.email,
            birthdate: _.get(
              data,
              'attributes.id_user_crew.data[0].attributes.birthdate'
            ),
            phone_number: phone_number,
            country_code: country_code ? { phone: country_code } : null,
            //YACHT
            id_yacht: {
              name: _.get(data, 'attributes.yacht_name'),
              id: _.get(data, 'id'),
            },
            yacht_type: yacht_type
              ? { label: yacht_type, id: yacht_type }
              : null,
            fees_type: fees_type ? { label: fees_type, id: fees_type } : null,
            port_of_registry: _.get(data, 'attributes.port_of_registry'),
            registry_type: registry_type
              ? { label: registry_type, id: registry_type }
              : null,
            invoicing_entity: invoicing_entity
              ? { label: invoicing_entity, id: invoicing_entity }
              : null,
            terms_and_condition: _.get(data, 'attributes.terms_and_condition'),

            invoice_company_name: _.get(
              data,
              'attributes.invoice_company_name'
            ),
            invoice_person_name: _.get(data, 'attributes.invoice_person_name'),
            invoice_person_lastname: _.get(
              data,
              'attributes.invoice_person_lastname'
            ),
            invoice_person_place_of_birth: _.get(
              data,
              'attributes.invoice_person_place_of_birth'
            ),
            invoice_person_birthdate: _.get(
              data,
              'attributes.invoice_person_birthdate'
            ),
            invoice_person_of_payment: _.get(
              data,
              'attributes.invoice_person_of_payment'
            ),

            invoice_street_address: _.get(
              data,
              'attributes.invoice_street_address'
            ),
            invoice_street_address2: _.get(
              data,
              'attributes.invoice_street_address2'
            ),
            invoice_city_address: _.get(
              data,
              'attributes.invoice_city_address'
            ),
            invoice_country_address: _.get(
              data,
              'attributes.invoice_country_address'
            ),
            invoice_zipcode_addess: _.get(
              data,
              'attributes.invoice_zipcode_addess'
            ),
            invoice_position: _.get(data, 'attributes.invoice_position'),
            invoice_email: _.get(data, 'attributes.invoice_email'),
            email_comunication: _.get(data, 'attributes.email_comunication'),

            vat_number_company_invoicing: _.get(
              data,
              'attributes.vat_number_company_invoicing'
            ),

            notification: _.get(data, 'attributes.notification')
              ? _.get(data, 'attributes.notification')
              : false,

            whatsapp: _.get(data, 'attributes.whatsapp')
              ? _.get(data, 'attributes.whatsapp')
              : false,
            feedback: _.get(data, 'attributes.feedback')
              ? _.get(data, 'attributes.feedback')
              : false,
            commercial: _.get(data, 'attributes.commercial')
              ? _.get(data, 'attributes.commercial')
              : false,

            client_of_signature: _.get(data, 'attributes.client_of_signature'),
            note: _.get(data, 'attributes.note'),
            yacht_name: _.get(data, 'attributes.yacht_name'),
            loa: _.get(data, 'attributes.loa'),
            beam: _.get(data, 'attributes.beam'),
            draft: _.get(data, 'attributes.draft'),
            gross_tonn: _.get(data, 'attributes.gross_tonn'),
            date_submit_signature: _.get(
              data,
              'attributes.date_submit_signature'
            ),
            id_user_crew: _.get(data, 'attributes.id_user_crew.data'),
            id_flag: flag
              ? {
                  name: flag?.attributes?.locality_flag,
                  id: flag?.id,
                }
              : null,
            url_web: _.get(data, 'attributes.url_web'),
            registration_status: _.get(data, 'attributes.registration_status'),
          }
        : null,
    };
  }
);
