import React from 'react';

import { Divider, Paper, Stack } from '@mui/material';
import { HeadProductsInLogbookEditable } from '../../../../module/listItemsProvisions/conf/Data.conf';
import TableProductsInLogbookEditable from '../../../../components/provisions/logbook/table/TableProductsInLogbookEditable.component';
import AccordionComponent from '../../../../components/common/Accordion.component';

const LogbookList = ({
  data,
  setSuccess = () => {},
  orderById,
  expanded,
  onChange = () => {},
}) => {
  return (
    <Paper variant='outlined' sx={{ marginBottom: 5 }}>
      <Stack divider={<Divider />}>
        <AccordionComponent
          expanded={expanded}
          onChange={onChange}
          title='Sent to Logbook'
          details={
            <TableProductsInLogbookEditable
              orderById={orderById}
              header={HeadProductsInLogbookEditable}
              data={data}
              setSuccessUpdate={setSuccess}
            />
          }
        />
      </Stack>
    </Paper>
  );
};

export default LogbookList;
