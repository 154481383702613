import moment from 'moment';
import Text from '../../../components/detail/components/Text.component';
import { CommonDetailConf } from '../../yachts/conf/Detail.conf';
import Link from '../../../components/detail/components/Link.component';
import { Grid } from '@mui/material';
import _ from 'lodash';
import LinkPermission from '../../../components/detail/components/LinkPermission.component';

export const DetailConf = {
  initialState: {
    loading: false,
    error: null,
    data: null,
    meta: null,
  },
  fields: [
    {
      ...CommonDetailConf,
      items: [
        {
          type: 'text',
          label: 'Yacht name',
          key: 'attributes.yacht',
          xs: 12,
        },
        ...CommonDetailConf.items.map(
          (item) =>
            item.key !== 'attributes.yacht_name' && {
              ...item,
              key: `attributes.id_yacht.data.${item.key}`,
            }
        ),
      ],
    },

    {
      id: 'na_agent',
      type: 'section',
      label: 'NA Agent',
      items: [
        {
          type: 'text',
          label: '',
          key: 'attributes.id_yacht.data.attributes.id_attendance.data[0].attributes.id_agent_NA.data.attributes.id_users_permissions_user.data',
          xs: 12,
        },
      ],
    },

    {
      id: 'booking_section',
      type: 'section',
      label: 'Booking Details',
      items: [
        {
          type: 'text',
          label: 'Check In',
          key: 'attributes.check_in_date',
          xs: 12,
          sm: 6,
          md: 4,
        },
        {
          type: 'text',
          label: 'Check Out',
          key: 'attributes.check_out_date',
          xs: 12,
          sm: 6,
          md: 4,
        },
        {
          type: 'booking_status',
          label: 'Status',
          key: 'attributes.booking_status',
          xs: 12,
          sm: 6,
          md: 4,
        },
        {
          type: 'text',
          label: 'Nights',
          key: 'attributes.booking_days',
          xs: 12,
          sm: 6,
          md: 4,
        },
        {
          type: 'text',
          label: 'Cash',
          key: 'attributes.cash',
          xs: 12,
          sm: 6,
          md: 4,
        },
        {
          type: 'text',
          label: 'Note',
          key: 'attributes.note',
          xs: 12,
        },
        {
          type: 'text',
          label: 'Operation',
          key: 'attributes.id_reservation.data.attributes.id_operation_list.id',
          xs: 12,
        },
      ],
    },
    {
      id: 'booking_location_section',
      type: 'section',
      label: 'Booking Location',
      items: [
        {
          type: 'text',
          label: 'Marina',
          key: 'attributes.id_marina.data.attributes.marina',
          xs: 12,
          sm: 6,
          md: 4,
        },
        {
          type: 'text',
          label: 'Location',
          key: 'attributes.id_marina.data.attributes.location',
          xs: 12,
          sm: 6,
          md: 4,
        },
        {
          type: 'text',
          label: 'Code',
          key: 'attributes.id_marina.data.attributes.code',
          xs: 12,
          sm: 6,
          md: 4,
        },
      ],
    },
  ],
  formatValue: (key, value) => {
    switch (key) {
      case 'attributes.yacht':
        return (
          <Grid container>
            <Grid item xs={12} sm={6} md={3}>
              <Text
                label='Yacht Name'
                value={
                  <LinkPermission
                    to={`/yachts/${value?.id}`}
                    value={value?.yacht_name}
                    page='YACHTS'
                  />
                }
              />
            </Grid>
            {value?.url_web && (
              <Grid item xs={12} sm={6} md={3}>
                <Link label='Web' value={value?.url_web} />
              </Grid>
            )}
          </Grid>
        );
      case 'attributes.id_reservation.data.attributes.id_operation_list.id':
        return (
          <Text
            label='Operation'
            value={
              <LinkPermission
                to={`/department/agency/${value}`}
                value={`${value}`}
                page='AGENCY'
              />
            }
          />
        );

      case 'attributes.id_yacht.data.attributes.draft':
        return (
          <Text label='Draft' value={_.isEmpty(value) ? `${value} m` : '-'} />
        );

      case 'attributes.id_yacht.data.attributes.loa':
        return (
          <Text label='Loa' value={_.isEmpty(value) ? `${value} m` : '-'} />
        );

      case 'attributes.id_yacht.data.attributes.beam':
        return (
          <Text label='Beam' value={_.isEmpty(value) ? `${value} m` : '-'} />
        );
      case 'attributes.check_in_date':
        return (
          <Text label='Check In' value={moment(value).format('DD-MM-YYYY')} />
        );
      case 'attributes.check_out_date':
        return (
          <Text label='Check Out' value={moment(value).format('DD-MM-YYYY')} />
        );
      case 'attributes.id_yacht.data.attributes.id_attendance.data[0].attributes.id_agent_NA.data.attributes.id_users_permissions_user.data':
        return (
          <Text
            label='Name'
            value={
              <LinkPermission
                to={`/contacts/${value?.id}`}
                value={`${value?.attributes?.name} ${value?.attributes?.surname}`}
                page='CONTACTS_NAUTICA'
              />
            }
          />
        );
      default:
        return null;
    }
  },
};
