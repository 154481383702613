import * as yup from 'yup';
import { RegistryTypes, Types } from './Data.conf';
import dayjs from 'dayjs';
import { API as flags } from '../../flag/api';

export const CreateConf = {
  schema: yup.object({
    yacht_name: yup
      .string()
      .nullable()
      .required('Yacht Name is a required field'),
    yacht_type: yup
      .object()
      .nullable()
      .required('Yacht Type is a required field'),
    loa: yup.number().nullable().required('LOA is a required field'),
    beam: yup.number().nullable().required('BEAM is a required field'),
    draft: yup.number().nullable().required('DRAFT is a required field'),
    gross_tonn: yup
      .number()
      .nullable()
      .required('GROSS TONN is a required field'),
    id_flag: yup.object().nullable().required('Flag is a required field'),
    registry_type: yup
      .object()
      .nullable()
      .required('Registry is a required field'),
    note: yup.string().nullable(),
    // port_of_registry: yup.string().nullable().required(),
  }),
  defaultValues: {
    yacht_name: null,
    loa: null,
    beam: null,
    draft: null,
    gross_tonn: null,
    yacht_type: null,
    id_flag: null,
    registry_type: null,
    note: null,
    // port_of_registry: null,
    date_submit_signature: dayjs(),
  },
  fields: [
    {
      id: 'yacht_divider',
      type: 'divider',
      label: 'Yacht',
      xs: 12,
    },

    {
      id: 'yacht_name',
      name: 'yacht_name',
      type: 'text',
      label: 'Yacht name',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'yacht_type',
      name: 'yacht_type',
      type: 'autocomplete',
      label: 'Yacht type',
      options: Types,
      required: true,
      xs: 12,
      sm: 6,
    },

    {
      id: 'loa',
      name: 'loa',
      type: 'number',
      label: 'Loa (m)',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'beam',
      name: 'beam',
      type: 'number',
      label: 'Beam (m)',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'draft',
      name: 'draft',
      type: 'number',
      label: 'Draft (m)',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'gross_tonn',
      name: 'gross_tonn',
      type: 'number',
      label: 'Gross tonn',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'registry_type',
      name: 'registry_type',
      type: 'autocomplete',
      label: 'Registry',
      options: RegistryTypes,
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'id_flag',
      name: 'id_flag',
      type: 'async_autocomplete',
      label: 'Flag',
      api: flags.select,
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'note',
      name: 'note',
      type: 'text',
      label: 'Note',
      xs: 12,
    },

    // {
    //   id: 'port_of_registry',
    //   name: 'port_of_registry',
    //   type: 'text',
    //   label: 'Port Of Registry',
    //   required: true,
    //   xs: 12,
    //   sm: 6,
    // },
  ],
};
