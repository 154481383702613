import React, {  useEffect } from 'react';
import { Container, Stack, Paper, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Back from '../../../components/common/Back.component';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../../components/common/Loading.component';
import DetailView from '../../../views/Detail.view';

import Typography from '../../../components/common/Typography.component';
import dayjs from 'dayjs';
import _ from 'lodash';
import EditButton from '../../../components/detail/components/EditButton.component';

const YachtDetailPage = ({module:{ tag, detail, query }}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { jwt } = useSelector((state) => state.login);
  const { loading, data, error } = useSelector(detail.selector.data);
  const navigate = useNavigate();
  const userAuth = useSelector((state) => state.login?.user);

  useEffect(() => {
    if (userAuth.permissions[_.toUpper(tag)]?.READ === 'true') {
      dispatch(
        query.detail.action({
          settings: query.detail.settings,
          token: jwt,
          params: { id },
          query: { populate: query.detail.populate },
        })
      );
    }

    return () => {
      dispatch(
        query.detail.action({
          settings: null,
          token: null,
          params: null,
          query: null,
        })
      );
    };
    // eslint-disable-next-line
  }, [id, userAuth, jwt]);

  useEffect(() => {
    if (
      error &&
      (error?.response?.status === 404 || error?.response?.status === 500)
    )
      navigate('/404');
  }, [error, navigate]);

  return (
    <Container disableGutters={true} maxWidth={false} sx={{ height: '100%' }}>
      <Stack spacing={2} justifyContent='center' alignItems='center' width={1}>
        <Back title={tag} />
        {loading && <Loading />}
        <Paper variant='outlined' sx={{ padding: 2, width: '100%' }}>
          {detail?.edit &&
            userAuth.permissions[_.toUpper(tag)]?.UPDATE === 'true' && (
              <Stack
                direction={'row'}
                justifyContent='flex-end'
                mb={2}
                spacing={1}
              >
                <EditButton to={detail.edit.to} tag={tag} />
                {/* {deleteView} */}
              </Stack>
            )}
          {userAuth.permissions[_.toUpper(tag)]?.READ === 'true' && (
            <DetailView
              data={data}
              selector={detail.selector.data}
              conf={detail.conf}
            />
          )}

          {data?.attributes?.is_migrated && (
            <Box
              display='flex'
              m={1}
              justifyContent='end'
              alignItems='center'
              sx={{ color: 'grey' }}
            >
              <Typography variant='caption'>{`Migrated at ${dayjs(
                data?.attributes?.createdAt
              ).format('DD-MM-YYYY')}`}</Typography>
            </Box>
          )}
        </Paper>
      </Stack>
    </Container>
  );
};

export default YachtDetailPage;
