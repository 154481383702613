import { dataSlice as createDataSLice } from '../../../core/data';
import { sliceList as createListSlice } from '../../../core/list';
import { sliceFilter as createFilterSlice } from '../../../core/filter';
import { detailSlice as createDetailSlice } from '../../../core/detail';
import { sliceSearch as createSearchSlice } from '../../../core/search';
import {
  combineReducers,
  createAction,
  createSelector,
} from '@reduxjs/toolkit';
import _ from 'lodash';

import { DataConf } from '../../operations/conf/Data.conf';
import { DetailConf } from '../../operations/conf/Detail.conf';
import { ListConf } from '../conf/List.conf';
import { FilterConf } from '../conf/Filter.conf';
import { SearchConf } from '../conf/Search.conf';
import { QueryConf } from '../conf/Query.conf';

import dayjs from 'dayjs';

export const tag = 'agency';

// REDUCERS
const dataSlice = createDataSLice(`${tag}/data`, DataConf.initialState);
const listSlice = createListSlice(`${tag}/list`, ListConf.initialState);
const filterSlice = createFilterSlice(`${tag}/filter`, FilterConf.initialState);
const searchSlice = createSearchSlice(`${tag}/search`, SearchConf.initialState);
const detailSlice = createDetailSlice(`${tag}/detail`, DetailConf.initialState);

export const reducer = combineReducers({
  search: searchSlice.reducer,
  data: dataSlice.reducer,
  list: listSlice.reducer,
  filter: filterSlice.reducer,
  detail: detailSlice.reducer,
});

// ACTIONS
export const actions = {
  fetchAllAction: createAction(`${tag}/fetchAll`),
  fetchDetailAction: createAction(`${tag}/fetchOne`),
  ...searchSlice.actions,
  ...dataSlice.actions,
  ...listSlice.actions,
  ...filterSlice.actions,
  ...detailSlice.actions,
};

// QUERY
export const querySelector = createSelector(
  (state) => state[tag].list,
  (state) => state[tag].filter,
  (state) => state[tag].search,
  (list, filter, search) => QueryConf.get(list, filter, search)
);

// LIST
export const listSelector = createSelector(
  (state) => state[tag].data,
  (state) => state[tag].list,
  ({ data, meta }, { pagination, sort }) => ({
    data,
    meta,
    pagination,
    sort,
  })
);

// DETAIL
export const detailSelector = createSelector(
  (state) => state[tag].detail,
  (detail) => detail
);

// EDIT
export const editSelector = createSelector(
  (state) => state[tag].detail,
  ({ data, loading, error }) => {
    const yacht = _.get(data, 'attributes.id_yacht.data');
    const mexal_person_in_charge = _.get(
      data,
      'attributes.id_mexal_person_in_charge.data'
    );

    // const service = _.get(data, 'attributes.id_service.data');
    // const supplier_operation = _.get(
    //   data,
    //   'attributes.id_supplier_operation.data'
    // );
    const id_user_in_charge = _.get(data, 'attributes.id_user_in_charge.data');
    const id_agent_NA = _.get(
      data,
      'attributes.id_yacht.data.attributes.id_attendance.data[0].attributes.id_agent_NA.data.attributes.id_users_permissions_user.data.attributes'
    );
    const marina = _.get(data, 'attributes.id_marina.data');
    const status_operation_BC = _.get(data, 'attributes.status_operation_BC');
    const status_operation_BF = _.get(data, 'attributes.status_operation_BF');
    const status_operation_booking = _.get(
      data,
      'attributes.status_operation_booking'
    );

    const account_type = _.get(data, 'attributes.account_type');

    const reservation = _.get(data, 'attributes.id_reservation.data');

    const driver_in_charge = _.get(data, 'attributes.id_driver_in_charge.data');
    const service_time = _.get(
      data,
      'attributes.id_reservation.data.attributes.service_time'
    );

    const crew_guest = _.get(
      data,
      'attributes.id_reservation.data.attributes.crew_guest'
    );
    const payment_type = _.get(
      data,
      'attributes.id_reservation.data.attributes.payment_type'
    );

    const reservation_piloti_in = _.get(
      data,
      'attributes.id_reservation_piloti_in.data'
    );

    const port = _.get(data, 'attributes.id_reservation.data.attributes.port');

    const id_arrival_departure_formality = _.get(
      data,
      'attributes.id_reservation.data.attributes.id_arrival_departure_formality.data'
    );
    const service_date = _.get(
      data,
      'attributes.id_reservation.data.attributes.service_date'
    );
    const check_out_date_reservation = _.get(
      data,
      'attributes.id_reservation.data.attributes.check_out_date'
    );
    const check_in_date = _.get(
      data,
      'attributes.id_reservation.data.attributes.check_in_date'
    );
    const type_of_contact = _.get(data, 'attributes.type_of_contact');

    return {
      loading,
      error,
      data: data
        ? {
            // ARRIVAL DEPARTURE FORMALITIES
            id_arrival_departure_formality: id_arrival_departure_formality
              ? {
                  name: id_arrival_departure_formality.attributes.value,
                  id: id_arrival_departure_formality.id,
                }
              : null,

            reservation_type: _.get(
              data,
              'attributes.id_reservation.data.attributes.reservation_type'
            ),

            // IMMIGRATION FORMALITIES
            crew_name: _.get(
              data,
              'attributes.id_reservation.data.attributes.crew_name'
            ),

            //COMMON RESERVATION
            crew_guest: crew_guest
              ? {
                  id: crew_guest,
                  label: crew_guest,
                }
              : null,
            client_name: _.get(
              data,
              'attributes.id_reservation.data.attributes.client_name'
            ),
            note_reservation: _.get(
              data,
              'attributes.id_reservation.data.attributes.note'
            ),
            payment_type: payment_type
              ? {
                  id: payment_type,
                  label: payment_type,
                }
              : null,
            contact_email: _.get(
              data,
              'attributes.id_reservation.data.attributes.contact_email'
            ),
            contact_number: _.get(
              data,
              'attributes.id_reservation.data.attributes.contact_number'
            ),
            // requested_date: _.get(data, 'attributes.requested_date'),

            //PORT CAMPI/BOE
            requested_dock: _.get(
              data,
              'attributes.id_reservation.data.attributes.requested_dock'
            ),

            //PILOT OUT
            check_out_date_reservation: check_out_date_reservation
              ? check_out_date_reservation
              : null,
            check_out_time: _.get(
              data,
              'attributes.id_reservation.data.attributes.check_out_time'
            )
              ? dayjs(
                  `${_.get(
                    data,
                    'attributes.id_reservation.data.attributes.check_out_date'
                  )}T${_.get(
                    data,
                    'attributes.id_reservation.data.attributes.check_out_time'
                  )}`
                )
              : null,
            port: port
              ? {
                  label: port,
                  id: port,
                }
              : null,
            id_reservation_piloti_in: reservation_piloti_in
              ? {
                  label: `${reservation_piloti_in.id} - ${reservation_piloti_in.attributes?.id_yacht?.data?.attributes?.yacht_name}`,
                  id: reservation_piloti_in.id,
                }
              : null,

            //PILOT IN
            check_in_date: check_in_date ? check_in_date : null,
            check_in_time: _.get(
              data,
              'attributes.id_reservation.data.attributes.check_in_time'
            )
              ? dayjs(
                  `${_.get(
                    data,
                    'attributes.id_reservation.data.attributes.check_in_date'
                  )}T${_.get(
                    data,
                    'attributes.id_reservation.data.attributes.check_in_time'
                  )}`
                )
              : null,

            //GARBAGE
            service_date: service_date ? service_date : null,
            requested_hour: _.get(data, 'attributes.requested_hour')
              ? dayjs(
                  `${_.get(data, 'attributes.requested_date')}T${_.get(
                    data,
                    'attributes.requested_hour'
                  )}`
                )
              : null,
            service_time: service_time
              ? dayjs(
                  `${_.get(
                    data,
                    'attributes.id_reservation.data.attributes.service_date'
                  )}T${service_time}`
                )
              : null,
            service_place: _.get(
              data,
              'attributes.id_reservation.data.attributes.service_place'
            ),
            garbage_quantity: _.get(
              data,
              'attributes.id_reservation.data.attributes.garbage_quantity'
            ),

            id_user_in_charge: id_user_in_charge
              ? {
                  name: `${id_user_in_charge.attributes.id_users_permissions_user.data.attributes.name} ${id_user_in_charge.attributes.id_users_permissions_user.data.attributes.surname}`,
                  id: id_user_in_charge.id,
                }
              : null,
            id_agent_NA: id_agent_NA
              ? {
                  label: `${id_agent_NA.name} ${id_agent_NA.surname}`,
                  id: id_agent_NA.name,
                }
              : null,

            //GENERIC
            requested_service_generic: _.get(
              data,
              'attributes.id_reservation.data.attributes.requested_service_generic'
            ),
            pax_number: _.get(
              data,
              'attributes.id_reservation.data.attributes.pax_number'
            ),

            //OPERATION
            id_driver_in_charge: driver_in_charge
              ? {
                  name: driver_in_charge.attributes.username_mexal,
                  id: driver_in_charge.id,
                }
              : null,
            id_reservation: reservation
              ? {
                  name: reservation.attributes.reservation_type,
                  id: reservation.id,
                }
              : null,
            id_yacht: yacht
              ? { name: yacht.attributes.yacht_name, id: yacht.id }
              : null,

            id_mexal_person_in_charge: mexal_person_in_charge
              ? {
                  name: `${mexal_person_in_charge.attributes.id_users_permissions_user.data.attributes.name} ${mexal_person_in_charge.attributes.id_users_permissions_user.data.attributes.surname}`,
                  id: mexal_person_in_charge.id,
                }
              : null,

            id_department: _.get(data, 'attributes.id_department.data.id'),

            // id_service: service
            //   ? {
            //       name: service.attributes.service,
            //       id: service.id,
            //     }
            //   : null,
            operation_service: _.get(data, 'attributes.operation_service'),

            service_detail: _.get(data, 'attributes.service_detail'),
            OC_BC_number: _.get(data, 'attributes.OC_BC_number'),
            OF_BF_number: _.get(data, 'attributes.OF_BF_number'),
            OC_fees: _.get(data, 'attributes.OC_fees'),
            check_out_date: _.get(data, 'attributes.check_out_date'),
            operation_date: _.get(data, 'attributes.operation_date'),
            status_operation_BC: status_operation_BC
              ? { label: status_operation_BC, id: status_operation_BC }
              : null,
            status_operation_BF: status_operation_BF
              ? { label: status_operation_BF, id: status_operation_BF }
              : null,
            status_operation_booking: status_operation_booking,
            selling_price: _.get(data, 'attributes.selling_price'),
            purchase_price: _.get(data, 'attributes.purchase_price'),
            purchase_date: _.get(data, 'attributes.purchase_date'),

            account_type: account_type
              ? { label: account_type, id: account_type }
              : null,

            supplier_correspondent: _.get(
              data,
              'attributes.supplier_correspondent'
            ),

            id_marina: marina
              ? {
                name: `${marina.attributes.location} - ${marina.attributes.marina}`,
                  id: marina.id,
                }
              : null,
            is_cagliari: _.get(data, 'attributes.is_cagliari')
              ? _.get(data, 'attributes.is_cagliari')
              : false,

            ft: _.get(data, 'attributes.ft'),
            note: _.get(data, 'attributes.note'),

            yacht_name: yacht ? yacht.attributes.yacht_name : '',
            agent_NA_name: id_agent_NA
              ? `${id_agent_NA.name} ${id_agent_NA.surname}`
              : '',
            type_of_contact: type_of_contact
              ? { label: type_of_contact, id: type_of_contact }
              : null,
            contact: _.get(data, 'attributes.contact') || '',
          }
        : null,
    };
  }
);
