import * as yup from 'yup';
import dayjs from 'dayjs';

import { PortOptions } from '../Data.conf';

export const PilotiINConf = {
  schema: {
    check_in_date: yup.date().nullable().required('Check in date is required or invalid').transform((value, originalValue) => {
      const parsedDate = dayjs(originalValue, 'YYYY-MM-DD', true);
      return parsedDate?.isValid() ? parsedDate.toDate() : null;
    }),
    check_in_time: yup.date().nullable().required('Service time is required or invalid')
    .transform((value, originalValue) => {
      const parsedTime = dayjs(originalValue, 'HH:mm', true);
      return parsedTime?.isValid() ? parsedTime.toDate() : null;
    }),
    port: yup.object().nullable().required('Port is required'),
  },
  defaultValues: {
    check_in_date: dayjs(),
    check_in_time: null,
    port: null,
  },
  fields: [
    {
      id: 'service_divider',
      type: 'divider',
      label: 'Service',
      xs: 12,
    },
    {
      id: 'check_in_date',
      name: 'check_in_date',
      type: 'date',
      label: 'Check in date',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'check_in_time',
      name: 'check_in_time',
      type: 'time',
      label: 'Check in time',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'port',
      name: 'port',
      type: 'autocomplete',
      label: 'Port',
      options: PortOptions,
      required: true,
      xs: 12,
      sm: 6,
    },

  ],
};
