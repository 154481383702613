import React from 'react';
import { Avatar, Stack } from '@mui/material';
import Typography from './Typography.component';

import PropTypes from 'prop-types';

const AvatarText = ({ icon, value, sxTypography, sx }) => {
  return (
    <Stack direction={'row'} spacing={2} alignItems={'center'}>
      {icon && <Avatar sx={{ width: 25, height: 25, ...sx }}>{icon}</Avatar>}
      <Typography
        sx={{
          fontSize: 15,
          fontWeight: 500,
          mr: 1,
          mt: 1.75,
          mb: 0.75,
          zIndex: 10,
          ...sxTypography,
        }}
      >
        {value}
      </Typography>
    </Stack>
  );
};

AvatarText.propTypes = {
  icon: PropTypes.node,
  sx: PropTypes.object,
  value: PropTypes.string,
};

export default AvatarText;
