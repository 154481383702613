import React, { Fragment, useCallback, useEffect, useState } from 'react';

import { Box, ClickAwayListener, Stack } from '@mui/material';
import Loading from '../../common/Loading.component';
import Alert from '../../common/Alert.component';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAPI } from '../../../api/request';
import { Form } from '../../form/Form';
import SaveIconButton from '../../operations/SaveIcon.component';
import * as yup from 'yup';
import _ from 'lodash';

const TableCellEdit = ({
  module: { query, tag },
  value,
  name,
  fields = [],
  defaultValues,
  schema = yup.object({}),
  children,
  form,
}) => {
  const { jwt } = useSelector((state) => state.login);
  const dispatch = useDispatch();

  const [viewSwitch, setViewSwitch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const _query = useSelector(query.all.selector);
  const userAuth = useSelector((state) => state.login?.user);

  const handleClick = () => {
    setViewSwitch(!viewSwitch);
  };

  const onSuccess = useCallback(() => {
    setSuccess(true);
    setError(false);
    setLoading(false);
    // eslint-disable-next-line
  }, []);

  const onError = useCallback(() => {
    setSuccess(false);
    setLoading(false);
    setError(true);
    // eslint-disable-next-line
  }, []);

  const onSubmit = useCallback(
    (values) => {
      setSuccess(false);
      setError(false);
      setLoading(true);
      const id = value?.id;
      fetchAPI(query.edit.settings, { id }, '', { ...values }, jwt)
        .then(onSuccess)
        .catch(onError);
    },
    [value, jwt, query, onSuccess, onError]
  );

  useEffect(() => {
    if (success) {
      const params = value?.id ? { id: value?.id } : {};
      dispatch(
        query.all.action({
          settings: query.all.settings,
          token: jwt,
          params,
          query: { ..._query, populate: query.all.populate },
        })
      );
    }
  }, [success, _query, jwt, dispatch, query, value]);

  return (
    <Fragment>
      {loading && <Loading />}
      {success && <Alert severity='success'>{'Success!!'}</Alert>}
      {error && <Alert severity='error'>{'An error has occurred'}</Alert>}
      {userAuth.permissions[_.toUpper(tag)]?.UPDATE === 'true' ? (
        !viewSwitch ? (
          <Box onClick={handleClick} sx={{ cursor: 'pointer' }}>
            {children}
          </Box>
        ) : (
          <ClickAwayListener onClickAway={() => setViewSwitch(false)}>
            <Stack direction={'row'}>
              <Form
                fields={fields}
                schema={schema}
                defaultValues={defaultValues}
                onSubmit={onSubmit}
              >
                <Stack direction={'row'}>
                  {form}
                  <SaveIconButton
                    disabled={success}
                    name={name}
                    defaultValue={value[name]}
                  />
                </Stack>
              </Form>
            </Stack>
          </ClickAwayListener>
        )
      ) : (
        <Fragment> {children} </Fragment>
      )}
    </Fragment>
  );
};

export default TableCellEdit;
