import * as yup from 'yup';
import dayjs from 'dayjs';

export const BerthBuoyConf = {
  schema: {
    check_in_date: yup
    .date()
    .nullable()
    .required('Date is required or invalid')
    .transform((value, originalValue) => {
      const parsedDate = dayjs(originalValue, 'YYYY-MM-DD', true);
      return parsedDate?.isValid() ? parsedDate.toDate() : null;
    }),
    check_out_date_reservation: yup
      .date()
      .nullable()
      .when('check_in_date', (check_in_date, schema) => {
        const date = check_in_date && dayjs(check_in_date).add(1, 'day');
        return date
          ? schema.min(
              date,
              'The check out date cannot be equal to or less than the check in date'
            )
          : schema;
      })
      .required('Check out date is required or invalid')
      .transform((value, originalValue) => {
        const parsedDate = dayjs(originalValue, 'YYYY-MM-DD', true);
        return parsedDate?.isValid() ? parsedDate.toDate() : null;
      }),
    requested_dock: yup.string().nullable(),
    contact_email: yup
      .string()
      .email()
      .nullable()
      .transform((value, originalValue) => {
        // Se il campo 'contact_email' è vuoto in fase di modifica, non passarlo all'API
        return originalValue === '' ? null : value;
      }),
    contact_number: yup.string().nullable(),
  },
  defaultValues: {
    check_in_date: dayjs(),
    check_out_date_reservation: dayjs().add(1, 'day'),
    requested_dock: null,
    contact_email: null,
    contact_number: null,
  },
  fields: [
    {
      id: 'service_divider',
      type: 'divider',
      label: 'Service',
      xs: 12,
    },
    {
      id: 'check_in_date',
      name: 'check_in_date',
      type: 'date',
      label: 'Check in date',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'check_out_date_reservation',
      name: 'check_out_date_reservation',
      type: 'date',
      label: 'Check out date reservation',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'requested_dock',
      name: 'requested_dock',
      type: 'text',
      label: 'Requested dock',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'contact_email',
      name: 'contact_email',
      type: 'text',
      label: 'Contact email',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'contact_number',
      name: 'contact_number',
      type: 'text',
      label: 'Contact number',
      required: false,
      xs: 12,
      sm: 6,
    },
  ],
};
