export const YachtConf = {
  schema: {},
  defaultValues: {},
  fields: [
    {
      id: 'yacht_divider',
      type: 'divider',
      label: 'Yacht',
      xs: 12,
    },
    {
      id: 'yacht_name',
      name: 'yacht_name',
      type: 'text',
      label: 'Yacht',
      required: true,
      disabled: true,
      xs: 12,
    },
    {
      id: 'agent_NA_name',
      name: 'agent_NA_name',
      type: 'text',
      label: 'NA Agent',
      required: true,
      disabled: true,
      xs: 12,
    },
    {
      id: 'service_generic',
      type: 'divider',
      label: 'Service',
      xs: 12,
    },
    {
      id: 'operation_service',
      name: 'operation_service',
      type: 'text',
      label: 'Service',
      required: true,
      xs: 12,
      sm: 12,
    },
  ],
};
