export const ServiceOptions = [
    { id: 'LAUNDRY SERVICE', label: 'LAUNDRY SERVICE', color: '#009ab6', },
    {
        id: 'FLOWERS ORDER',
        label: 'FLOWERS ORDER',
        color: '#009ab6',
    },
    {
        id: 'SIM CARDS & TOP UPS',
        label: 'SIM CARDS & TOP UPS',
        color: '#009ab6',
    },
    {
        id: 'PHARMACY ORDER',
        label: 'PHARMACY ORDER',
        color: '#009ab6',
    },
    {
        id: 'GENERIC GOODS',
        label: 'GENERIC GOODS',
        color: '#009ab6',
    },
    {
        id: 'GENERIC SERVICE',
        label: 'GENERIC SERVICE',
        color: '#009ab6',
    },

];