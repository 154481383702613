export const AgencyOptions = [
  {
    id: 'ARRIVAL & DEPARTURE FORMALITIES',
    label: 'ARRIVAL & DEPARTURE FORMALITIES',
    color: '#009ab6',
  },
  {
    id: 'CASH DECLARATION',
    label: 'CASH DECLARATION',
    color: '#009ab6',
  },
  {
    id: 'SANITATION FORMALITIES',
    label: 'SANITATION FORMALITIES',
    color: '#009ab6',
  },
  {
    id: 'IMMIGRATION FORMALITIES',
    label: 'IMMIGRATION FORMALITIES',
    color: '#009ab6',
  },
  {
    id: 'GARBAGE DISPOSAL',
    label: 'GARBAGE DISPOSAL',
    color: '#009ab6',
  },
  {
    id: 'PILOTS IN',
    label: 'PILOTS IN',
    color: '#009ab6',
  },
  {
    id: 'PILOTS OUT',
    label: 'PILOTS OUT',
    color: '#009ab6',
  },
  {
    id: 'BERTH RESERVATION',
    label: 'BERTH RESERVATION',
    color: '#009ab6',
  },
  {
    id: 'BUOY RESERVATION',
    label: 'BUOY RESERVATION',
    color: '#009ab6',
  },
  {
    id: 'GENERIC SERVICE',
    label: 'GENERIC SERVICE',
    color: '#009ab6',
  },
];

export const PaymentType = [
  {
    id: 'None',
    label: 'None',
  },
  {
    id: 'NAUTICA ASSISTANCE',
    label: 'NAUTICA ASSISTANCE',
  },
  {
    id: 'DIRECTLY (CLIENT)',
    label: 'DIRECTLY (CLIENT)',
  },
];

export const PortOptions = [
  { id: 'MARINA DI OLBIA', label: 'MARINA DI OLBIA' },
  { id: 'MOLO BRIN / ISOLA BIANCA', label: 'MOLO BRIN / ISOLA BIANCA' },
  { id: 'CAGLIARI', label: 'CAGLIARI' },
];

export const CrewGuestType = [
  {
    id: 'CREW',
    label: 'CREW',
  },
  {
    id: 'GUEST',
    label: 'GUEST',
  },
];
