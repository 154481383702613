import * as yup from 'yup';

import dayjs from 'dayjs';

import { API as users_nautica } from '../../users_nautica/api';
// import { API as serviceOperationList } from '../../serviceOperationList/api';
// import { API as supplierOperationList } from '../../supplierOperationList/api';
import { API as marina } from '../../marina/api';
import {
  AccountType,
  StatusOperationType,
  StatusOperationBookingType,
} from './Data.conf';
import _ from 'lodash';
import { ContactType } from './Data.conf';
import { PaymentType } from '../../agency/conf/Data.conf';

export const OperationConf = {
  schema: {
    id_yacht: yup.object().nullable().required('Yacht is required'),
    id_mexal_person_in_charge: yup.object().nullable(),
    id_user_in_charge: yup
      .object()
      .nullable()
      .required('User in charge is required'),
    requested_date: yup
      .date()
      .nullable()
      .required('Requested date is required'),
    requested_hour: yup.date().nullable().typeError('Time format invalid'),
    service_detail: yup.string().nullable(),
    selling_price: yup.string().nullable(),
    purchase_price: yup.string().nullable(),
    status_operation_BC: yup.object().nullable(),
    status_operation_BF: yup.object().nullable(),
    OC_BC_number: yup.string().nullable(),
    OF_BF_number: yup.string().nullable(),
    OC_fees: yup.string().nullable(),
    status_operation_booking: yup
      .string()
      .nullable()
      .required('Status operation is required'),
    // id_supplier_operation: yup.object().nullable(),
    supplier_correspondent: yup.string().nullable(),
    account_type: yup.object().nullable(),
    id_marina: yup.object().nullable(),
    note: yup.string().nullable(),
    is_cagliari: yup.boolean().nullable(),
    ft: yup.boolean().nullable(),
    check_out_date: yup
      .date()
      .nullable()
      .transform((value) => {
        if (value && dayjs(value).isValid()) {
          return value;
        }
        return null;
      }),
    type_of_contact: yup.object().nullable(),
    contact: yup.string().nullable(),
    payment_type: yup.object().nullable().required('Payment type is required'),
  },
  defaultValues: {
    operation_date: dayjs().format('YYYY-MM-DD').toString(),
    requested_date: dayjs(),
    id_yacht: null,
    id_mexal_person_in_charge: null,
    id_user_in_charge: null,
    requested_hour: null,
    service_detail: '',
    selling_price: '',
    purchase_price: '',
    status_operation_BC: _.find(StatusOperationType, { id: 'OPEN' }),
    status_operation_BF: _.find(StatusOperationType, { id: 'OPEN' }),
    OC_BC_number: '',
    OF_BF_number: '',
    OC_fees: '',
    status_operation_booking: _.find(StatusOperationBookingType, {
      id: 'NEW REQUEST',
    }).id,
    account_type: _.find(AccountType, {
      id: 'BOAT',
    }),
    id_marina: null,
    note: null,
    is_cagliari: false,
    ft: false,
    check_out_date: null,
    supplier_correspondent: '',
    hour_type: null,
    contact: '',
    type_of_contact: null,
    payment_type: _.find(PaymentType, {
      id: 'NAUTICA ASSISTANCE',
    }),
  },
  fields: [
    {
      id: 'generic',
      type: 'divider',
      label: 'Date',
      xs: 12,
    },
    {
      id: 'requested_date',
      name: 'requested_date',
      type: 'date',
      label: 'Requested date',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'check_out_date',
      name: 'check_out_date',
      type: 'date',
      label: 'Check out date',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'requested_hour',
      name: 'requested_hour',
      type: 'time',
      label: 'Time',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'id_marina',
      name: 'id_marina',
      type: 'async_autocomplete',
      label: 'Location',
      api: marina.select,
      required: false,
      xs: 12,
      sm: 6,
    },

    {
      id: 'operation_generic',
      type: 'divider',
      label: 'Operation details',
      xs: 12,
    },
    {
      id: 'operation_date',
      name: 'operation_date',
      type: 'date',
      label: 'Operation date',
      disabled: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'supplier_correspondent',
      name: 'supplier_correspondent',
      type: 'text',
      label: 'Supplier',
      required: false,
      xs: 12,
      sm: 6,
    },

    {
      id: 'id_user_in_charge',
      name: 'id_user_in_charge',
      type: 'async_autocomplete',
      label: 'User in charge',
      api: users_nautica.selectName,
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'status_operation_booking',
      name: 'status_operation_booking',
      type: 'select-custom',
      label: 'Status operation booking',
      required: true,
      options: StatusOperationBookingType,
      xs: 12,
      sm: 6,
      disableClearable: true,
    },
    {
      id: 'contact',
      name: 'contact',
      type: 'text',
      label: 'Contact',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'type_of_contact',
      name: 'type_of_contact',
      type: 'autocomplete',
      label: 'E-mail / Whatsapp',
      required: false,
      options: ContactType,
      xs: 12,
      sm: 6,
    },
    {
      id: 'note',
      name: 'note',
      type: 'text',
      label: 'Note',
      required: false,
      xs: 12,
    },
    {
      id: 'price_generic',
      type: 'divider',
      label: 'Price',
      xs: 12,
    },
    {
      id: 'selling_price',
      name: 'selling_price',
      type: 'text',
      label: 'Selling price',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'purchase_price',
      name: 'purchase_price',
      type: 'text',
      label: 'Purchase price',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'account_type',
      name: 'account_type',
      type: 'autocomplete',
      label: 'Account CH/BOAT',
      required: false,
      options: AccountType,
      xs: 12,
      sm: 6,
    },
    {
      id: 'payment_type',
      name: 'payment_type',
      type: 'autocomplete',
      label: 'Payment type',
      options: PaymentType,
      required: true,
      xs: 12,
      sm: 6,
    },

    {
      id: 'user_divider',
      type: 'divider',
      label: 'Mexal',
      xs: 12,
    },
    {
      id: 'id_mexal_person_in_charge',
      name: 'id_mexal_person_in_charge',
      type: 'async_autocomplete',
      label: 'User',
      api: users_nautica.selectName,
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'OC_fees',
      name: 'OC_fees',
      type: 'text',
      label: 'OC fees ',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'status_operation_BC',
      name: 'status_operation_BC',
      type: 'autocomplete',
      label: 'Status operation BC',
      required: false,
      options: StatusOperationType,
      xs: 12,
      sm: 6,
    },
    {
      id: 'OC_BC_number',
      name: 'OC_BC_number',
      type: 'text',
      label: 'OC BC number',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'status_operation_BF',
      name: 'status_operation_BF',
      type: 'autocomplete',
      label: 'Status operation BF',
      required: false,
      options: StatusOperationType,
      xs: 12,
      sm: 6,
    },
    {
      id: 'OF_BF_number',
      name: 'OF_BF_number',
      type: 'text',
      label: 'OF BF number',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'is_cagliari',
      name: 'is_cagliari',
      type: 'switch',
      label: 'Is cagliari',
      required: false,
      disabled: true,
      xs: 12,
    },
    {
      id: 'ft',
      name: 'ft',
      label: 'Invoiced',
      type: 'switch',
      required: false,
      xs: 12,
    },
  ],
};
