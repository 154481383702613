import * as yup from 'yup';
import { API as selectCourier } from '../../api';

export const ShippingCollectedConf = {
	schema: {
		requested: yup.string().nullable(),
		collected_from: yup.string().nullable(),
		collected_to: yup.string().nullable(),
		id_courier: yup.object().nullable(),
		note_shipping: yup.string().nullable(),

	},
	defaultValues: {
		requested: '',
		collected_from: '',
		collected_to: '',
		id_courier: null,
		note_shipping: '',
	},
	fields: [

		{
			id: 'collected_from',
			name: 'collected_from',
			type: 'text',
			label: 'From',
			required: false,
			xs: 12,
			sm: 6,
		},
		{
			id: 'collected_to',
			name: 'collected_to',
			type: 'text',
			label: 'To',
			required: false,
			xs: 12,
			sm: 6,
		},
		{
			id: 'requested',
			name: 'requested',
			type: 'text',
			label: 'Requested By',
			required: false,
			xs: 12,
			sm: 6,
		},
		{
			id: 'id_courier',
			name: 'id_courier',
			type: 'async_autocomplete',
			label: 'Courier',
			api: selectCourier.couriers,
			required: false,
			xs: 12,
			sm: 6,
		},
		{
			id: 'note_shipping',
			name: 'note_shipping',
			type: 'text',
			label: 'Note',
			required: false,
			xs: 12,
		},

	],
};
