import React, { Fragment } from 'react';
import MuiButton from '@mui/material/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from './Typography.component';
import PropTypes from 'prop-types';
import _ from 'lodash';

const Back = ({ title, page = '', ...props }) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const onClick = () => {
    navigate(-1);
  };

  return (
    <Fragment>
      <Box display={'flex'} sx={{ width: 1 }} justifyContent={'space-between'}>
        <MuiButton
          onClick={onClick}
          size='large'
          startIcon={<ArrowBackIcon />}
          {...props}
        >
          {_.lowerCase(state?.from) !== _.lowerCase(title) ? 'Back' : title}
        </MuiButton>
      </Box>
      <Typography
        variant='h3'
        sx={{ fontWeight: 'bold', flexGrow: 1, textAlign: 'center' }}
      >
        {page}
      </Typography>
    </Fragment>
  );
};

Back.propTypes = {
  title: PropTypes.string,
  page: PropTypes.string,
};

export default Back;
