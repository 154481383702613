import React, { Fragment } from 'react';
import { Box, TextField, Typography, useTheme } from '@mui/material';

import _ from 'lodash';

const Info = ({
  item,
  editable,
  onChange = () => {},
  name = 'note',
  label = 'Note',
  currentState,
}) => {
  const theme = useTheme();
  const value = _.has(currentState, `${name}.${item?.id}`)
    ? currentState?.[name]?.[item?.id]
    : item?.attributes?.[name];
  return (
    <Fragment>
      {!editable?.[item?.id] ? (
        value && (
          <Box>
            <Typography
              variant='body2'
              sx={{
                display: 'inline',
                fontWeight: 'bold',
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.primary.main,
              }}
            >{`${label}: `}</Typography>
            <Typography
              variant='body2'
              sx={{
                display: 'inline',
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.primary.main,
              }}
            >
              {value}
            </Typography>
          </Box>
        )
      ) : (
        <TextField
          size='small'
          value={
            _.has(currentState, `${name}.${item?.id}`)
              ? currentState?.[name]?.[item?.id]
              : item?.attributes?.[name] || ''
          }
          sx={{
            '& input': {
              height: '14px',
            },
            marginTop: 1,
            maxWidth: 400,
          }}
          onChange={(event) => onChange(event?.target?.value, name, item?.id)}
          label={label}
        />
      )}
    </Fragment>
  );
};
export default Info;
