import { memo } from 'react';
import NavGroup from './NavGroup';
import { DashboardConf } from '../../../../../core/dashboard';

const MenuList = () => {
  const { routes } = DashboardConf;


  return routes.map((item) => <NavGroup key={item.id} item={item} />)

};

export default memo(MenuList);
