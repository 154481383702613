import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchAPI } from '../../../api/request';
import { actions } from '../slice';
import _ from 'lodash';

const USER_TYPE = 'CREW';
function* loginSaga(action) {
  const { settings, params, query, body, token } = action.payload;
  try {
    yield put({ type: actions.userLoading.type });
    const response = yield call(fetchAPI, settings, params, query, body, token);
    if (_.toUpper(response?.user?.user_type) === USER_TYPE) {
      yield put({
        type: actions.userError.type,
        payload: 'User UNAUTHORIZED',
      });
    } else {
      yield put({ type: actions.userSave.type, payload: response });
    }
  } catch (error) {
    yield put({
      type: actions.userError.type,
      payload: error.response?.data?.error?.message,
    });
  }
}

export function* saga() {
  yield takeLatest(actions.userLoginAction, loginSaga);
}
