import React from "react";
import { Detail } from "../components/detail/Detail";

const DetailView = ({    
    conf,
    data
}) => {

    const { fields, formatValue } = conf

    return (
        <Detail render={formatValue} fields={fields} data={data} />
    )
}

export default DetailView