import { menuSlice as createMenuSlice } from '../../../core/menu'

export const tag = 'menu'

// REDUCERS
const menuSlice = createMenuSlice(`${tag}/menu`)

export const reducer = menuSlice.reducer


// ACTIONS
export const actions = {
    ...menuSlice.actions,
}
