import React, { Fragment, useCallback, useEffect, useState } from 'react';
import {
  Container,
  Stack,
  Dialog,
  DialogContent,
  DialogContentText,
  Button,
  Box,
  IconButton,
} from '@mui/material';
import Loading from '../../common/Loading.component';
import { useSelector } from 'react-redux';
import Alert from '../../common/Alert.component';
import { fetchAPI } from '../../../api/request';
import { API as departments } from '../../../module/departments/api';
import _ from 'lodash';
import { Form } from '../../form/Form';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router';
import { API as operations } from '../../../module/operations/api';
import dayjs from 'dayjs';
import { API as me } from '../../../module/me/api';

const ModalLogbook = ({
  children,
  conf,
  setSuccess = () => {},
  disabled,
  productsList,
  setChecked = () => {},
  operationType = 'PICKUP',
  module: {code}
}) => {
  const { id_operation } = useParams();
  const { jwt } = useSelector((state) => state.login);
  const [loading, setLoading] = useState(false);
  const [successAdd, setSuccessAdd] = useState(false);
  const [idDepartment, setIdDepartment] = useState(null);
  const [error, setError] = useState(null);
  const [yacht, setYacht] = useState(false);
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState(null);
  const { fields, schema, defaultValues } = conf;

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setOpen(true);
    setSuccessAdd(false);
    setError(null);
  }, []);

  const onError = useCallback((error) => {
    setError(error);
    setLoading(false);
    // eslint-disable-next-line
  }, []);

  const onSuccess = useCallback(() => {
    setSuccess(true);
    setSuccessAdd(true);
    setLoading(false);
    setChecked(null);
    handleClose();
    // eslint-disable-next-line
  }, [setLoading, setSuccessAdd]);

  const onSubmit = useCallback(
    (values) => {
      setError(null);
      if (!_.isEmpty(productsList)) {
        setSuccessAdd(false);
        setLoading(true);
        setSuccess(false);
        const dataForm = {
          ...values,
          id_list_items: productsList,
          status_logbook: 'NEW REQUEST',
          id_operator: user?.id,
          id_department: idDepartment,
          status_request_logbook: 'OPEN',
          operation_type: operationType,
          id_yacht: yacht,
          id_location: values?.id_location?.id,
          id_supplier: values?.id_supplier?.id,
          logbook_date: dayjs().format('YYYY-MM-DD'),
          collection_delivery_time: dayjs(
            values?.collection_delivery_time
          ).format('HH:mm:ss'),
          collection_delivery_date: dayjs(
            values?.collection_delivery_date
          ).format('YYYY-MM-DD'),

        };

        fetchAPI(
          { path: '/logbooks', method: 'POST', auth: true },
          {},
          '',
          { data: dataForm },
          jwt
        )
          .then(onSuccess)
          .catch(onError);
      } else {
        onError({ message: 'Select at least one product to send to Logbook.' });
      }
    },
    // eslint-disable-next-line
    [
      setLoading,
      jwt,
      onError,
      onSuccess,
      idDepartment,
      yacht,
      user,
      productsList,
      operationType,
    ]
  );

  useEffect(() => {
    fetchAPI(departments.select, {code}, '', null, jwt)
      .then((response) => {
        setIdDepartment(_.head(response)?.id);
      })
      .catch(onError);
    // eslint-disable-next-line
  }, [code]);

  useEffect(() => {
    fetchAPI(me.me, {}, '', null, jwt)
      .then((response) => {
        setUser(response?.id_users_nautica);
      })
      .catch(onError);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchAPI(operations.operation, { id: id_operation }, '', null, jwt)
      .then((response) => {
        setYacht(response?.data?.attributes?.id_yacht?.data?.id);
      })
      .catch(onError);
    //eslint-disable-next-line
  }, [id_operation, jwt]);

  return (
    <Fragment>
      {loading && <Loading />}
      {successAdd && <Alert severity='success'>{'Success!!'}</Alert>}
      {error && (
        <Alert severity='error'>
          {error?.message ? error.message : 'An error has occurred'}
        </Alert>
      )}

      <Button
        variant='contained'
        disabled={disabled}
        onClick={() => handleOpen()}
      >
        {`Send to Logbook`}
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          <DialogContentText>
            <Container
              disableGutters={true}
              maxWidth={false}
              sx={{ height: '100%' }}
            >
              <Stack
                spacing={2}
                justifyContent='center'
                alignItems='center'
                width={1}
              >
                <Form
                  fields={fields}
                  schema={schema}
                  defaultValues={defaultValues}
                  onSubmit={onSubmit}
                >
                  {children}

                  <Box
                    display='flex'
                    justifyContent='right'
                    alignItems={'center'}
                  >
                    <Button
                      type='submit'
                      variant='contained'
                      disabled={successAdd}
                      autoFocus
                    >
                      Confirm
                    </Button>
                  </Box>
                </Form>

                {children}
              </Stack>
            </Container>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default ModalLogbook;
