import React from 'react';

import Divider from '../form/components/Divider.component';
import { Stack } from '@mui/material';
import { API as yachts } from '../../module/yachts/api';
import { API as users_nautica } from '../../module/users_nautica/api';
import AsyncAutocomplete from '../form/components/AsyncAutocomplete.component';

const EditYachtAndNAgentForm = ({
  id_yacht = { api: yachts.select, required: true, disabled: false },
  id_agent_NA = {
    api: users_nautica.selectName,
    required: true,
    disabled: false,
  },
}) => {
  return (
    <Stack spacing={1}>
      <Stack>
        <Divider label='Yacht' />
        <AsyncAutocomplete
          id='id_yacht'
          label='Yacht'
          name='id_yacht'
          required={id_yacht?.required}
          api={id_yacht?.api}
          disabled={id_yacht?.disabled}
        />
      </Stack>
      <Stack>
        <Divider label='NA Agent' />
        <AsyncAutocomplete
          id='id_agent_NA'
          label='NA Agent'
          name='id_agent_NA'
          required={id_agent_NA?.required}
          api={id_agent_NA?.api}
          disabled={id_agent_NA?.disabled}
        />
      </Stack>
    </Stack>
  );
};

export default EditYachtAndNAgentForm;
