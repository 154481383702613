import SailingIcon from '@mui/icons-material/Sailing';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import GroupIcon from '@mui/icons-material/Group';
import ListIcon from '@mui/icons-material/List';
import _ from 'lodash';
import { DepartmentsConf } from './Data.conf';

export const routes = [
  {
    id: 'section_yachts',
    children: [
      {
        id: 'yachts',
        to: '/yachts',
        title: 'Yachts',
        icon: <SailingIcon />,
        children: [],
      },
    ],
  },

  {
    id: 'section_management',
    title: 'MANAGEMENT',
    children: [
      {
        id: 'operations-list',
        to: '/department/operations-list',
        title: 'Overview',
        icon: <ListIcon />,
        children: [],
      },
      {
        id: 'attendances',
        to: '/attendances',
        title: 'Attendances',
        icon: <DoneAllIcon />,
        children: [],
      },
      {
        id: 'booking',
        to: '/booking',
        title: 'Bookings',
        icon: <CalendarMonthIcon />,
        children: [],
      },
      {
        id: 'contacts',
        to: '/contacts',
        title: 'Directory',
        icon: <GroupIcon />,
        children: [],
      },
    ],
  },
  {
    id: 'section_departments',
    title: 'DEPARTMENTS',
    children: _.concat(
      DepartmentsConf
    ),
  },
];

export const DashboardConf = {
  routes,
};
