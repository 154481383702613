import * as yup from 'yup';
import { CrewGuestType, } from '../Data.conf';
import dayjs from 'dayjs';
import { API as marina } from '../../../marina/api';

export const HotelConf = {
  schema: {
    structure_name: yup.string().nullable().required('Hotel is required'),
    check_in_date: yup.date().nullable().required('Check in date is required'),
    id_marina: yup.object().nullable(),
    check_out_date_reservation: yup
      .date()
      .nullable()
      .when('check_in_date', (check_in_date, schema) => {
        const date = check_in_date && dayjs(check_in_date).add(1, 'day');
        return date
          ? schema.min(
              date,
              'The check out date cannot be equal to or less than the check in date'
            )
          : schema;
      })
      .required('Check out date is required'),
    pax_number: yup.string().nullable().required('Pax is required'),
    crew_guest: yup.object().nullable().required('Crew / Guest is required'),
    client_name: yup.string().nullable().required('Name is required'),
  },
  defaultValues: {
    structure_name: null,
    check_in_date: null,
    check_out_date_reservation: null,
    pax_number: null,
    crew_guest: null,
    id_marina: null,
    client_name: null,
  },
  fields: [
    {
      id: 'reservation_divider',
      type: 'divider',
      label: 'Reservation',
      xs: 12,
    },
    {
      id: 'check_in_date',
      name: 'check_in_date',
      type: 'date',
      label: 'Check in date',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'check_out_date_reservation',
      name: 'check_out_date_reservation',
      type: 'date',
      label: 'Check out date',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'structure_name',
      name: 'structure_name',
      type: 'text',
      label: 'Hotel',
      required: true,
      xs: 12,
    },
    {
      id: 'pax_number',
      name: 'pax_number',
      type: 'number',
      label: 'Pax',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'client_name',
      name: 'client_name',
      type: 'text',
      label: 'Client name',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'crew_guest',
      name: 'crew_guest',
      type: 'autocomplete',
      label: 'Crew / Guest',
      options: CrewGuestType,
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'id_marina',
      name: 'id_marina',
      type: 'async_autocomplete',
      label: 'Location',
      api: marina.select,
      required: false,
      xs: 12,
      sm: 6,
    },
  ],
};
