export const SearchConf = {
    query: {
        keys: [{
            key: 'yacht_type',            
            operator: '$containsi'
        }, {
            key: 'yacht_name',          
            operator: '$containsi'
        }, {
            key: 'port_of_registry',          
            operator: '$containsi'
        }]
    },
    placeholder: 'Search by name, type or port of registry',
    initialState: {
        value: ''
    },
}