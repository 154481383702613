import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Container, Paper, Stack } from '@mui/material';
import Back from '../../../components/common/Back.component';
import Loading from '../../../components/common/Loading.component';
import Alert from '../../../components/common/Alert.component';
import EditView from '../../../views/Edit.view';
import { fetchAPI } from '../../../api/request';
import dayjs from 'dayjs';
import _ from 'lodash';

const ShippingEditPage = ({module:{edit,query,tag}}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { jwt, user } = useSelector((state) => state.login);
  const {
    loading: loadingGet,
    data,
    error: errorGET,
  } = useSelector(edit.selector.data);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const onError = useCallback(() => {
    setSuccess(false);
    setLoading(false);
    setError(true);
    // eslint-disable-next-line
  }, []);
  const onSuccess = useCallback(
    (values) => {
      const data = {
        ...values,
        id_operation_list: values?.id_operation_list,
        date: values?.date ? dayjs(values?.date).format('YYYY-MM-DD') : null,
        requested: values?.requested,
        collected_from: values?.collected_from,
        collected_to: values?.collected_to,
        price: values?.price,
        customer_price: values?.customer_price,
        note: values?.note_shipping,
        id_courier: values?.id_courier?.id,
        tracking_number: values?.tracking_number,
        sender: values?.sender,
        item: values?.item,
        invoice_value: values?.invoice_value,
        duty_paid: values?.duty_paid?.id,
        courier_price: values?.courier_price,
        unimare_price: values?.unimare_price,
        markup: values?.markup,
        handling: values?.handling,
        receiver: values?.receiver,
        details: values?.details,
        is_parcel_for_you: values?.is_parcel_for_you,
        is_pre_alert: values?.is_pre_alert,
        storage: values?.storage,
        id_shipping_status: values?.id_shipping_status?.id,
        country: values?.country,
        UE: values?.UE,
        eta: values?.eta ? dayjs(values?.eta).format('YYYY-MM-DD') : null,
        collected_date: values?.collected_date
          ? dayjs(values?.collected_date).format('YYYY-MM-DD')
          : null,
      };

      setLoading(false);
      fetchAPI(
        {
          path: `/shippings/${values?.id_shipping}`,
          method: 'PUT',
          auth: true,
        },
        {},
        '',
        { data: data },
        jwt
      )
        .then(() => {
          setSuccess(true);
          setError(false);
        })
        .catch(onError);
    },
    [setLoading, setSuccess, jwt, onError]
  );

  const onSubmit = useCallback(
    (values) => {
      const data = {
        ...values,
        id_user_last_updated: user?.id_users_nautica?.id || null,
        ft: values?.ft ? values.ft : null,
        id_agent_NA: values?.id_agent_NA?.id,
        id_mexal_person_in_charge: values?.id_mexal_person_in_charge?.id,
        id_supplier_operation: values?.id_supplier_operation?.id,
        id_yacht: values?.id_yacht?.id,
        id_service: values?.id_service?.id,
        id_technical_work: values?.id_technical_work?.id,
        status_operation_booking: values?.status_operation_booking,
        status_operation_BF: values?.status_operation_BF?.id,
        status_operation_BC: values?.status_operation_BC?.id,
        check_out_date: values?.check_out_date
          ? dayjs(values?.check_out_date).format('YYYY-MM-DD')
          : null,
        operation_date: values?.operation_date
          ? dayjs(values?.operation_date).format('YYYY-MM-DD')
          : null,
        requested_date: values?.requested_date
          ? dayjs(values?.requested_date).format('YYYY-MM-DD')
          : null,
        requested_hour: values?.requested_hour
          ? dayjs(values?.requested_hour).format('HH:mm:ss')
          : null,
        account_type: values?.account_type?.id,
        port: values?.port?.id,
        OC_fees: _.isEmpty(values?.OC_fees) ? null : values?.OC_fees,
        selling_price: _.isEmpty(values?.selling_price)
          ? null
          : values?.selling_price,
        purchase_price: _.isEmpty(values?.purchase_price)
          ? null
          : values?.purchase_price,
        type_of_contact: values?.type_of_contact?.id,
      };
      setError(false);
      setSuccess(false);
      setLoading(true);
      fetchAPI(
        { path: `/operation-lists/${id}`, method: 'PUT', auth: true },
        {},
        '',
        { data: data },
        jwt
      )
        .then(() =>
          onSuccess({
            id_shipping: values?.id_shipping,
            ...values,
          })
        )
        .catch(onError);
    },
    [user?.id_users_nautica?.id, id, jwt, onError, onSuccess]
  );

  useEffect(() => {
    dispatch(
      query.detail.action({
        settings: query.detail.settings,
        token: jwt,
        params: { id },
        query: { populate: query.detail.populate },
      })
    );

    return () => {
      dispatch(
        query.detail.action({
          settings: null,
          token: null,
          params: null,
          query: null,
        })
      );
    };
  }, [id, dispatch, jwt,query]);

  useEffect(() => {
    if (
      (errorGET &&
        (errorGET?.response?.status === 404 ||
          errorGET?.response?.status === 500)) ||
      data?.shipping_type === null
    )
      navigate('/404');
  }, [errorGET, navigate, data, id]);

  return (
    <Container disableGutters={true} maxWidth={false} sx={{ height: '100%' }}>
      <Stack
        spacing={2}
        sx={{ padding: 2 }}
        justifyContent='center'
        alignItems='center'
      >
        <Back title={tag} />
        <Container>
          {loading && <Loading />}
          {success && <Alert severity='success'>{'Success'}</Alert>}
          {error && <Alert severity='error'>{'An error has occurred'}</Alert>}
          {loadingGet ? (
            <Loading />
          ) : (
            <Paper variant='outlined'>
              <Box sx={{ padding: 2 }}>
                {data?.shipping_type && (
                  <EditView
                    data={data}
                    id={id}
                    conf={edit.conf[data?.shipping_type]}
                    disabled={loading}
                    onEditSubmit={onSubmit}
                    onEditError={onError}
                    onEditSuccess={onSuccess}
                    >
                  </EditView>
                )}
              </Box>
            </Paper>
          )}
        </Container>
      </Stack>
    </Container>
  );
};

export default ShippingEditPage;
