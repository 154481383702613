import MuiTypography from '@mui/material/Typography';

export const ListConf = {
  initialState: {
    pagination: {
      page: 1,
      pageSize: 25,
    },
    sort: {
      by: 'createdAt',
      direction: 'desc',
    },
  },
  rowsPerPageOptions: [25, 50, 100],
  columns: [
    {
      id: 'attributes.category',
      key: 'category',
      sort_key: 'category',
      sortable: true,
      label: 'Category',
    },
    {
      id: 'attributes.item_type_ITA',
      key: 'item_type_ITA',
      sort_key: 'item_type_ITA',
      sortable: true,
      label: 'Type ITA',
    },
    {
      id: 'attributes.name_ITA',
      key: 'name_ITA',
      sort_key: 'name_ITA',
      sortable: true,
      label: 'Name ITA',
    },
    {
      id: 'attributes.item_type_ENG',
      key: 'item_type_ENG',
      sort_key: 'item_type_ENG',
      sortable: true,
      label: 'Type ENG',
    },

    {
      id: 'attributes.name_ENG',
      key: 'name_ENG',
      sort_key: 'name_ENG',
      sortable: true,
      label: 'Name ENG',
    },
  ],
  render: {
    formatHead: (key, value) => {
      switch (key) {
        default:
          return (
            <MuiTypography
              variant='subtitle2'
              sx={{ fontWeight: 'bold', color: 'white' }}
            >
              {value}
            </MuiTypography>
          );
      }
    },
    formatValue: (key, value, onClick) => {
      switch (key) {
        default:
          return (
            <MuiTypography variant='subtitle2' onClick={onClick}>
              {value ? value : '-'}
            </MuiTypography>
          );
      }
    },
  },
};
