import React, { useState } from 'react';

import Error from './Error.component';
import { useController, useFormContext } from 'react-hook-form';
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import PropTypes from 'prop-types';

const PasswordField = ({ name, required, label, ...props }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const {
    field: { ref, ...inputProps },
  } = useController({
    name: name,
    control: control,
    rules: { required },
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <FormControl
      variant='outlined'
      fullWidth
      required={required}
      error={!!errors[name]?.message}
      {...inputProps}
      inputRef={ref}
      {...props}
    >
      {label && (
        <InputLabel htmlFor='outlined-adornment-password'>{label}</InputLabel>
      )}
      <OutlinedInput
        type={showPassword ? 'text' : 'password'}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge='end'
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label={label}
      />
      <Error error={errors[name]?.message} />
    </FormControl>
    // <Box display='flex' flexDirection={'column'}>
    //   <MUITextField
    //     error={!!errors[name]?.message}
    //     {...inputProps}
    //     inputRef={ref}
    //     {...props}
    //     required={required}
    //   />
    //   <Error error={errors[name]?.message} />
    // </Box>
  );
};

PasswordField.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
};

export default PasswordField;
