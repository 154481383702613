import * as yup from "yup";
import { CategoryType } from "./Data.conf";


export const FilterConf = {
    schema: yup.object({}),
    initialState: {
        value: {
            category: null,

        }
    },
    fields: [
        {
            id: 'category',
            name: 'category',
            label: 'Category',
            type: 'autocomplete',
            options: CategoryType,
            xs: 12,
            sm: 3,
        },
    ],
    query: {
        keys: [
            {
                key: 'category',
                value: 'category.label',
                operator: '$eq'
            },
        ]
    }
}