export const ServiceOptions = [
    {
        id: 'CHEF ORDER',
        label: 'CHEF ORDER',
        color: '#009ab6',
    },
    {
        id: 'CHIEF-STEW ORDER',
        label: 'CHIEF-STEW ORDER',
        color: '#009ab6',
    },
    {
        id: 'QUOTATION REQUEST',
        label: 'QUOTATION REQUEST',
        color: '#009ab6',
    },
    {
        id: 'TAKE AWAY',
        label: 'TAKE AWAY',
        color: '#009ab6',
    },
    {
        id: 'GENERIC ORDER',
        label: 'GENERIC ORDER',
        color: '#009ab6',
    },
];