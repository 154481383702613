import React from "react";
import {  IconButton, Toolbar, Tooltip, Typography } from "@mui/material";
import { alpha } from '@mui/material/styles';

const TableToolbar = (props) => {
    const { numSelected, toolbar,onClickToolbar } = props;

    return !!numSelected && (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : null}

            {numSelected !== null &&
                <>
                    {numSelected > 0 && (
                        <Tooltip title={toolbar?.label}>
                            <IconButton color="primary" size='large' onClick={onClickToolbar}>
                                {toolbar?.icon}
                            </IconButton>
                        </Tooltip>
                    )}
                </>}
        </Toolbar>
    );
}

export default TableToolbar