import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../../components/common/Loading.component';
import users_crew from '../../../module/users_crew';
import { useNavigate, useParams } from 'react-router-dom';
import users_nautica from '../../../module/users_nautica';
import TemplateDetail from '../../templates/Detail.template';
import ContactsDeletePage from '../delete/ContactsDelete.page';

const ContactsDetailPage = ({module}) => {
  const {edit, query} = module
  const { id } = useParams();
  const dispatch = useDispatch();
  const { jwt } = useSelector((state) => state.login);
  const {
    loading: loadingGet,
    data,
    error,
  } = useSelector(edit.selector.data);
  const [userType, setUserType] = useState(data?.userType || null);
  const navigate = useNavigate();
  const nautica_id = useMemo(() => data?.id_users_nautica?.id, [data]);
  const crew_id = useMemo(() => data?.id_users_crew?.id, [data]);

  useEffect(() => {
    dispatch(
      query.detail.action({
        settings: query.detail.settings,
        token: jwt,
        params: { id },
        query: { populate: query.detail.populate },
      })
    );

    return () => {
      dispatch(
        query.detail.action({
          settings: null,
          token: null,
          params: null,
          query: null,
        })
      );
    };
  }, [id, jwt, dispatch,query]);

  useEffect(() => {
    if (data && data?.userType) setUserType(data?.userType);
  }, [data]);

  useEffect(() => {
    if (
      error &&
      (error?.response?.status === 404 || error?.response?.status === 500)
    )
      navigate('/404');
  }, [error, navigate]);

  return (
    <Fragment>
      {loadingGet && <Loading />}
      {userType?.id === 'Crew' && (
        <TemplateDetail
          module={{
            ...module,
            detail: users_crew.detail,
            tag: users_crew.tag,
          }}
          deleteView={
            <ContactsDeletePage
              module={{
                ...module,
                query: users_crew.query,
                tag: users_crew.tag,
              }}
              id={crew_id}
            />
          }
        />
      )}

      {userType?.id === 'Nautica' && (
        <TemplateDetail
          module={{
            ...module,
            detail: users_nautica.detail,
            tag: users_nautica.tag,
          }}
          deleteView={
            <ContactsDeletePage
              module={{
                ...module,
                query: users_nautica.query,
                tag: users_nautica.tag,
              }}
              id={nautica_id}
            />
          }
        />
      )}
    </Fragment>
  );
};

export default ContactsDetailPage;
