// CONF
import { UpdateConf } from './conf/Update.conf';


// SELECTOR
import { tag } from './slice';


// API
import { API } from './api';
import { CreateConf } from './conf/Create.conf';

export { tag };

const captain = {
  tag,
  query: {
    edit: {
      settings: API.update,
    },
    create: {
      settings: API.create,
    },
  },
  create: {
    conf: CreateConf,
  },
  edit: {
    conf: UpdateConf,
  },
};

export default captain;
