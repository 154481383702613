import MuiChip from '@mui/material/Chip';
import { StatusLogbook } from '../../logbook/conf/Data.conf';
import { indigo } from '@mui/material/colors';
import CancelIcon from '@mui/icons-material/Cancel';
import MuiTypography from '@mui/material/Typography';
import { Stack, Box } from '@mui/system';
import _ from 'lodash';

export const ListConf = {
  columns: [
    { label: 'PRODUCT', key: 'product', id: 'attributes' },
    {
      label: 'QUANTITY',
      key: 'quantity',
      id: 'attributes',
      width: 140,
    },
    {
      label: 'LOGBOOK',
      key: 'id_logbook',
      id: 'attributes.id_logbook.data.attributes.status_logbook',
      width: 140,
    },
    {
      label: 'SUPPLIER',
      key: 'id_supplier',
      id: 'attributes.id_logbook.data.attributes.id_supplier.data.attributes',
      width: 200,
    },
  ],
  render: {
    formatValue: (key, value) => {
      switch (key) {
        case 'product':
          const product = value?.id_item?.data?.attributes;
          return (
            <Stack>
              {product?.name_ITA || product?.name_ENG}
              {value?.note && (
                <Box>
                  <MuiTypography
                    variant='body2'
                    sx={{
                      display: 'inline',
                      fontWeight: 'bold',
                      backgroundColor: indigo[50],
                      color: indigo[500],
                    }}
                  >{`Description: `}</MuiTypography>
                  <MuiTypography
                    variant='body2'
                    sx={{
                      display: 'inline',
                      backgroundColor: indigo[50],
                      color: indigo[500],
                    }}
                  >
                    {value?.note}
                  </MuiTypography>
                </Box>
              )}

              {value?.internal_note && (
                <Box>
                  <MuiTypography
                    variant='body2'
                    sx={{
                      display: 'inline',
                      fontWeight: 'bold',
                      backgroundColor: indigo[50],
                      color: indigo[500],
                    }}
                  >{`Internal Note: `}</MuiTypography>
                  <MuiTypography
                    variant='body2'
                    sx={{
                      display: 'inline',
                      backgroundColor: indigo[50],
                      color: indigo[500],
                    }}
                  >
                    {value?.internal_note}
                  </MuiTypography>
                </Box>
              )}
            </Stack>
          );

        case 'quantity':
          return `${value?.quantity || '-'} ${value?.quantity_type || ''}`;

        case 'id_logbook':
          const color = _.find(
            StatusLogbook,
            (element) => element.label.toUpperCase() === value?.toUpperCase()
          );
          return value ? (
            <MuiChip
              label={value}
              color={color?.color}
              sx={{
                color: color?.color ? '#fff' : color?.textHex,
                backgroundColor: color?.hex,
                fontWeight: 500,
                fontSize: 11,
                width: 86,
              }}
              size='small'
            />
          ) : (
            <CancelIcon color='error' />
          );

        case 'id_supplier':
          return value ? `${value?.mexal_code} -  ${value?.company_name}` : '-';
        default:
          return <></>;
      }
    },
  },
};
