import React from 'react';

import { Toolbar, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

const TableToolbarSelected = ({
  numSelected,
  label = 'selected',
  backgroundColor = grey[100],
}) => {
  return (
    numSelected > 0 && (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          backgroundColor: backgroundColor,
          width: '100%',
        }}
      >
        <Typography
          sx={{ flex: '1 1 100%', fontWeight: 'bold' }}
          color='inherit'
          variant='subtitle1'
          component='div'
        >
          {`${numSelected} ${label}`}
        </Typography>
      </Toolbar>
    )
  );
};

export default TableToolbarSelected;
