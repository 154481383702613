import React from 'react';
import Box from '@mui/material/Box';
import CircleIcon from '@mui/icons-material/Circle';
import { v4 as uuidv4 } from 'uuid';
import _get from 'lodash/get';
import { Stack } from '@mui/material';

const RowItem = ({ module, data }) => {
  const {
    detail: {
      conf: { fields, formatValue },
    },
  } = module;

  return (
    <Box
      style={{
        boxSizing: 'border-box',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {fields?.map(({ key, id }, index) => (
        <Stack
          key={uuidv4()}
          direction={index !== 0 ? 'column' : 'row'}
          alignItems={index === 0 && 'center'}
        >
          {index === 0 && (
            <CircleIcon style={{ width: 8, height: 8, marginRight: 2 }} />
          )}
          {formatValue(key, _get(data, id), () => {})}
        </Stack>
      ))}
    </Box>
  );
};

export default RowItem;
