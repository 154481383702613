import React from 'react';
import Button from '../form/components/Button.component';
import { Form } from '../form/Form';
import { Box } from '@mui/material';

const Create = ({
  disabled = false,
  fields,
  schema,
  defaultValues,
  onSubmit,
  children,
  actions,
}) => {
  return (
    <Form
      fields={fields}
      schema={schema}
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      actions={actions}
    >
      {children}
      <Box display={'flex'} justifyContent='flex-end'>
        <Button
          type='submit'
          variant='outlined'
          sx={{ mt: 1, mb: 2 }}
          disabled={disabled}
        >
          Save
        </Button>
      </Box>
    </Form>
  );
};

export default Create;
