import React from "react";
import Button from "../../common/Button.component";
import AddIcon from '@mui/icons-material/Add';

const AddButton = ({
    onClick,
    label = 'ADD'
}) => {

    return (
        <Button variant="outlined" startIcon={<AddIcon />} onClick={onClick} sx={{ height: '100%'}}>
            {label}
        </Button>
    )
}

export default AddButton