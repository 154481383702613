import {
  Calendar as BigCalendar,
  Views,
  dayjsLocalizer,
} from 'react-big-calendar';

import dayjs from 'dayjs';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Toolbar from './components/Toolbar.component';
import { useMemo, useRef } from 'react';
import { Grid, Typography } from '@mui/material';
import { useState, useCallback } from 'react';
import { useEffect } from 'react';
import _ from 'lodash';
import SidebarComponent from './components/Sidebar.component';
import updateLocale from 'dayjs/plugin/updateLocale';

function sortEvents(events, sort) {
  return _.orderBy(events, [sort?.by], [sort?.order]);
}

const localizer = {
  ...dayjsLocalizer(dayjs),
  sortEvents: sortEvents,
};

dayjs.extend(updateLocale);

dayjs.updateLocale('en', {
  weekStart: 1,
});

function Event(event) {
  const ref = useRef(null);

  return <Typography ref={ref}>{event?.event?.label}</Typography>;
}

const Calendar = ({
  range,
  height = 'auto',
  minHeight = 700,
  events,
  sort,
  defaultValue,
  onRangeChange = () => {},
  eventPropGetter = () => {},
  onSelectEvent = () => {},
}) => {
  const [selectDate, setSelectedDate] = useState(null);
  const [view, setView] = useState(Views.MONTH);
  const [eventList, setEventList] = useState([]);
  const [eventsPopup, setEventsPopup] = useState(false);
  const [date, setDate] = useState(selectDate);

  const { components, defaultDate } = useMemo(
    () => ({
      components: {
        toolbar: (props) =>
          Toolbar({ ...props, range, setEventsPopup, defaultValue }),
        event: Event,
      },
      defaultDate: range.startDate ? dayjs(range.startDate) : dayjs(),
    }),
    [range, defaultValue]
  );

  const onView = useCallback((newView) => setView(newView), [setView]);
  const onNavigate = useCallback((newDate) => setDate(newDate), [setDate]);

  useEffect(() => {
    setEventsPopup(false);
  }, [defaultDate, view]);

  const onShowMore = useCallback(
    (events, date) => {
      const sortedEvents = _.orderBy(events, [sort.by], [sort.order]);
      setEventList(sortedEvents);
      setSelectedDate(date);
      setDate(date);
      setEventsPopup(true);
    },
    [sort]
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={eventsPopup ? 9 : 12}>
        <BigCalendar
          defaultDate={defaultDate}
          date={date}
          localizer={localizer}
          events={events}
          startAccessor='start'
          endAccessor='end'
          popup={true}
          style={{
            height,
            minHeight,
          }}
          onShowMore={onShowMore}
          onRangeChange={onRangeChange}
          onSelectEvent={onSelectEvent}
          eventPropGetter={eventPropGetter}
          onView={onView}
          views={['month', 'week', 'day']}
          components={components}
          onNavigate={onNavigate}
        />
      </Grid>
      {eventsPopup ? (
        <Grid item xs={3}>
          <SidebarComponent
            selectDate={selectDate}
            setEventsPopup={setEventsPopup}
            eventList={eventList}
            date={date}
          />
        </Grid>
      ) : undefined}
    </Grid>
  );
};

export default Calendar;
