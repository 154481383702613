import * as yup from 'yup';
import { Roles } from './Data.conf';
import dayjs from 'dayjs';

export const CreateConf = {
	schema: {
		crew_responsability: yup.object().nullable(),
		birthdate: yup
			.date()
			.nullable()
			.transform((value) => {
				if (value && dayjs(value).isValid()) {
					return value;
				}
				return null;
			}),
	},
	defaultValues: {
		crew_responsability: '',
		birthdate: null,
	},
	fields: [
		{
			id: 'birthdate',
			name: 'birthdate',
			type: 'date',
			label: 'Birthdate',
			required: false,
			xs: 12,
			sm: 6,
		},
		{
			id: 'crew_responsability',
			name: 'crew_responsability',
			type: 'autocomplete',
			label: 'Crew Responsability',
			options: Roles,
			required: false,
			xs: 12,
			sm: 6,
		},
	],
};
