export const ViewsConf = {
    options: [{
        label: 'List',
        value: 'list'
    }, {
        label: 'Calendar',
        value: 'calendar'
    }],
    initialState: {
        value: {
            label: 'Calendar',
            value: 'calendar'
        }
    }
}