import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  useTheme,
  Typography,
} from '@mui/material';

const AccordionComponent = ({
  expanded = true,
  onChange = () => {},
  title,
  details,
}) => {
  const theme = useTheme();
  return (
    <Accordion
      expanded={expanded}
      onChange={onChange}
      sx={{
        backgroundColor: theme.palette.secondary.medium_20,
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ height: 30 }}>
        <Typography fontWeight='bold'>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>{details}</AccordionDetails>
    </Accordion>
  );
};

export default AccordionComponent;
