import { grey } from '@mui/material/colors';
import DownlaodComponent from '../../../components/detail/components/Download.component';
import { Box } from '@mui/material';
import _ from 'lodash';

export const DetailConf = {
  initialState: {
    loading: false,
    error: null,
    data: null,
    meta: null,
  },
  fields: [
    {
      id: 'documents',
      type: 'section',
      label: '',
      sx: {
        backgroundColor: '#e8eaf6CC',
      },
      sxTypography: {
        backgroundColor: '#c5cae9',
        fontSize: 14,
        fontWeight: 'bold',
        color: grey[700],
        height: 35,
      },

      items: [
        {
          type: 'text',
          label: 'Description',
          key: 'attributes.description',
          xs: 12,
          lg: 6,
        },
        {
          type: 'download',
          label: 'Documents',
          key: 'attributes.document.data',
          xs: 12,
          lg: 6,
        },
      ],
    },
  ],
  formatValue: (key, value) => {
    switch (key) {

      case 'attributes.document.data':
        const documentName = _.get(value, 'attributes.name');
        const extension = documentName.split('.').pop();
        const truncatedName = documentName.length > 20 ? `${documentName.substring(0, 12)}(...).${extension}` : documentName;
        return (

          <Box
            sx={{
              marginRight: { xs: 0, sm: 1.1 },
              marginBottom: { xs: 1, sm: 0 },
              width: '97%',
            }}
          >
            <DownlaodComponent
              label={documentName}
              value={{
                title: truncatedName,

                url: _.get(value, 'attributes.url'),
              }}
              tag='shipping_documents'
            />
          </Box>
        );
      default:
        return null;
    }
  },
};
