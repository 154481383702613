import React from 'react';
import MuiTypography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { green, red } from '@mui/material/colors';

const Text = ({ label, value, variant = 'subtitle1', sx = {} }) => {
  return (
    <Box display='flex'>
      {label && (
        <MuiTypography
          variant={variant}
          textTransform={'capitalize'}
          whiteSpace='nowrap'
          pr={1}
          fontWeight={'bold'}
          color='#446080'
        >{`${label}:`}</MuiTypography>
      )}
      <MuiTypography variant={variant} sx={{ color: '#446080', ...sx }}>
        {value === 'true' || value === true ? (
          <CheckCircleIcon sx={{ color: green[500] }} />
        ) : value === 'false' || value === false ? (
          <CancelIcon sx={{ color: red[500] }} />
        ) : (
          value
        )}
      </MuiTypography>
    </Box>
  );
};

Text.propTypes = {
  label: PropTypes.string,
  //value: PropTypes.string,
};

export default Text;
