
// API
import { DetailConf } from './conf/Detail.conf';

// SELECTOR
import { detailSelector, tag } from './slice';

// API
import { API } from './api';
const users_crew = {
  tag,
  query: {
    create: {
      settings: API.create,
    },
    delete: {
      settings: API.delete,
    },
  },
  detail: {
    conf: DetailConf,
    selector: {
      data: detailSelector,
    },
    edit: {
      to: 'edit',
    },
  },
  remove: {
    selector: {
      data: detailSelector,
    },
  },
};

export default users_crew;
