import * as yup from 'yup';
import _ from 'lodash';
import { OperationConf } from '../../operations/conf/Create.conf';
import dayjs from 'dayjs';
import { AccountType } from '../../operations/conf/Data.conf';

export const NewYachtConf = {
  schema: {
    yacht_name: yup.string().nullable().required('Yacht name is required'),
    yacht_type: yup.object().nullable().required('Yacht type is required'),
    loa: yup.string().nullable().required('Loa is required'),
    beam: yup.string().nullable().required('Beam is required'),
    draft: yup.string().nullable().required('Draft is required'),
    gross_tonn: yup.string().nullable().required('Gross tonn type is required'),
  },
  defaultValues: {
    yacht_name: null,
    port_of_registry: null,
    loa: null,
    beam: null,
    draft: null,
    gross_tonn: null,
  },
  fields: [],
};

export const CreateConf = {
  id_yacht: {
    schema: yup.object({
      ..._.omit(OperationConf.schema, ['check_out_date', 'payment_type']),
      operation_service: yup.string().nullable().required('Service is required'),
    }),
    defaultValues: {
      ..._.omit(OperationConf.defaultValues, [
        'check_out_date',
        'payment_type'
      ]),
      operation_service: '',
      requested_date: dayjs(),
      account_type: _.find(AccountType, { id: 'BOAT' }),
    },
    fields: [
      {
        id: 'service_generic',
        type: 'divider',
        label: 'Service',
        xs: 12,
      },
      {
        id: 'operation_service',
        name: 'operation_service',
        type: 'text',
        label: 'Service',
        required: true,
        xs: 12,
      },
      {
        id: 'service_detail',
        name: 'service_detail',
        type: 'text',
        label: 'Service detail',
        required: false,
        xs: 12,
        sm: 6,
      },
      ...OperationConf.fields.slice(4, 5),

      {
        id: 'requested_date',
        name: 'requested_date',
        type: 'date',
        label: 'Date',
        required: true,
        xs: 12,
        sm: 6,
      },
      ...OperationConf.fields.slice(3, 4),
      ...OperationConf.fields.slice(5, 17),
      ...OperationConf.fields.slice(18),
    ],

  },
  newYacht: {
    schema: yup.object({
      ..._.omit(OperationConf.schema, [
        'id_yacht',
        'check_out_date',
        'payment_type',
      ]),
      operation_service: yup.string().nullable().required('Service is required'),
      ...NewYachtConf.schema,
    }),
    defaultValues: {
      ..._.omit(OperationConf.defaultValues, [
        'id_yacht',
        'check_out_date',
        'payment_type'
      ]),
      operation_service: '',
      account_type: _.find(AccountType, { id: 'BOAT' }),
      requested_date: dayjs(),
      ...NewYachtConf.defaultValues,
    },
    fields: _.concat(
      {
        id: 'service_generic',
        type: 'divider',
        label: 'Service',
        xs: 12,
      },
      {
        id: 'operation_service',
        name: 'operation_service',
        type: 'text',
        label: 'Service',
        required: true,
        xs: 12,
      },
      {
        id: 'service_detail',
        name: 'service_detail',
        type: 'text',
        label: 'Service detail',
        required: false,
        xs: 12,
        sm: 6,
      },
      ...OperationConf.fields.slice(4, 5),

      {
        id: 'requested_date',
        name: 'requested_date',
        type: 'date',
        label: 'Date',
        required: true,
        xs: 12,
        sm: 6,
      },
      ...OperationConf.fields.slice(3, 4),
      ...OperationConf.fields.slice(5, 17),
      ...OperationConf.fields.slice(18),
    ),
  },
};
