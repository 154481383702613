import PropTypes from 'prop-types';
import { Fragment, forwardRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { ListItemButton, ListItemIcon, ListItemText, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import menu from '../../../../../../module/menu';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level }) => {
    const theme = useTheme();
    const { pathname } = useLocation();
    const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));
    const dispatch = useDispatch();
    const { selectedItem } = useSelector((state) => state.menu);
    const { drawerOpen } = useSelector((state) => state.menu);

    let itemTarget = '_self';
    if (item.target) {
        itemTarget = '_blank';
    }

    let listItemProps = { component: forwardRef((props, ref) => <Link ref={ref} {...props} to={item.to} target={itemTarget} />) };
    if (item?.external) {
        listItemProps = { component: 'a', href: item.to, target: itemTarget };
    }


    const menuIcon = item.icon ? item.icon : (
        <FiberManualRecordIcon
            sx={{
                width: selectedItem === item.id ? 8 : 6,
                height: selectedItem === item.id ? 8 : 6,
                ml: `${level * 24}px`,
                mr:level

            }}
            fontSize={level > 0 ? 'inherit' : 'medium'}
        />
    );

    const itemHandler = (id) => {
        dispatch(menu.query.active.action(id))
        if (matchesSM) dispatch(menu.query.open.action(false))
    };



    // active menu item on page load
    useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === item.id);
        if (currentIndex > -1) {
            dispatch(menu.query.active.action(item.id))
        }
        // eslint-disable-next-line
    }, [pathname]);


    return (
        <ListItemButton
            {...listItemProps}
            disabled={item.disabled}
            sx={{
                borderRadius: `5px`,
                mb: 0.5,
                alignItems: 'flex-start',
                justifyContent: 'center',
                backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                py: level > 1 ? 1 : 1.25,
            }}
            selected={selectedItem === item.id}
            onClick={() => itemHandler(item.id)}
        >

            {drawerOpen ?
                <Fragment>
                    <ListItemIcon sx={{ my: 'auto', minWidth: !item?.icon ? 18 : 36 }}>{menuIcon}</ListItemIcon>
                    <ListItemText
                        primary={
                            <Typography variant={selectedItem === item.id ? 'h5' : 'body1'} color="inherit">
                                {item.title}
                            </Typography>
                        }
                        secondary={
                            item.caption && (
                                <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                                    {item.caption}
                                </Typography>
                            )
                        }
                    />
                </Fragment>
                :
                item.icon &&
                <ListItemIcon sx={{ minWidth: 10 }}>
                    <Tooltip title={item.title}>
                        {item.icon}
                    </Tooltip>
                </ListItemIcon>
            }

        </ListItemButton>
    );
};

NavItem.propTypes = {
    item: PropTypes.object,
    level: PropTypes.number
};

export default NavItem;
