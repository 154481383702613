import React, { Fragment, useCallback } from 'react';
//import { useSelector } from 'react-redux';
import Update from '../components/crud/Update';

const EditView = ({
  selector,
  conf,
  onEditSubmit = () => {},
  disabled,
  children,
  data,
  actions,
}) => {
  const { fields, schema } = conf;
  //  const { data } = useSelector(selector);

  const onSubmit = useCallback(
    (values) => {
      onEditSubmit(values);
      setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 10);
    },
    [onEditSubmit]
  );

  return (
    <Fragment>
      {data && (
        <Update
          defaultValues={data}
          onSubmit={onSubmit}
          disabled={disabled}
          fields={fields}
          schema={schema}
          actions={actions}
        >
          {children}
        </Update>
      )}
    </Fragment>
  );
};

export default EditView;
