import * as yup from 'yup';
import { API as ArrivalDepartureFormality } from '../../../arrivalDepartureFormality/api';

export const ArrivalDepartureConf = {
  schema: {
    id_arrival_departure_formality: yup
      .object()
      .nullable()
      .required('Service is required'),
  },
  defaultValues: {
    id_arrival_departure_formality: null,
  },
  fields: [
    {
      id: 'service_divider',
      type: 'divider',
      label: 'Service',
      xs: 12,
    },
    {
      id: 'id_arrival_departure_formality',
      name: 'id_arrival_departure_formality',
      type: 'async_autocomplete',
      label: 'Service',
      api: ArrivalDepartureFormality.selectAD,
      required: true,
      xs: 12,
      sm: 6,
    },
  ],
};
