import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchAPI } from '../../../api/request';
import { actions } from '../slice';

function* fetchAll(action) {
  const { settings, params, query, body, token } = action.payload;

  try {
    yield put({ type: actions.loadingChange.type });
    const response = yield call(fetchAPI, settings, params, query, body, token);
    yield put({ type: actions.dataChange.type, payload: response });
  } catch (error) {
    yield put({ type: actions.errorChange.type, error });
  }
}

function* fetchDetail(action) {
  const { settings, params, query, body, token, query2, settings2 } =
    action.payload;

  if (settings && params && query && token) {
    try {
      yield put({ type: actions.loadingDetailChange.type });
      const response = yield call(
        fetchAPI,
        settings,
        params,
        query,
        body,
        token
      );
      const response2 = yield call(
        fetchAPI,
        settings2,
        params,
        query2,
        body,
        token
      );
     
      const payload = {
        data: {
          query: response?.data,
          query2: response2?.data,
        },
        meta: {
          query: response?.meta,
          query2: response2?.meta,
        },
      };
      yield put({ type: actions.dataDetailChange.type, payload: payload });
    } catch (error) {
      yield put({ type: actions.errorDetailChange.type, error });
    }
  } else {
    yield put({
      type: actions.dataDetailChange.type,
      payload: {
        initialState: {
          loading: false,
          error: null,
          data: null,
          meta: null,
        },
      },
    });
  }
}

export function* saga() {
  yield takeLatest(actions.fetchAllAction, fetchAll);
  yield takeLatest(actions.fetchDetailAction, fetchDetail);
}
