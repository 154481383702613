import React, { useEffect } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box, FormControlLabel, Switch, styled } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';

const CustomSwitch = styled(Switch)(() => ({
  '& .MuiSwitch-switchBase + .MuiSwitch-track': {
    backgroundColor: 'red',
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: 'rgba(0, 128, 0, 0.5)',
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track.MuiSwitch-track': {
    opacity: 1,
  },
  '& .MuiSwitch-switchBase': {
    transform: 'translateY(2.5px)',
    '&.Mui-checked': {
      transform: 'translate(25px,2.5px)',
    },
  },
}));

const BoxStyle = {
  background: '#fff',
  display: 'flex',
  borderRadius: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  width: 15,
  height: 15,
};

const SwitchForm = ({ value, name, required }) => {
  const { control } = useFormContext();
  const {
    field: { ref, onChange, ...inputProps },
  } = useController({
    name: name,
    control: control,
    rules: { required },
  });

  useEffect(() => {
    if (name && value) onChange(!!value[name]);
  }, [value, name, onChange]);

  return (
    <FormControlLabel
      control={
        <CustomSwitch
          required={required}
          ref={ref}
          onChange={onChange}
          checked={inputProps.value}
          icon={
            <Box
              sx={{
                ...BoxStyle,
              }}
            >
              <CancelIcon color='error' />
            </Box>
          }
          checkedIcon={
            <Box
              sx={{
                ...BoxStyle,
              }}
            >
              <CheckCircleIcon color='success' />
            </Box>
          }
          {...inputProps}
        />
      }
    />
  );
};

export default SwitchForm;
