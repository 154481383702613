import * as yup from 'yup';
import { CrewGuestType, PlaceMedicalType } from '../Data.conf';
import { API as marina } from '../../../marina/api';

export const MedicalConf = {
  schema: {
    doctor: yup.string().nullable().required('Doctor is required'),
    patient: yup.string().nullable(),
    id_marina: yup.object().nullable(),
    place_medical: yup.object().nullable().required('Place is required'),
    place_details_medical: yup.string().nullable(),
    service_date: yup.date().nullable().required('Service date is required'),
    service_time: yup.date().nullable().required('Service time is required'),
    crew_guest: yup.object().nullable().required('Crew / Guest is required'),
  },
  defaultValues: {
    doctor: null,
    patient: null,
    service_date: null,
    service_time: null,
    id_marina: null,
    place_medical: null,
    place_details_medical: null,
    crew_guest: null,
  },
  fields: [
    {
      id: 'reservation_divider',
      type: 'divider',
      label: 'Reservation',
      xs: 12,
    },
    {
      id: 'service_date',
      name: 'service_date',
      type: 'date',
      label: 'Service date',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'service_time',
      name: 'service_time',
      type: 'time',
      label: 'Service time',
      required: true,
      xs: 12,
      sm: 6,
    },

    {
      id: 'doctor',
      name: 'doctor',
      type: 'text',
      label: 'Doctor',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'patient',
      name: 'patient',
      type: 'text',
      label: 'Patient',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'place_medical',
      name: 'place_medical',
      type: 'autocomplete',
      label: 'Place',
      options: PlaceMedicalType,
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'place_details_medical',
      name: 'place_details_medical',
      type: 'text',
      label: 'Place details',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'crew_guest',
      name: 'crew_guest',
      type: 'autocomplete',
      label: 'Crew / Guest',
      options: CrewGuestType,
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'id_marina',
      name: 'id_marina',
      type: 'async_autocomplete',
      label: 'Location',
      api: marina.select,
      required: false,
      xs: 12,
      sm: 6,
    },
  ],
};
