export const API = {
  all: {
    path: '/departments',
    method: 'GET',
    get: null,
    auth: true,
    fallback: [],
    transform: (data) =>
      data?.data.map((item) => ({
        name: item.attributes.name_department,
        id: item.id,
      })),
  },
  select: {
    path: '/departments?filters[short_description]={{code}}',
    method: 'GET',
    get: null,
    auth: true,
    fallback: [],
    transform: (data) =>
      data?.data.map((item) => ({
        name: item.attributes.name_department,
        id: item.id,
      })),
  },
  conciergeAndAgency: {
    path: '/departments?filters[id]=1&filters[id]=2',
    method: 'GET',
    get: null,
    auth: true,
    fallback: [],
    transform: (data) =>
      data?.data.map((item) => ({
        name: item.attributes.name_department,
        id: item.id,
      })),
  },
  activeDepartments: {
    path: '/departments?filters[short_description][$eq]=AG&filters[short_description][$eq]=CON&filters[short_description][$eq]=TECH&filters[short_description][$eq]=PRV&filters[short_description][$eq]=DEP&filters[short_description][$eq]=INT&filters[short_description][$eq]=SHIP',
    method: 'GET',
    get: null,
    auth: true,
    fallback: [],
    transform: (data) =>
      data?.data.map((item) => ({
        name: item.attributes.name_department,
        id: item.id,
      })),
  },
  logbookDepartments: {
    path: '/departments?filters[type]=OPERATIVO',
    method: 'GET',
    get: null,
    auth: true,
    fallback: [],
    transform: (data) =>
      data?.data.map((item) => ({
        name: item.attributes.name_department,
        id: item.id,
      })),
  },
};
