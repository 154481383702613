import Divider from './components/Divider.component';
import Autocomplete from './components/Autocomplete.component';
import TextField from './components/TextField.component';
import AsyncAutocomplete from './components/AsyncAutocomplete.component';
import UploadButton from './components/Upload.component';
import DatePicker from './components/DatePicker.component';
import RadioButtons from './components/RadioButtons.component';
import Checkbox from './components/Checkbox.component';
import Typography from '../common/Typography.component';
import CheckboxGroup from './components/CheckboxGroup.component';
import DrawComponent from './components/Draw.component';
import PropTypes from 'prop-types';
import TimePicker from './components/TimePicker.component';
import AsyncListItems from './components/AsyncListItems.component';
import AddMoreFormFields from './components/AddMoreFormFields.component';
import CountryAutocomplete from './components/CountryAutocomplete.component';
import Switch from './components/Switch.component';
import AutocompleteMultiple from './components/AutocompleteMultiple.component';
import AsyncAutocompleteMultiple from './components/AsyncAutocompleteMultiple.component';
import PasswordField from './components/PasswordField.component';
import VirtualizedAsyncAutocomplete from './components/VirtualizedAsyncAutocomplete.component';
import SelectCustom from './components/SelectCustom.component';
import TextFieldMultiline from './components/TextFieldMultiline.component';

export const DynamicControl = ({
	id,
	name,
	type,
	label,
	options = [],
	url,
	api,
	required,
	sx,
	disableFuture,
	disablePast,
	...props
}) => {
	switch (type) {
		case 'text':
			return <TextField name={name} required={required} id={id} label={label} height={props.height} {...props} />;
		case 'text_multiline':
			return <TextFieldMultiline name={name} required={required} id={id} label={label} height={props.height} {...props} multiline='true' />;
		case 'password':
			return <TextField name={name} required={required} id={id} label={label} type='password' />;
		case 'number':
			return <TextField name={name} required={required} id={id} label={label} type='number' />;
		case 'autocomplete':
			return <Autocomplete name={name} required={required} id={id} label={label} options={options} {...props} />;
		case 'async_autocomplete':
			return <AsyncAutocomplete name={name} required={required} id={id} label={label} url={url} api={api} {...props} />;
		case 'divider': {
			return <Divider label={label} />;
		}
		case 'upload': {
			return <UploadButton name={name} required={required} id={id} label={label} {...props} />;
		}
		case 'date': {
			return (
				<DatePicker
					name={name}
					required={required}
					id={id}
					label={label}
					disableFuture={disableFuture}
					disablePast={disablePast}
					{...props}
				/>
			);
		}
		case 'radio': {
			return <RadioButtons name={name} required={required} id={id} options={options} label={label} />;
		}
		case 'checkbox_group': {
			return <CheckboxGroup name={name} required={required} id={id} label={label} options={options} />;
		}
		case 'checkbox': {
			return <Checkbox name={name} required={required} id={id} label={label} />;
		}
		case 'typography': {
			return <Typography {...sx}>{label}</Typography>;
		}
		case 'iframe': {
			return <iframe src={url} title={label} style={{ width: '100%' }} />;
		}
		case 'draw': {
			return <DrawComponent name={name} title={props.title} />;
		}
		case 'time': {
			return <TimePicker name={name} required={required} id={id} label={label} />;
		}

		case 'async_list': {
			return <AsyncListItems name={name} required={required} id={id} label={label} url={url} api={api} />;
		}

		case 'add_more_form_fields':
			return <AddMoreFormFields name={name} required={required} id={id} label={label} options={options} />;

		case 'country-autocomplete':
			return <CountryAutocomplete name={name} required={required} id={id} label={label} options={options} />;

		case 'switch': {
			return <Switch name={name} required={required} id={id} label={label} {...props} />;
		}
		case 'autocomplete_multiple':
			return (
				<AutocompleteMultiple name={name} required={required} id={id} label={label} options={options} {...props} />
			);
		case 'async_autocomplete_multiple':
			return (
				<AsyncAutocompleteMultiple
					name={name}
					required={required}
					id={id}
					label={label}
					url={url}
					api={api}
					{...props}
				/>
			);
		case 'password-eye':
			return <PasswordField name={name} required={required} id={id} label={label} type='password' />;

		case 'virtualized_async_autocomplete':
			return (
				<VirtualizedAsyncAutocomplete
					name={name}
					required={required}
					id={id}
					label={label}
					url={url}
					api={api}
					{...props}
				/>
			);

		case 'select-custom':
			return (
				<SelectCustom
					name={name}
					required={required}
					label={label}
					options={options}
					{...props}
				/>
			);
		default:
			return null;
	}
};

DynamicControl.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string,
	type: PropTypes.string,
	label: PropTypes.string,
	options: PropTypes.array,
	url: PropTypes.string,
	required: PropTypes.bool,
	sx: PropTypes.object,
	disableFuture: PropTypes.bool,
	disablePast: PropTypes.bool,
};
