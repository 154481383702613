import PropTypes from 'prop-types';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
    IconButton,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Popover,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import { useSelector } from 'react-redux';
import ListItem from '../ListItem';
import _ from 'lodash';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';

const NavMenuPopover = ({ menu, level }) => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const { pathname } = useLocation();
    const { selectedItem } = useSelector((state) => state.menu);

    // const handlePopoverOpen = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };

    const handlePopoverClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleClick = () => {
        setOpen(!open);
    };

    const onClickIcon = (event) => {
        if (anchorEl) {
            setAnchorEl(null);
        } else {
            setAnchorEl(event.currentTarget);
        }
    };
    const checkOpenForParent = useCallback((child, id) => {
        child.forEach((item) => {
            if (item.to === pathname) {
                setOpen(true);
                setSelected(id);
            }
        });
    }, [pathname]);

    // menu collapse for sub-levels
    useEffect(() => {
        const childrens = menu.children ? menu.children : [];
        childrens.forEach((item) => {
            if (item.children?.length) {
                checkOpenForParent(item.children, menu.id);
            }
        });

        if (
            _.find(childrens, function (item) {
                return selectedItem === item.id;
            })
        ) {
            setSelected(menu.id);
        } else {
            setSelected(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname, menu, selectedItem]);

    return (
        <Fragment>
            <ListItemButton
                sx={{
                    borderRadius: `5px`,
                    mb: 0.5,
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                    py: level > 1 ? 1 : 1.25,
                }}
                selected={selected === menu.id}
                onClick={handleClick}
            >
                <Fragment>
                    {menu?.icon ? (
                        <ListItemIcon sx={{ my: 'auto', minWidth: 18 }}>
                            <IconButton
                                // onMouseEnter={handlePopoverOpen}
                                //  onMouseLeave={handlePopoverClose}
                                onClick={onClickIcon}
                                sx={{
                                    padding: 0,
                                    '&:hover': {
                                        backgroundColor: 'transparent',
                                    },
                                }}
                                color={selected === menu?.id ? 'primary' : 'none'}
                            >
                                <Tooltip title={menu?.title}>{menu?.icon}</Tooltip>
                            </IconButton>
                        </ListItemIcon>
                    ) : (
                        <ListItemButton
                            sx={{
                                padding: 0,
                                borderRadius: `5px`,
                                alignItems: 'flex-start',
                                backgroundColor:
                                    level > 1 ? 'transparent !important' : 'inherit',
                            }}
                            onClick={onClickIcon}
                        >
                            <ListItemText
                                primary={
                                    <Typography
                                        variant={selected === menu.id ? 'h5' : 'body1'}
                                        color='inherit'
                                        sx={{ my: 'auto' }}
                                    >
                                        {menu.title}
                                    </Typography>
                                }
                                secondary={
                                    menu.caption && (
                                        <Typography
                                            variant='caption'
                                            sx={{ ...theme.typography.subMenuCaption }}
                                            display='block'
                                            gutterBottom
                                        >
                                            {menu.caption}
                                        </Typography>
                                    )
                                }
                            />

                            {!!Boolean(anchorEl) ? (
                                <IconChevronUp
                                    stroke={1.5}
                                    size='16px'
                                    style={{ marginTop: 'auto', marginBottom: 'auto' }}
                                />
                            ) : (
                                <IconChevronDown
                                    stroke={1.5}
                                    size='16px'
                                    style={{ marginTop: 'auto', marginBottom: 'auto' }}
                                />
                            )}
                        </ListItemButton>
                    )}
                </Fragment>
            </ListItemButton>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 50,
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                onMouseLeave={handlePopoverClose}
                sx={{ maxHeight: 450, borderRadius: 20 }}
                disableRestoreFocus
            >
                <List sx={{ p: 1 }}>
                    {menu?.children?.map((item, index) => {
                        return item.to ? (
                            <ListItem key={index} item={item} level={1} />
                        ) : (
                            <NavMenuPopover key={index} menu={item} level={1} />
                        );
                    })}
                </List>
            </Popover>
        </Fragment>
    );
};

NavMenuPopover.propTypes = {
    menu: PropTypes.object,
    level: PropTypes.number,
};

export default NavMenuPopover;
