export const API = {
    flags: {
        path: '/flags?pagination[page]=1&pagination[pageSize]=1000',
        method: 'GET',
        get: null,
        auth: true,
        fallback: null,
    },

    select: {
        path: '/flags?pagination[page]=1&pagination[pageSize]=1000&sort[0]=locality_flag',
        method: 'GET',
        get: null,
        auth: true,        
        fallback: [],
        transform: (data) => data?.data?.map((item) => ({
            name: item.attributes.locality_flag,
            id: item.id
        }))
    },
}
