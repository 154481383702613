export const API = {
  shippingDocuments: {
    path: '/operation-lists?filters[id_department][short_description][$eq]=TECH&populate[id_yacht][populate][id_attendance][populate][id_agent_NA][populate][id_users_permissions_user]=%2A&populate[id_department]=%2A&populate[id_marina]=%2A&populate[id_service]=%2A',
    method: 'GET',
    get: null,
    auth: true,
    fallback: null,
  },
  shippingDocument: {
    path: '/shipping-documents/{{id}}',
    method: 'GET',
    get: null,
    auth: true,
    fallback: null,
  },
  create: {
    path: '/shipping-documents',
    method: 'POST',
    get: null,
    auth: true,
    fallback: null,
    adapter: (body) => {
      const formData = new FormData();
      const data = {
        id_shipping: body?.id_shipping,
        description: body?.description,
      };

      if (body?.document) {
        const file = body?.document[0];
        formData.append('files.document', file, file?.name);
      }

      formData.append('data', JSON.stringify(data));
      return formData;
    },
  },
  delete: {
    path: '/shipping-documents/{{id}}',
    method: 'DELETE',
    get: null,
    auth: true,
    fallback: null,
  },
  update: {
    path: '/shipping-documents/{{id}}',
    method: 'PUT',
    get: null,
    auth: true,
    fallback: null,
    adapter: (body) => ({
      data: {
        ...body,
      },
    }),
  },
};
