import dayjs from 'dayjs';
import _ from 'lodash';

export const API = {
  agency: {
    path: '/operation-lists?filters[id_department][short_description]=AG&populate[id_department]=%2A&populate[id_marina]=%2A&populate[id_service]=%2A&populate[id_user_in_charge][populate][id_users_permissions_user][fields]=name,surname&populate[id_mexal_person_in_charge]=%2A&populate[id_yacht][populate][id_attendance][populate][id_agent_NA][populate][id_users_permissions_user][fields][0][1]=name, surname&populate[id_reservation]=%2A',
    method: 'GET',
    get: null,
    auth: true,
    fallback: null,
    transform: (data) => ({
      meta: data?.meta,
      data: data?.data.map((item) => ({
        id: item?.id,
        attributes: {
          ...item?.attributes,
          ft_id: {
            ft: item?.attributes.ft,
            id: item?.id,
          },
          check_in_out_date: {
            requested_date: item?.attributes.requested_date,
            check_out_date: item?.attributes?.check_out_date,
          },
        },
      })),
    }),
  },
  operation: {
    path: '/operation-lists/{{id}}?populate[id_reservation_piloti_in][populate][id_operation_list]=%2A&populate[id_yacht][populate][id_attendance][populate][id_agent_NA][populate][id_users_permissions_user][fields][0][1]=name, surname&populate[id_department]=%2A&populate[id_marina]=%2A&populate[id_mexal_person_in_charge][populate][id_users_permissions_user][fields]=name,surname&populate[id_reservation][populate][id_arrival_departure_formality]=%2A&populate[id_service]=%2A&populate[id_yacht][populate][id_flag]=%2A&populate[id_user_in_charge][populate][id_users_permissions_user][fields]=name,surname&populate[id_supplier_operation]=%2A&populate[id_user_last_updated][populate][id_users_permissions_user]=%2A&populate[id_reservation_piloti_out]=%2A&[populate][id_reservation][populate][id_booking]=%2A',
    method: 'GET',
    get: null,
    auth: true,
    fallback: null,
  },
  update: {
    path: '/operation-lists/{{id}}',
    method: 'PUT',
    get: null,
    auth: true,
    fallback: null,
    adapter: (body) => ({
      data: {
        ...body,
        id_agent_NA: body?.id_agent_NA?.id,
        id_mexal_person_in_charge: body?.id_mexal_person_in_charge?.id ? body?.id_mexal_person_in_charge?.id : null,
        status_operation_booking: body?.status_operation_booking,
        // id_supplier_operation: body?.id_supplier_operation?.id,
        id_yacht: body?.id_yacht?.id,
        // id_marina: body?.id_marina?.id,
        // id_service: body?.id_service?.id,
        id_technical_work: body?.id_technical_work?.id,
        status_operation_BF: body?.status_operation_BF?.id,
        status_operation_BC: body?.status_operation_BC?.id,
        check_out_date: body?.check_out_date
          ? dayjs(body?.check_out_date).format('YYYY-MM-DD')
          : null,
        operation_date: body?.operation_date
          ? dayjs(body?.operation_date).format('YYYY-MM-DD')
          : null,
        requested_date: body?.requested_date
          ? dayjs(body?.requested_date).format('YYYY-MM-DD')
          : null,
        requested_hour: body?.requested_hour
          ? dayjs(body?.requested_hour).format('HH:mm:ss')
          : null,
        account_type: body?.account_type?.id,
        OC_fees: _.isEmpty(body?.OC_fees) ? null : body?.OC_fees,
        selling_price: _.isEmpty(body?.selling_price)
          ? null
          : body?.selling_price,
        purchase_price: _.isEmpty(body?.purchase_price)
          ? null
          : body?.purchase_price,
        id_reservation_piloti_in: body?.id_reservation_piloti_in?.id,
        type_of_contact: body?.type_of_contact?.id,
      },
    }),
  },
  delete: {
    path: '/operation-lists/{{id}}',
    method: 'DELETE',
    get: null,
    auth: true,
    fallback: null,
  },
  create: {
    path: '/operation-lists',
    method: 'POST',
    get: null,
    auth: true,
    fallback: null,
    adapter: (body) => {
      return {
        data: {
          ...body,
          id_agent_NA: body?.id_agent_NA?.id,
          id_mexal_person_in_charge: body?.id_mexal_person_in_charge?.id,
          id_supplier_operation: body?.id_supplier_operation?.id,
          id_yacht: body?.id_yacht?.id,
          id_service: body?.id_service?.id,
          id_technical_work: body?.id_technical_work?.id,
          status_operation_booking: body?.status_operation_booking,
          status_operation_BF: body?.status_operation_BF?.id,
          status_operation_BC: body?.status_operation_BC?.id,
          check_out_date: body?.check_out_date
            ? dayjs(body?.check_out_date).format('YYYY-MM-DD')
            : null,
          operation_date: body?.operation_date
            ? dayjs(body?.operation_date).format('YYYY-MM-DD')
            : null,
          requested_date: body?.requested_date
            ? dayjs(body?.requested_date).format('YYYY-MM-DD')
            : null,
          requested_hour: body?.requested_hour
            ? dayjs(body?.requested_hour).format('HH:mm:ss')
            : null,
          account_type: body?.account_type?.id,
          port: body?.port?.id,
          OC_fees: _.isEmpty(body?.OC_fees) ? null : body?.OC_fees,
          selling_price: _.isEmpty(body?.selling_price)
            ? null
            : body?.selling_price,
          purchase_price: _.isEmpty(body?.purchase_price)
            ? null
            : body?.purchase_price,
          type_of_contact: body?.type_of_contact?.id,
        },
      };
    },
  },
  updateFromList: {
    path: '/operation-lists/{{id}}',
    method: 'PUT',
    get: null,
    auth: true,
    fallback: null,
    adapter: (body) => ({
      data: {
        ...body,
      },
    }),
  },
  createReservation: {
    path: '/reservations',
    method: 'POST',
    get: null,
    auth: true,
    fallback: null,
    adapter: (body) => {
      return {
        data: {
          ...body,
          id_operation_list: body?.id_operation_list,
          port: body?.port?.id,
          service_date: body?.service_date
            ? dayjs(body?.service_date).format('YYYY-MM-DD')
            : null,
          service_time: body?.service_time
            ? dayjs(body?.service_time).format('HH:mm:ss')
            : null,
          required_vehicle: body?.required_vehicle?.id,
          crew_guest: body?.crew_guest?.id,
          payment_type: body?.payment_type ? body?.payment_type?.id : null,
          check_in_date: body?.check_in_date
            ? dayjs(body?.check_in_date).format('YYYY-MM-DD')
            : null,
          check_out_date: body?.check_out_date_reservation
            ? dayjs(body?.check_out_date_reservation).format('YYYY-MM-DD')
            : null,

          place_medical: body?.place_medical?.id,
          pickup_date: body?.pickup_date
            ? dayjs(body?.pickup_date).format('YYYY-MM-DD')
            : null,
          drop_off_date: body?.drop_off_date
            ? dayjs(body?.drop_off_date).format('YYYY-MM-DD')
            : null,
          requested_service_ticket: body?.requested_service_ticket?.id,
          return_date: body?.return_date
            ? dayjs(body?.return_date).format('YYYY-MM-DD')
            : null,
          return_time: body?.return_time
            ? dayjs(body?.return_time).format('HH:mm:ss')
            : null,
          requested_service_beauty: body?.requested_service_beauty?.id,
          mode_beauty: body?.mode_beauty?.id,
          requested_service_clean: body?.requested_service_clean?.id,

          covid_test_type: body?.covid_test_type?.id,
          check_in_time: body?.check_in_time
            ? dayjs(body?.check_in_time).format('HH:mm:ss')
            : null,
          check_out_time: body?.check_out_time
            ? dayjs(body?.check_out_time).format('HH:mm:ss')
            : null,
          id_reservation_piloti_in: body?.id_reservation_piloti_in?.id,
          note: body?.note_reservation,

          //ARRIVAL DEPARTURE FORMALITIES
          id_arrival_departure_formality:
            body?.id_arrival_departure_formality?.id,
          pax_number: _.isEmpty(body?.pax_number) ? null : body?.pax_number,
        },
      };
    },
  },
  updateReservation: {
    path: '/reservations/{{id}}',
    method: 'PUT',
    get: null,
    auth: true,
    fallback: null,
    adapter: (body) => ({
      data: {
        ...body,
        port: body?.port?.id,
        id_yacht: body?.id_yacht?.id,
        service_date: body?.service_date
          ? dayjs(body?.service_date).format('YYYY-MM-DD')
          : null,
        service_time: body?.service_time
          ? dayjs(body?.service_time).format('HH:mm:ss')
          : null,
        required_vehicle: body?.required_vehicle?.id,
        crew_guest: body?.crew_guest?.id,
        payment_type: body?.payment_type ? body?.payment_type?.id : null,
        check_in_date: body?.check_in_date
          ? dayjs(body?.check_in_date).format('YYYY-MM-DD')
          : null,
        check_out_date: body?.check_out_date_reservation
          ? dayjs(body?.check_out_date_reservation).format('YYYY-MM-DD')
          : null,
        place_medical: body?.place_medical?.id,
        place_details_medical: body?.place_details_medical,
        pickup_date: body?.pickup_date
          ? dayjs(body?.pickup_date).format('YYYY-MM-DD')
          : null,
        drop_off_date: body?.drop_off_date
          ? dayjs(body?.drop_off_date).format('YYYY-MM-DD')
          : null,
        requested_service_ticket: body?.requested_service_ticket?.id,
        return_date: body?.return_date
          ? dayjs(body?.return_date).format('YYYY-MM-DD')
          : null,
        return_time: body?.return_time
          ? dayjs(body?.return_time).format('HH:mm:ss')
          : null,
        requested_service_beauty: body?.requested_service_beauty?.id,
        mode_beauty: body?.mode_beauty?.id,
        requested_service_clean: body?.requested_service_clean?.id,
        covid_test_type: body?.covid_test_type?.id,
        check_in_time: body?.check_in_time
          ? dayjs(body?.check_in_time).format('HH:mm:ss')
          : null,
        check_out_time: body?.check_out_time
          ? dayjs(body?.check_out_time).format('HH:mm:ss')
          : null,
        id_agent_NA: body?.id_agent_NA?.id,
        id_mexal_person_in_charge: body?.id_mexal_person_in_charge?.id,
        note: body?.note_reservation,

        //Arrival formalities
        id_arrival_departure_formality:
          body?.id_arrival_departure_formality?.id,
        id_reservation_piloti_in: body?.id_reservation_piloti_in?.id,
        pax_number: _.isEmpty(body?.pax_number) ? null : body?.pax_number,
      },
    }),
  },
};
