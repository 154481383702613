import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import AsyncAutocomplete from '../form/components/AsyncAutocomplete.component';
import TextField from '../form/components/TextField.component';
import Autocomplete from '../form/components/Autocomplete.component';
import { Types } from '../../module/yachts/conf/Data.conf';
import { useFormContext } from 'react-hook-form';

const NewYachtForm = ({
  valueTab,
  handleChange = () => {},
  childrenFirst,
  childrenSecond,
  children,
  api,
}) => {
  const { setValue } = useFormContext();

  const onChange = (e, value) => {
    if (value !== 0) {
      setValue('id_agent_NA', null);
      setValue('id_yacht', null);
    }
    handleChange(e, value);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12}>
        <Tabs
          value={valueTab}
          onChange={onChange}
          aria-label='basic tabs example'
        >
          <Tab label='Use existing yacht' {...a11yProps(0)} />
          <Tab label='Create new yacht' {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={valueTab} index={0}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <AsyncAutocomplete
                id='id_yacht'
                label='Yacht'
                name='id_yacht'
                required={true}
                api={api}
              />
            </Grid>
            <Grid item xs={12}>
              {childrenFirst}
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={valueTab} index={1}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <TextField
                name={'yacht_name'}
                id={'yacht_name'}
                label={'Yacht name'}
                required={true}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                name={'yacht_type'}
                id={'yacht_type'}
                label={'Yacht type'}
                required={true}
                options={Types}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name={'loa'}
                id={'loa'}
                label={'Loa (m)'}
                type='number'
                required={true}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name={'beam'}
                id={'beam'}
                label={'Beam (m)'}
                type='number'
                required={true}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name={'draft'}
                id={'draft'}
                label={'Draft (m)'}
                type='number'
                required={true}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name={'gross_tonn'}
                id={'gross_tonn'}
                label={'Gross tonn'}
                type='number'
                required={true}
              />
            </Grid>
            <Grid item xs={12}>
              {childrenSecond}
            </Grid>
          </Grid>
        </TabPanel>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default NewYachtForm;
