import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import PropTypes from 'prop-types';

const ConfirmDialog = ({
  open = false,
  onClose = () => {},
  title = '',
  description = '',
  cancelAction = () => {},
  confirmAction = () => {},
  children,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {description}
        </DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelAction} variant='outlined'>
          Cancel
        </Button>
        <Button
          onClick={confirmAction}
          autoFocus
          variant='contained'
          color='error'
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;

ConfirmDialog.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  cancelAction: PropTypes.func,
  confirmAction: PropTypes.func,
};
