import _ from 'lodash';
import { FilterConf } from './Filter.conf';
import { SearchConf } from './Search.conf';

export const QueryConf = {
    get: (list, filter, search) => {
        const { pagination, sort } = list;
        const { value: searchValue } = search;
        const { value: filterValue } = filter;

        return {
            pagination: { ...pagination },
            sort: [`${sort.by}:${sort.direction}`],
            filters: {
          $or: SearchConf.query.keys.map(({ key, operator }) =>
              _.setWith(
                  {},
                  _.chunk([...key.split('.'), operator]),
                  searchValue,
                  Object
              )
          ),
          $and: FilterConf.query.keys.map(({ key, operator, value }) => {
              const newValue = _.get(filterValue, _.head(_.split(value, '.')));
              if (_.isArray(newValue)) {
                  return {
                      $or: newValue.map((item) => {
                          return _.setWith(
                              {},
                              _.chunk([...key.split('.'), operator]),
                              _.get(item, _.last(_.split(value, '.'))),
                              Object
                          );
                      }),
                  };
              } else {
                  return _.setWith(
                      {},
                      _.chunk([...key.split('.'), operator]),
                      _.get(filterValue, value),
                      Object
                  );
              }
        }),
            },
        };
    },
};
