import React from 'react';

import { Card, CardContent, Typography, Grid } from '@mui/material';
import { useTheme } from '@emotion/react';

const CardWrapper = ({
  children,
  backgroundColor,
  height,
  icon,
  minHeight,
}) => {
  const theme = useTheme();
  const IconPrimary = icon;
  const primaryIcon = icon ? <IconPrimary fontSize='large' /> : null;

  return (
    <Card
      sx={{
        backgroundColor: backgroundColor || theme.palette.primary.main,
        position: 'relative',
        height: height,
        alignItems: 'center',
        display: 'flex',
        borderRadius: 3,
        minHeight: minHeight,
      }}
    >
      <CardContent
        sx={{
          padding: 2,
          '&.MuiCardContent-root:last-child': {
            padding: 2,
          },
          width: '100%',
        }}
      >
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            sm={primaryIcon ? 8 : 12}
            md={primaryIcon ? 10 : 12}
            lg={primaryIcon ? 10.5 : 12}
          >
            {children}
          </Grid>

          {primaryIcon && (
            <Grid item xs={12} sm={2}>
              <Typography
                variant='body2'
                sx={{
                  position: 'absolute',
                  right: 20,
                  top: '16%',
                  color: '#fff',
                  '&> svg': { width: 50, height: 50, opacity: '0.7' },
                  [theme.breakpoints.down('md')]: {
                    '&> svg': { width: 40, height: 40 },
                  },
                  [theme.breakpoints.down('sm')]: {
                    '&> svg': { display: 'none' },
                  },
                }}
              >
                {primaryIcon}
              </Typography>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CardWrapper;
