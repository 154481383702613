import React from 'react';
import { Grid } from '@mui/material';
import Divider from '../form/components/Divider.component';
import TextField from '../form/components/TextField.component';

const ServiceForm = () => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Divider label='Service' />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id='operation_service'
          name='operation_service'
          label='Service'
          type='text'
          required={true}
        />
      </Grid>
    </Grid>
  );
};

export default ServiceForm;
