import { Toolbar, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';

const TableToolbar = ({ numSelected }) => {
  return (
    numSelected > 0 && (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          bgcolor: grey[100],
          width: '100%',
        }}
      >
        {numSelected > 0 && (
          <Typography
            sx={{ flex: '1 1 100%', fontWeight: 'bold' }}
            color='inherit'
            variant='subtitle1'
            component='div'
          >
            {numSelected} selected
          </Typography>
        )}
      </Toolbar>
    )
  );
};

export default TableToolbar;
