import React, { Fragment, useState, useCallback } from 'react';
import { Paper } from '@mui/material';
import { fetchAPI } from '../../../../api/request';
import { useSelector } from 'react-redux';
import itemsProvisions from '../../../../module/listItemsProvisions';
import ConfirmDialog from '../../../common/Confirm.component';
import Loading from '../../../common/Loading.component';
import { columns } from '../../../../module/listItemsProvisions/conf/Data.conf';
import _ from 'lodash';
import TableRowSort from '../../../listItem/table/sort/TableRowSort.component';
import TableRow from './components/TableRow.component';
import TableToolbar from './components/TableToolbar.component';
import TableVirtuoso from '../../../virtuosoTable/TableVirtuoso.component';

const TableItemsProvisions = ({
  data,
  setSuccessEdit = () => {},
  checked,
  onSelectItem,
}) => {
  const { jwt } = useSelector((state) => state.login);
  const [editable, setEditable] = useState(null);
  const [value, setValue] = useState(false);
  const [currentState, setCurrentState] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [orderBy, setOrderBy] = useState(
    'attributes.id_logbook.data.attributes.id_supplier.data.attributes.label'
  );
  const [order, setOrder] = useState('asc');
  const sortedData = _.orderBy(data, [orderBy], [order]);

  const handleSort = (columnId) => {
    const isAsc = orderBy === columnId && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(columnId);
  };

  const onChange = (value, name, id) => {
    setCurrentState({
      ...currentState,
      [name]: { ...currentState?.[name], [id]: value ? value : null },
    });

    onSelectItem({
      id: id,
      status: false,
    });
  };

  const handleClose = useCallback(() => {
    setOpen(false);
    setValue(null);
  }, []);

  const handleOpen = useCallback((item) => {
    setOpen(true);
    setValue(item);
  }, []);

  // eslint-disable-next-line
  const onSuccess = (item) => {
    setLoading(false);
    setEditable({ ...editable, [item?.id]: false });
    handleClose();
  };

  const onError = useCallback(() => {
    setLoading(false);
  }, []);

  const onSaveAction = useCallback(
    (item) => {
      setLoading(true);
      const data = {
        quantity: currentState?.quantity?.[item?.id],
        available: currentState?.available?.[item?.id],
        quantity_type: currentState?.quantity_type?.[item?.id],
        category: currentState?.category?.[item?.id],
        note: currentState?.note?.[item?.id],
        internal_note: currentState?.internal_note?.[item?.id],
      };

      fetchAPI(
        itemsProvisions.query.edit.settings,
        { id: item.id },
        '',
        { ...data },
        jwt
      )
        .then(() => onSuccess(item))
        .catch(onError);
    },
    [currentState, onSuccess, jwt, onError]
  );

  const onDeleteAction = useCallback(() => {
    setSuccessEdit(false);
    fetchAPI(
      itemsProvisions.query.delete.settings,
      { id: value.id },
      '',
      {},
      jwt
    )
      .then(() => {
        onSuccess();
        setSuccessEdit(true);
        onSelectItem({
          id: value?.id,
          status: false,
        });
      })
      .catch(onError);
  }, [setSuccessEdit, onSuccess, jwt, onError, value, onSelectItem]);

  const handleEditable = (item) => {
    setEditable({ ...editable, [item?.id]: true });
  };

  // const selectAll = useCallback(() => {
  //   if (productsListRef?.current?.size !== 0) {
  //     productsListRef.current.clear();
  //   } else {
  //      data?.map((item) =>  productsListRef.current.add(item?.id));
  //   }
  // }, [data, productsListRef]);

  return (
    <Fragment>
      {loading && <Loading />}
      <TableToolbar
        numSelected={_.keys(_.pickBy(checked, _.identity))?.length}
      />
      <Paper style={{ height: 450, width: '100%' }}>
        <TableVirtuoso
          data={sortedData}
          fixedHeaderContent={() => (
            <TableRowSort
              columns={columns}
              onSort={handleSort}
              order={order}
              orderBy={orderBy}
            />
          )}
          itemContent={(_index, item) => {
            return (
              <TableRow
                item={item}
                onChange={onChange}
                editable={editable}
                currentState={currentState}
                checked={checked}
                onSelectItem={onSelectItem}
                handleEditable={handleEditable}
                onSaveAction={onSaveAction}
                handleOpen={handleOpen}
              />
            );
          }}
        />
      </Paper>

      <ConfirmDialog
        open={open}
        onClose={handleClose}
        title='Are you sure you want to delete this?'
        description='It will be deleted permanently. You cannot undo this action.'
        cancelAction={handleClose}
        confirmAction={onDeleteAction}
      />
    </Fragment>
  );
};
export default TableItemsProvisions;
