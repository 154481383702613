import MuiTypography from '@mui/material/Typography';
import { Stack, Box } from '@mui/system';
import dayjs from 'dayjs';
import CalendarIcon from '@mui/icons-material/CalendarMonth';
import PersonIcon from '@mui/icons-material/Person';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import _ from 'lodash';
import { Status } from '../../booking/conf/Data.conf';
import moment from 'moment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import TableCellEdit from '../../../components/table/components/TableCellEdit.component';
import { API } from '../api/index';
import technical from '..';
import SwitchForm from '../../../components/operations/SwitchForm.component';
import RectangleChip from '../../../components/common/RectangleChip.component';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';

export const ListConf = {
  initialState: {
    pagination: {
      page: 1,
      pageSize: 100,
    },
    sort: {
      by: 'operation_date', //FOR DEPARTMENT TECHNICAL
      direction: 'desc',
    },
  },
  rowsPerPageOptions: [25, 50, 100],
  columns: [
    {
      id: 'attributes.status_operation_booking',
      key: 'status_operation_booking',
      sort_key: 'status_operation_booking',
      sortable: true,
      label: 'Status',
      width: '200px',
    },
    {
      id: 'attributes.id_yacht.data.attributes.yacht_name',
      key: 'yacht_name',
      sort_key: 'id_yacht.yacht_name',
      sortable: true,
      label: 'Yacht Name',
      width: '180px'
    },
    {
      id: 'attributes.operation_service',
      key: 'service',
      sort_key: 'operation_service',
      sortable: true,
      label: 'Service',
      width: '170px',
    },
    {
      id: 'attributes.requested_date',
      key: 'requested_date',
      sort_key: 'requested_date',
      sortable: true,
      label: 'Service Date',
      width: '160px',
    },
    {
      id: 'attributes.requested_hour',
      key: 'requested_hour',
      sort_key: 'requested_hour',
      sortable: true,
      label: 'Service Time',
      width: '120px',
    },

    {
      id: 'attributes.id_marina.data.attributes.marina',
      key: 'id_marina',
      sort_key: 'id_marina.marina',
      sortable: true,
      label: 'Location',
      width: 160,
    },
    {
      id: 'attributes.id_user_in_charge.data.attributes.id_users_permissions_user.data.attributes',
      key: 'id_user_in_charge',
      sort_key: 'id_user_in_charge.id_users_permissions_user.name',
      sortable: true,
      label: 'User in charge',
      width: '180px',
    },
    {
      id: 'attributes.status_operation_BC',
      key: 'status_operation_BC',
      sort_key: 'status_operation_BC',
      sortable: true,
      label: 'BC',
      width: '50px',
    },
    {
      id: 'attributes.status_operation_BF',
      key: 'status_operation_BF',
      sort_key: 'status_operation_BF',
      sortable: true,
      label: 'BF',
      width: '50px',
    },
    {
      id: 'attributes.ft_id',
      key: 'ft_id',
      sort_key: 'ft',
      sortable: true,
      label: 'Invoiced',
      width: '50px',
      checkbox: true,
    },
    {
      id: 'attributes.operation_date',
      key: 'operation_date',
      sort_key: 'operation_date',
      sortable: true,
      label: 'Request date',
      width: '140px',
    },


  ],
  render: {
    formatHead: (key, value) => {
      switch (key) {
        default:
          return (
            <MuiTypography
              variant='body1'
              sx={{ fontWeight: 'bold', color: 'white' }}
            >
              {value.toUpperCase()}
            </MuiTypography>
          );
      }
    },
    formatValue: (key, value, onClick) => {
      switch (key) {
        case 'status_operation_booking':
          const color = _.find(
            Status,
            (item) => item.label.toUpperCase() === value.toUpperCase()
          );
          return (
            <Box onClick={onClick}>
              {value ? (
                <RectangleChip
                  value={value}
                  backgroundColor={color?.hex}
                  color='#fff'
                />
              ) : (
                '-'
              )}
            </Box>
          );
        case 'yacht_name': {
          return (
            <MuiTypography
              variant='body1'
              onClick={onClick}
              sx={{ height: '100%', fontWeight: 'bold' }}
            >
              {value || '-'}
            </MuiTypography>
          );
        }
        case 'date_out':
          return (
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <CalendarIcon color='action' />
              <MuiTypography
                sx={{ fontWeight: 'bold', height: 1 }}
                variant='body1'
                onClick={onClick}
              >
                {value || '-'}
              </MuiTypography>
            </Stack>
          );

        case 'operation_date':
        case 'requested_date': {
          return (
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <CalendarIcon color='action' />
              <MuiTypography
                sx={{ fontWeight: 'bold', height: 1 }}
                variant='body1'
                onClick={onClick}
              >
                {value ? dayjs(value).format('DD-MM-YYYY') : '-'}
              </MuiTypography>
            </Stack>
          );
        }

        case 'requested_hour': {
          return value ? (
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <AccessTimeIcon color='action' />
              <MuiTypography
                sx={{ fontWeight: 'bold', height: 1 }}
                variant='body1'
                onClick={onClick}
              >
                {moment(value, 'HH:mm:ss').format('HH:mm')}
              </MuiTypography>
            </Stack>
          ) : (
            <MuiTypography
              variant='body1'
              onClick={onClick}
              sx={{ height: '100%' }}
            >
              -
            </MuiTypography>
          );
        }
        case 'id_user_in_charge':
        case 'name': {
          return value ? (
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <PersonIcon color='action' fontSize='small' />
              <MuiTypography
                sx={{ height: 1 }}
                variant='body1'
                onClick={onClick}
              >
                {`${value?.name} ${value?.surname}`}
              </MuiTypography>
            </Stack>
          ) : (
            '-'
          );
        }
        case 'status_operation_BC':
        case 'status_operation_BF': {
          return value === 'OPEN' ? (
            <CancelIcon color='error' />
          ) : value === 'CLOSED' ? (
            <CheckCircleIcon color='success' />
          ) : <DoNotDisturbIcon color='disabled' />;
        }
        case 'ft_id':
          return (
            <TableCellEdit
              module={{
                ...technical,
                query: {
                  ...technical.query,
                  edit: { settings: API.updateFromList },
                },
              }}
              value={value}
              name={'ft'}
              defaultValues={{ ft: value?.ft }}
              form={<SwitchForm value={value} name={'ft'} />}
            >
              {!!value?.ft ? (
                <CheckCircleIcon color='success' />
              ) : (
                <CancelIcon color='error' />
              )}
            </TableCellEdit>
          );

        default:
          return (
            <MuiTypography
              variant='body1'
              onClick={onClick}
              sx={{ height: '100%' }}
            >
              {value || '-'}
            </MuiTypography>
          );
      }
    },
  },
};
