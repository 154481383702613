import { dataSlice as createDataSLice } from '../../../core/data';
import { sliceList as createListSlice } from '../../../core/list';
import { sliceFilter as createFilterSlice } from '../../../core/filter';
import { detailSlice as createDetailSlice } from '../../../core/detail';
import { sliceSearch as createSearchSlice } from '../../../core/search';
import { sliceSegmentedControls as createSegmentedControlsSlice } from '../../../core/segmentedControls';
import { sliceCalendar as createCalendarSlice } from '../../../core/calendar';
import {
  combineReducers,
  createAction,
  createSelector,
} from '@reduxjs/toolkit';
import _ from 'lodash';
import moment from 'moment';

import { ListConf } from '../conf/List.conf';
import { SearchConf } from '../conf/Search.conf';
import { ViewsConf } from '../conf/Views.conf';
import { FilterConf } from '../conf/Filter.conf';
import { DataConf, Status } from '../conf/Data.conf';
import { QueryConf } from '../conf/Query.conf';
import { DetailConf } from '../conf/Detail.conf';
import { CalendarConf } from '../conf/Calendar.conf';

export const tag = 'booking';

// REDUCERS
const dataSlice = createDataSLice(`${tag}/data`, DataConf.initialState);
const listSlice = createListSlice(`${tag}/list`, ListConf.initialState);
const filterSlice = createFilterSlice(`${tag}/filter`, FilterConf.initialState);
const searchSlice = createSearchSlice(`${tag}/search`, SearchConf.initialState);
const viewSlice = createSegmentedControlsSlice(
  `${tag}/view`,
  ViewsConf.initialState
);
const detailSlice = createDetailSlice(`${tag}/detail`, DetailConf.initialState);
const calendarSlice = createCalendarSlice(
  `${tag}/calendar`,
  CalendarConf.initialState
);

export const reducer = combineReducers({
  search: searchSlice.reducer,
  data: dataSlice.reducer,
  list: listSlice.reducer,
  filter: filterSlice.reducer,
  detail: detailSlice.reducer,
  view: viewSlice.reducer,
  calendar: calendarSlice.reducer,
});

// ACTIONS
export const actions = {
  fetchAllAction: createAction(`${tag}/fetchAll`),
  fetchDetailAction: createAction(`${tag}/fetchOne`),
  ...searchSlice.actions,
  ...viewSlice.actions,
  ...dataSlice.actions,
  ...listSlice.actions,
  ...filterSlice.actions,
  ...detailSlice.actions,
  ...calendarSlice.actions,
};

// QUERY
export const querySelector = createSelector(
  (state) => state[tag].list,
  (state) => state[tag].filter,
  (state) => state[tag].search,
  (state) => state[tag].view,
  (state) => state[tag].calendar,
  (list, filter, search, view, calendar) =>
    QueryConf.get(list, filter, search, view, calendar)
);

// LIST
export const listSelector = createSelector(
  (state) => state[tag].data,
  (state) => state[tag].list,
  ({ data, meta }, { pagination, sort }) => ({
    data,
    meta,
    pagination,
    sort,
  })
);

// CALENDAR
export const calendarSelector = createSelector(
  (state) => state[tag].data,
  ({ data }) => {
    return data.map((item) => {
      const id_marina = _.get(item, 'attributes.id_marina.data');
      const yacht_name = _.get(
        item,
        'attributes.id_yacht.data.attributes.yacht_name'
      );

      return {
        id: item.id,
        label: id_marina ? (
          <>
            <b>{yacht_name}</b> - {id_marina.attributes.marina}
          </>
        ) : (
          yacht_name
        ),
        title: id_marina
          ? `${yacht_name} - ${id_marina.attributes.marina}`
          : `${yacht_name}`,
        start: moment(_.get(item, 'attributes.check_in_date')).toDate(),
        end: moment(_.get(item, 'attributes.check_out_date')).toDate(),
        booking_status: _.get(item, 'attributes.booking_status'),
        id_yacht: _.get(item, 'attributes.id_yacht.data.attributes'),
      };
    });
  }
);

// DETAIL
export const detailSelector = createSelector(
  (state) => state[tag].detail,
  (detail) => detail
);

// EDIT
export const editSelector = createSelector(
  (state) => state[tag].detail,
  ({ data, loading, error }) => {
    const yacht = _.get(data, 'attributes.id_yacht.data');
    const place = _.get(data, 'attributes.id_marina.data');
    const agent_NA = _.get(
      data,
      'attributes.id_yacht.data.attributes.id_attendance.data[0].attributes.id_agent_NA.data'
    );
    const id_reservation = _.get(data, 'attributes.id_reservation.data');

    return {
      loading,
      error,
      data: data
        ? {
            note: _.get(data, 'attributes.note'),
            id_yacht: yacht
              ? { name: yacht.attributes.yacht_name, id: yacht.id }
              : null,
            check_in_date: _.get(data, 'attributes.check_in_date'),
            check_out_date: _.get(data, 'attributes.check_out_date'),
            cash: _.get(data, 'attributes.cash')
              ? _.get(data, 'attributes.cash')
              : false,
            booking_status: _.find(
              Status,
              (i) =>
                i.label.toUpperCase() ===
                _.get(data, 'attributes.booking_status').toUpperCase()
            ),
            id_marina: place
              ? {
                  name: `${place.attributes.code} - ${place.attributes.location} - ${place.attributes.marina}`,
                  id: place.id,
                }
              : null,
            id_agent_NA: agent_NA
              ? {
                  label: `${agent_NA.attributes?.id_users_permissions_user?.data?.attributes?.name} ${agent_NA.attributes.id_users_permissions_user?.data?.attributes?.surname}`,
                  id: agent_NA?.id,
                }
              : null,
            id_reservation: id_reservation,
          }
        : null,
    };
  }
);
