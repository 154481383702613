import moment from 'moment';
import Text from '../../../components/detail/components/Text.component';
import { OperationTypes, StatusLogbook } from './Data.conf';
import _ from 'lodash';
import LinkPermission from '../../../components/detail/components/LinkPermission.component';
import LinkProductsList from '../../../components/item/button/ButtonProductsList.component';
import { Box } from '@mui/material';
import RectangleChip from '../../../components/common/RectangleChip.component';

const SHORT_DESCRIPTION_PROVISIONS = 'PRV';
const SHORT_DESCRIPTION_DEPOT = 'DEP';
const SHORT_DESCRIPTION_INTERIORS = 'INT';

export const DetailConf = {
  initialState: {
    loading: false,
    error: null,
    data: null,
    meta: null,
  },
  fields: [

    {
      id: 'info_fields_section',
      type: 'section',
      label: '',
      items: [
        {
          type: 'text',
          label: 'Yacht Name',
          key: 'attributes.id_yacht.data',
          xs: 12,
          sm: 6,
          mb: 1.5,
        },
        {
          type: 'text',
          label: 'Agent NA',
          key: 'attributes.id_yacht.data.attributes.id_attendance.data[0].attributes.id_agent_NA.data.attributes.id_users_permissions_user.data',
          xs: 12,
          sm: 6,
        },
      ],
    },
    {
      id: 'new_service_sections',
      type: 'section',
      label: 'SERVICE',
      items: [
        {
          type: 'text',
          label: '',
          key: 'attributes.operation_type',
          xs: 12,
          sm: 6,
        },
        {
          type: 'text',
          label: '',
          key: 'attributes.status_logbook',
          xs: 12,
          sm: 6,
          mb: 4,
        },
        {
          type: 'text',
          label: 'Delivery Date',
          key: 'attributes.collection_delivery_date',
          xs: 12,
          sm: 6,
        },
        {
          type: 'text',
          label: 'Delivery Time',
          key: 'attributes.collection_delivery_time',
          xs: 12,
          sm: 6,
        },

        {
          type: 'text',
          label: 'Location',
          key: 'attributes.id_location.data',
          xs: 12,
          sm: 6,

        },
        {
          type: 'text',
          label: '',
          key: 'attributes.id_list_items',
          xs: 12,
          mt: 4,
          mb: 4

        },
        {
          type: 'boolean',
          label: 'Done',
          key: 'attributes.id_list.check_depot_department',
          xs: 12,
          sm: 6,
        },

        {
          type: 'text',
          label: 'Address',
          key: 'attributes.address',
          xs: 12,
          sm: 6,
        },
        {
          type: 'text',
          label: 'Contact On Board',
          key: 'attributes.contact_on_board_for_delivery',
          xs: 12,
          sm: 6,
        },
        {
          type: 'text',
          label: 'Priority',
          key: 'attributes.priority',
          xs: 12,
          sm: 6,
        },
        {
          type: 'text',
          label: 'Supplier',
          key: 'attributes.id_supplier.data.attributes.label',
          xs: 12,
          sm: 6,
        },
        {
          type: 'text',
          label: 'User in Charge',
          key: 'attributes.id_operator.data.attributes.id_users_permissions_user.data',
          xs: 12,
          sm: 6,
        },
        {
          type: 'text',
          label: 'Operation Date',
          key: 'attributes.logbook_date',
          xs: 12,
          sm: 6,
        },
        {
          type: 'text',
          label: 'Notes',
          key: 'attributes.delivery_notes',
          xs: 12,
          sm: 6,
        },
        {
          type: 'text',
          label: 'Department',
          key: 'attributes.id_department.data.attributes.name_department',
          xs: 12,
        },
      ]
    },
    {
      id: 'pickup_delivery_location',
      type: 'section',
      label: 'DELIVERIES',
      items: [

        {
          type: 'text',
          label: 'Deliveries in Charge',
          key: 'attributes.manager.data.attributes.id_users_permissions_user.data',
          xs: 12,
          sm: 6,
        },
        {
          type: 'text',
          label: 'Driver',
          key: 'attributes.id_driver_in_charge.data.attributes.id_users_permissions_user.data',
          xs: 12,
          sm: 6,
        },
        {
          type: 'text',
          label: 'Delivery Cost',
          key: 'attributes.delivery_cost',
          xs: 12,
          sm: 6,
        },
        {
          type: 'text',
          label: 'Vehicle',
          key: 'attributes.assigned_transport',
          xs: 12,
          sm: 6,
        },
        {
          type: 'text',
          label: 'Pick Up Cost',
          key: 'attributes.collection_cost',
          xs: 12,
          sm: 6,
        },
        {
          type: 'text',
          label: 'Note',
          key: 'attributes.collection_delivery_notes',
          xs: 12,

        },
      ],
    },
  ],
  formatValue: (key, value) => {
    switch (key) {
      case 'attributes.id_yacht.data':
        return (
          <Text
            label='Yacht name'
            value={
              <LinkPermission
                to={`/yachts/${value?.id}`}
                value={value?.attributes?.yacht_name}
                page='YACHTS'
              />
            }
          />
        );
      case 'attributes.id_location.data':
        return (
          <Text
            label='Location'

            value={`${value?.attributes?.location} - ${value?.attributes?.marina}`}


          />
        );

      case 'attributes.id_yacht.data.attributes.draft':
        return <Text label='Draft' value={value ? `${value} m` : '-'} />;

      case 'attributes.id_yacht.data.attributes.loa':
        return <Text label='Loa' value={value ? `${value} m` : '-'} />;
      case 'attributes.id_department.data.attributes.name_department':
        return <Text sx={{ fontWeight: 'bold' }} label='Department' value={value ? `${value}` : '-'} />;

      case 'attributes.id_yacht.data.attributes.beam':
        return <Text label='Beam' value={value ? `${value} m` : '-'} />;

      case 'attributes.id_driver_in_charge.data.attributes.id_users_permissions_user.data':
        return (
          <Text
            label='Driver'
            value={
              <LinkPermission
                to={`/contacts/${value?.id}`}
                value={`${value?.attributes?.name} ${value?.attributes?.surname}`}
                page='CONTACTS_NAUTICA'
              />
            }
          />
        );
      case 'attributes.manager.data.attributes.id_users_permissions_user.data':
        return (
          <Text
            label='Manager Pick-Up / Deliver'
            value={
              <LinkPermission
                to={`/contacts/${value?.id}`}
                value={`${value?.attributes?.name} ${value?.attributes?.surname}`}
                page='CONTACTS_NAUTICA'
              />
            }
          />
        );

      case 'attributes.collection_delivery_date':
        return <Text label='Date' value={moment(value).format('DD-MM-YYYY')} />;
      case 'attributes.logbook_date':
        return <Text label='Operation Date' value={moment(value).format('DD-MM-YYYY')} />;

      case 'attributes.collection_delivery_time':
        return (
          <Text
            label='Time'
            value={value ? moment(value, 'HH:mm:ss').format('HH:mm') : '-'}
          />
        );

      case 'attributes.id_list_items':
        if (!_.isEmpty(value?.data)) {
          switch (value?.id_department) {
            case SHORT_DESCRIPTION_PROVISIONS:
              return (
                <LinkProductsList
                  label={'ITEMS LIST'}
                  to={`/department/provisions/${value?.data[0]?.attributes?.id_list?.data?.attributes?.id_operation_list?.data?.id}/list/${value?.data[0]?.attributes?.id_list?.data?.id}`}
                  state={{
                    id_operation: `${value?.data[0]?.attributes?.id_list?.data?.attributes?.id_operation_list?.data?.id}`,
                    id_logbook: value?.id_logbook,
                  }}
                  variant='contained'
                  sx={{ backgroundColor: '#009ab6' }}
                  page='PROVISIONS'
                />
              );
            case SHORT_DESCRIPTION_DEPOT:
              return (
                <LinkProductsList
                  label='Items List'
                  to={`/department/depot/${value?.data[0]?.attributes?.id_list?.data?.attributes?.id_operation_list?.data?.id}/list/${value?.data[0]?.attributes?.id_list?.data?.id}`}
                  state={{
                    id_operation: `${value?.data[0]?.attributes?.id_list?.data?.attributes?.id_operation_list?.data?.id}`,
                  }}
                  variant='contained'
                  sx={{ backgroundColor: '#009ab6' }}
                  page='DEPOT'
                />
              );
            case SHORT_DESCRIPTION_INTERIORS:
              return (
                <LinkProductsList
                  label='Items List'
                  to={`/department/interiors/${value?.data[0]?.attributes?.id_list?.data?.attributes?.id_operation_list?.data?.id}/list/${value?.data[0]?.attributes?.id_list?.data?.id}`}
                  state={{
                    id_operation: `${value?.data[0]?.attributes?.id_list?.data?.attributes?.id_operation_list?.data?.id}`,
                  }}
                  variant='contained'
                  sx={{ backgroundColor: '#009ab6' }}
                  page='INTERIORS'
                />
              );

            default:
              return null;
          }
        }
        return null;

      case 'attributes.id_operator.data.attributes.id_users_permissions_user.data':
        return (
          <Text
            label='User in Charge'
            value={
              <LinkPermission
                to={`/contacts/${value?.id}`}
                value={`${value?.attributes?.name} ${value?.attributes?.surname}`}
                page='CONTACTS_NAUTICA'
              />
            }
          />
        );
      case 'attributes.id_yacht.data.attributes.id_attendance.data[0].attributes.id_agent_NA.data.attributes.id_users_permissions_user.data':
        return (
          <Text
            label='Agente NA'
            value={
              <LinkPermission
                to={`/contacts/${value?.id}`}
                value={`${value?.attributes?.name} ${value?.attributes?.surname}`}
                page='CONTACTS_NAUTICA'
              />
            }
          />
        );
      case 'attributes.status_logbook':
        const color = _.find(
          StatusLogbook,
          (item) => item.label.toUpperCase() === value.toUpperCase()
        );
        return (
          <Box >
            {value ? (
              <RectangleChip
                value={value}
                backgroundColor={color?.hex}
                color='#fff'
                />
              ) : (
                '-'
            )}
          </Box>
        );
      case 'attributes.operation_type':
        return value ? (
          <RectangleChip
            width={102}

            value={value}
            color={value === 'DELIVERY' && '#fff'}
            backgroundColor={_.find(OperationTypes, ((item) => item.id === value))?.backgroundColor}
            icon={
              _.find(OperationTypes, ((item) => item.id === value))?.icon
            }
          />
        ) : (
          '-'
        );

      case 'attributes.list_status':
        switch (value?.id_department) {
          case SHORT_DESCRIPTION_PROVISIONS:
            return <Text label={'Status'} value={value?.list_item_status} />;
          case SHORT_DESCRIPTION_DEPOT:
            return (
              <Text label={'Status'} value={value?.list_item_status_depot} />
            );
          default:
            return <></>;
        }

      case 'attributes.id_list.check_depot_department':
        return value?.id_department === SHORT_DESCRIPTION_DEPOT ? (
          <Text label='Done' value={value?.check_depot === 'OPEN'} />
        ) : (
          <></>
        );
      default:
        return null;
    }
  },
};
