import { Button } from '@mui/material';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const ButtonComponet = ({
  to,
  state,
  label,
  variant = 'text',
  disabled = false,
  ...sx
}) => {
  const navigate = useNavigate();

  const onClick = useCallback(
    (e) => {
      if (to) navigate(`${to}`, { state: state });
    },
    [navigate, to, state]
  );

  return (
    <Button variant={variant} onClick={onClick} disabled={disabled} {...sx}>
      {label}
    </Button>
  );
};

export default ButtonComponet;
