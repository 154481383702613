import React, { useEffect, useRef } from 'react';
import MuiTable from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from './components/TableFooter.component';
import TableHead from './components/TableHead.component';
import TableBody from './components/TableBody.component';
import { Box } from '@mui/material';
import TableToolbar from './components/TableToolbar.component';

const Table = ({
  render,
  rows = [],
  columns = [],
  pagination,
  total = 0,
  rowsPerPageOptions,
  onChangePagination = () => {},
  sort,
  onChangeSort = () => {},
  onClick = () => {},
  onSelectItem = () => {},
  selected,
  toolbar,
  onClickToolbar = () => {},
}) => {
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      ref?.current?.scrollTo({ top: 0, left: 0 });
    }
  }, [rows, ref]);

  return (
    <Box sx={{ width: '100%', overflow: 'hidden' }}>
      <TableToolbar
        numSelected={selected?.length}
        toolbar={toolbar}
        onClickToolbar={onClickToolbar}
      />
      <TableContainer sx={{ height: '70vh', overflow: 'auto' }} ref={ref}>
        <MuiTable stickyHeader={true}>
          <TableHead
            formatHead={render.formatHead}
            columns={columns}
            sort={sort}
            onChangeSort={onChangeSort}
            rowCount={rows?.length}
            numSelected={selected?.length}
          />
          <TableBody
            formatValue={render.formatValue}
            rows={rows}
            columns={columns}
            onClick={onClick}
            onSelectItem={onSelectItem}
            selected={selected}
          />
        </MuiTable>
      </TableContainer>
      <TableFooter
        pagination={pagination}
        total={total}
        rowsPerPageOptions={rowsPerPageOptions}
        onChange={onChangePagination}
      />
    </Box>
  );
};

export default Table;
