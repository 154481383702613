import React from 'react';
import TextField from '../form/components/TextField.component';
import Divider from '../form/components/Divider.component';
import { Stack } from '@mui/material';

const DisabledYachtAndNAgentForm = () => {
  return (
    <Stack spacing={1}>
      <Stack>
        <Divider label='Yacht' />
        <TextField
          id='yacht_name'
          label='Yacht'
          name='yacht_name'
          required={true}
          disabled={true}
        />
      </Stack>
      <TextField
        id='agent_NA_name'
        label='NA Agent'
        name='agent_NA_name'
        required={true}
        disabled={true}
      />
    </Stack>
  );
};

export default DisabledYachtAndNAgentForm;
