import * as yup from 'yup';
import { API as ArrivalDepartureFormality } from '../../../arrivalDepartureFormality/api';

export const ImmigrationFormalitiesConf = {
  schema: {
    id_arrival_departure_formality: yup
      .object()
      .nullable()
      .required('Service is required'),
    crew_name: yup.string().nullable().required('Crew name is required'),
  },
  defaultValues: {
    id_arrival_departure_formality: null,
    crew_name: null,
  },
  fields: [
    {
      id: 'service_divider',
      type: 'divider',
      label: 'Service',
      xs: 12,
    },
    {
      id: 'id_arrival_departure_formality',
      name: 'id_arrival_departure_formality',
      type: 'async_autocomplete',
      label: 'Service',
      api: ArrivalDepartureFormality.selectImmigrationFormalities,
      required: true,
      xs: 12,
      sm: 6,
    },

    {
      id: 'crew_name',
      name: 'crew_name',
      type: 'text',
      label: 'Crew name',
      required: true,
      xs: 12,
      sm: 6,
    },
  ],
};
