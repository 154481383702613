import { Box, IconButton } from '@mui/material';
import React from 'react';
import { Delete, Save, Edit } from '@mui/icons-material';

const IconsCrud = ({
  editable,
  onSaveAction = () => {},
  handleEditable = () => {},
  disabledEdit = false,
  disabledDelete = false,
  handleOpen = () => {},
}) => {
  return (
    <Box display={'flex'}>
      {editable ? (
        <IconButton color='info' component='label' onClick={onSaveAction}>
          <Save />
        </IconButton>
      ) : (
        <IconButton
          color='info'
          component='label'
          onClick={handleEditable}
          disabled={disabledEdit}
        >
          <Edit />
        </IconButton>
      )}
      <IconButton
        color='error'
        component='label'
        onClick={handleOpen}
        disabled={disabledDelete}
      >
        <Delete />
      </IconButton>
    </Box>
  );
};

export default IconsCrud;
