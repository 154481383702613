import { Box, Container, Paper, Stack } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchAPI } from '../../../api/request';
import Alert from '../../../components/common/Alert.component';
import Back from '../../../components/common/Back.component';
import Loading from '../../../components/common/Loading.component';
import AddView from '../../../views/Add.view';
import { API as departments } from '../../../module/departments/api';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import AgentNAForm from '../../../components/agentNA/AgentNAForm.component';
import { API as yachts } from '../../../module/yachts/api';
import DepotNewYachtForm from '../../../components/yacht/DepotNewYachtForm.component';
import dayjs from 'dayjs';

const DepotAddPage = ({ module: { code, query, tag, create } }) => {
  const navigate = useNavigate();
  const { jwt, user } = useSelector((state) => state.login);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [idDepartment, setIdDepartment] = useState(null);
  const [error, setError] = useState(false);
  const [valueTab, setValueTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const onError = useCallback(() => {
    setSuccess(false);
    setLoading(false);
    setError(true);
    // eslint-disable-next-line
  }, []);

  const onSuccess = useCallback(
    (resp) => {
      fetchAPI(
        query.create.list,
        {},
        '',
        {
          id_operation_list: resp.data.id,
        },
        jwt
      )
        .then((list) => {
          setError(false);
          setSuccess(true);
          setLoading(false);
          navigate(`/department/depot/${resp.data.id}/list/${list.data.id}`, {
            replace: true,
            state: { id_operation: `${resp.data.id}` },
          });
        })
        .catch(onError);
    },
    [setSuccess, setLoading, jwt, onError, navigate, query]
  );

  const onSubmit = useCallback(
    (values) => {
      setSuccess(false);
      setLoading(true);
      setError(false);
      const newYacht = {
        yacht_name: values?.yacht_name,
        loa: values?.loa ? values?.loa : '0',
        beam: values?.beam ? values?.beam : '0',
        draft: values?.draft ? values?.draft : '0',
        gross_tonn: values?.gross_tonn ? values?.gross_tonn : '0',
        yacht_type: values?.yacht_type?.id,
        date_submit_signature: dayjs().format('YYYY-MM-DD'),
        registration_status: 'PENDING',
      };

      const data =
        valueTab !== 0
          ? { ...values, ...newYacht, id_department: idDepartment, id_user_last_updated: user?.id_users_nautica?.id || null }
          : { ...values, id_department: idDepartment, id_user_last_updated: user?.id_users_nautica?.id || null };

      fetchAPI(query.create.settings, {}, '', { ...data }, jwt)
        .then((response) => onSuccess(response))
        .catch(onError);
    },
    [setLoading, jwt, onError, onSuccess, idDepartment, query, valueTab, user]
  );

  useEffect(() => {
    fetchAPI(departments.select, { code: code }, '', null, jwt)
      .then((response) => {
        setIdDepartment(_.head(response)?.id);
      })
      .catch(onError);
  }, [code, jwt, onError]);

  return (
    <Container disableGutters={true} maxWidth={false} sx={{ height: '100%' }}>
      <Stack
        spacing={2}
        sx={{ padding: 2 }}
        justifyContent='center'
        alignItems='center'
      >
        <Back title={tag} />
        <Container>
          {loading && <Loading />}
          {success && <Alert severity='success'>{'Success: Add'}</Alert>}
          {error && <Alert severity='error'>{'An error has occurred'}</Alert>}

          <Paper variant='outlined'>
            <Box sx={{ padding: 2 }}>
              {idDepartment && (
                <AddView
                  conf={
                    valueTab !== 0
                      ? create.conf?.NEW_YACHT
                      : create.conf?.DEFAULT
                  }
                  disabled={loading}
                  onAddSubmit={onSubmit}
                  onAddError={onError}
                  onAddSuccess={onSuccess}
                  actions={
                    <>
                      <DepotNewYachtForm
                        api={yachts.selectNoDRAFT}
                        valueTab={valueTab}
                        handleChange={handleChangeTab}
                      />
                      {valueTab === 0 && <AgentNAForm />}
                    </>
                  }
                />
              )}
            </Box>
          </Paper>
        </Container>
      </Stack>
    </Container>
  );
};

export default DepotAddPage;
