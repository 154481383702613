import dayjs from 'dayjs';
import { getItem } from '../../../utility/Storage.utility';

export const FilterConf = {
  schema: null,
  initialState: {
    value: {
      from: getItem('from')
        ? getItem('from')
        : `${dayjs().year()}-01-01T00:00:00Z`,
      to: getItem('to') ? getItem('to') : `${dayjs().year()}-12-31T24:00:00Z`, 
    },
  },
  fields: [],
  query: {
    keys: [
      {
        key: 'createdAt',
        value: 'from',
        operator: '$gte',
      },
      {
        key: 'createdAt',
        value: 'to',
        operator: '$lte',
      },
    ],
  },
};
