export const SearchConf = {
    query: {
        keys: [{
            key: 'id_yacht.yacht_name',
            operator: '$containsi'
        }, 
    ]
    },
    placeholder: 'Search by yacht name',
    initialState: {
        value: ''
    },
}