export const Status = [
  {
    label: 'Reservation made by boat'.toUpperCase(),
    id: 1,
    color: 'info',
    hex: '#0288d1',
  },
  {
    label: 'Confirmed'.toUpperCase(),
    id: 2,
    color: 'success',
    hex: '#2e7d32',
  },
  {
    label: 'Not Confirmed'.toUpperCase(),
    id: 3,
    color: 'error',
    hex: '#d32f2f',
  },
  {
    label: 'At anchor'.toUpperCase(),
    id: 4,
    color: 'secondary',
    hex: '#2d3537',
  },
  {
    label: 'In pending'.toUpperCase(),
    id: 5,
    color: 'warning',
    hex: '#ed6c02',
  },
  {
    label: 'Reservation canceled or moved'.toUpperCase(),
    id: 6,
    color: 'default',
    hex: '#e9e9e9',
  },
  {
    label: 'Generic bay'.toUpperCase(),
    id: 7,
    color: 'default',
    hex: '#e9e9e9',
  },
];

export const first_contact = [
  { label: 'USER', id: 'USER' },
  { label: 'INFO', id: 'INFO' },
];

export const DataConf = {
  initialState: {
    loading: false,
    error: null,
    data: [],
    meta: null,
  },
};
