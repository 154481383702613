import React from 'react';
import { Box, Paper, Stack, Divider } from '@mui/material';
import { SimpleCreateConf } from '../../../../module/logbook/conf/SimpleCreate.conf';
import ModalLogbook from '../../../../components/logbook/modal/ModalLogbook.component';
import TableItemsProvisions from '../../../../components/provisions/items/table/TableItemsProvisions.component';
import provisions from '../../../../module/provisions';
import AccordionComponent from '../../../../components/common/Accordion.component';

const ProductsList = ({
  data,
  expanded,
  onChange = () => {},
  setSuccess = () => {},
  checked,
  disabledButtonLogbook,
  productsList,
  setChecked = () => {},
  onSelectItem = () => {},
}) => {
  return (
    <Box>
      <Paper variant='outlined'>
        <Stack divider={<Divider />}>
          <AccordionComponent
            expanded={expanded}
            onChange={onChange}
            title='Products'
            details={
              <TableItemsProvisions
                data={data}
                setSuccessEdit={setSuccess}
                onSelectItem={onSelectItem}
                checked={checked}
              />
            }
          />
        </Stack>
      </Paper>
      <Box display={'flex'} justifyContent={'flex-end'} mt={1}>
        <ModalLogbook
          disabled={disabledButtonLogbook}
          conf={SimpleCreateConf}
          setSuccess={setSuccess}
          productsList={productsList}
          setChecked={setChecked}
          module={provisions}
        />
      </Box>
    </Box>
  );
};

export default ProductsList;
