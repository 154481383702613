import * as yup from 'yup';

import _ from 'lodash';
import { OperationConf } from '../../operations/conf/Create.conf';
import dayjs from 'dayjs';
import { AccountType } from '../../operations/conf/Data.conf';

export const CommonFiels = [
  {
    id: 'yacht_divider',
    type: 'divider',
    label: 'Yacht',
    xs: 12,
  },
  {
    id: 'yacht_name',
    name: 'yacht_name',
    type: 'text',
    label: 'Yacht',
    required: true,
    disabled: true,
    xs: 12,
  },
  {
    id: 'agent_NA_name',
    name: 'agent_NA_name',
    type: 'text',
    label: 'NA Agent',
    disabled: true,
    required: false,
    xs: 12,
  },
];

export const UpdateConf = {
  schema: yup.object({
    ..._.omit(OperationConf.schema, ['check_out_date', 'payment_type']),
    operation_service: yup.string().nullable().required('Service is required'),
  }),
  defaultValues: {
    ..._.omit(OperationConf.defaultValues, [
      'check_out_date',
      'payment_type'
    ]),
    operation_service: '',
    requested_date: dayjs(),
    account_type: _.find(AccountType, { id: 'BOAT' }),

  },

  fields: [
    ...CommonFiels,
    {
      id: 'service_generic',
      type: 'divider',
      label: 'Service',
      xs: 12,
    },
    {
      id: 'operation_service',
      name: 'operation_service',
      type: 'text',
      label: 'Service',
      required: true,
      xs: 12,
    },
    {
      id: 'service_detail',
      name: 'service_detail',
      type: 'text',
      label: 'Service detail',
      required: false,
      xs: 12,
      sm: 6,
    },
    ...OperationConf.fields.slice(4, 5),

    {
      id: 'requested_date',
      name: 'requested_date',
      type: 'date',
      label: 'Date',
      required: true,
      xs: 12,
      sm: 6,
    },
    ...OperationConf.fields.slice(3, 4),
    ...OperationConf.fields.slice(5, 17),
    ...OperationConf.fields.slice(18),
  ],
};
