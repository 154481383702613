import * as yup from 'yup';
import { QuantityType } from './Data.conf';
import { API as products } from '../../products/api';
import _ from 'lodash';
import { AvailableType } from '../../listItem/conf/Data.conf';

const Conf = {
  schema: yup.object({
    available: yup.object().nullable().required('Available is required'),
    quantity: yup.string().nullable(),
    quantity_type: yup.object().nullable(),
    id_item: yup.object().nullable().required('Product is required'),
    note: yup.string().nullable(),
    internal_note: yup.string().nullable(),
    attachment: yup.mixed().nullable(),
  }),
  defaultValues: {
    available: _.find(AvailableType, ['id', 'NEW ITEM']),
    quantity: '',
    quantity_type: null,
    id_item: null,
    note: '',
    // category: null,
    internal_note: '',
    attachment: null,
  },
  fields: [
    {
      id: 'id_item',
      name: 'id_item',
      type: 'async_autocomplete',
      label: 'Product',
      api: products.select,
      required: true,
      xs: 12,
      sm: 5,
      //md: 4,
      autoFocus: true,
    },
    {
      id: 'quantity',
      name: 'quantity',
      type: 'number',
      label: 'Quantity',
      xs: 12,
      sm: 2,
      md: 2,
    },
    {
      id: 'quantity_type',
      name: 'quantity_type',
      type: 'autocomplete',
      label: 'Type',
      options: QuantityType,
      xs: 12,
      sm: 2,
      md: 2,
    },
    {
      id: 'available',
      name: 'available',
      type: 'autocomplete',
      label: 'Available',
      options: AvailableType,
      required: true,
      xs: 12,
      sm: 3,
      md: 3,
    },
    // {
    //   id: 'category',
    //   name: 'category',
    //   type: 'autocomplete',
    //   label: 'Category',
    //   options: CategoryType,
    //   xs: 12,
    //   sm: 3,
    //   md: 2,
    // },
    {
      id: 'note',
      name: 'note',
      type: 'text',
      label: 'Description',
      xs: 12,
    },
    {
      id: 'internal_note',
      name: 'internal_note',
      type: 'text',
      label: 'Internal Note',
      xs: 12,
    },
    {
      id: 'attachment',
      name: 'attachment',
      label: 'Photo',
      type: 'upload',
      xs: 12,
      sm: 6,
      md: 4,
    },
  ],
};

export const CreateConf = {
  default: { ...Conf },
  hasLogbook: {
    schema: Conf.schema,
    defaultValues: {
      ...Conf.defaultValues,
      available: _.find(AvailableType, ['id', 'ORDERED']),
    },
    fields: Conf.fields,
  },
};
