import {
  Autocomplete,
  Box,
  Container,
  Grid,
  Paper,
  Stack,
  TextField,
} from '@mui/material';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchAPI } from '../../../api/request';
import Alert from '../../../components/common/Alert.component';
import Back from '../../../components/common/Back.component';
import Loading from '../../../components/common/Loading.component';
import AddView from '../../../views/Add.view';
import dayjs from 'dayjs';
import { AgencyOptions } from '../../../module/agency/conf/Data.conf';
import NewYachtForm from '../../../components/yacht/NewYachtForm.component';
import { API as departments } from '../../../module/departments/api';
import _ from 'lodash';
import YachtForm from '../../../components/yacht/YachtForm.component';
import AgentNAForm from '../../../components/agentNA/AgentNAForm.component';
import PilotINForm from '../../../components/pilotIN/PilotINForm.component';
import { API as yachts } from '../../../module/yachts/api';

const AgencyAddPage = ({module:{code, query, create}}) => {
  const { jwt, user } = useSelector((state) => state.login);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [reservationType, setReservationType] = useState(null);
  const [valueTab, setValueTab] = useState(0);
  const [error, setError] = useState(false);
  const [idDepartment, setIdDepartment] = useState(null);

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };
  const handleChangeReservation = (newValue) => {
    setReservationType(newValue);
    setValueTab(0);
  };

  const onError = useCallback((error) => {
    setSuccess(false);
    setLoading(false);
    setError(error);
    // eslint-disable-next-line
  }, []);

  const onSuccess = useCallback(
    (values) => {
      const newYacht = {
        yacht_name: values?.yacht_name,
        loa: values?.loa,
        beam: values?.beam,
        draft: values?.draft,
        gross_tonn: values?.gross_tonn,
        yacht_type: values?.yacht_type?.id,
        date_submit_signature: dayjs().format('YYYY-MM-DD'),
        registration_status: 'PENDING',
      };

      const data = {
        ...values,
        reservation_type: reservationType.id,
      };

      setLoading(false);

      fetchAPI(
        query.create.settings2,
        {},
        '',

        valueTab !== 0
          ? {
              ...data,
              ...newYacht,
              id_yacht: null,
            }
          : { ...data, id_yacht: values?.id_yacht?.id },

        jwt
      )
        .then(() => {
          setSuccess(true);
          setError(false);
        })
        .catch(onError);
    },
    [setLoading, setSuccess, jwt, onError, reservationType, valueTab, query]
  );

  const onSubmit = useCallback(
    (values) => {
      const data = {
        ...values,
        id_department: idDepartment,
        id_user_last_updated: user?.id_users_nautica?.id || null,
      };

      setSuccess(false);
      setError(false);
      setLoading(true);
      fetchAPI(query.create.settings, {}, '', data, jwt)
        .then((resp) =>
          onSuccess({
            id_operation_list: resp?.data?.id,
            ...values,
          })
        )
        .catch(onError);
    },
    [setLoading, jwt, onError, onSuccess, idDepartment, query, user]
  );

  useEffect(() => {
    fetchAPI(departments.select, { code: code }, '', null, jwt)
      .then((response) => {
        setIdDepartment(_.head(response)?.id);
      })
      .catch(onError);
    // eslint-disable-next-line
  }, []);

  return (
    <Container disableGutters={true} maxWidth={false} sx={{ height: '100%' }}>
      <Stack
        spacing={2}
        sx={{ padding: 2 }}
        justifyContent='center'
        alignItems='center'
      >
        <Back title={'AGENCY'} />
        <Container>
          {loading && <Loading />}
          {success && <Alert severity='success'>{'Success: Add'}</Alert>}
          {error && (
            <Alert severity='error'>
              {error?.response?.data?.error?.message
                ? error?.response?.data?.error?.message
                : 'An error has occurred'}
            </Alert>
          )}
          <Paper variant='outlined'>
            <Box sx={{ padding: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Autocomplete
                    disablePortal
                    options={AgencyOptions}
                    onChange={(_, newValue) => {
                      handleChangeReservation(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label='Reservation Type' />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  {reservationType && (
                    <AddView
                      conf={
                        reservationType.id === 'BERTH RESERVATION' ||
                        reservationType.id === 'BUOY RESERVATION' ||
                        reservationType.id === 'PILOTS IN'
                          ? {
                              ...create.conf[reservationType?.id],
                              schema:
                                valueTab !== 0
                                  ? create.conf[
                                      `${reservationType?.id} NEW YACHT`
                                    ].schema
                                  : create.conf[reservationType?.id]
                                      .schema,
                            }
                          : create.conf[reservationType?.id]
                      }
                      disabled={loading || success}
                      onAddSubmit={onSubmit}
                      onAddError={onError}
                      onAddSuccess={onSuccess}
                      actions={
                        <Fragment>
                          {reservationType?.id !== 'PILOTS IN' &&
                          reservationType.id !== 'BERTH RESERVATION' &&
                          reservationType.id !== 'BUOY RESERVATION' ? (
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                <YachtForm api={yachts.selectNoDRAFT} />
                              </Grid>
                              {valueTab === 0 && (
                                <>
                                  <Grid item xs={12}>
                                    <AgentNAForm />
                                    </Grid>
                                </>
                              )}
                              <Grid item xs={12}>
                                {reservationType?.id === 'PILOTS OUT' && (
                                  <PilotINForm />
                                )}
                              </Grid>
                            </Grid>
                          ) : (
                            <Fragment>
                              {(reservationType?.id === 'PILOTS IN' ||
                                reservationType.id !== 'BERTH RESERVATION' ||
                                reservationType.id !== 'BUOY RESERVATION') && (
                                <NewYachtForm
                                  api={yachts.selectNoDRAFT}
                                  valueTab={valueTab}
                                  handleChange={handleChangeTab}
                                />
                              )}

                                {valueTab === 0 && <AgentNAForm />}
                            </Fragment>
                          )}
                        </Fragment>
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Container>
      </Stack>
    </Container>
  );
};

export default AgencyAddPage;
