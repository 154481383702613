import * as yup from 'yup';
import { OperationConf } from '../../operations/conf/Create.conf';
import _ from 'lodash';
import { API as marina } from '../../marina/api';
import { API as servicesOperationList } from '../api/index'

const OperationConfig = { ...OperationConf };

export const CreateConf = {
  schema: yup.object({ ...OperationConf.schema, id_service: yup.object().nullable().required('Service is required') }),
  defaultValues: OperationConfig.defaultValues,
  fields: _.concat(
    {
      id: 'service_generic',
      type: 'divider',
      label: 'Service',
      xs: 12,
    },
    {
      id: 'id_service',
      name: 'id_service',
      type: 'async_autocomplete',
      api: servicesOperationList.servicesOperationList,
      label: 'Service',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'id_marina',
      name: 'id_marina',
      type: 'async_autocomplete',
      label: 'Location',
      api: marina.select,
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'requested_date',
      name: 'requested_date',
      type: 'date',
      label: 'Delivery Date',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'requested_hour',
      name: 'requested_hour',
      type: 'time',
      label: 'Delivery Time',
      required: false,
      xs: 12,
      sm: 4,
    },
    {
      id: 'hour_type',
      name: 'hour_type',
      type: 'autocomplete',
      label: 'Hour Type',
      required: false,
      options: [
        { id: 'AM', label: 'AM' },
        { id: 'PM', label: 'PM' },
      ],
      xs: 12,
      sm: 2,
    },

    OperationConf.fields.slice(5, 17),
    OperationConf.fields.slice(18)
  ),
};
