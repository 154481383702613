import * as yup from 'yup';
import { CrewGuestType } from '../Data.conf';
import { API as marina } from '../../../marina/api';

export const RestaurantBeachClubConf = {
  schema: {
    service_date: yup.date().nullable().required('Service date is required'),
    service_time: yup.date().nullable().required('Service time is required'),
    structure_name: yup.string().nullable().required('Restaurant is required'),
    pax_number: yup.string().nullable().required('Pax is required'),
    id_marina: yup.object().nullable(),
    crew_guest: yup.object().nullable().required('Crew / Guest is required'),
    client_name: yup.string().nullable().required('Name is required'),
  },
  defaultValues: {
    service_date: null,
    service_time: null,
    structure_name: null,
    pax_number: null,
    crew_guest: null,
    id_marina: null,
    client_name: null,
  },
  fields: [
    {
      id: 'reservation_divider',
      type: 'divider',
      label: 'Reservation',
      xs: 12,
    },
    {
      id: 'service_date',
      name: 'service_date',
      type: 'date',
      label: 'Service date',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'service_time',
      name: 'service_time',
      type: 'time',
      label: 'Service time',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'structure_name',
      name: 'structure_name',
      type: 'text',
      label: 'Structure Name',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'pax_number',
      name: 'pax_number',
      type: 'number',
      label: 'Pax',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'crew_guest',
      name: 'crew_guest',
      type: 'autocomplete',
      label: 'Crew / Guest',
      options: CrewGuestType,
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'client_name',
      name: 'client_name',
      type: 'text',
      label: 'Reservation in the name of',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'id_marina',
      name: 'id_marina',
      type: 'async_autocomplete',
      label: 'Location',
      api: marina.select,
      required: false,
      xs: 12,
      sm: 6,
    },
  ],
};
