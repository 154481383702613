import * as yup from 'yup';
import { RequestedServiceCleanType } from '../Data.conf';
import { API as marina } from '../../../marina/api';

export const CleaningConf = {
  schema: {
    service_date: yup.date().nullable().required('Service date is required'),
    service_time: yup.date().nullable().required('Service time is required'),
    id_marina: yup.object().nullable(),
    service_place: yup.string().nullable().required('Location is required'),
    requested_service_clean: yup
      .object()
      .nullable()
      .required('Requested service is required'),
    pax_number: yup.string().nullable().required('Pax is required'),
    requested_hour_service_clean: yup
      .string()
      .nullable()
      .required('Hours  is required'),
  },
  defaultValues: {
    service_date: null,
    service_time: null,
    service_place: null,
    requested_service_clean: null,
    pax_number: null,
    requested_hour_service_clean: null,
    id_marina: null,
  },
  fields: [
    {
      id: 'reservation_divider',
      type: 'divider',
      label: 'Reservation',
      xs: 12,
    },
    {
      id: 'service_date',
      name: 'service_date',
      type: 'date',
      label: 'Service date',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'service_time',
      name: 'service_time',
      type: 'time',
      label: 'Service time',
      required: true,
      xs: 12,
      sm: 6,
    },

    {
      id: 'requested_service_clean',
      name: 'requested_service_clean',
      type: 'autocomplete',
      label: 'Requested service clean',
      options: RequestedServiceCleanType,
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'service_place',
      name: 'service_place',
      type: 'text',
      label: 'Service Place',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'id_marina',
      name: 'id_marina',
      type: 'async_autocomplete',
      label: 'Location',
      api: marina.select,
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'pax_number',
      name: 'pax_number',
      type: 'number',
      label: 'Pax',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'requested_hour_service_clean',
      name: 'requested_hour_service_clean',
      type: 'text',
      label: 'Hours',
      required: true,
      xs: 12,
      sm: 6,
    },
  ],
};
