import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchAPI } from '../../../api/request';
import { DetailConf } from '../../operations/conf/Detail.conf';
import { actions } from '../slice';

function* fetchAll(action) {
  const { settings, params, query, body, token } = action.payload;

  try {
    yield put({ type: actions.loadingChange.type });
    const response = yield call(fetchAPI, settings, params, query, body, token);
    yield put({ type: actions.dataChange.type, payload: response });
  } catch (error) {
    yield put({ type: actions.errorChange.type, error });
  }
}

function* fetchDetail(action) {
  const { settings, params, query, body, token } = action.payload;

  if (settings && params && query && token) {
    try {
      yield put({ type: actions.loadingDetailChange.type });
      const response = yield call(
        fetchAPI,
        settings,
        params,
        query,
        body,
        token
      );
      yield put({ type: actions.dataDetailChange.type, payload: response });
    } catch (error) {
      yield put({ type: actions.errorDetailChange.type, error });
    }
  } else {
    yield put({
      type: actions.dataDetailChange.type,
      payload: { ...DetailConf.initialState },
    });
  }
}

export function* saga() {
  yield takeLatest(actions.fetchAllAction, fetchAll);
  yield takeLatest(actions.fetchDetailAction, fetchDetail);
}
