import { createSlice } from '@reduxjs/toolkit'

export const sliceList = (name, initialState) => createSlice({
    name,
    initialState: { ...initialState },
    reducers: {
        paginationChange: (state, action) => {
            state.pagination = { page: action.payload.page, pageSize: action.payload.pageSize }
        },
        sortChange: (state, action) => {
            state.sort = { by: action.payload, direction: action.payload === state.sort?.by && state.sort?.direction === 'asc' ? 'desc' : 'asc' }
        },
        resetPaginationAndSort: (state) => {
            const { pagination, sort } = { ...initialState }
            state.pagination = pagination
            state.sort = sort
        },
        selectedChange: (state, action) => {
            const selectedIndex = state.selected.indexOf(action.payload);
            let newSelected = [];

            if (selectedIndex === -1) {
                newSelected = newSelected.concat(state.selected, action.payload);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(state.selected.slice(1));
            } else if (selectedIndex === state.selected.length - 1) {
                newSelected = newSelected.concat(state.selected.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    state.selected.slice(0, selectedIndex),
                    state.selected.slice(selectedIndex + 1)
                );
            }

            state.selected = newSelected
        },
    },
})