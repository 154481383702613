import TechnicalIcon from '@mui/icons-material/Handyman';
import ProvisionsIcon from '@mui/icons-material/Restaurant';
import InteriorsIcon from '@mui/icons-material/Yard';
import ConciergeIcon from '@mui/icons-material/ConfirmationNumber';
import DepotIcon from '@mui/icons-material/Webhook';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LogbookIcon from '@mui/icons-material/FormatAlignJustify';

export const DepartmentsConf = [
  {
    id: 'agency',
    to: '/department/agency',
    title: 'Agency',
    icon: <DirectionsBoatIcon />,
    children: [],
    disabled: false,
    color: '#00568d',
  },
  {
    id: 'concierge',
    to: '/department/concierge',
    title: 'Concierge',
    icon: <ConciergeIcon />,
    children: [],
    disabled: false,
    color: '#00568d',
  },
  {
    id: 'logbook',
    to: '/logbook',
    title: 'Deliveries',
    disabled: false,
    icon: <LogbookIcon />,
    children: [],
  },
  {
    id: 'depot',
    to: '/department/depot',
    title: 'Depot',
    icon: <DepotIcon />,
    children: [],
    disabled: false,
    color: '#f1b000',
  },
  {
    id: 'interiors',
    to: '/department/interiors',
    title: 'Interiors',
    icon: <InteriorsIcon />,
    children: [],
    disabled: false,
    color: '#009db7',
  },
  {
    id: 'provisions',
    to: '/department/provisions',
    title: 'Provisions',
    icon: <ProvisionsIcon />,
    children: [],
    disabled: false,
    color: '#009db7',
  },
  {
    id: 'shipping',
    to: '/department/shipping',
    title: 'Shipping',
    icon: <LocalShippingIcon />,
    children: [],
    disabled: false,
    color: '#535252',
  },
  {
    id: 'technical',
    to: '/department/technical',
    title: 'Technical',
    icon: <TechnicalIcon />,
    children: [],
    disabled: false,
    color: '#f1b000',
  },
];
