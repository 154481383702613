import React from 'react';
import { Box, Paper, Stack, Divider } from '@mui/material';
import { SimpleCreateConf } from '../../../../module/logbook/conf/SimpleCreate.conf';
import ModalLogbook from '../../../../components/logbook/modal/ModalLogbook.component';
import AccordionComponent from '../../../../components/common/Accordion.component';
import interiors from '../../../../module/interiors';
import TableVirtuosoItems from '../../../../components/listItem/table/TableViruosoItems.component';
import itemsInteriors from '../../../../module/listItemsInteriors';
import { columns } from '../../../../module/listItemsInteriors/conf/Data.conf';
import TableRowEditable from '../../../../components/interiors/table/TableRowEditable.component';

const ItemsList = ({
  data,
  expanded,
  onChange = () => {},
  setSuccess = () => {},
  checked,
  disabledButtonLogbook,
  productsList,
  setChecked = () => {},
  onSelectItem = () => {},
}) => {
  return (
    <Box>
      <Paper variant='outlined'>
        <Stack divider={<Divider />}>
          <AccordionComponent
            expanded={expanded}
            onChange={onChange}
            title='Products'
            details={
              <TableVirtuosoItems
                data={data}
                setSuccessEdit={setSuccess}
                onSelectItem={onSelectItem}
                checked={checked}
                module={itemsInteriors}
                columns={columns}
                orderById={
                  'attributes.id_logbook.data.attributes.id_supplier.data.attributes.label'
                }
                orderType={'asc'}
              >
                <TableRowEditable hasCheckbox={true} />
              </TableVirtuosoItems>
            }
          />
        </Stack>
      </Paper>
      <Box display={'flex'} justifyContent={'flex-end'} mt={1}>
        <ModalLogbook
          disabled={disabledButtonLogbook}
          conf={SimpleCreateConf}
          setSuccess={setSuccess}
          productsList={productsList}
          setChecked={setChecked}
          module={interiors}
        />
      </Box>
    </Box>
  );
};

export default ItemsList;
