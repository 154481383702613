import React, { useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import Autocomplete from '../form/components/Autocomplete.component';
import Divider from '../form/components/Divider.component';
import { useSelector } from 'react-redux';
import { fetchAPI } from '../../api/request';

const PilotINForm = ({ disabled = false }) => {
  const { watch, setValue } = useFormContext();
  const watchYacht = watch('id_yacht');
  const { jwt } = useSelector((state) => state.login);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (watchYacht) {
      fetchAPI(
        {
          path: `/operation-lists?filters[id_reservation][reservation_type][$eq]=PILOTS IN&filters[$and][0][id_yacht][id][$eq]=${watchYacht.id}&filters[$and][1][id_reservation_piloti_out][id][$null]=true&populate[id_yacht]=%2A&populate[id_reservation][populate][id_yacht]=%2A&populate[id_reservation_piloti_in]=%2A`,
          // path: `reservations?filters[reservation_type]=PILOT IN&populate[id_yacht]=%2A&pagination[page]=1&pagination[pageSize]=1000&filters[id_yacht]=${watchYacht.id}`,
          method: 'GET',
          auth: true,
          transform: (data) =>
            data?.data?.map((item) => {
              return {
                label: `${item.attributes?.id_reservation?.data?.id} - ${item?.attributes?.id_yacht?.data?.attributes?.yacht_name}`,
                id: item.attributes?.id_reservation?.data?.id,
              };
            }),
        },
        {},
        '',
        {},
        jwt
      )
        .then((resp) => {
          setOptions(resp);
        })
        .catch(() => setOptions([]));
    } else {
      setValue('id_reservation_piloti_in', null);
    }
  }, [watchYacht, jwt, setValue]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Divider label='Pilot In' />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          name={'id_reservation_piloti_in'}
          id={'id_reservation_piloti_in'}
          label={'Pilot In'}
          options={options}
          disabled={!watchYacht || disabled}
          required={true}
        />
      </Grid>
    </Grid>
  );
};

export default PilotINForm;
