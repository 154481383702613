import React, { Fragment, useCallback, useMemo, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useSelector } from 'react-redux';
import Loading from '../../../components/common/Loading.component';
import Modal from '../../../components/common/Modal.component';
import AddView from '../../../views/Add.view';
import { fetchAPI } from '../../../api/request';
import Alert from '../../../components/common/Alert.component';
import _ from 'lodash';

const ShippingDocumentsAddPage = ({
  module: { query, create, tag },
  setSuccessAdd = () => {},
  data,
  success,
}) => {
  const { jwt } = useSelector((state) => state.login);
  const userAuth = useSelector((state) => state.login?.user);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const hasPermission = useMemo(
    () => userAuth.permissions[_.toUpper(tag)]?.CREATE === 'true',
    [userAuth, tag]
  );
  const onError = useCallback((error) => {
    setSuccessAdd(false);
    setLoading(false);
    setError(error);
    // eslint-disable-next-line
  }, []);

  const onSuccess = useCallback(() => {
    setSuccessAdd(true);
    setLoading(false);
    setError(false);
    setOpenModal(false);
  }, [setLoading, setSuccessAdd]);

  const onSubmit = useCallback(
    (values) => {
      if (hasPermission) {
        const id_shipping = data?.attributes?.id_shipping?.data?.id;
        setError(false);
        setSuccessAdd(false);
        setLoading(true);
        setOpenModal(true);

        fetchAPI(
          query.create.settings,
          {},
          '',
          { ...values, id_shipping: id_shipping },
          jwt
        )
          .then(onSuccess)
          .catch((error) => onError(error));
      }
    },
    [
      setLoading,
      jwt,
      onError,
      onSuccess,
      data,
      query,
      setSuccessAdd,
      hasPermission,
    ]
  );

  return (
    <Fragment>
      {success && <Alert severity='success'>{'Success: Add'}</Alert>}
      {error && (
        <Alert severity='error'>
          {error?.response?.data?.error?.message
            ? error?.response?.data?.error?.message
            : 'An error has occurred'}
        </Alert>
      )}

      {loading && <Loading />}

      {hasPermission && (
        <Modal
          label={'Add Document'}
          variant='outlined'
          startIcon={<AddIcon />}
          openModal={openModal}
        >
          <AddView conf={create.conf} onAddSubmit={onSubmit} />
        </Modal>
      )}
    </Fragment>
  );
};

export default ShippingDocumentsAddPage;
