import React, { Fragment, useCallback, useMemo, useState } from 'react';

import {
  TableCell as MuiTableCell,
  Collapse,
  Box,
  useTheme,
} from '@mui/material';

import _ from 'lodash';
import { useSelector } from 'react-redux';
import { HeaderProductsInCollapse } from '../../../../../module/listItemsProvisions/conf/Data.conf';
import ConfirmDialog from '../../../../common/Confirm.component';
import { fetchAPI } from '../../../../../api/request';
import itemsProvisions from '../../../../../module/listItemsProvisions';
import Loading from '../../../../common/Loading.component';
import ItemAdd from '../../../../listItem/create';
import TableRow from '../../../items/table/components/TableRow.component';
import TableRowSort from '../../../../listItem/table/sort/TableRowSort.component';
import TableVirtuoso from '../../../../virtuosoTable/TableVirtuoso.component';

const STATUS_LOGBOOK = 'DELIVERED';

const TableCollapse = ({
  open,
  data,
  setSuccessUpdate = () => {},
  idLogbook,
  orderById = 'createdAt',
  orderType = 'desc',
  status_logbook,
}) => {
  const theme = useTheme();
  const { jwt } = useSelector((state) => state.login);

  const [orderBy, setOrderBy] = useState(orderById);
  const [order, setOrder] = useState(orderType);
  const [currentState, setCurrentState] = useState(null);
  const [editable, setEditable] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [value, setValue] = useState(null);

  //Sort products rows for each logbook
  const sortedData = useMemo(
    () => _.orderBy(data, [orderBy], [order]),
    [orderBy, order, data]
  );
  const tableHeight = useMemo(
    () =>
      sortedData.length === 1 ? 220 : Math.min(sortedData.length * 115, 500),
    [sortedData]
  );

  const handleSort = (columnId) => {
    const isAsc = orderBy === columnId && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(columnId);
  };

  const onChange = (value, name, id) => {
    setCurrentState({
      ...currentState,
      [name]: { ...currentState?.[name], [id]: value ? value : null },
    });
  };

  const handleClose = useCallback(() => {
    setOpenDelete(false);
    setValue(null);
  }, []);

  const handleOpen = useCallback((item) => {
    setOpenDelete(true);
    setValue(item);
  }, []);

  const handleEditable = (item) => {
    setEditable({ ...editable, [item?.id]: true });
  };

  // eslint-disable-next-line
  const onSuccess = (item) => {
    setLoading(false);
    setEditable({ ...editable, [item?.id]: false });
    handleClose();
  };

  const onError = useCallback(() => {
    setLoading(false);
  }, []);

  const onDeleteAction = useCallback(() => {
    setSuccessUpdate(false);
    fetchAPI(
      itemsProvisions.query.delete.settings,
      { id: value.id },
      '',
      {},
      jwt
    )
      .then(() => {
        onSuccess();
        setSuccessUpdate(true);
      })
      .catch(onError);
  }, [setSuccessUpdate, onSuccess, jwt, onError, value]);

  const onSaveAction = useCallback(
    (item) => {
      setLoading(true);
      const data = {
        quantity: currentState?.quantity?.[item?.id],
        available: currentState?.available?.[item?.id],
        quantity_type: currentState?.quantity_type?.[item?.id],
        category: currentState?.category?.[item?.id],
        note: currentState?.note?.[item?.id],
      };

      fetchAPI(
        itemsProvisions.query.edit.settings,
        { id: item.id },
        '',
        { ...data },
        jwt
      )
        .then(() => onSuccess(item))
        .catch(onError);
    },
    [currentState, onSuccess, jwt, onError]
  );

  return (
    <Fragment>
      <MuiTableCell
        style={{ paddingBottom: 0, paddingTop: 0, width: '100%' }}
        colSpan={4}
      >
        <Collapse in={open || false} timeout='auto' unmountOnExit>
          {loading && <Loading />}

          <Box m={1}>
            <Box display={'flex'} justifyContent={'right'}>
              <ItemAdd
                idLogbook={idLogbook}
                module={{
                  ...itemsProvisions,
                  create: { conf: itemsProvisions.create.conf.hasLogbook },
                }}
                productsList={sortedData}
                isModal={true}
                setSuccessAdd={setSuccessUpdate}
                disabled={status_logbook === STATUS_LOGBOOK}
              />
            </Box>
            <TableVirtuoso
              style={{ height: tableHeight, width: '100%', zIndex: 15 }}
              data={sortedData}
              fixedHeaderContent={() => (
                <TableRowSort
                  columns={HeaderProductsInCollapse}
                  onSort={handleSort}
                  order={order}
                  orderBy={orderBy}
                  backgroundColor={theme.palette.secondary.medium_20}
                  colorText={theme.palette.primary.dark}
                  sx={{ padding: 1.5 }}
                />
              )}
              itemContent={(_index, item) => {
                return (
                  <TableRow
                    onChange={onChange}
                    item={
                      sortedData.length === 1
                        ? { ...item, disabledDelete: true }
                        : item
                    }
                    currentState={currentState}
                    editable={editable}
                    handleEditable={handleEditable}
                    onSaveAction={onSaveAction}
                    handleOpen={handleOpen}
                    hasCheckbox={false}
                    crudDisabled={
                      status_logbook && status_logbook === STATUS_LOGBOOK
                    }
                  />
                );
              }}
            />

            <ConfirmDialog
              open={openDelete}
              onClose={handleClose}
              title='Are you sure you want to delete this?'
              description='It will be deleted permanently. You cannot undo this action.'
              cancelAction={handleClose}
              confirmAction={onDeleteAction}
            />
          </Box>
        </Collapse>
      </MuiTableCell>
    </Fragment>
  );
};

export default TableCollapse;
