import { createSlice } from '@reduxjs/toolkit'

export const sliceSearch = (name, initialState) => createSlice({
    name,
    initialState: { ...initialState },
    reducers: {
        searchChange: (state, action) => {
            state.value = action.payload
        }
    },
})