import dayjs from 'dayjs';

export const API = {
  bookings: {
    path: '/bookings?populate[id_yacht][populate][id_attendance][populate][id_agent_NA][populate][id_users_permissions_user]=%2A&populate[id_yacht][populate][id_attendance][populate][id_agent_NA][fields][0]=id_users_permissions_user&populate[id_marina]=%2A&populate[id_reservation]=%2A',
    method: 'GET',
    get: null,
    auth: true,
    fallback: null,
  },
  booking: {
    path: '/bookings/{{id}}',
    method: 'GET',
    get: null,
    auth: true,
    fallback: null,
    transform: (data) => ({
      meta: data?.meta,
      data: {
        id: data?.data?.id,
        attributes: {
          ...data?.data?.attributes,
          yacht: {
            yacht_name:
              data?.data?.attributes?.id_yacht?.data?.attributes?.yacht_name,
            url_web:
              data?.data?.attributes?.id_yacht?.data?.attributes?.url_web,
            id: data?.data?.attributes?.id_yacht?.data?.id,
          },
        },
      },
    }),
  },
  update: {
    path: '/bookings/{{id}}',
    method: 'PUT',
    get: null,
    auth: true,
    fallback: null,
    adapter: (body) => ({
      data: {
        ...body,
        id_yacht: body?.id_yacht?.id,
        id_agent_NA: body?.id_agent_NA?.id,
        booking_status: body?.booking_status?.label,
        id_marina: body?.id_marina?.id,
        check_in_date: dayjs(body?.check_in_date).format('YYYY-MM-DD'),
        check_out_date: dayjs(body?.check_out_date).format('YYYY-MM-DD'),
      },
    }),
  },
  delete: {
    path: '/bookings/{{id}}',
    method: 'DELETE',
    get: null,
    auth: true,
    fallback: null,
  },
  create: {
    path: '/bookings',
    method: 'POST',
    get: null,
    auth: true,
    fallback: null,
    adapter: (body) => ({
      data: {
        ...body,
        id_yacht: body?.id_yacht?.id,
        id_agent_NA: body?.id_agent_NA?.id,
        booking_status: body?.booking_status?.label,
        id_marina: body?.id_marina?.id,
        check_in_date: dayjs(body?.check_in_date).format('YYYY-MM-DD'),
        check_out_date: dayjs(body?.check_out_date).format('YYYY-MM-DD'),
      },
    }),
  },
};
