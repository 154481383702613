import { useSelector } from 'react-redux';
import { useLocation, Navigate } from 'react-router-dom';
import { getHasPermissions } from '../utility/Permissions.utility';

const AuthRoute = ({ children }) => {
  let location = useLocation();
  const { jwt, user } = useSelector((state) => state.login);

  if (jwt && user) {
    const hasPermission = getHasPermissions({ location, user });

    if (hasPermission === 'false' && location?.pathname !== '/404') {
      return <Navigate to='/404' state={{ from: location }} replace />;
    }
    return children;
  }

  return <Navigate to='/' state={{ from: location }} replace />;
};

export default AuthRoute;
