import React from 'react';
import MuiFormControlLabel from '@mui/material/FormControlLabel';
import { useController, useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';
import Error from './Error.component';
import MuiSwitch from '@mui/material/Switch';

import PropTypes from 'prop-types';
import _ from 'lodash';

const Switch = ({ name, required, label, ...props }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const {
    field: { ref, onChange, ...inputProps },
  } = useController({
    name: name,
    control: control,
    rules: { required },
  });

  return (
    <Box display='flex' flexDirection={'column'}>
      <MuiFormControlLabel
        label={label}
        control={
          <MuiSwitch
            required={required}
            ref={ref}
            onChange={onChange}
            checked={inputProps.value}
            error={_.toString(!!errors[name]?.message)}
            {...inputProps}
            {...props}
          />
        }
      />
      <Error error={errors[name]?.message} />
    </Box>
  );
};

Switch.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Switch;
