import React, { useCallback, useEffect, useState } from 'react';
import { Container, Stack, Paper, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Back from '../../../components/common/Back.component';
import { useNavigate, useParams } from 'react-router-dom';
import EditView from '../../../views/Edit.view';
import Loading from '../../../components/common/Loading.component';
import { fetchAPI } from '../../../api/request';
import Alert from '../../../components/common/Alert.component';
import attendances from '../../../module/attendances';
import { API as provenenceLists } from '../../../module/provenenceLists/api';
import AttendanceForm from '../../../components/attendance/AttendanceForm.component';
import EditYachtAndNAgentForm from '../../../components/yachtAndAgentNA/EditYachtAndNAgentForm.component';

const AttendacesEditPage = () => {
  const { id } = useParams();
  const { jwt } = useSelector((state) => state.login);

  const {
    loading: loadingGet,
    data,
    error: errorGET,
  } = useSelector(attendances.edit.selector.data);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const onSuccess = useCallback(() => {
    setSuccess(true);
    setLoading(false);
    setError(false);
  }, []);

  const onError = useCallback(() => {
    setSuccess(false);
    setLoading(false);
    setError(true);
    // eslint-disable-next-line
  }, []);

  const onSubmitAttendances = useCallback(
    (data) => {
      fetchAPI(
        attendances.query.edit.settings,
        { id },
        '',
        { ...data, note: data?.note_attendance },
        jwt
      )
        .then(onSuccess)
        .catch(onError);
    },
    [jwt, onError, onSuccess, id]
  );

  const onSubmit = useCallback(
    (values) => {
      setError(false);
      setSuccess(false);
      setLoading(true);
      if (data?.id_provenence_list) {
        fetchAPI(
          provenenceLists.edit,
          { id: data?.id_provenence_list },
          '',
          {
            data: {
              provenence_group: values.provenence_group?.id,
              first_contact: values.first_contact?.id,
              provenence_sales_channel: values.provenence_sales_channel?.id,
              provenence: values.provenence?.id || null,
              note: values.note,
              users_nauticas: values.users_nauticas?.id || null,
            },
          },
          jwt
        )
          .then((resp) =>
            onSubmitAttendances({
              ...values,
              id_provenence_list: resp?.data?.id,
              note: null,
            })
          )
          .catch(onError);
      } else {
        fetchAPI(
          provenenceLists.create,
          {},
          '',
          {
            data: {
              provenence_group: values.provenence_group?.id,
              first_contact: values.first_contact?.id,
              provenence_sales_channel: values.provenence_sales_channel?.id,
              provenence: values.provenence?.id,
              note: values.note,
              users_nauticas: values.users_nauticas?.id,
            },
          },
          jwt
        )
          .then((resp) =>
            onSubmitAttendances({
              ...values,
              id_provenence_list: resp?.data?.id,
            })
          )
          .catch(onError);
      }
    },
    [setLoading, jwt, onError, onSubmitAttendances, data]
  );

  useEffect(() => {
    dispatch(
      attendances.query.detail.action({
        settings: attendances.query.detail.settings,
        token: jwt,
        params: { id },
        query: { populate: attendances.query.detail.populate },
      })
    );

    return () => {
      dispatch(
        attendances.query.detail.action({
          settings: null,
          token: null,
          params: null,
          query: null,
        })
      );
    };
  }, [id, jwt, dispatch]);

  useEffect(() => {
    if (
      errorGET &&
      (errorGET?.response?.status === 404 || errorGET?.response?.status === 500)
    )
      navigate('/404');
  }, [errorGET, navigate]);

  return (
    <Container disableGutters={true} maxWidth={false} sx={{ height: '100%' }}>
      <Stack
        spacing={2}
        sx={{ padding: 2 }}
        justifyContent='center'
        alignItems='center'
      >
        <Back title={attendances.tag} />
        <Container>
          {(loading || loadingGet) && <Loading />}
          {success && <Alert severity='success'>{'Success!!'}</Alert>}
          {error && <Alert severity='error'>{'An error has occurred'}</Alert>}

          <Paper variant='outlined'>
            <Box sx={{ padding: 2 }}>
              <EditView
                data={data}
                conf={attendances.edit.conf}
                api={attendances.query.edit}
                onEditSubmit={onSubmit}
                disabled={loading || loadingGet}
                onEditError={onError}
                actions={<EditYachtAndNAgentForm />}
              >
                <AttendanceForm data={data} />
              </EditView>
            </Box>
          </Paper>
        </Container>
      </Stack>
    </Container>
  );
};

export default AttendacesEditPage;
