import React from 'react';
import { useSelector } from 'react-redux';
import Calendar from '../components/calendar/Calendar';

const CalendarView = ({
  height = 'auto',
  minHeight = 700,
  range,
  onClick = () => {},
  calendar,
  defaultValue,
}) => {
  const {
    conf: { render, initialState },
    selector,
  } = calendar;
  const events = useSelector(selector.data);

  return (
    <Calendar
      range={range}
      height={{ height, minHeight }}
      events={events}
      eventPropGetter={render.eventPropGetter}
      onSelectEvent={onClick}
      sort={initialState.sort}
      defaultValue={defaultValue}
    />
  );
};

export default CalendarView;
