import React from 'react';
import { TextField, Autocomplete } from '@mui/material';
import { ListCheckDepot } from '../../../../module/depot/conf/Data.conf';

const EditDepot = ({ onChange = () => {}, currentState, disabled = false }) => {
  return (
    <Autocomplete
      name='check_depot'
      onChange={(event, newValue) => onChange(newValue, 'check_depot')}
      value={currentState?.check_depot || null}
      options={ListCheckDepot}
      disabled={disabled}
      size='small'
      sx={{ width: { xs: '100%', sm: 150 } }}
      disableClearable
      renderInput={(params) => <TextField {...params} label={'Done'} />}
    />
  );
};

export default EditDepot;
