import React from 'react';

import Slider from 'react-slick';

const SliderSettings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 2,
};

const SliderComponent = ({ children }) => {
  return <Slider {...SliderSettings}>{children}</Slider>;
};

export default SliderComponent;
