import React, { Fragment } from 'react';
import _ from 'lodash';
import { Stack, TableCell, Box, Typography, Checkbox } from '@mui/material';
import Info from '../../listItem/table/components/Info.component';
import Detail from '../../listItem/table/components/Detail.component';
import { QuantityType } from '../../../module/listItemsInteriors/conf/Data.conf';
import ControlAutocomplete from '../../listItem/table/components/ControlAutocomplete.component';
import ControlTextField from '../../listItem/table/components/ControlTextField.component';
import { v4 as uuidv4 } from 'uuid';
import CardMedia from '../../common/CardMedia.component';
import SliderComponent from '../../common/Slider.component';
import IconsCrud from '../../common/IconsCrud.component';
import { AvailableType } from '../../../module/listItem/conf/Data.conf';

const STATE_ENABLE = 'READY';

const TableRowEditable = ({
  item,
  onChange = () => {},
  editable,
  currentState,
  checked,
  onSelectItem = () => {},
  handleEditable = () => {},
  onSaveAction = () => {},
  handleOpen = () => {},
  hasCheckbox = true,
  crudDisabled,
}) => {
  return (
    <Fragment>
      {hasCheckbox && (
        <TableCell>
          <Checkbox
            checked={checked?.[item?.id] || false}
            onChange={() => {
              onSelectItem({
                id: item?.id,
                status: _.has(checked, item?.id) ? !checked?.[item?.id] : true,
              });
            }}
            disabled={
              !editable?.[item?.id]
                ? _.has(currentState?.available, [item?.id])
                  ? currentState?.available?.[item?.id] !== STATE_ENABLE
                  : item?.attributes?.available !== STATE_ENABLE
                : true
            }
          />
        </TableCell>
      )}

      <TableCell>
        <Stack spacing={1}>
          <Detail item={item} />
          <Info
            onChange={onChange}
            item={item}
            currentState={currentState}
            editable={editable}
            name='note'
            label='Description'
          />
          <Info
            onChange={onChange}
            item={item}
            currentState={currentState}
            editable={editable}
            name='internal_note'
            label='Internal Note'
          />
        </Stack>
      </TableCell>

      <TableCell width={250}>
        {item?.attributes?.attachment?.data ? (
          <Box mb={1}>
            <SliderComponent>
              {item?.attributes?.attachment?.data?.map((attachment, index) => {
                const base_url =
                  process.env.REACT_APP_BASE_URL.split('/api')[0];
                const url = _.get(attachment?.attributes, 'url');
                return (
                  <Box pr={1} key={uuidv4()}>
                    <CardMedia image={`${base_url}${url}`} />
                  </Box>
                );
              })}
            </SliderComponent>
          </Box>
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell>
        <ControlTextField
          item={item}
          editable={editable}
          currentState={currentState}
          onChange={onChange}
          name='quantity'
          label='Quantity'
          visibleText={false}
        />

        {!editable?.[item?.id] && (
          <Stack direction={'row'} spacing={1}>
            <Typography>
              {_.has(currentState, `quantity.${item?.id}`)
                ? currentState?.quantity?.[item?.id] || '-'
                : item?.attributes?.quantity || '-'}
            </Typography>
            <Typography>
              {_.has(currentState, `quantity_type.${item?.id}`)
                ? currentState?.quantity_type?.[item?.id]
                : item?.attributes?.quantity_type}
            </Typography>
          </Stack>
        )}
      </TableCell>

      <TableCell>
        <ControlAutocomplete
          item={item}
          editable={editable}
          options={QuantityType}
          currentState={currentState}
          onChange={onChange}
          name='quantity_type'
          label='Type'
          visibleText={false}
        />
      </TableCell>

      <TableCell>
        <ControlAutocomplete
          item={item}
          editable={editable}
          options={AvailableType}
          currentState={currentState}
          onChange={onChange}
          name='available'
          label='Available'
          required={true}
          disableClearable={true}
        />
      </TableCell>

      <TableCell>
        <IconsCrud
          editable={editable?.[item?.id]}
          onSaveAction={() => onSaveAction(item)}
          handleEditable={() => handleEditable(item)}
          disabledEdit={
            crudDisabled ? crudDisabled : item?.disabledEdit || false
          }
          disabledDelete={
            crudDisabled ? crudDisabled : item?.disabledDelete || false
          }
          handleOpen={() => handleOpen(item)}
        />
      </TableCell>
    </Fragment>
  );
};

export default TableRowEditable;
