import * as yup from 'yup';
import { API as yachts } from '../../yachts/api';
import { OperationTypes, StatusLogbookDeliveries } from './Data.conf';
import { API as departments } from '../../departments/api';
import dayjs from 'dayjs';

export const FilterConf = {
  schema: yup.object({
    id_yacht: yup.object().nullable(),
    id_department: yup.object().nullable(),
    start_date: yup
      .date()
      .nullable()
      .transform((value) => {
        if (value && dayjs(value).isValid()) {
          const formattedDate = dayjs(value).format('YYYY-MM-DD');
          return new Date(formattedDate);
        }
        return null;
      }),
  }),
  initialState: {
    value: {
      id_yacht: null,
      id_department: null,
      status_logbook: null,
      operation_type: null,
      start_date: dayjs().format('YYYY-MM-DD').toString(),
    },
  },
  fields: [
    {
      id: 'id_department',
      name: 'id_department',
      type: 'async_autocomplete',
      label: 'Department',
      api: departments.logbookDepartments,
      required: false,
      xs: 12,
      sm: 6,
      md: 4,
    },
    {
      id: 'id_yacht',
      name: 'id_yacht',
      type: 'async_autocomplete',
      label: 'Yacht',
      api: yachts.selectNoDRAFT,
      required: false,
      xs: 12,
      sm: 6,
      md: 4,
    },
    {
      id: 'status_logbook',
      name: 'status_logbook',
      type: 'autocomplete_multiple',
      label: 'Status',
      options: StatusLogbookDeliveries,
      required: false,
      xs: 12,
      sm: 6,
      md: 4,
    },
    {
      id: 'operation_type',
      name: 'operation_type',
      type: 'autocomplete',
      label: 'Operation Type',
      options: OperationTypes,
      required: false,
      xs: 12,
      sm: 6,
      md: 4,
    },
    {
      id: 'start_date',
      name: 'start_date',
      type: 'date',
      label: 'Date',
      required: false,
      xs: 12,
      sm: 6,
      md: 4,
    },
  ],
  query: {
    keys: [
      {
        key: 'id_yacht.id',
        value: 'id_yacht.id',
        operator: '$eq',
      },
      {
        key: 'id_department.id',
        value: 'id_department.id',
        operator: '$eq',
      },
      {
        key: 'status_logbook',
        value: 'status_logbook.label',
        operator: '$eq',
      },
      {
        key: 'operation_type',
        value: 'operation_type.label',
        operator: '$eq',
      },
      {
        key: 'collection_delivery_date',
        value: 'start_date',
        operator: '$gte',
      },
    ],
  },
};
