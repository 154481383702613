import React from 'react';
import {
  Container,
  Stack,
  Paper,
  Box,
  useTheme,
  Grid,
  Card,
  Typography,
  CardContent,
} from '@mui/material';
import LogoSection from '../../layout/mainLayout/logoSection';

const GuestCard = ({ children, title, subTitle }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor:
          theme.palette.mode === 'dark'
            ? theme.palette.background.default
            : theme.palette.primary.light,
        minHeight: '90vh',
      }}
    >
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        sx={{ minHeight: '100vh' }}
      >
        <Grid
          item
          container
          justifyContent='center'
          md={6}
          lg={7}
          sx={{ my: 3 }}
        >
          <Card sx={{ borderRadius: 2, maxWidth: { xs: 300, sm: 500 } }}>
            <CardContent>
              <Grid container spacing={2} justifyContent='center'>
                <Grid item xs={12}>
                  <Grid
                    container
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    spacing={4}
                  >
                    <Grid
                      item
                      xs={12}
                      justifyContent={'center'}
                      alignItems='center'
                      display='flex'
                    >
                      <LogoSection />
                    </Grid>

                    <Grid item>
                      <Stack justifyContent={'center'} textAlign={'center'}>
                        {title && (
                          <Typography
                            color={theme.palette.secondary.main}
                            gutterBottom
                            variant={'h3'}
                          >
                            {title}
                          </Typography>
                        )}
                        {subTitle && (
                          <Typography
                            color='textPrimary'
                            gutterBottom
                            variant='h4'
                          >
                            {subTitle}
                          </Typography>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Stack
                    spacing={2}
                    justifyContent='center'
                    alignItems='center'
                  >
                    <Container>
                      <Paper>{children}</Paper>
                    </Container>
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GuestCard;
