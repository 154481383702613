import { DynamicControl } from './DynamicControl';
import PropTypes from 'prop-types';
import { DynamicFieldData } from './DynamicControlTypes';
import { Grid } from '@mui/material';
import Section from './components/Section.component';
import _get from 'lodash/get';
import _isNull from 'lodash/isNull';
import _isUndefined from 'lodash/isUndefined';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _compact from 'lodash/compact';
import _isEmpty from 'lodash/isEmpty';
import { v4 as uuidv4 } from 'uuid';
import { useMemo } from 'react';
import _isBoolean from 'lodash/isBoolean';
import _trim from 'lodash/trim';

export const Detail = ({ fields, render, data }) => {
  const sections = useMemo(() => {
    return _filter(fields, ({ items }) => {
      const values = _compact(_map(items, ({ key }) => _get(data, key)));
      return !_isEmpty(values);
    });
  }, [fields, data]);

  return (
    <Grid container spacing={2}>
      {sections.map((section) => (
        <Grid
          item
          key={uuidv4()}
          xs={section.xs ? section.xs : 12}
          sm={section.sm}
        >
          <Section label={section.label} {...section}>
            <Grid container ml={0.5}>
              {section.items.map(({ xs, sm, md, lg, key, ...d }) => {
                return _isNull(_get(data, key)) ||
                  _isUndefined(_get(data, key)) ||
                  (_isEmpty(_trim(_get(data, key))) &&
                    !_isBoolean(_get(data, key))) ? null : (
                  <Grid item xs={xs} sm={sm} md={md} lg={lg} key={uuidv4()} {...d}>
                    {render(key, _get(data, key)) || (
                      <DynamicControl {...d} value={_get(data, key)} />
                    )}
                  </Grid>
                );
              })}
            </Grid>
          </Section>
        </Grid>
      ))}
    </Grid>
  );
};

Detail.propTypes = {
  fields: PropTypes.arrayOf(DynamicFieldData),
};
