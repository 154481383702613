import React, { useCallback } from 'react';
import Create from '../components/crud/Create';

const AddView = ({
  conf,
  onAddSubmit = () => {},
  disabled = false,
  children,
  actions,
}) => {
  const { fields, schema, defaultValues } = conf;

  const onSubmit = useCallback(
    (values) => {
      onAddSubmit(values);
      setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 10);
    },
    [onAddSubmit]
  );

  return (
    <Create
      defaultValues={defaultValues}
      fields={fields}
      schema={schema}
      onSubmit={onSubmit}
      disabled={disabled}
      actions={actions}
    >
      {children}
    </Create>
  );
};

export default AddView;
