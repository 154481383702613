import * as yup from 'yup';

export const ShippingStorageConf = {
	schema: {
		storage: yup.string().nullable(),
		details: yup.string().nullable(),
	},
	defaultValues: {
		storage: '',
		details: '',
	},
	fields: [
		{
			id: 'storage',
			name: 'storage',
			type: 'text',
			label: 'Storage',
			required: false,
			xs: 12,
		},
		{
			id: 'details',
			name: 'details',
			type: 'text',
			label: 'Details',
			required: false,
			xs: 12,
		},
	],
};
