import React, { useCallback, useEffect, useState } from 'react';
import {
  Container,
  Stack,
  Paper,
  Box,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Back from '../../../components/common/Back.component';
import { useNavigate, useParams } from 'react-router-dom';
import EditView from '../../../views/Edit.view';
import Loading from '../../../components/common/Loading.component';
import { fetchAPI } from '../../../api/request';
import Alert from '../../../components/common/Alert.component';
import yacht from '../../../module/yachts';
import dayjs from 'dayjs';
import _flatten from 'lodash/flatten';
//import _find from 'lodash/find';
//import yachtDocuments from '../../../module/yachtDocuments';
import _ from 'lodash';
//import users_crew from '../../../module/users_crew';
import InvoicingEntityForm from '../../../components/invoicingEntity/InvoicingEntityForm.component';
import YachtAllFieldsForm from '../../../components/yacht/YachtAllFieldsForm.component';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import captain from '../../../module/captain';
import AddView from '../../../views/Add.view';

const YachtEditPage = ({ tag, edit, query }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { jwt } = useSelector((state) => state.login);
  const {
    loading: loadingGet,
    data,
    error: errorGET,
  } = useSelector(edit.selector.data);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [disableAdd, setDisableAdd] = useState(false);
  const [invoiceType, setInvoiceType] = useState(data?.invoicing_entity?.id);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  //const [types, setTypes] = useState([]);

  const onSuccess = useCallback(() => {
    setSuccess(true);
    setLoading(false);
    setError(false);
  }, []);

  const onError = useCallback(() => {
    setSuccess(false);
    setLoading(false);
    setError(true);
    // eslint-disable-next-line
  }, []);

  const onSubmit = useCallback(
    (values, e) => {
      // let promiseSignature = Promise.reject(0);
      // let promiseYachtStamp = Promise.reject(0);
      // let promisePassport = Promise.reject(0);
      // let promiseVatDocument = Promise.reject(0);
      // let promiseCaptain = Promise.reject(0);
      const obj =
        invoiceType === 'NATURAL PERSON'
          ? {
              ...values,
              invoicing_entity: invoiceType,
              invoice_company_name: null,
              vat_number_company_invoicing: null,
            }
          : {
              ...values,
              invoicing_entity: invoiceType,
              invoice_person_birthdate: null,
              invoice_person_lastname: null,
              invoice_person_name: null,
              invoice_person_place_of_birth: null,
            };
      setError(false);
      setSuccess(false);
      setLoading(true);

      const promiseForm = fetchAPI(
        query.edit.settings,
        { id },
        '',
        {
          ...obj,
          date_submit_signature: obj?.date_submit_signature
            ? dayjs(obj?.date_submit_signature).format('YYYY-MM-DD')
            : null,
          registration_status:
            data?.registration_status !== 'ONLINE'
              ? 'MANUAL'
              : data?.registration_status,
        },
        jwt
      );

      // if (data?.id_user_crew && _.isEmpty(data?.id_user_crew)) {
      //   promiseCaptain = fetchAPI(
      //     users_crew.query.create.settings,
      //     {},
      //     '',
      //     {
      //       ...obj,
      //       id_yacht: id,
      //       crew_responsability: 'Captain',
      //     },
      //     jwt
      //   );
      // }

      // if (
      //   values?.vat_document !== undefined &&
      //   values?.vat_document?.length !== 0
      // ) {
      //   const documentType = _find(
      //     types,
      //     (t) => t.attributes.slug === 'certificate-of-vat-or-incorporation'
      //   );
      //   promiseVatDocument = Array.from(values.vat_document).map((file) =>
      //     fetchAPI(
      //       yachtDocuments.query.create.settings,
      //       {},
      //       '',
      //       {
      //         file,
      //         idYacht: id,
      //         key: 'vat_document',
      //         documentType: documentType?.id,
      //       },
      //       jwt
      //     )
      //   );
      // }
      // if (
      //   values?.signature_draw !== undefined &&
      //   values?.signature_draw?.length !== 0
      // ) {
      //   const documentType = _find(
      //     types,
      //     (t) => t.attributes.slug === 'signature'
      //   );

      //   promiseSignature = fetchAPI(
      //     yachtDocuments.query.create.settings,
      //     {},
      //     '',
      //     {
      //       file: values.signature_draw,
      //       idYacht: id,
      //       key: 'signature_draw',
      //       documentType: documentType?.id,
      //     },
      //     jwt
      //   );
      // }

      // if (
      //   values?.yacht_stamp !== undefined &&
      //   values?.yacht_stamp?.length !== 0
      // ) {
      //   const documentType = _find(
      //     types,
      //     (t) => t.attributes.slug === 'yacht-stamp'
      //   );

      //   promiseYachtStamp = Array.from(values.yacht_stamp).map((file) =>
      //     fetchAPI(
      //       yachtDocuments.query.create.settings,
      //       {},
      //       '',
      //       {
      //         file,
      //         idYacht: id,
      //         key: 'signature_draw',
      //         documentType: documentType.id,
      //       },
      //       jwt
      //     )
      //   );
      // }

      // if (values?.passaport !== undefined && values?.passaport?.length !== 0) {
      //   const documentType = _find(
      //     types,
      //     (t) => t.attributes.slug === 'captain-pass-port'
      //   );
      //   promisePassport = Array.from(values.passaport).map((file) =>
      //     fetchAPI(
      //       yachtDocuments.query.create.settings,
      //       {},
      //       '',
      //       {
      //         file,
      //         idYacht: id,
      //         key: 'passaport',
      //         documentType: documentType?.id,
      //       },
      //       jwt
      //     )
      //   );
      // }

      Promise.any(
        _flatten([
          promiseForm,
          // promiseSignature,
          // promiseYachtStamp,
          // promisePassport,
          // promiseVatDocument,
          // promiseCaptain,
        ])
      )
        .then(() => onSuccess())
        .catch((error) => onError(error));
    },
    [setLoading, id, jwt, onError, query, onSuccess, invoiceType, data]
  );

  const onSubmitCaptain = useCallback(
    (values) => {
      setLoading(true);

      let obj = values;
      if (!_.isEmpty(obj?.country_code)) {
        obj = {
          ...values,
          phone_number: `${obj?.country_code?.phone}-${obj?.phone_number}`,
        };
      }
      delete obj.country_code;

      obj = {
        id_yacht: id,
        crew_responsability: 'Captain',
        phone_number: obj?.phone_number,
        surname: obj?.surname,
        name: obj?.name,
        birthdate: obj?.birthdate
          ? dayjs(obj?.birthdate).format('YYYY-MM-DD')
          : null,
      };

      if (data?.username) {
        fetchAPI(
          captain.query.edit.settings,
          { id: data?.id_user_crew[0]?.id },
          '',
          { data: obj },
          jwt
        )
          .then(() => onSuccess())
          .catch((error) => onError(error));
      } else {
        fetchAPI(
          captain.query.create.settings,
          {},
          '',
          {
            data: {
              ...obj,
              email: values?.email,
              username: values?.username,
              is_delivery_manager_on_board: true,
            },
          },

          jwt
        )
          .then(() => {
            setDisableAdd(true);
            onSuccess();
          })
          .catch((error) => onError(error));
      }
    },
    [setLoading, id, jwt, onError, onSuccess, data]
  );

  // useEffect(() => {
  //   fetchAPI(yachtDocuments.query.types.settings, {}, '', {}, jwt).then(
  //     (response) => setTypes(response)
  //   );
  // }, [jwt]);

  useEffect(() => {
    if (!_.isEmpty(data) && data?.invoicing_entity?.id === undefined) {
      setInvoiceType('COMPANY');
    } else {
      setInvoiceType(data?.invoicing_entity?.id);
    }
  }, [data]);

  useEffect(() => {
    dispatch(
      query.detail.action({
        settings: query.detail.settings,
        token: jwt,
        params: { id },
        query: { populate: query.detail.populate },
      })
    );

    return () => {
      dispatch(
        query.detail.action({
          settings: null,
          token: null,
          params: null,
          query: null,
        })
      );
    };
  }, [id, jwt, query, dispatch]);

  useEffect(() => {
    if (
      errorGET &&
      (errorGET?.response?.status === 404 || errorGET?.response?.status === 500)
    )
      navigate('/404');
  }, [errorGET, navigate]);

  return (
    <Container disableGutters={true} maxWidth={false} sx={{ height: '100%' }}>
      <Stack spacing={2} justifyContent='center' alignItems='center'>
        <Back title={tag} />
        <Container>
          {(loading || loadingGet) && <Loading />}
          <Paper variant='outlined'>
            {success && <Alert severity='success'>{'Success!!'}</Alert>}
            {error && <Alert severity='error'>{'An error has occurred'}</Alert>}

            {invoiceType && (
              <Box sx={{ padding: 2 }}>
                <EditView
                  data={data}
                  conf={
                    data?.username
                      ? yacht.edit.conf[`${invoiceType}_captain`]
                      : yacht.edit.conf[invoiceType]
                  }
                  api={query.edit}
                  onEditSubmit={onSubmit}
                  disabled={loading || loadingGet}
                  onEditError={onError}
                  actions={
                    <Stack spacing={2}>
                      <YachtAllFieldsForm />
                      <InvoicingEntityForm
                        invoiceType={invoiceType}
                        setInvoiceType={setInvoiceType}
                      />
                    </Stack>
                  }
                />
                <Accordion
                  disableGutters
                  elevation={1}
                  sx={{ marginBottom: 5 }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                      }}
                      variant='subtitle1'
                    >
                      Captain
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {data?.username ? (
                      <EditView
                        data={data}
                        conf={captain.edit.conf}
                        //api={captain.query.edit}
                        onEditSubmit={onSubmitCaptain}
                        disabled={loading || loadingGet}
                        onEditError={onError}
                      />
                    ) : (
                      <AddView
                        conf={captain.create.conf}
                        // api={captain.query.create}
                        onAddSubmit={onSubmitCaptain}
                        disabled={disableAdd}
                        //onEditError={onError}
                      />
                    )}
                  </AccordionDetails>
                </Accordion>
              </Box>
            )}
          </Paper>
        </Container>
      </Stack>
    </Container>
  );
};

export default YachtEditPage;
