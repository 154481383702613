// CONF
import { CreateConf } from './conf/Create.conf';
import { UpdateConf } from './conf/Update.conf';
import { ListConf } from './conf/List.conf';
import { FilterConf } from './conf/Filter.conf';
import { SearchConf } from './conf/Search.conf';
import { DetailConf } from './conf/Detail.conf';

// REDUX
import { reducer, actions } from './slice';

// SELECTOR
import { tag, querySelector, listSelector, detailSelector, editSelector } from './slice';

// SAGA
import { saga } from './saga';

// API
import { API } from './api';

export { reducer, saga, tag };

const contacts = {
	tag,
	query: {
		all: {
			selector: querySelector,
			action: actions.fetchAllAction,
			settings: API.contacts,
			populate: '*',
		},
		allNotBlocked: {
			selector: querySelector,
			action: actions.fetchAllAction,
			settings: API.contactsNotBlocked,
			populate: '*',
		},
		detail: {
			action: actions.fetchDetailAction,
			settings: API.contact,
			populate: '*',
		},
		create: {
			settings: API.create,
		},
		edit: {
			settings: API.update,
		},
		delete: {
			settings: API.delete,
		},
		CSV: {
			settings: API.usersCSV,
		},
	},
	search: {
		conf: SearchConf,
		actions: {
			search: actions.searchChange,
		},
	},
	filter: {
		conf: FilterConf,
		actions: {
			filter: actions.filterChange,
		},
	},
	list: {
		conf: ListConf,
		actions: {
			pagination: actions.paginationChange,
			sort: actions.sortChange,
		},
		selector: {
			data: listSelector,
		},
		add: {
			to: 'add',
		},
	},
	detail: {
		conf: DetailConf,
		selector: {
			data: detailSelector,
		},
		edit: {
			to: 'edit',
		},
	},
	edit: {
		conf: UpdateConf,
		selector: {
			data: editSelector,
		},
	},
	create: {
		conf: CreateConf,
	},
	remove: {
		selector: {
			data: detailSelector,
		},
	},
};

export default contacts;
