export const QuantityType = [
  { label: 'Kg', id: 'Kg' },
  { label: 'g', id: 'g' },
  { label: 'L', id: 'L' },
  { label: 'units', id: 'units' },
  { label: 'pieces', id: 'pieces' },
  { label: 'tubs', id: 'tubs' },
  { label: 'balls', id: 'balls' },
  { label: 'packets', id: 'packets' },
  { label: 'boxes', id: 'boxes' },
  { label: 'punnets', id: 'punnets' },
  { label: 'bunches', id: 'bunches' },
  { label: 'heads', id: 'heads' },
  { label: 'tins', id: 'tins' },
  { label: 'bottles', id: 'bottles' },
  { label: 'jars', id: 'jars' },
  { label: 'leaves', id: 'leaves' },
  { label: 'case', id: 'case' },
  { label: 'case of 4', id: 'case of 4' },
  { label: 'case of 6', id: 'case of 6' },
  { label: 'case of 12', id: 'case of 12' },
  { label: 'case of 24', id: 'case of 24' },
  { label: 'case of 36', id: 'case of 36' },
  { label: 'side', id: 'side' },

  // { label: 'stems', id: 'stems' },
  // { label: 'box', id: 'box' },
  // { label: 'bunch', id: 'bunch' },
];

export const CategoryType = [
  { id: 'DRY', label: 'DRY' },
  { id: 'FRESH', label: 'FRESH' },
  { id: 'FROZEN', label: 'FROZEN' },
];

export const DataConf = {
  initialState: {
    loading: false,
    error: null,
    data: [],
    meta: null,
  },
};

export const columns = [
  {
    width: 50,
    label: '',
    dataKey: 'checkbox',
    sortable: false,
  },
  {
    width: 400,
    label: 'PRODUCT',
    dataKey: 'name',
    key: 'attributes.id_item.data.attributes.name_ITA',
    sortable: true,
  },
  {
    width: 240,
    label: 'Photo',
    dataKey: 'photo',
    key: 'attributes.photo',
    sortable: false,
  },
  {
    width: 110,
    label: 'Quantity',
    dataKey: 'quantity',
    key: 'attributes.quantity',
    sortable: true,
  },
  {
    width: 100,
    label: '',
    dataKey: 'quantity_type',
    sortable: false,
  },
  {
    width: 220,
    label: 'Available',
    dataKey: 'available',
    key: 'attributes.available',
    sortable: true,
  },
  // {
  //   width: 180,
  //   label: 'Category',
  //   dataKey: 'category',
  //   key: 'attributes.available',
  //   sortable: true,
  // },
  {
    width: 140,
    label: '',
    dataKey: 'crud',
    sortable: false,
  },
];

export const HeadProductsInLogbookEditable = [
  {
    width: 40,
    label: '',
    dataKey: 'collapse',
    sortable: false,
  },
  {
    id: 'SUPPLIER',
    label: 'SUPPLIER',
    key: 'attributes.id_logbook.data.attributes.id_supplier.data.attributes.label',
    sortable: true,
  },
  {
    id: 'STATUS',
    label: 'STATUS',
    key: 'attributes.id_logbook.data.attributes.status_logbook',
    sortable: true,
  },

  {
    width: 40,
    label: '',
    dataKey: 'logbook',
    sortable: false,
  },
];

export const HeaderProductsInCollapse = [
  {
    id: 'PRODUCT',
    label: 'PRODUCT',
    sortable: true,
    key: 'attributes.id_item.data.attributes.name_ITA',
    width: 400,
  },
  {
    id: 'PHOTO',
    label: 'PHOTO',
    key: 'attributes.photo',
    sortable: true,
    width: 240,
  },
  {
    id: 'QUANTITY',
    label: 'QUANTITY',
    key: 'attributes.quantity',
    sortable: true,
    width: 110,
  },
  {
    label: '',
    dataKey: 'quantity_type',
    sortable: false,
    width: 170,
  },
  {
    id: 'AVAILABLE',
    label: 'AVAILABLE',
    key: 'attributes.available',
    sortable: true,
    width: 220,
  },
  // {
  //   id: 'CATEGORY',
  //   label: 'CATEGORY',
  //   key: 'attributes.available',
  //   sortable: true,
  //   width: 180,
  // },
  {
    width: 140,
    label: '',
    dataKey: 'crud',
    sortable: false,
  },
];
