import * as yup from 'yup';

import { API as users_nautica } from '../../users_nautica/api';
import { API as suppliers } from '../../supplier/api';
import { API as marina } from '../../marina/api';
import { API as departments } from '../../departments/api';
import dayjs from 'dayjs';
import _ from 'lodash';
import {
  OperationTypes,
  StatusLogbookDefault,
  StatusLogbookDeliveries,
  StatusPriority,
} from './Data.conf';

export const LogbookConf = {
  schema: yup.object({
    id_yacht: yup.object().nullable().required('Yacht Name is required'),
    id_department: yup.object().nullable().required('Department is required'),
    status_logbook: yup.string().nullable().required('Status is required'),
    manager: yup.object().nullable(),
    operation_type: yup
      .string()
      .nullable()
      .required('Operation type is required'),
    id_operator: yup.object().nullable().required('User is required'),
    delivery_notes: yup.string().nullable(),
    delivery_cost: yup.number().nullable(),
    priority: yup.object().nullable().required('Priority is required'),
    address: yup.string().nullable(),
    id_marina: yup.object().nullable(),
    id_location: yup.object().nullable().required('Location in required'),
    collection_delivery_date: yup.date().nullable().required('Date is required'),
    collection_delivery_time: yup
      .date()
      .nullable()
      .required('Time is required')
      .transform((value) => {
        if (value && dayjs(value).isValid()) {
          return value;
        }
        return null;
      }),
    collection_delivery_notes: yup.string().nullable(),
    collection_cost: yup.number().nullable(),
    id_supplier: yup.object().nullable().required('Supplier is required'),
    assigned_transport: yup.string().nullable(),
    id_driver_in_charge: yup.object().nullable(),
    contact_on_board_for_delivery: yup.string().nullable(),
    logbook_date: yup
      .date()
      .nullable()
      .required('Operation date is required'),
  }),
  defaultValues: {
    id_yacht: null,
    id_department: null,
    manager: null,
    operation_type: null,
    id_operator: null,
    delivery_cost: null,
    address: '',
    delivery_notes: '',
    priority: null,
    id_marina: null,
    id_location: null,
    collection_delivery_date: null,
    collection_delivery_time: null,
    collection_delivery_notes: '',
    collection_cost: null,
    id_supplier: null,
    assigned_transport: '',
    id_driver_in_charge: null,
    contact_on_board_for_delivery: '',
    logbook_date: dayjs().format('YYYY-MM-DD').toString(),
    status_logbook: _.find(
      StatusLogbookDefault,
      { id: 'NEW REQUEST' }
    ).id,
  },
  fields: [
    {
      id: 'id_department',
      type: 'divider',
      label: 'User & Department',
      xs: 12,
    },
    {
      id: 'id_operator',
      name: 'id_operator',
      type: 'async_autocomplete',
      label: 'User',
      api: users_nautica.selectName,
      required: true,
      disabled: false,
      xs: 12,
    },
    {
      id: 'id_department',
      name: 'id_department',
      type: 'async_autocomplete',
      label: 'Department',
      api: departments.logbookDepartments,
      required: true,
      disabled: false,
      xs: 12,
    },
    {
      id: 'operation_divider',
      type: 'divider',
      label: 'Operation details',
      xs: 12,
    },
    {
      id: 'operation_type', 
      name: 'operation_type',
      type: 'select-custom',
      label: 'Operation type',
      required: true,
      options: OperationTypes,
      xs: 12,
      sm: 6,
    },
    {
      id: 'collection_delivery_date',
      name: 'collection_delivery_date',
      type: 'date',
      label: 'Date',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'collection_delivery_time',
      name: 'collection_delivery_time',
      type: 'time',
      label: 'Time',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'id_location',
      name: 'id_location',
      type: 'async_autocomplete',
      label: 'Location',
      api: marina.select,
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'address',
      name: 'address',
      type: 'text',
      label: 'Address',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'id_supplier',
      name: 'id_supplier',
      type: 'async_autocomplete',
      label: 'Supplier',
      api: suppliers.select,
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'contact_on_board_for_delivery',
      name: 'contact_on_board_for_delivery',
      type: 'text',
      label: 'Contact On Board',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'priority',
      name: 'priority',
      type: 'autocomplete',
      label: 'Priority',
      required: true,
      options: StatusPriority,
      xs: 12,
      sm: 6,
    },
    {
      id: 'delivery_notes',
      name: 'delivery_notes',
      type: 'text',
      label: 'Notes',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'logbook_date',
      name: 'logbook_date',
      type: 'date',
      label: 'Operation date',
      disabled: true,
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'delivery_divider',
      type: 'divider',
      label: 'Pick-Up / Delivery Info ',
      xs: 12,
    },
    {
      id: 'manager',
      name: 'manager',
      type: 'async_autocomplete',
      label: 'Manager Pick-Up / Delivery',
      api: users_nautica.deliveries,
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'id_driver_in_charge',
      name: 'id_driver_in_charge',
      type: 'async_autocomplete',
      label: 'Driver in charge',
      api: users_nautica.drivers,
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'assigned_transport',
      name: 'assigned_transport',
      type: 'text',
      label: 'Vehicle',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'delivery_cost',
      name: 'delivery_cost',
      type: 'number',
      label: 'Delivery Cost',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'collection_cost',
      name: 'collection_cost',
      type: 'number',
      label: 'Pick-Up Cost',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'collection_delivery_notes',
      name: 'collection_delivery_notes',
      type: 'text',
      label: 'Pick-Up / Delivery Notes',
      required: false,
      xs: 12,
      sm: 6,
    },
  ],
};

const EditNoDep = {
  DEFAULT: {
    schema: LogbookConf.schema,
    defaultValues: LogbookConf.defaultValues,
    fields: _.concat(
      LogbookConf.fields.slice(0, 2),
      {
        id: 'id_department',
        name: 'id_department',
        type: 'async_autocomplete',
        label: 'Departments',
        api: departments.all,
        disabled: true,
        required: false,
        xs: 12,
      },
      LogbookConf.fields.slice(3, 12),
      {
        id: 'status_logbook',
        name: 'status_logbook',
        type: 'select-custom',
        label: 'Status',
        required: true,
        options: StatusLogbookDefault,
        xs: 12,
        sm: 6,
      },
      LogbookConf.fields.slice(12, 14)
    ),
  },
  DELIVERIES: {
    schema: LogbookConf.schema,
    defaultValues: LogbookConf.defaultValues,
    fields: _.concat(
      LogbookConf.fields.slice(0, 2),
      {
        id: 'id_department',
        name: 'id_department',
        type: 'async_autocomplete',
        label: 'Departments',
        api: departments.all,
        disabled: true,
        required: true,
        xs: 12,
      },
      LogbookConf.fields.slice(3, 12),
      {
        id: 'status_logbook',
        name: 'status_logbook',
        type: 'select-custom',
        label: 'Status',
        required: true,
        options: StatusLogbookDeliveries,
        xs: 12,
        sm: 6,
      },
      LogbookConf.fields.slice(12)
    ),
  },
  SHIPPING: {
    schema: LogbookConf.schema,
    defaultValues: LogbookConf.defaultValues,
    fields: _.concat(
      LogbookConf.fields.slice(0, 2),
      {
        id: 'id_department',
        name: 'id_department',
        type: 'async_autocomplete',
        label: 'Departments',
        api: departments.all,
        disabled: true,
        required: true,
        xs: 12,
      },
      LogbookConf.fields.slice(3, 12),
      {
        id: 'status_logbook',
        name: 'status_logbook',
        type: 'select-custom',
        label: 'Status',
        required: true,
        options: StatusLogbookDeliveries,
        xs: 12,
        sm: 6,
      },
      LogbookConf.fields.slice(12, 14)
    ),
  },
};

const DefaultConf = {
  DEFAULT: {
    schema: LogbookConf.schema,
    defaultValues: LogbookConf.defaultValues,
    fields: _.concat(
      LogbookConf.fields.slice(0, 12),
      {
        id: 'status_logbook',
        name: 'status_logbook',
        type: 'select-custom',
        label: 'Status',
        required: true,
        options: StatusLogbookDefault,
        xs: 12,
        sm: 6,
      },
      LogbookConf.fields.slice(12, 14)
    ),
  },
  DELIVERIES: {
    schema: LogbookConf.schema,
    defaultValues: LogbookConf.defaultValues,
    fields: _.concat(
      LogbookConf.fields.slice(0, 12),
      {
        id: 'status_logbook',
        name: 'status_logbook',
        type: 'select-custom',
        label: 'Status',
        required: true,
        options: StatusLogbookDeliveries,
        xs: 12,
        sm: 6,
      },
      LogbookConf.fields.slice(12)
    ),
  },
  SHIPPING: {
    schema: LogbookConf.schema,
    defaultValues: LogbookConf.defaultValues,
    fields: _.concat(
      LogbookConf.fields.slice(0, 12),
      {
        id: 'status_logbook',
        name: 'status_logbook',
        type: 'select-custom',
        label: 'Status',
        required: true,
        options: StatusLogbookDeliveries,
        xs: 12,
        sm: 6,
      },
      LogbookConf.fields.slice(12, 14)
    ),
  },

};
export const UpdateConf = {
  default: DefaultConf,
  editNoDep: EditNoDep,
};
