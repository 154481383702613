import * as yup from 'yup';
import { API as selectCourier } from '../../api';

export const ShippingSentConf = {
  schema: {
    receiver: yup.string().nullable(),
    country: yup.string().nullable(),
    UE: yup.boolean().nullable(),
    tracking_number: yup.string().nullable(),
    customer_price: yup.string().nullable(),
    is_pre_alert: yup.boolean().nullable(),
    id_courier: yup.object().when('tracking_number', {
      is: (tracking_number) =>
        tracking_number === null || tracking_number === '',
      then: yup.object().nullable(),
      otherwise: yup
        .object()
        .nullable()
        .required('Courier is required when Tracking Number is provided'),
    }),
  },
  defaultValues: {
    receiver: '',
    country: '',
    UE: false,
    tracking_number: '',
    customer_price: '',
    is_pre_alert: false,
    id_courier: null,
  },
  fields: [
    {
      id: 'id_courier',
      name: 'id_courier',
      type: 'async_autocomplete',
      label: 'Courier',
      api: selectCourier.couriers,
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'tracking_number',
      name: 'tracking_number',
      type: 'text',
      label: 'Tracking Number',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'receiver',
      name: 'receiver',
      type: 'text',
      label: 'Receiver',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'country',
      name: 'country',
      type: 'text',
      label: 'Country',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'details',
      name: 'details',
      type: 'text',
      label: 'Details',
      required: false,
      xs: 12,
    },
    {
      id: 'is_pre_alert',
      name: 'is_pre_alert',
      label: 'Pre Alert',
      type: 'switch',
      required: false,
      xs: 12,
    },
    {
      id: 'UE',
      name: 'UE',
      type: 'switch',
      label: 'UE',
      required: false,
      xs: 12,
    },

  ],
};
