import React, { useEffect } from 'react';
import { Container, Stack, Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Loading from '../../components/common/Loading.component';
import DetailView from '../../views/Detail.view';

const DetailMePage = ({ module: { detail, query } }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { jwt } = useSelector((state) => state.login);
  const { loading, data } = useSelector(detail.selector.data);

  useEffect(() => {
    dispatch(
      query.detail.action({
        settings: query.detail.settings,
        token: jwt,
        params: { id },
        query: { populate: query.detail.populate },
      })
    );

    return () => {
      dispatch(
        query.detail.action({
          settings: null,
          token: null,
          params: null,
          query: null,
        })
      );
    };
    // eslint-disable-next-line
  }, [id]);

  return (
    <Container disableGutters={true} maxWidth={false} sx={{ height: '100%' }}>
      <Stack spacing={2} justifyContent='center' alignItems='center' width={1}>
        {loading && <Loading />}
        <Paper variant='outlined' sx={{ padding: 2, width: '100%' }}>
          <DetailView
            data={data}
            selector={detail.selector.data}
            conf={detail.conf}
          />
        </Paper>
      </Stack>
    </Container>
  );
};

export default DetailMePage;
