import moment from 'moment';
import Text from '../../../components/detail/components/Text.component';
import { Link } from 'react-router-dom';

export const DetailConf = {
	initialState: {
		loading: false,
		error: null,
		data: null,
		meta: null,
	},
	fields: [
		{
			id: 'user_type',
			type: 'section',
			label: 'User Type',
			xs: 12,
			items: [{ type: 'text', label: 'Type', key: 'role.name', xs: 12, sm: 6 }],
		},
		{
			id: 'contact_section',
			type: 'section',
			label: 'Contact',
			xs: 12,
			items: [
				{ type: 'text', label: 'Name', key: 'name', xs: 12, sm: 6 },
				{ type: 'text', label: 'Surname', key: 'surname', xs: 12, sm: 6 },
				{ type: 'text', label: 'Email', key: 'email', xs: 12, sm: 6 },
				{ type: 'text', label: 'Username', key: 'username', xs: 12, sm: 6 },
				{ type: 'text', label: 'Birthdate', key: 'id_users_crew.birthdate', xs: 12, sm: 6 },
				{
					type: 'text',
					label: 'Phone',
					key: 'id_users_crew.phone_number',
					xs: 12,
					sm: 6,
				},
				{
					type: 'boolean',
					label: 'Confirmed',
					key: 'confirmed',
					xs: 12,
					sm: 6,
				},
			],
		},

		{
			id: 'yacht_section',
			type: 'section',
			label: 'Yacht',
			xs: 12,
			items: [
				{
					type: 'text',
					label: 'Name',
					key: 'id_users_crew.id_yacht',
					xs: 12,
					sm: 6,
				},
				{
					type: 'text',
					label: 'Role',
					key: 'id_users_crew.crew_responsability',
					xs: 12,
					sm: 6,
				},
				{
					type: 'text',
					label: 'Manager on board',
					key: 'id_users_crew.is_delivery_manager_on_board',
					xs: 12,
					sm: 6,
				},
			],
		},
	],
	formatValue: (key, value) => {
		switch (key) {
			case 'id_users_crew.id_yacht':
				return (
					<Text
						label='Name'
						value={
							<Link to={`/yachts/${value?.id}`} style={{ color: 'blue' }}>
								{value.yacht_name}
							</Link>
						}
					/>
				);
			case 'id_users_crew.birthdate':
				return <Text label='Birthdate' value={moment(value).format('DD-MM-YYYY')} />;
			default:
				return null;
		}
	},
};
