import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Box, Container, Grid, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Loading from '../../../components/common/Loading.component';
import ItemAdd from '../../../components/listItem/create';
import Back from '../../../components/common/Back.component';
import { fetchAPI } from '../../../api/request';
import Alert from '../../../components/common/Alert.component';
import { API as listAPI } from '../../../module/list/api';
import _ from 'lodash';
import { API as listItemsProvisionsAPI } from '../../../module/listItemsProvisions/api';
import itemsProvisions from '../../../module/listItemsProvisions';
import PaperTypography from '../../../components/common/PaperTypography.component';
import ProductsList from './components/ProductsList.component';
import LogbookList from './components/LogbookList.component';
import OrderInfo from '../../../components/order/OrderInfo.component';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import dayjs from 'dayjs';
import InfoIcon from '@mui/icons-material/Info';
import EditList from '../../../components/list/edit/EditList.component';
import ErrorIcon from '@mui/icons-material/Error';

// const STATUS_LIST_ENABLE = 'IN PROGRESS';
const AvatarStyle = {
  avatar: {
    sx: {
      color: '#005488',
      opacity: 0.9,
    },
  },
};

const ItemsProvisionsListPage = ({ module: { query, detail, code } }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, id_operation } = useParams();
  const { jwt } = useSelector((state) => state.login);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  // const [listStatus, setListStatus] = useState(null);
  const { state } = useLocation();
  const [expanded, setExpanded] = useState({ products: true, logbook: true });
  const { loading: loadingAPI, data } = useSelector(detail.selector.data);
  const [checked, setChecked] = useState(null);
  const [list, setList] = useState(null);

  const onSelectItem = ({ id, status }) => {
    setChecked({
      ...checked,
      [id]: status,
    });
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded({ ...expanded, [panel]: isExpanded });
  };

  const onError = useCallback((error) => {
    setSuccess(false);
    setLoading(false);
    setError(error);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchAPI(listAPI.item, { id }, '', null, jwt)
      .then((resp) => {
        // setListStatus(resp?.data?.attributes?.list_item_status);
        setList(resp?.data?.attributes);
      })
      .catch((error) => onError(error));
    // eslint-disable-next-line
  }, [id, success]);

  useEffect(() => {
    if (
      (error &&
        (error?.response?.status === 404 || error?.response?.status === 500)) ||
      state?.id_operation !== id_operation
    )
      navigate('/404');
  }, [error, navigate, state, id_operation]);

  useEffect(() => {
    dispatch(
      query.detail.action({
        settings: query.detail.settings, //ITEMS LIST
        token: jwt,
        params: { id },
        query: { populate: query.detail.populate },
        query2: { populate: '*' },
        settings2: listItemsProvisionsAPI.listItemsWithLogbook, //ITEMS IN THE LOGBOOK
      })
    );
    return () => {
      dispatch(
        query.detail.action({
          settings: null,
          token: null,
          params: null,
          query: null,
        })
      );
    };
    // eslint-disable-next-line
  }, [id, success]);

  return (
    <Fragment>
      <Container disableGutters={true} maxWidth={false} sx={{ height: '100%' }}>
        {error && <Alert severity='error'>{'An error has occurred'}</Alert>}
        {success && <Alert severity='success'>{'Success'}</Alert>}

        <Back title='Back' page='Items List' />
        {list && (
          <Grid container pt={3} spacing={2.5} alignItems={'center'}>
            <Grid item xs={12} md={list?.warning_notes ? 8 : 12}>
              <OrderInfo
                isLoading={loading || loadingAPI}
                data={[
                  {
                    id: 'yacht_name',
                    value:
                      list?.id_operation_list?.data?.attributes?.id_yacht?.data
                        ?.attributes?.yacht_name,
                    icon: (
                      <DirectionsBoatIcon style={{ width: 16, height: 16 }} />
                    ),
                    sxTypography: {
                      fontSize: 17,
                    },
                    ...AvatarStyle,
                  },
                  {
                    id: 'date',
                    value:
                      list?.id_operation_list?.data?.attributes?.requested_date,
                    icon: (
                      <CalendarMonthIcon style={{ width: 16, height: 16 }} />
                    ),
                    ...AvatarStyle,
                  },
                  {
                    id: 'hour',
                    value:
                      list?.id_operation_list?.data?.attributes
                        ?.requested_hour ||
                      list?.id_operation_list?.data?.attributes?.hour_type
                        ? `${
                            list?.id_operation_list?.data?.attributes
                              ?.requested_hour
                              ? `   ${dayjs(
                                  list?.id_operation_list?.data?.attributes
                                    ?.requested_hour,
                                  'HH:mm:ss'
                                ).format('HH:mm')}`
                              : ''
                          } ${
                            list?.id_operation_list?.data?.attributes?.hour_type
                              ? list?.id_operation_list?.data?.attributes
                                  ?.hour_type
                              : ''
                          }`
                        : null,
                    icon: (
                      <QueryBuilderIcon style={{ width: 16, height: 16 }} />
                    ),
                    ...AvatarStyle,
                  },
                  {
                    id: 'location',
                    value:
                      list?.id_operation_list?.data?.attributes?.id_marina?.data
                        ?.attributes?.marina,
                    icon: <LocationOnIcon style={{ width: 16, height: 16 }} />,
                    ...AvatarStyle,
                  },
                  {
                    id: 'status_operation_booking',
                    value:
                      list?.id_operation_list?.data?.attributes
                        ?.status_operation_booking,
                    icon: <InfoIcon style={{ width: 16, height: 16 }} />,
                    ...AvatarStyle,
                  },
                ]}
                height={100}
                backgroundColor={'#005488'}
              />
            </Grid>
            {list?.warning_notes && (
              <Grid item xs={12} md={4}>
                <OrderInfo
                  isLoading={loading || loadingAPI}
                  data={[
                    {
                      id: 'warning_notes',
                      value: list?.warning_notes,
                      sxTypography: {
                        fontSize: 15,
                      },
                    },
                  ]}
                  icon={ErrorIcon}
                  height={100}
                  backgroundColor={'rgb(216, 67, 21)'}
                />
              </Grid>
            )}
          </Grid>
        )}

        <EditList
          item={list}
          department={code}
          setSuccessEdit={setSuccess}
          successEdit={success}
        />

        <Box sx={{ mb: 1 }}>
          <ItemAdd
            setSuccessAdd={setSuccess}
            module={{
              ...itemsProvisions,
              create: { conf: itemsProvisions.create.conf.default },
            }}
          />
        </Box>
        {loading || loadingAPI ? (
          <Loading />
        ) : (
          _.isEmpty(data?.query) &&
          _.isEmpty(data?.query2) && (
            <PaperTypography title={'Add Items to list'} />
          )
        )}
        <Stack spacing={6} mb={6}>
          {!_.isEmpty(data?.query) && (
            <ProductsList
              data={data?.query}
              expanded={expanded['products']}
              onChange={handleChange('products')}
              setSuccess={setSuccess}
              checked={checked}
              disabledButtonLogbook={
                // listStatus !== STATUS_LIST_ENABLE ||
                _.isEmpty(_.keys(_.pickBy(checked, _.identity)))
              }
              productsList={_.keys(_.pickBy(checked, _.identity))}
              setChecked={setChecked}
              onSelectItem={onSelectItem}
            />
          )}
          {!_.isEmpty(data?.query2) && (
            <LogbookList
              data={data?.query2}
              setSuccess={setSuccess}
              expanded={expanded['logbook']}
              onChange={handleChange('logbook')}
              orderById={
                'attributes.id_logbook.data.attributes.id_supplier.data.attributes.label'
              }
            />
          )}
        </Stack>
      </Container>
    </Fragment>
  );
};

export default ItemsProvisionsListPage;
