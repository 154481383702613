import dayjs from 'dayjs';
import _ from 'lodash';

export const API = {
  create: {
    path: '/users-crews',
    method: 'POST',
    get: null,
    auth: true,
    fallback: null,
    adapter: (body) => {
      return {
        data: {
          ...body,
          username: body?.username,
          email: body?.email,
          name: body?.name,
          surname: body?.surname,
          phone_number: body?.phone_number,
          birthdate: body?.birthdate
            ? dayjs(body?.birthdate).format('YYYY-MM-DD')
            : null,
          crew_responsability: body?.crew_responsability?.label,
        },
      };
    },
  },

  delete: {
    path: '/users-crews/{{id}}',
    method: 'DELETE',
    get: null,
    auth: true,
    fallback: null,
  },

  update: {
    path: '/users-crews/{{id}}',
    method: 'PUT',
    get: null,
    auth: true,
    fallback: null,
    adapter: (body) => {
      let obj = body;
      if (!_.isEmpty(body?.country_code)) {
        obj = {
          ...body,
          phone_number: `${body?.country_code?.phone}-${body?.phone_number}`,
        };
      }
      delete obj.country_code;

      return {
        data: {
          birthdate: obj?.birthdate
            ? dayjs(obj?.birthdate).format('YYYY-MM-DD')
            : null,
          username: obj?.username,
          password: obj?.password,
          email: obj?.email,
          surname: obj?.surname,
          name: obj?.surname,
          id_yacht: obj?.id_yacht,
          crew_responsability: obj?.crew_responsability,
          phone_number: obj?.phone_number,
        },
      };
    },
  },
};
