export const StatusOperationBC = [
  {
    label: 'None',
    id: 0,
  },
  {
    label: 'Open'.toUpperCase(),
    id: 1,
    color: 'success',
    hex: '#2e7d32',
  },
  {
    label: 'Closed'.toUpperCase(),
    id: 2,
    color: 'error',
    hex: '#d32f2f',
  },
];

export const StatusOperationBF = [
  {
    label: 'None',
    id: 0,
  },
  {
    label: 'Open'.toUpperCase(),
    id: 1,
    color: 'success',
    hex: '#2e7d32',
  },
  {
    label: 'Closed'.toUpperCase(),
    id: 2,
    color: 'error',
    hex: '#d32f2f',
  },
];

export const AccountType = [
  {
    id: 'None',
    label: 'None',
  },
  {
    id: 'CHARTER',
    label: 'CHARTER',
  },
  {
    id: 'BOAT',
    label: 'BOAT',
  },
];

export const StatusOperationType = [
  {
    id: 'None',
    label: 'None',
  },
  {
    id: 'OPEN',
    label: 'OPEN',
  },
  {
    id: 'CLOSED',
    label: 'CLOSED',
  },
];

export const StatusOperationBookingType = [
  {
    id: 'NEW REQUEST',
    label: 'NEW REQUEST',
    color: '#fff',
    backgroundColor: '#005488',
  },
  {
    id: 'STAND-BY',
    label: 'STAND-BY',
    color: '#fff',
    backgroundColor: '#ff6500',
  },
  {
    id: 'IN PROGRESS',
    label: 'IN PROGRESS',
    color: '#fff',
    backgroundColor: '#f6ca00',
  },
  {
    id: 'CONFIRMED',
    label: 'CONFIRMED',
    color: '#fff',
    backgroundColor: '#00c256',
  },
  {
    id: 'COMPLETED',
    label: 'COMPLETED',
    color: '#fff',
    backgroundColor: '#008c40',
  },
  {
    id: 'DECLINED',
    label: 'DECLINED',
    color: '#fff',
    backgroundColor: '#ff001b',
  },
  {
    id: 'CANCELLED',
    label: 'CANCELLED',
    color: '#fff',
    backgroundColor: '#ff001b',
  },
];

export const DEFAULT_STATUS_OPERATION_BOOKING = [
  {
    label: 'NEW REQUEST',
    id: 'NEW REQUEST',
  },
  {
    label: 'STAND-BY',
    id: 'STAND-BY',
  },
  {
    id: 'IN PROGRESS',
    label: 'IN PROGRESS',
  },
  {
    id: 'CONFIRMED',
    label: 'CONFIRMED',
  },

];

export const DataConf = {
  initialState: {
    loading: false,
    error: null,
    data: [],
    meta: null,
  },
};

export const NewStatus = [
  {
    id: 'NEW REQUEST',
    label: 'NEW REQUEST',
    color: '#fff',
    backgroundColor: '#005488',
  },
  {
    id: 'STAND-BY',
    label: 'STAND-BY',
    color: '#fff',
    backgroundColor: '#ff6500',
  },
  {
    id: 'IN PROGRESS',
    label: 'IN PROGRESS',
    color: '#fff',
    backgroundColor: '#f6ca00',
  },
  {
    id: 'CONFIRMED',
    label: 'CONFIRMED',
    color: '#fff',
    backgroundColor: '#00c256',
  },
  {
    id: 'COMPLETED',
    label: 'COMPLETED',
    color: '#fff',
    backgroundColor: '#008c40',
  },
  {
    id: 'DECLINED',
    label: 'DECLINED',
    color: '#fff',
    backgroundColor: '#ff001b',
  },
  {
    id: 'CANCELLED',
    label: 'CANCELLED',
    color: '#fff',
    backgroundColor: '#ff001b',
  },
];

export const DefaultStatusOpBooking = StatusOperationBookingType?.filter(
  (item) =>
    item?.id === 'NEW REQUEST' ||
    item?.id === 'STAND-BY' ||
    item?.id === 'IN PROGRESS' ||
    item?.id === 'CONFIRMED'
);

export const ContactType = [
  {
    id: 'EMAIL',
    label: 'EMAIL',
  },
  {
    id: 'WHATSAPP',
    label: 'WHATSAPP',
  },
  {
    id: 'SHOP',
    label: 'SHOP',
  },
];
