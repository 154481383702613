import { dataSlice as createDataSLice } from '../../../core/data';
import { sliceList as createListSlice } from '../../../core/list';
import { sliceFilter as createFilterSlice } from '../../../core/filter';
import { sliceSearch as createSearchSlice } from '../../../core/search';
import { detailSlice as createDetailSlice } from '../../../core/detail';
import { combineReducers, createAction, createSelector } from '@reduxjs/toolkit';

import { ListConf } from '../conf/List.conf';
import { SearchConf } from '../conf/Search.conf';
import { FilterConf } from '../conf/Filter.conf';
import { DataConf, UsersType } from '../conf/Data.conf';
import { QueryConf } from '../conf/Query.conf';
import { DetailConf } from '../conf/Detail.conf';

import _ from 'lodash';

export const tag = 'contacts';

// REDUCERS
const dataSlice = createDataSLice(`${tag}/data`, DataConf.initialState);
const listSlice = createListSlice(`${tag}/list`, ListConf.initialState);
const filterSlice = createFilterSlice(`${tag}/filter`, FilterConf.initialState);
const searchSlice = createSearchSlice(`${tag}/search`, SearchConf.initialState);
const detailSlice = createDetailSlice(`${tag}/detail`, DetailConf.initialState);

export const reducer = combineReducers({
	search: searchSlice.reducer,
	data: dataSlice.reducer,
	list: listSlice.reducer,
	filter: filterSlice.reducer,
	detail: detailSlice.reducer,
});

// ACTIONS
export const actions = {
	fetchAllAction: createAction(`${tag}/fetchAll`),
	fetchDetailAction: createAction(`${tag}/fetchOne`),
	...searchSlice.actions,
	...dataSlice.actions,
	...listSlice.actions,
	...filterSlice.actions,
	...detailSlice.actions,
};

// QUERY
export const querySelector = createSelector(
	(state) => state[tag].list,
	(state) => state[tag].filter,
	(state) => state[tag].search,
	(list, filter, search) => QueryConf.get(list, filter, search)
);

// LIST
export const listSelector = createSelector(
	(state) => state[tag].data,
	(state) => state[tag].list,
	({ data, meta }, { pagination, sort }) => ({
		data,
		meta,
		pagination,
		sort,
	})
);

// DETAIL
export const detailSelector = createSelector(
	(state) => state[tag].detail,
	({ data, loading, error }) => {
		const user = data?.id_users_crew ? data?.id_users_crew : data?.id_users_nautica;
		return {
			loading,
			error,
			data: {
				...data,
				user: user,
			},
		};
	}
);

// EDIT
export const editSelector = createSelector(
	(state) => state[tag].detail,
	({ data, loading, error }) => {
		const user = data ? (data?.id_users_crew ? data?.id_users_crew : data?.id_users_nautica) : null;
		const user_nautica_role = _.get(data, 'id_users_role');
		const site = _.get(data, 'id_users_nautica.id_site');
		const department = _.get(data, 'id_users_nautica.id_users_nautica_departments[0].id_department');
		const user_crew_responsability = _.get(data, 'id_users_crew');
		const id_users_nautica = _.get(data, 'id_users_nautica');
		const id_users_crew = _.get(data, 'id_users_crew');
		return {
			loading,
			error,
			data: data
				? {
					id: data?.id_users_crew
						? data?.id_users_crew?.id
						: data?.id_users_nautica
							? data?.id_users_nautica?.id
							: null,

					email: data?.email,
					username: data?.username,
					name: data?.name,
					surname: data?.surname,
					blocked: data?.blocked,
					phone_number: user?.phone_number,
					extension_number: user?.extension_number,
					subrole: data?.id_users_nautica?.id_users_nautica_departments[0]?.subrole,
					id_users_role: user_nautica_role ? { name: user_nautica_role?.role, id: user_nautica_role?.id } : null,
					id_site: site ? { name: site?.description_site, id: site?.id } : null,
					id_department: department ? { name: department?.name_department, id: department?.id } : null,
					crew_responsability: user_crew_responsability
						? { label: user_crew_responsability?.crew_responsability, id: user_crew_responsability?.id }
						: null,
					userType: _.find(UsersType, (i) => i.id === _.get(data, 'role.name')),
					id_yacht: data?.id_yacht && {
						id: data?.id_yacht?.id,
						name: data?.id_yacht?.yacht_name,
					},
					id_users_nautica: id_users_nautica,
					id_users_crew: id_users_crew,
					birthdate: _.get(data, 'id_users_crew.birthdate'),
				}
				: null,
		};
	}
);
