export const Status = [
  {
    label: 'New Request'.toUpperCase(),
    id: 1,
    hex: '#005488',
    textHex: '#fff',
  },
  {
    label: 'Booked directly'.toUpperCase(),
    id: 2,
    hex: '#005488',
    textHex: '#fff',
  },
  {
    label: 'At anchor'.toUpperCase(),
    id: 3,
    hex: '#005488',
    textHex: '#fff',
  },

  {
    label: 'Stand-by'.toUpperCase(),
    id: 4,
    hex: '#ff6500',
    textHex: '#fff',
  },
  {
    label: 'In progress'.toUpperCase(),
    id: 5,
    hex: '#f6ca00',
    textHex: '#fff',
  },
  {
    label: 'Declined'.toUpperCase(),
    id: 6,
    hex: '#ff001b',
    textHex: '#fff',
  },
  {
    label: 'Cancelled'.toUpperCase(),
    id: 7,
    hex: '#ff001b',
    textHex: '#fff',
  },
  {
    label: 'Confirmed'.toUpperCase(),
    id: 8,
    hex: '#00c256',
    textHex: '#fff',
  },
  {
    label: 'Completed'.toUpperCase(),
    id: 8,
    hex: '#008c40',
    textHex: '#fff',
  },
];

export const StatusDefault = [
  {
    label: 'Booked directly'.toUpperCase(),
    id: 1,
    hex: '#005488',
    textHex: '#fff',
  },
  {
    label: 'At anchor'.toUpperCase(),
    id: 2,
    hex: '#005488',
    textHex: '#fff',
  },
];

export const StatusBooking = [
  {
    id: 'NEW REQUEST',
    label: 'NEW REQUEST',
    color: '#fff',
    backgroundColor: '#005488',
  },
  {
    id: 'BOOKED DIRECTLY',
    label: 'BOOKED DIRECTLY',
    color: '#fff',
    backgroundColor: '#005488',
  },
  {
    id: 'AT ANCHOR',
    label: 'AT ANCHOR',
    color: '#fff',
    backgroundColor: '#005488',
  },
  {
    id: 'STAND-BY',
    label: 'STAND-BY',
    color: '#fff',
    backgroundColor: '#ff6500',
  },
  {
    id: 'IN PROGRESS',
    label: 'IN PROGRESS',
    color: '#fff',
    backgroundColor: '#f6ca00',
  },
  {
    id: 'CONFIRMED',
    label: 'CONFIRMED',
    color: '#fff',
    backgroundColor: '#00c256',
  },
  {
    id: 'COMPLETED',
    label: 'COMPLETED',
    color: '#fff',
    backgroundColor: '#008c40',
  },
  {
    id: 'DECLINED',
    label: 'DECLINED',
    color: '#fff',
    backgroundColor: '#ff001b',
  },
  {
    id: 'CANCELLED',
    label: 'CANCELLED',
    color: '#fff',
    backgroundColor: '#ff001b',
  },
];

export const DefaultStatusBooking = StatusBooking?.filter(
  (item) =>
    item?.id === 'NEW REQUEST' ||
    item?.id === 'IN PROGRESS' ||
    item?.id === 'CONFIRMED' ||
    item?.id === 'AT ANCHOR' ||
    item?.id === 'BOOKED DIRECTLY'
);

export const DataConf = {
  initialState: {
    loading: false,
    error: null,
    data: [],
    meta: null,
  },
};
