import { yearSlice as createYearSlice } from '../../../core/year'
import {  createSelector } from '@reduxjs/toolkit'
import { FilterConf } from '../conf/Filter.conf'
export const tag = 'year'

// REDUCERS
const yearSlice = createYearSlice(`${tag}/year`, FilterConf.initialState.value)

export const reducer = yearSlice.reducer


// ACTIONS
export const actions = {
    ...yearSlice.actions,
}

// USER
export const yearSelector = createSelector(
    (state) => state[tag].year,
    (data) => data
)