import React, { useCallback } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const options = [
  {
    label: 'Month',
    value: 'month',
  },
  {
    label: 'Week',
    value: 'week',
  },
  {
    label: 'Day',
    value: 'day',
  },
];

const ViewsComponent = ({ handleChangeView = () => {}, value = 'month' }) => {
  const onChange = useCallback(
    (_, newValue) => {
      if (newValue) {
        handleChangeView(newValue);
      }
    },
    [handleChangeView]
  );

  return (
    <ToggleButtonGroup
      color='secondary'
      exclusive
      value={value}
      onChange={onChange}
    >
      {options.map(({ value, label }) => (
        <ToggleButton key={value} value={value}>
          {label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default ViewsComponent;
