import PropTypes from 'prop-types';

export const DynamicFieldData = PropTypes.shape({
  id: PropTypes.string,
  type: PropTypes.oneOf([
    'divider',
    'section',
    'text',
    'booking_status',
    'download',
    'documents',
    'link',
    'boolean'
  ]).isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  xs: PropTypes.number,
  sm: PropTypes.number,
});
