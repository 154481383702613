import * as yup from 'yup';
import { API as yachts } from '../../yachts/api';
import { API as marina } from '../../marina/api';
import { DefaultStatusBooking, StatusBooking } from './Data.conf';
import { API as users_nautica } from '../../users_nautica/api';
import dayjs from 'dayjs';

export const FilterConf = {
  schema: yup.object({
    id_yacht: yup.object().nullable(),
    id_agent_NA: yup.array().nullable(),
    start_date: yup
      .date()
      .nullable()
      .transform((value) => {
        if (value && dayjs(value).isValid()) {
          const formattedDate = dayjs(value).format('YYYY-MM-DD');
          return new Date(formattedDate);
        }
        return null;
      }),
    end_date: yup
      .date()
      .nullable()
      .when('start_date', (start_date, schema) =>
        start_date
          ? schema.min(start_date, "End date can't be before Start date")
          : schema
      )
      .transform((value) => {
        if (value && dayjs(value).isValid()) {
          const formattedDate = dayjs(value).format('YYYY-MM-DD');
          return new Date(formattedDate);
        }
        return null;
      }),
    booking_status: yup.array().nullable(),
    id_marina: yup.object().nullable(),
  }),
  initialState: {
    value: {
      id_yacht: null,
      id_agent_NA: [],
      start_date: dayjs().format('YYYY-MM-DD').toString(),
      end_date: dayjs().endOf('year').format('YYYY-MM-DD').toString(),
      booking_status: DefaultStatusBooking,
      id_marina: null,
    },
  },
  fields: [
    {
      id: 'id_yacht',
      name: 'id_yacht',
      type: 'async_autocomplete',
      label: 'Yacht',
      api: yachts.selectWithAttendances,
      required: false,
      xs: 12,
      sm: 6,
      md: 3,
    },
    {
      id: 'id_agent_NA',
      name: 'id_agent_NA',
      type: 'async_autocomplete_multiple',
      label: 'NA Agency',
      api: users_nautica.selectName,
      required: false,
      xs: 12,
      sm: 6,
      md: 3,
    },
    {
      id: 'booking_status',
      name: 'booking_status',
      type: 'autocomplete_multiple',
      label: 'Status Operation Booking',
      options: StatusBooking,
      required: false,
      xs: 12,
      sm: 6,
      md: 3,
    },
    {
      id: 'id_marina',
      name: 'id_marina',
      type: 'async_autocomplete',
      label: 'Location',
      api: marina.select,
      required: false,
      xs: 12,
      sm: 6,
      md: 3,
    },
    {
      id: 'start_date',
      name: 'start_date',
      type: 'date',
      label: 'Start Date',
      required: false,
      xs: 12,
      sm: 6,
      md: 3,
    },
    {
      id: 'end_date',
      name: 'end_date',
      type: 'date',
      label: 'End Date',
      required: false,
      xs: 12,
      sm: 6,
      md: 3,
    },
  ],
  query: {
    keys: [
      {
        key: 'id_yacht.id',
        value: 'id_yacht.id',
        operator: '$eq',
      },
      {
        key: 'id_yacht.id_attendance.id_agent_NA.id',
        value: 'id_agent_NA.id',
        operator: '$eq',
      },
      {
        key: 'booking_status',
        value: 'booking_status.label',
        operator: '$eq',
      },
      {
        key: 'id_marina.id',
        value: 'id_marina.id',
        operator: '$eq',
      },
      {
        key: 'check_in_date',
        value: 'end_date',
        operator: '$lte',
      },
      {
        key: 'check_out_date',
        value: 'start_date',
        operator: '$gte',
      },
    ],
  },
};

/* export const FilterCalendarConf = {
  ...FilterConf,
  initialState: {
    ...FilterConf.initialState,
    value: {
      ...FilterConf.initialState.value,
      booking_status: DefaultStatusBooking,
    },
  },
}; */
