export const QuantityType = [
  { label: 'Kg', id: 'Kg' },
  { label: 'g', id: 'g' },
  { label: 'L', id: 'L' },
  { label: 'units', id: 'units' },
  { label: 'pieces', id: 'pieces' },
  { label: 'tubs', id: 'tubs' },
  { label: 'balls', id: 'balls' },
  { label: 'packets', id: 'packets' },
  { label: 'boxes', id: 'boxes' },
  { label: 'punnets', id: 'punnets' },
  { label: 'bunches', id: 'bunches' },
  { label: 'heads', id: 'heads' },
  { label: 'tins', id: 'tins' },
  { label: 'bottles', id: 'bottles' },
  { label: 'jars', id: 'jars' },
  { label: 'leaves', id: 'leaves' },
];

export const CategoryType = [
  { id: 'DRY', label: 'DRY' },
  { id: 'FRESH', label: 'FRESH' },
  { id: 'FROZEN', label: 'FROZEN' },
];

export const ListItemStatus = [
  'TO DO',
  'IN PROGRESS',
  'STAND-BY',
  'COMPLETED',
  'CONFIRMED',
];
export const DataConf = {
  initialState: {
    loading: false,
    error: null,
    data: [],
    meta: null,
  },
};

export const HeadProductsInLogbook = [
  {
    id: 'PRODUCT',
    label: 'PRODUCT',
    sortable: true,
    key: 'attributes.id_item.data.attributes.name_ITA',
  },
  {
    id: 'QUANTITY',
    label: 'QUANTITY',
    key: 'attributes.quantity',
    sortable: true,
  },

  {
    id: 'AVAILABLE',
    label: 'AVAILABLE',
    key: 'attributes.available',
    sortable: true,
  },
  {
    id: 'STATUS',
    label: 'STATUS',
    key: 'attributes.id_logbook.data.attributes.status_logbook',
    sortable: true,
  },
];
export const columns = [
  {
    width: 720,
    label: 'PRODUCT',
    dataKey: 'name',
    key: 'attributes.id_item.data.attributes.name_ITA',
    sortable: true,
  },
  {
    width: 240,
    label: 'Photo',
    dataKey: 'photo',
    key: 'attributes.photo',
    sortable: false,
  },
  {
    width: 180,
    label: 'Quantity',
    dataKey: 'quantity',
    key: 'attributes.quantity',
    sortable: true,
  },
  {
    width: 200,
    label: 'Available',
    dataKey: 'available',
    key: 'attributes.available',
    sortable: true,
  },
  {
    width: 140,
    label: '',
    dataKey: 'crud',
    sortable: false,
  },
];
