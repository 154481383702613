import MuiTypography from '@mui/material/Typography';
import { Stack, Box } from '@mui/system';
import dayjs from 'dayjs';
import CalendarIcon from '@mui/icons-material/CalendarMonth';
import PersonIcon from '@mui/icons-material/Person';
import _ from 'lodash';
import { Status } from '../../booking/conf/Data.conf';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import TableCellEdit from '../../../components/table/components/TableCellEdit.component';
import SwitchForm from '../../../components/operations/SwitchForm.component';
import operations from '../index';
import { API } from '../api';
import RectangleChip from '../../../components/common/RectangleChip.component';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import { ShippingOptions } from './Data.conf';

export const ListConf = {
    initialState: {
        pagination: {
            page: 1,
            pageSize: 100,
        },
        sort: {
            by: 'operation_date',
            direction: 'desc',
        },
    },
    rowsPerPageOptions: [25, 50, 100],
    columns: [
        {
            id: 'attributes.status_operation_booking',
            key: 'status_operation_booking',
            sort_key: 'status_operation_booking',
            sortable: true,
            label: 'Status',
            width: 150,
        },
        {
            id: 'attributes.id_yacht.data.attributes.yacht_name',
            key: 'yacht_name',
            sort_key: 'id_yacht.yacht_name',
            sortable: true,
            label: 'Yacht Name',
            width: '200px',
        },
        {
            id: 'attributes.id_shipping.data.attributes.id_shipping_type.data.attributes.shipping_type',
            key: 'id_shipping_type',
            sort_key: 'id_shipping.id_shipping_type.shipping_type',
            sortable: true,
            label: 'Shipping Type',
            width: '150px',
        },
        {
            id: 'attributes.id_shipping.data.attributes.id_courier.data.attributes.name',
            key: 'id_courier',
            sort_key: 'id_shipping.id_courier.name',
            sortable: true,
            label: 'Courier',
            width: '150px',
        },
        {
            id: 'attributes.id_shipping.data.attributes.tracking_number',
            key: 'tracking_number',
            sort_key: 'id_shipping.tracking_number',
            sortable: true,
            label: 'Tracking Number',
            width: '150px',
        },
        {
            id: 'attributes.requested_date',
            key: 'requested_date',
            sort_key: 'requested_date',
            sortable: true,
            label: 'Date In',
            width: 180,
        },
        {
            id: 'attributes.check_out_date',
            key: 'check_out_date',
            sort_key: 'check_out_date',
            sortable: true,
            label: 'Date Out',
            width: 180,
        },
        {
            id: 'attributes.id_user_in_charge.data.attributes.id_users_permissions_user.data.attributes',
            key: 'id_user_in_charge',
            sort_key: 'id_user_in_charge.id_users_permissions_user.name',
            sortable: true,
            label: 'User in charge',
            width: 180,
        },
        {
            id: 'attributes.status_operation_BC',
            key: 'status_operation_BC',
            sort_key: 'status_operation_BC',
            sortable: true,
            label: 'BC',
            width: '50',
        },
        {
            id: 'attributes.status_operation_BF',
            key: 'status_operation_BF',
            sort_key: 'status_operation_BF',
            sortable: true,
            label: 'BF',
            width: 50,
        },
        {
            id: 'attributes.ft_id',
            key: 'ft_id',
            sort_key: 'ft',
            sortable: true,
            label: 'Invoiced',
            width: 50,
            checkbox: true,
        },
        {
            id: 'attributes.operation_date',
            key: 'operation_date',
            sort_key: 'operation_date',
            sortable: true,
            label: 'Request date',
            width: '120px',
        },
    ],
    render: {
        formatHead: (key, value) => {
            switch (key) {
                default:
                    return (
                        <MuiTypography
                            variant='body1'
                            sx={{ fontWeight: 'bold', color: 'white' }}
                        >
                            {value.toUpperCase()}
                        </MuiTypography>
                    );
            }
        },
        formatValue: (key, value, onClick) => {
            switch (key) {
                case 'status_operation_booking':
                    const color = _.find(
                        Status,
                        (item) => item.label.toUpperCase() === value.toUpperCase()
                    );
                    return (
                        <Box onClick={onClick}>
                            {value ? (
                                <RectangleChip
                                    value={value}
                                    backgroundColor={color?.hex}
                                    color={'#fff'}
                                />
                            ) : (
                                '-'
                            )}
                        </Box>
                    );

                case 'yacht_name': {
                    return (
                        <MuiTypography
                            variant='body1'
                            onClick={onClick}
                            sx={{ height: '100%', fontWeight: 'bold' }}
                        >
                            {value || '-'}
                        </MuiTypography>
                    );
                }
                case 'requested_date':
                    return (
                        <Stack direction={'row'} spacing={1} alignItems={'center'}>
                            <CalendarIcon color='action' />
                            <MuiTypography
                                sx={{ fontWeight: 'bold', height: 1 }}
                                variant='body1'
                                onClick={onClick}
                            >
                                {value ? dayjs(value).format('DD-MM-YYYY') : '-'}
                            </MuiTypography>
                        </Stack>
                    );
                case 'check_out_date':
                    return (
                        <Stack direction={'row'} spacing={1} alignItems={'center'}>
                            <CalendarIcon color='action' />
                            <MuiTypography
                                sx={{ fontWeight: 'bold', height: 1 }}
                                variant='body1'
                                onClick={onClick}
                            >
                                {value ? dayjs(value).format('DD-MM-YYYY') : '-'}
                            </MuiTypography>
                        </Stack>
                    );

                case 'operation_date': {
                    return (
                        <Stack direction={'row'} spacing={1} alignItems={'center'}>
                            <CalendarIcon color='action' />
                            <MuiTypography
                                sx={{ fontWeight: 'bold', height: 1 }}
                                variant='body1'
                                onClick={onClick}
                            >
                                {value ? dayjs(value).format('DD-MM-YYYY') : ''}
                            </MuiTypography>
                        </Stack>
                    );
                }
                case 'id_user_in_charge':
                case 'name': {
                    return value ? (
                        <Stack direction={'row'} spacing={1} alignItems={'center'}>
                            <PersonIcon color='action' fontSize='small' />
                            <MuiTypography
                                sx={{ height: 1 }}
                                variant='body1'
                                onClick={onClick}
                            >
                                {`${value?.name} ${value?.surname}`}
                            </MuiTypography>
                        </Stack>
                    ) : (
                        '-'
                    );
                }
                case 'status_operation_BC':
                case 'status_operation_BF': {
                    return value === 'OPEN' ? (
                        <CancelIcon color='error' />
                    ) : value === 'CLOSED' ? (
                        <CheckCircleIcon color='success' />
                    ) : <DoNotDisturbIcon color='disabled' />;
                }
                case 'ft_id':
                    return (
                        <TableCellEdit
                            module={{
                                ...operations,
                                query: {
                                    ...operations.query,
                                    edit: { settings: API.updateFromList },
                                },
                            }}
                            value={value}
                            name={'ft'}
                            defaultValues={{ ft: value?.ft }}
                            form={<SwitchForm value={value} name={'ft'} />}
                        >
                            {!!value?.ft ? (
                                <CheckCircleIcon color='success' />
                            ) : (
                                <CancelIcon color='error' />
                            )}
                        </TableCellEdit>
                    );
                case 'id_shipping_type':
                    const reservationColor = _.find(
                        ShippingOptions,
                        (item) => item?.id === value
                    )?.color;
                    return value ? (
                        <RectangleChip
                            value={value}
                            width={'auto'}
                            backgroundColor={reservationColor}
                            color='#fff'
                        />
                    ) : (
                        '-'
                    );

                default:
                    return (
                        <MuiTypography
                            variant='body1'
                            onClick={onClick}
                            sx={{ height: '100%' }}
                        >
                            {value || '-'}
                        </MuiTypography>
                    );
            }
        },
    },
};
