export const API = {
  technicalWorks: {
    path: '/operation-lists?filters[id_department][short_description][$eq]=TECH&populate[id_yacht][populate][id_attendance][populate][id_agent_NA][populate][id_users_permissions_user]=%2A&populate[id_department]=%2A&populate[id_marina]=%2A&populate[id_service]=%2A',
    method: 'GET',
    get: null,
    auth: true,
    fallback: null,
  },
  technicalWork: {
    path: '/technical-works/{{id}}',
    method: 'GET',
    get: null,
    auth: true,
    fallback: null,
  },
  create: {
    path: '/technical-works',
    method: 'POST',
    get: null,
    auth: true,
    fallback: null,
  },
  delete: {
    path: '/technical-works/{{id}}',
    method: 'DELETE',
    get: null,
    auth: true,
    fallback: null,
  },

};
