import dayjs from 'dayjs';
import _ from 'lodash';

export const API = {
  shipping: {
    path: '/operation-lists?filters[id_department][short_description][$eq]=SHIP&populate[id_yacht][populate][id_attendance][populate][id_agent_NA][populate][id_users_permissions_user]=%2A&populate[id_department]=%2A&populate[id_marina]=%2A&populate[id_service]=%2A&populate[id_user_in_charge][populate][id_users_permissions_user][fields]=name,surname&populate[id_list][populate][id_list_items][populate][id_item]=%2A&populate[id_list][populate][id_list_items][populate][id_logbook][populate][id_supplier]=%2A&populate[id_shipping][populate][id_shipping_type][populate][id_shipping_status]=%2A&populate[id_shipping][populate][id_courier]=%2A',
    method: 'GET',
    get: null,
    auth: true,
    fallback: null,
    transform: (data) => ({
      meta: data?.meta,
      data: data?.data.map((item) => ({
        id: item?.id,
        attributes: {
          ...item?.attributes,
          ft_id: {
            ft: item?.attributes.ft,
            id: item?.id,
          },
          is_booked_id: {
            is_booked: item?.attributes.is_booked,
            id: item?.id,
          },
          date_out: ` ${item?.attributes?.check_out_date
              ? dayjs(item?.attributes?.check_out_date).format('DD-MM-YYYY')
              : '-'
            }`,
        },
      })),
    }),
  },
  operation: {
    path: '/operation-lists/{{id}}?populate[id_reservation_piloti_in][populate][id_yacht]=%2A&populate[id_yacht][populate][id_attendance][populate][id_agent_NA][populate][id_users_permissions_user][fields][0][1]=name, surname&populate[id_department]=%2A&populate[id_marina]=%2A&populate[id_mexal_person_in_charge][populate][id_users_permissions_user][fields]=name,surname&populate[id_reservation][populate][id_arrival_departure_formality]=%2A&populate[id_service]=%2A&populate[id_yacht][populate][id_flag]=%2A&populate[id_user_in_charge][populate][id_users_permissions_user][fields]=name,surname&populate[id_supplier_operation]=%2A&populate[id_shipping_status]=%2A&populate[id_shipping][populate][id_courier]=%2A&populate[id_shipping][populate][id_shipping_status]=%2A&populate[id_shipping][populate][id_shipping_type][populate][id_shipping_status]=%2A&populate[id_shipping][populate][id_shipping_document][populate][document]=%2A&populate[id_user_last_updated][populate][id_users_permissions_user]=%2A',
    method: 'GET',
    get: null,
    auth: true,
    fallback: null,
    transform: (data) => {
      return {
        data: {
          ...data?.data,
          attributes: {
            ...data?.data?.attributes,
            id_list_id: {
              id_list: data?.data?.attributes?.id_list?.data?.id,
              id_department:
                data?.data?.attributes?.id_department?.data?.attributes
                  ?.short_description,
            },
            shippingStatusOptions:
              data?.data?.attributes.id_shipping.data.attributes.id_shipping_type.data.attributes.id_shipping_status.data?.map(
                (item) => ({
                  id: item?.id,
                  label: item?.attributes?.shipping_status,
                })
              ),
            id_shipping: {
              ...data?.data?.attributes?.id_shipping,
              data: {
                ...data?.data?.attributes?.id_shipping?.data,
                attributes: {
                  ...data?.data?.attributes?.id_shipping?.data?.attributes,
                  type_shipping_collected_date: {
                    collected_date:
                      data?.data?.attributes?.id_shipping?.data?.attributes
                        ?.collected_date,
                    id_shipping_type:
                      data?.data?.attributes?.id_shipping?.data?.attributes
                        ?.id_shipping_type?.data?.attributes?.shipping_type,
                  },
                  courier_tracking_number: {
                    tracking_number:
                      data?.data?.attributes?.id_shipping?.data?.attributes
                        ?.tracking_number,
                    id_courier:
                      data?.data?.attributes?.id_shipping?.data?.attributes
                        ?.id_courier?.data?.id,
                  },
                },
              },
            },
          },
        },
        meta: data?.meta,
      };
    },
  },
  delete: {
    path: '/operation-lists/{{id}}',
    method: 'DELETE',
    get: null,
    auth: true,
    fallback: null,
  },
  create: {
    path: '/operation-lists',
    method: 'POST',
    get: null,
    auth: true,
    fallback: null,
    adapter: (body) => {
      return {
        data: {
          ...body,
          ft: body?.ft ? body.ft : null,
          id_agent_NA: body?.id_agent_NA?.id,
          id_mexal_person_in_charge: body?.id_mexal_person_in_charge?.id,
          id_supplier_operation: body?.id_supplier_operation?.id,
          id_yacht: body?.id_yacht?.id,
          id_service: body?.id_service?.id,
          id_technical_work: body?.id_technical_work?.id,
          status_operation_booking: body?.status_operation_booking,
          status_operation_BF: body?.status_operation_BF?.id,
          status_operation_BC: body?.status_operation_BC?.id,
          check_out_date: body?.check_out_date
            ? dayjs(body?.check_out_date).format('YYYY-MM-DD')
            : null,
          operation_date: body?.operation_date
            ? dayjs(body?.operation_date).format('YYYY-MM-DD')
            : null,
          requested_date: body?.requested_date
            ? dayjs(body?.requested_date).format('YYYY-MM-DD')
            : null,
          requested_hour: body?.requested_hour
            ? dayjs(body?.requested_hour).format('HH:mm:ss')
            : null,
          account_type: body?.account_type?.id,
          port: body?.port?.id,
          OC_fees: _.isEmpty(body?.OC_fees) ? null : body?.OC_fees,
          selling_price: _.isEmpty(body?.selling_price)
            ? null
            : body?.selling_price,
          purchase_price: _.isEmpty(body?.purchase_price)
            ? null
            : body?.purchase_price,
          type_of_contact: body?.type_of_contact?.id,

        },
      };
    },
  },

  selectShippingType: {
    path: '/shipping-types?populate=%2A&sort[shipping_type]=asc',
    method: 'GET',
    get: null,
    auth: true,
    fallback: [],
    transform: (data) => {
      return data?.data?.map((item) => ({
        id: item?.id,
        name: item?.attributes?.shipping_type,
        options: item?.attributes?.id_shipping_status?.data?.map((item) => ({
          id: item?.id,
          label: item?.attributes?.shipping_status,
        })),
      }));
    },
  },

  couriers: {
    path: '/couriers',
    method: 'GET',
    get: null,
    auth: true,
    fallback: [],
    transform: (data) => {
      return data?.data?.map((item) => ({
        id: item?.id,
        name: item?.attributes?.name,
      }));
    },
  },

  createShipping: {
    path: '/shippings',
    method: 'POST',
    get: null,
    auth: true,
    fallback: null,
    adapter: (body) => {
      return {
        data: {
          ...body,
          id_operation_list: body?.id_operation_list,
          date: body?.date ? dayjs(body?.date).format('YYYY-MM-DD') : null,
          requested: body?.requested,
          collected_from: body?.collected_from,
          collected_to: body?.collected_to,
          price: body?.price,
          customer_price: body?.customer_price,
          note: body?.note_shipping,
          id_courier: body?.id_courier?.id,
          tracking_number: body?.tracking_number,
          sender: body?.sender,
          item: body?.item,
          invoice_value: body?.invoice_value,
          duty_paid: body?.duty_paid?.id,
          courier_price: body?.courier_price,
          unimare_price: body?.unimare_price,
          markup: body?.markup,
          handling: body?.handling,
          receiver: body?.receiver,
          details: body?.details,
          storage: body?.storage,
          id_shipping_status: body?.id_shipping_status?.id,
          country: body?.country,
          UE: body?.UE,
          eta: body?.eta ? dayjs(body?.eta).format('YYYY-MM-DD') : null,
          is_parcel_for_you: body?.is_parcel_for_you,
          is_pre_alert: body?.is_pre_alert,
          collected_date: body?.collected_date
            ? dayjs(body?.collected_date).format('YYYY-MM-DD')
            : null,
        },
      };
    },
  },
};
