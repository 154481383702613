import MuiTypography from '@mui/material/Typography';
import { Status } from './Data.conf';
import _ from 'lodash';
import CalendarIcon from '@mui/icons-material/CalendarMonth';
import PersonIcon from '@mui/icons-material/Person';
import { Box } from '@mui/material';
import { Fragment } from 'react';
import moment from 'moment';
import RectangleChip from '../../../components/common/RectangleChip.component';

export const ListConf = {
  initialState: {
    pagination: {
      page: 1,
      pageSize: 100,
    },
    sort: {
      by: 'createdAt',
      direction: 'desc',
    },
  },
  rowsPerPageOptions: [25, 50, 100],
  columns: [
    {
      id: 'attributes.booking_status',
      key: 'booking_status',
      sort_key: 'booking_status',
      sortable: true,
      label: 'STATUS',
      width: 180
    },
    {
      id: 'attributes.id_yacht.data.attributes.yacht_name',
      key: 'yacht_name',
      sort_key: 'id_yacht.yacht_name',
      sortable: true,
      label: 'NAME',
    },

    {
      id: 'attributes.id_marina.data.attributes.marina',
      key: 'place',
      sort_key: 'id_marina.marina',
      sortable: true,
      label: 'MARINA',
    },
    {
      id: 'attributes.check_in_date',
      key: 'check_in_date',
      sort_key: 'check_in_date',
      sortable: true,
      label: 'CHECK IN',
      width: 160,
      maxWidth: 160,
    },
    {
      id: 'attributes.check_out_date',
      key: 'check_out_date',
      sort_key: 'check_out_date',
      sortable: true,
      label: 'CHECK OUT',
      width: 160,
      maxWidth: 160,
    },
    {
      id: 'attributes.booking_days',
      key: 'booking_days',
      sort_key: 'booking_days',
      sortable: true,
      label: 'NIGHTS',
      align: 'center',
      width: 100,
      maxWidth: 100,
    },
    {
      id: 'attributes.id_yacht.data.attributes.id_attendance.data[0].attributes.id_agent_NA.data.attributes.id_users_permissions_user.data.attributes',
      key: 'name',
      sort_key:
        'id_yacht.id_attendance.id_agent_NA.id_users_permissions_user.name',
      sortable: true,
      label: 'NA AGENCY',
    },
  ],
  render: {
    formatHead: (key, value) => {
      switch (key) {
        default:
          return (
            <MuiTypography
              variant='body1'
              sx={{ fontWeight: 'bold', color: 'white' }}
            >
              {value}
            </MuiTypography>
          );
      }
    },
    formatValue: (key, value, onClick) => {
      switch (key) {
        case 'name':
          return (
            <Box display='flex' alignItems='center' onClick={onClick}>
              {value ? (
                <Fragment>
                  <PersonIcon color='action' fontSize='small' />
                  <MuiTypography sx={{ ml: 1 }} variant='body1'>
                    {`${value?.name} ${value?.surname}`}
                  </MuiTypography>
                </Fragment>
              ) : (
                '-'
              )}
            </Box>
          );
        case 'place':
          return (
            <MuiTypography variant='body1' onClick={onClick}>
              {value ? value : '-'}
            </MuiTypography>
          );
        case 'check_in_date':
        case 'check_out_date':
          return (
            <Box display='flex' alignItems='center' onClick={onClick}>
              {value ? (
                <Fragment>
                  <CalendarIcon color='action' />
                  <MuiTypography sx={{ ml: 1 }} variant='body1'>
                    {moment(value).format('DD-MM-YYYY')}
                  </MuiTypography>
                </Fragment>
              ) : (
                '-'
              )}
            </Box>
          );
        case 'booking_status':
          const color = _.find(
            Status,
            (item) => item.label.toUpperCase() === value.toUpperCase()
          );
          return (
            <Box onClick={onClick}>
              {value ? (
                <RectangleChip
                value={value}
                backgroundColor={color?.hex}
                color={'#fff'}
                width='150px'
              />
              ) : (
                '-'
              )}
            </Box>
          );
        default:
          return (
            <MuiTypography
              variant='body1'
              sx={{ fontWeight: 'bold' }}
              onClick={onClick}
            >
              {value ? value : '-'}
            </MuiTypography>
          );
      }
    },
  },
};
