import dayjs from 'dayjs';
import * as yup from 'yup';
import { CrewGuestType, RequiredVehicleType } from '../Data.conf';
import { API as marina } from '../../../marina/api';

export const TransferReservationConf = {
  schema: {
    service_date: yup.date().nullable().required('Service date is required'),
    service_time: yup.date().nullable().required('Service time is required'),
    pickup_site: yup.string().nullable(),
    drop_off_site: yup.string().nullable(),
    id_marina: yup.object().nullable(),
    available: yup.boolean().nullable(),
    vehicles_number: yup
      .string()
      .nullable()
      .required('Vehicles number is required'),
    required_vehicle: yup.object().nullable(),
    pax_number: yup.string().nullable(),
    crew_guest: yup.object().nullable(),
    client_name: yup.string().nullable(),
    flight_details: yup.string().nullable(),
    reservation_code: yup.string().nullable(),
  },
  defaultValues: {
    service_date: dayjs(),
    service_time: null,
    pickup_site: null,
    drop_off_site: null,
    available: false,
    vehicles_number: null,
    required_vehicle: null,
    pax_number: null,
    id_marina: null,
    crew_guest: null,
    client_name: null,
    flight_details: null,
    reservation_code: null,
  },
  fields: [
    {
      id: 'reservation_divider',
      type: 'divider',
      label: 'Reservation',
      xs: 12,
    },
    {
      id: 'service_date',
      name: 'service_date',
      type: 'date',
      label: 'Service date',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'service_time',
      name: 'service_time',
      type: 'time',
      label: 'Service time',
      required: true,
      xs: 12,
      sm: 6,
    },

    {
      id: 'pickup_site',
      name: 'pickup_site',
      type: 'text',
      label: 'Pick up',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'drop_off_site',
      name: 'drop_off_site',
      type: 'text',
      label: 'Drop off',
      required: false,
      xs: 12,
      sm: 6,
    },

    {
      id: 'available',
      name: 'available',
      type: 'switch',
      label: 'Available',
      required: false,
      xs: 12,
    },
    {
      id: 'pax_number',
      name: 'pax_number',
      type: 'number',
      label: 'Pax',
      required: false,
      xs: 12,
      sm: 6,
    },

    {
      id: 'vehicles_number',
      name: 'vehicles_number',
      type: 'number',
      label: 'Vehicles number',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'required_vehicle',
      name: 'required_vehicle',
      type: 'autocomplete',
      label: 'Required vehicle',
      options: RequiredVehicleType,
      xs: 12,
      sm: 6,
    },
    {
      id: 'crew_guest',
      name: 'crew_guest',
      type: 'autocomplete',
      label: 'Crew / Guest',
      options: CrewGuestType,
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'client_name',
      name: 'client_name',
      type: 'text',
      label: 'Pax name',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'flight_details',
      name: 'flight_details',
      type: 'text',
      label: 'Flight',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'reservation_code',
      name: 'reservation_code',
      type: 'text',
      label: 'Reservation / Confirm code',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'id_marina',
      name: 'id_marina',
      type: 'async_autocomplete',
      label: 'Location',
      api: marina.select,
      required: false,
      xs: 12,
      sm: 6,
    },
  ],
};
