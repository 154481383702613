import React from 'react';
import MuiChip from '@mui/material/Chip';
import MuiTypography from '@mui/material/Typography';

const RectangleChip = ({
  width = 120,
  value,
  fontWeight = 500,
  backgroundColor,
  color,
  icon,
  onClick = () => {},
  size,
  ...sx
}) => {
  return (
    <MuiChip
      label={
        <MuiTypography
          variant='body1'
          onClick={onClick}
          sx={{ height: '100%', fontWeight: fontWeight, align: 'center' }}
        >
          {value}
        </MuiTypography>
      }
      sx={{
        boxShadow: 0.5,
        borderRadius: 2,
        backgroundColor: backgroundColor,
        color: color,
        width: width,
        ...sx,
      }}
      icon={icon}
      size={size}
    />
  );
};

export default RectangleChip;
