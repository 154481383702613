import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../../components/common/Loading.component';
import Modal from '../../../components/common/Modal.component';

import { fetchAPI } from '../../../api/request';
import Alert from '../../../components/common/Alert.component';
import EditView from '../../../views/Edit.view';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

const ShippingDocumentsEditPage = ({
  module: { query, edit, tag },
  setSuccessUpdate = () => {},
  success,
  id,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { jwt } = useSelector((state) => state.login);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const {
    loading: loadingGet,
    data,
    error: errorGET,
  } = useSelector(edit.selector.data);
  const userAuth = useSelector((state) => state.login?.user);
  const hasPermission = useMemo(
    () => userAuth.permissions[_.toUpper(tag)]?.UPDATE === 'true',
    [userAuth, tag]
  );
  const onError = useCallback((error) => {
    setSuccessUpdate(false);
    setLoading(false);
    setError(error);
    // eslint-disable-next-line
  }, []);

  const onSuccess = useCallback(() => {
    setSuccessUpdate(true);
    setLoading(false);
    setError(false);
    setOpenModal(false);
  }, [setLoading, setSuccessUpdate]);

  const onSubmit = useCallback(
    (values) => {
      if (hasPermission) {
        setError(false);
        setSuccessUpdate(false);
        setLoading(true);
        setOpenModal(true);

        fetchAPI(query.edit.settings, { id: id }, '', { ...values }, jwt)
          .then(onSuccess)
          .catch((error) => onError(error));
      }
    },
    [
      setLoading,
      jwt,
      onError,
      onSuccess,
      query,
      setSuccessUpdate,
      id,
      hasPermission,
    ]
  );

  useEffect(() => {
    if (openModal) {
      dispatch(
        query.detail.action({
          settings: query.detail.settings,
          token: jwt,
          params: { id },
          query: { populate: query.detail.populate },
        })
      );

      return () => {
        dispatch(
          query.detail.action({
            settings: null,
            token: null,
            params: null,
            query: null,
          })
        );
      };
    }
  }, [id, dispatch, jwt, openModal, query]);

  useEffect(() => {
    if (
      errorGET &&
      (errorGET?.response?.status === 404 || errorGET?.response?.status === 500)
    )
      navigate('/404');
  }, [errorGET, navigate, data, id]);

  return (
    <Fragment>
      {success && <Alert severity='success'>{'Success: Add'}</Alert>}
      {error && (
        <Alert severity='error'>
          {error?.response?.data?.error?.message
            ? error?.response?.data?.error?.message
            : 'An error has occurred'}
        </Alert>
      )}

      {(loading || loadingGet) && <Loading />}

      {hasPermission && (
        <Modal
          startIcon={<EditIcon style={{ height: 22 }} />}
          openModal={openModal}
          setOpenModal={setOpenModal}
        >
          <EditView
            data={data}
            conf={edit.conf}
            onEditSubmit={onSubmit}
            disabled={loading}
          />
        </Modal>
      )}
    </Fragment>
  );
};

export default ShippingDocumentsEditPage;
