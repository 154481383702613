import React, { Fragment, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchAPI } from '../../../api/request';
import Loading from '../../../components/common/Loading.component';
import Alert from '../../../components/common/Alert.component';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDialog from '../../../components/common/Confirm.component';
import _ from 'lodash';

const TechnicalWorkDeletePage = ({ module: { remove, query, tag }, id }) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const { jwt } = useSelector((state) => state.login);
  const [error, setError] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const userAuth = useSelector((state) => state.login?.user);

  const onClose = useCallback(() => {
    setOpenDelete(false);
  }, [setOpenDelete]);

  const onOpen = useCallback(() => {
    setOpenDelete(true);
  }, [setOpenDelete]);

  const onSuccess = useCallback(() => {
    setLoading(true);
    setError(null);
    // navigate(remove?.navigate ? remove?.navigate : -2);
    navigate(0);
  }, [navigate, setLoading]);

  const onError = useCallback((error) => {
    setLoading(false);
    setError(error);
    // eslint-disable-next-line
  }, []);

  const onSubmit = useCallback(() => {
    if (userAuth.permissions[_.toUpper(tag)]?.DELETE === 'true') {
      setError(false);
      setLoading(false);
      fetchAPI(query.delete.settings, { id }, '', {}, jwt)
        .then(onSuccess)
        .catch((error) => onError(error));
    }
  }, [id, jwt, onError, onSuccess, query, userAuth, tag]);

  return (
    <Fragment>
      {loading && <Loading />}
      {error && (
        <Alert severity='error'>
          {error?.response?.data?.error?.message
            ? error?.response?.data?.error?.message
            : 'An error has occurred'}
        </Alert>
      )}

      <IconButton color='error' onClick={onOpen}>
        <DeleteIcon />
      </IconButton>

      <ConfirmDialog
        open={openDelete}
        onClose={onClose}
        title='Are you sure you want to delete this?'
        description='It will be deleted permanently. You cannot undo this action.'
        cancelAction={onClose}
        confirmAction={onSubmit}
      />
    </Fragment>
  );
};

export default TechnicalWorkDeletePage;
