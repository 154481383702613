import * as yup from 'yup';
import {
  CrewGuestType,
  TypeOfServiceRental,
} from '../Data.conf';
import { API as marina } from '../../../marina/api';

export const RentalConf = {
  schema: {
    pickup_site: yup.string().nullable().required('Pickup site is required'),
    id_marina: yup.object().nullable(),
    drop_off_site: yup
      .string()
      .nullable()
      .required('Drop off site is required'),
    service_type: yup.object().nullable(),
    service_details: yup.string().nullable(),
    pickup_date: yup.date().nullable().required('Pickup date is required'),

    drop_off_date: yup
      .date()
      .nullable()
      .required('Drop off date is required')
      .when('pickup_date', (pickup_date, schema) =>
        pickup_date
          ? schema.min(pickup_date, "Drop off date can't be before Pickup date")
          : schema
      ),
    crew_guest: yup.object().nullable().required('Crew / Guest is required'),
  },
  defaultValues: {
    pickup_site: null,
    drop_off_site: null,
    service_type: null,
    id_marina: null,
    pickup_date: null,
    drop_off_date: null,
    crew_guest: null,
    service_details: null,
  },
  fields: [
    {
      id: 'reservation_rental_divider',
      type: 'divider',
      label: 'Reservation',
      xs: 12,
    },
    {
      id: 'pickup_date',
      name: 'pickup_date',
      type: 'date',
      label: 'Pickup date',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'pickup_site',
      name: 'pickup_site',
      type: 'text',
      label: 'Pickup site',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'drop_off_date',
      name: 'drop_off_date',
      type: 'date',
      label: 'Drop off date',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'drop_off_site',
      name: 'drop_off_site',
      type: 'text',
      label: 'Drop off site',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'service_type',
      name: 'service_type',
      type: 'autocomplete',
      label: 'Type of Service',
      required: false,
      options: TypeOfServiceRental,
      xs: 12,
      sm: 6,
    },
    {
      id: 'service_details',
      name: 'service_details',
      type: 'text',
      label: 'Service Details',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'crew_guest',
      name: 'crew_guest',
      type: 'autocomplete',
      label: 'Crew / Guest',
      options: CrewGuestType,
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'id_marina',
      name: 'id_marina',
      type: 'async_autocomplete',
      label: 'Location',
      api: marina.select,
      required: false,
      xs: 12,
      sm: 6,
    },
  ],
};
