import { IconButton, TableCell, TableRow, Tooltip } from '@mui/material';
import React from 'react';
import MuiChip from '@mui/material/Chip';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { Link } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { grey } from '@mui/material/colors';

const TableRowLogbook = ({
  mexal_code,
  label,
  color,
  status_logbook,
  id,
  open,
  onChangeExpanded = () => {},
}) => {
  return (
    <TableRow
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
        backgroundColor: open && grey[50],
      }}
      id={`row-${id}`}
    >
      <TableCell component='th' scope='row'>
        <IconButton size='small' onClick={onChangeExpanded}>
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </TableCell>

      <TableCell component='th' scope='row'>
        {`${mexal_code} - ${label}`}
      </TableCell>
      <TableCell component='th' scope='row'>
        {status_logbook && (
          <MuiChip
            label={status_logbook}
            color={color?.color}
            sx={{
              color: color?.color ? '#fff' : color?.textHex,
              backgroundColor: color?.hex,
              fontWeight: 500,
              fontSize: 11,
              width: 86,
            }}
            size='small'
          />
        )}
      </TableCell>

      <TableCell component='th' scope='row'>
        <Tooltip title='Logbook'>
          <Link to={`/logbook/${id}`}>
            <IconButton color='primary'>
              <LibraryBooksIcon />
            </IconButton>
          </Link>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

export default TableRowLogbook;
