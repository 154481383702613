import * as yup from 'yup';
import { API as products } from '../../products/api';
import _ from 'lodash';
import { AvailableType } from '../../listItem/conf/Data.conf';

const Conf = {
  schema: yup.object({
    available: yup.object().nullable().required('Available is required'),
    quantity: yup.string().nullable(),
    id_item: yup.object().nullable().required('Product is required'),
    attachment: yup.mixed().nullable(),
  }),
  defaultValues: {
    available: _.find(AvailableType, ['id', 'NEW ITEM']),
    quantity: '',
    id_item: null,
    note: '',
    attachment: null,
  },
  fields: [
    {
      id: 'id_item',
      name: 'id_item',
      type: 'virtualized_async_autocomplete',
      label: 'Product',
      api: products.selectDEP,
      required: true,
      xs: 12,
      sm: 6,
      autoFocus: true,
    },
    {
      id: 'quantity',
      name: 'quantity',
      type: 'number',
      label: 'Quantity',
      xs: 12,
      sm: 3,
    },

    {
      id: 'available',
      name: 'available',
      type: 'autocomplete',
      label: 'Available',
      options: AvailableType,
      required: true,
      xs: 12,
      sm: 3,
      disableClearable: true,
    },
    {
      id: 'note',
      name: 'note',
      type: 'text',
      label: 'Note',
      xs: 12,
    },
    {
      id: 'attachment',
      name: 'attachment',
      label: 'Photo',
      type: 'upload',
      xs: 12,
      sm: 6,
      md: 4,
    },
  ],
};

export const CreateConf = {
  default: { ...Conf },
  hasLogbook: {
    schema: Conf.schema,
    defaultValues: {
      ...Conf.defaultValues,
      available: _.find(AvailableType, ['id', 'ORDERED']),
    },
    fields: Conf.fields,
  },
};
