import { Grid, IconButton } from '@mui/material';
import MuiFormControl from '@mui/material/FormControl';
import React, { useCallback } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import Button from '../../common/Button.component';
import { DynamicControl } from '../DynamicControl';
import DeleteIcon from '@mui/icons-material/Delete';
import Divider from './Divider.component';

import PropTypes from 'prop-types';

const AddMoreFormFields = ({ name, required, options }) => {
  const { control } = useFormContext();
  const {
    field: { onChange, value },
  } = useController({
    name: name,
    control: control,
    rules: { required },
  });

  const addFields = useCallback(() => {
    let newfield = {};
    onChange(value ? [...value, newfield] : [newfield]);
  }, [value, onChange]);

  const handleFormChange = (index, event, data) => {
    let dataValues = [...value];
    if (event.target.value) {
      dataValues[index][event.target.name] = event.target.value;
    } else {
      dataValues[index][event.target.name] = data;
    }
    onChange(dataValues);
  };
  const removeFields = (index) => {
    let data = [...value];
    data.splice(index, 1);
    onChange(data);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <MuiFormControl>
          {value &&
            value?.map((input, index) => (
              <Grid container spacing={1} alignItems='center' key={index}>
                {options.map(({ xs, sm, ...d }, i) => (
                  <Grid item xs={xs} sm={sm} key={i}>
                    <DynamicControl
                      {...d}
                      value={input[d.name] ? input[d.name] : null}
                      onChange={(event, data) =>
                        event?.target?.name
                          ? handleFormChange(index, event, data)
                          : handleFormChange(
                              index,
                              { target: { name: d.name } },
                              data
                            )
                      }
                    />
                  </Grid>
                ))}
                <Grid item xs={1}>
                  <IconButton onClick={() => removeFields(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            ))}
        </MuiFormControl>
      </Grid>
      <Grid item xs={12}>
        <Button
          onClick={() => addFields()}
          variant='contained'
          size='small'
          sx={{ width: 200 }}
        >
          Add new item
        </Button>
      </Grid>
    </Grid>
  );
};

export default AddMoreFormFields;

AddMoreFormFields.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.array,
};
