// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Stack } from '@mui/material';

// project imports
import LogoSection from '../logoSection';

// assets
import { IconMenu2 } from '@tabler/icons-react';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import menu from '../../../module/menu';
import user from '../../../module/login';
import Profile from './profile';
import Season from './season';


const Header = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { drawerOpen } = useSelector((state) => state.menu);

  const openDrawer = useCallback(() => {
    dispatch(menu.query.open.action(!drawerOpen));
  }, [drawerOpen, dispatch]);

  const onLogout = async () => {
    dispatch(
      user.query.logout.action({
        settings: user.query.logout.settings,
        query: null,
        body: null,
      })
    ); // Clear local storage
  };

  return (
    <Stack
      direction={'row'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={1}
    >
      <Avatar
        variant='rounded'
        sx={{
          ...theme.typography.commonAvatar,
          ...theme.typography.mediumAvatar,
          overflow: 'hidden',
          transition: 'all .2s ease-in-out',
          background:
            theme.palette.mode === 'dark'
              ? theme.palette.dark.main
              : theme.palette.secondary.light,
          color:
            theme.palette.mode === 'dark'
              ? theme.palette.secondary.main
              : theme.palette.secondary.dark,
          '&:hover': {
            background:
              theme.palette.mode === 'dark'
                ? theme.palette.secondary.main
                : theme.palette.secondary.dark,
            color:
              theme.palette.mode === 'dark'
                ? theme.palette.secondary.light
                : theme.palette.secondary.light,
          },
        }}
        onClick={() => openDrawer()}
        color='inherit'
      >
        <IconMenu2 stroke={1.5} size='20px' />
      </Avatar>

      <Box
        justifyContent={'center'}
        display={'flex'}
        alignItems={'center'}
        width={'100%'}
      >
        <LogoSection />
      </Box>
      
      <Stack direction={'row'} spacing={2}>
        <Season />
        <Profile onLogout={onLogout} />
      </Stack>
    </Stack>
  );
};

export default Header;
