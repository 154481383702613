import React from 'react';
import MuiTableCell from '@mui/material/TableCell';
import MuiTableHead from '@mui/material/TableHead';
import MuiTableRow from '@mui/material/TableRow';
import MuiTableSortLabel from '@mui/material/TableSortLabel';
import { useTheme } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

const TableHead = ({
  columns,
  sort,
  onChangeSort = () => {},
  formatHead = () => {},
}) => {
  const theme = useTheme();
  const createSortHandler = (property) => () => {
    onChangeSort(property);
  };

  return (
    <MuiTableHead>
      <MuiTableRow>
        {columns.map((column) => (
          <MuiTableCell
            key={uuidv4()}
            align={column.align}
            style={{
              minWidth: column.width || 220,
              maxWidth: column?.maxWidth,
            }}
            sx={{ backgroundColor: theme.palette.secondary.medium }}
          >
            {column.sortable ? (
              <MuiTableSortLabel
                active={sort.by === column.key}
                direction={sort.direction}
                onClick={createSortHandler(column.sort_key)}
              >
                {formatHead(column.key, column.label)}
              </MuiTableSortLabel>
            ) : (
              formatHead(column.key, column.label)
            )}
          </MuiTableCell>
        ))}
      </MuiTableRow>
    </MuiTableHead>
  );
};

export default TableHead;
