import React from 'react';
import MUIAutocomplete from '@mui/material/Autocomplete';
import MUITextField from '@mui/material/TextField';
import Error from './Error.component';
import { useController, useFormContext } from 'react-hook-form';
import { Box, Chip } from '@mui/material';

import PropTypes from 'prop-types';
import _ from 'lodash';

const AutocompleteMultiple = ({
  name,
  required,
  options,
  label,
  disabled,
  ...props
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const {
    field: { ref, onChange, value, ...inputProps },
  } = useController({
    name: name,
    control: control,
    rules: { required },
  });

  return (
    <Box display='flex' flexDirection={'column'}>
      <MUIAutocomplete
        {...inputProps}
        multiple
        value={value || []}
        options={options}
        getOptionLabel={(option) => option?.label || ''}
        isOptionEqualToValue={(option, value) => option?.label === value?.label}
        filterOptions={(options, state) => {
          // Filter out any options that have the same name as the user's input value
          const inputValue = state?.inputValue?.toLowerCase();
          return options?.filter((option) =>
            _.includes(option?.label.toLowerCase(), inputValue.toLowerCase())
          );
        }}
        renderInput={(params) => (
          <MUITextField
            {...params}
            label={label}
            required={required}
            error={!!errors[name]?.message}
          />
        )}
        onChange={(e, data) => {
          onChange(data);
        }}
        disabled={disabled}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              label={option?.label}
              {...getTagProps({ index })}
              sx={{
                '&.MuiAutocomplete-tag': {
                  color: option?.color,
                  backgroundColor: option?.backgroundColor,
                },
              }}
            />
          ))
        }
        {...props}
      />
      <Error error={errors[name]?.message} />
    </Box>
  );
};

AutocompleteMultiple.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
};

export default AutocompleteMultiple;