import dayjs from 'dayjs';
import * as yup from 'yup';

export const GarbageConf = {
  schema: {
    service_time: yup.date().nullable().required('Service time is required or invalid')
    .transform((value, originalValue) => {
      const parsedTime = dayjs(originalValue, 'HH:mm', true);
      return parsedTime?.isValid() ? parsedTime.toDate() : null;
    }),
    // service_place: yup.string().nullable(),
    garbage_quantity: yup
      .string()
      .nullable()
      .required('Garbage quantity is required'),
  },
  defaultValues: {
    service_time: null,
    // service_place: null,
    garbage_quantity: null,
    payment_type: null,
  },
  fields: [
    {
      id: 'service_divider',
      type: 'divider',
      label: 'Service',
      xs: 12,
    },
    {
      id: 'service_time',
      name: 'service_time',
      type: 'time',
      label: 'Service time',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'garbage_quantity',
      name: 'garbage_quantity',
      label: 'Garbage quantity',
      type: 'text',
      required: true,
      xs: 12,
      sm: 6,
    },
    // {
    //   id: 'service_place',
    //   name: 'service_place',
    //   type: 'text',
    //   label: 'Location',
    //   required: false,
    //   xs: 12,
    //   sm: 6,
    // },
  ],
};
