import React, {
  useEffect,
  useState,
  Fragment,
  useContext,
  createContext,
} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import useMediaQuery from '@mui/material/useMediaQuery';
import ListSubheader from '@mui/material/ListSubheader';
import Popper from '@mui/material/Popper';
import { useTheme, styled } from '@mui/material/styles';
import { VariableSizeList } from 'react-window';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { fetchAPI } from '../../../api/request';
import MUICircularProgress from '@mui/material/CircularProgress';
import { useController, useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';
import Error from './Error.component';
import PropTypes from 'prop-types';

const LISTBOX_PADDING = 10;

function renderRow(props) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: style.top + LISTBOX_PADDING,
  };

  if (dataSet.hasOwnProperty('group')) {
    return (
      <ListSubheader key={dataSet.key} component='div' style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    <Typography component='li' {...dataSet[0]} noWrap style={inlineStyle}>
      {dataSet[1]}
    </Typography>
  );
}

const OuterElementContext = createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const { children, ...other } = props;
  const itemData = [];
  children.forEach((item) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (child.hasOwnProperty('group')) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width='100%'
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType='ul'
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

const VirtualizedAsyncAutocomplete = ({
  name,
  required,
  url,
  api,
  label,
  disabled,
  autoFocus = false,
  disableClearable = false,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const { jwt } = useSelector((state) => state.login);
  const [loading, setLoading] = useState(false);
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const {
    field: { ref, onChange, value, ...inputProps },
  } = useController({ name: name, control: control, rules: { required } });

  useEffect(() => {
    setLoading(true);
    //onChange(null)
    fetchAPI(api, {}, {}, {}, jwt)
      .then((response) => {
        setLoading(false);
        setOptions(response);
      })
      .catch(() => {
        setLoading(false);
        setOptions([]);
      });
  }, [jwt, api]);

  return (
    <Box display='flex' flexDirection={'column'}>
      <Autocomplete
        {...inputProps}
        ref={ref}
        value={value || null}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        disableListWrap
        PopperComponent={StyledPopper}
        ListboxComponent={ListboxComponent}
        options={options}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            required={required}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {loading ? (
                    <MUICircularProgress color='inherit' size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </Fragment>
              ),
            }}
            error={!!errors[name]?.message}
            autoFocus={autoFocus}
          />
        )}
        disabled={disabled}
        renderOption={(props, option) => [props, option?.name]}
        renderGroup={(params) => params}
        disableClearable={disableClearable}
        onChange={(e, data) => {
          onChange(data);
        }}
        getOptionLabel={(option) => option?.name || ''}
        isOptionEqualToValue={(option, value) =>
          option.name === value.name && option.id === value.id
        }
        {...props}
      />
      <Error error={errors[name]?.message} />
    </Box>
  );
};

VirtualizedAsyncAutocomplete.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
  url: PropTypes.string,
  api: PropTypes.object,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  disableClearable: PropTypes.bool,
};

export default VirtualizedAsyncAutocomplete;
