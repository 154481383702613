import { Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EventComponent from './Event.component';
import dayjs from 'dayjs';

const SidebarComponent = ({
  selectDate,
  eventList,
  date,
  setEventsPopup = () => {},
}) => {
  return (
    <Box width='100%' height='700px' paddingTop='42px'>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Typography variant='h6' fontWeight='bold' p={1}>
          {dayjs(selectDate).format('dddd, MMM, D')}
        </Typography>
        <IconButton onClick={() => setEventsPopup(false)}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box overflow='scroll' height='95%'>
        {eventList.map((event) => (
          <EventComponent key={event.id} event={event} date={date} />
        ))}
      </Box>
    </Box>
  );
};

export default SidebarComponent;
