import React, { Fragment, useState, useCallback } from 'react';
import { Paper } from '@mui/material';
import { fetchAPI } from '../../../../api/request';
import { useSelector } from 'react-redux';
import ConfirmDialog from '../../../common/Confirm.component';
import Loading from '../../../common/Loading.component';
import { columns } from '../../../../module/listItemsDepot/conf/Data.conf';
import _ from 'lodash';
import TableRowSort from '../../../listItem/table/sort/TableRowSort.component';
import TableRow from './components/TableRow.component';
import itemsDepot from '../../../../module/listItemsDepot';
import TableVirtuoso from '../../../virtuosoTable/TableVirtuoso.component';

const AVAILABLE = 'READY';
const AVAILABLE_IN_STORE = 'IN STOCK';
const STATUS_LOGBOOK = 'DELIVERED';

const TableProductsComponent = ({
  data,
  setSuccessEdit = () => {},
  checked = [],
  setChecked = () => {},
}) => {
  const { jwt } = useSelector((state) => state.login);
  const [editable, setEditable] = useState(null);
  const [value, setValue] = useState(false);
  const [currentState, setCurrentState] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [orderBy, setOrderBy] = useState(
    'attributes.id_logbook.data.attributes.id_supplier.data.attributes.label'
  );
  const [order, setOrder] = useState('asc');
  const sortedData = _.orderBy(data, [orderBy], [order]);

  const handleSort = (columnId) => {
    const isAsc = orderBy === columnId && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(columnId);
  };

  const onChange = (value, name, id) => {
    setCurrentState({
      ...currentState,
      [name]: { ...currentState?.[name], [id]: value ? value : null },
    });
  };

  const handleClose = useCallback(() => {
    setOpen(false);
    setValue(null);
  }, []);

  const handleOpen = useCallback((item) => {
    setOpen(true);
    setValue(item);
  }, []);

  // eslint-disable-next-line
  const onSuccess = (item) => {
    setLoading(false);
    setEditable({ ...editable, [item?.id]: false });
    handleClose();
  };

  const onError = useCallback(() => {
    setLoading(false);
  }, []);

  const onChangeChecked = useCallback(
    (item) => {
      const itemId = item?.id;
      const depotStatus = currentState?.available?.[itemId];

      if (depotStatus !== undefined) {
        if (depotStatus === AVAILABLE || depotStatus === AVAILABLE_IN_STORE) {
          if (!_.includes(checked, itemId)) {
            setChecked(_.concat(checked, itemId));
          }
        } else if (_.includes(checked, itemId)) {
          setChecked(_.filter(checked, (id) => id !== itemId));
        }
      }
    },
    [checked, currentState, setChecked]
  );

  const onSaveAction = useCallback(
    (item) => {
      setLoading(true);
      const data = {
        quantity: currentState?.quantity?.[item?.id],
        available: currentState?.available?.[item?.id],
        quantity_type: currentState?.quantity_type?.[item?.id],
        category: currentState?.category?.[item?.id],
        note: currentState?.note?.[item?.id],
      };

      fetchAPI(
        itemsDepot.query.edit.settings,
        { id: item.id },
        '',
        { ...data },
        jwt
      )
        .then(() => {
          onSuccess(item);
          onChangeChecked(item);
        })
        .catch(onError);
    },
    [currentState, onSuccess, jwt, onError, onChangeChecked]
  );

  const onDeleteAction = useCallback(() => {
    setSuccessEdit(false);
    fetchAPI(itemsDepot.query.delete.settings, { id: value.id }, '', {}, jwt)
      .then(() => {
        onSuccess();
        setSuccessEdit(true);
        setChecked(
          _.remove(checked, function (n) {
            return n === value.id;
          })
        );
      })
      .catch(onError);
  }, [setSuccessEdit, onSuccess, jwt, onError, value, checked, setChecked]);

  const handleEditable = (item) => {
    setEditable({ ...editable, [item?.id]: true });
  };

  return (
    <Fragment>
      {loading && <Loading />}

      <Paper style={{ height: 450, width: '100%' }}>
        <TableVirtuoso
          data={sortedData}
          fixedHeaderContent={() => (
            <TableRowSort
              columns={columns}
              onSort={handleSort}
              order={order}
              orderBy={orderBy}
            />
          )}
          itemContent={(_index, item) => (
            <TableRow
              item={item}
              onChange={onChange}
              editable={editable}
              currentState={currentState}
              checked={checked}
              handleEditable={handleEditable}
              onSaveAction={onSaveAction}
              handleOpen={handleOpen}
              crudDisabled={
                item?.attributes?.id_logbook?.data?.attributes
                  ?.status_logbook === STATUS_LOGBOOK
              }
            />
          )}
        />
      </Paper>

      <ConfirmDialog
        open={open}
        onClose={handleClose}
        title='Are you sure you want to delete this?'
        description='It will be deleted permanently. You cannot undo this action.'
        cancelAction={handleClose}
        confirmAction={onDeleteAction}
      />
    </Fragment>
  );
};
export default TableProductsComponent;
