import React from 'react';
import { Box } from '@mui/material';
import Button from '../components/form/components/Button.component';
import { Form } from '../components/form/Form';

const LoginView = ({ conf, onLoginSubmit = () => {}, children }) => {
  const { fields, schema, defaultValues } = conf;

  return (
    <Form
      fields={fields}
      schema={schema}
      defaultValues={defaultValues}
      onSubmit={onLoginSubmit}
    >
      {children}
      <Box display={'flex'} justifyContent='flex-end'>
        <Button type='submit' variant='outlined' sx={{ mt: 1, mb: 2 }}>
          Login
        </Button>
      </Box>
    </Form>
  );
};

export default LoginView;
