import React from 'react';
import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';

const LastUpdateInfo = ({ updatedAt, username }) => {
    const usernameString = ` from user ${username}`;
    return (
        <Box
            display='flex'
            m={1}
            justifyContent='start'
            alignItems='center'
            sx={{ color: 'grey' }}
        >
            <Typography variant='caption'>{`Last update: ${dayjs(
                updatedAt
            ).format('DD/MM/YYYY')} at h ${dayjs(updatedAt).format('HH:mm')}${username ? usernameString : ''}`}</Typography>

        </Box >
    );
};

export default LastUpdateInfo;
