import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Divider, List, Typography, useMediaQuery } from '@mui/material';
import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';
import { useSelector } from 'react-redux';
import NavMenuPopover from '../NavMenuPopover';
import { Fragment } from 'react';

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const NavGroup = ({ item }) => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const { drawerOpen } = useSelector((state) => state.menu);

    return (
        <Fragment>
            <List
                sx={{ padding: 0 }}
                subheader={
                    item.title && drawerOpen && (
                        <Typography
                            variant='caption'
                            sx={{ ...theme.typography.menuCaption }}
                            display='block'
                            gutterBottom
                        >
                            {item.title}
                            {item.caption && (
                                <Typography
                                    variant='caption'
                                    sx={{ ...theme.typography.subMenuCaption }}
                                    display='block'
                                    gutterBottom
                                >
                                    {item.caption}
                                </Typography>
                            )}
                        </Typography>
                    )
                }
            >
                {item.children?.map((menu) =>
                    menu?.to ? (
                        <NavItem key={menu?.id} item={menu} level={1} />
                    ) : matchUpMd && !drawerOpen ? (
                        <NavMenuPopover key={menu?.id} menu={menu} level={1} />
                    ) : (
                        <NavCollapse key={menu?.id} menu={menu} level={1} />
                    )
                )}
            </List>
            {/* group divider */}
            {drawerOpen && <Divider sx={{ mt: 0.25, mb: 0.35 }} />}
        </Fragment>
    );
};

NavGroup.propTypes = {
    item: PropTypes.object,
};

export default NavGroup;
