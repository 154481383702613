import { dataSlice as createDataSLice } from '../../../core/data';
import { sliceList as createListSlice } from '../../../core/list';
import { sliceFilter as createFilterSlice } from '../../../core/filter';
import { detailSlice as createDetailSlice } from '../../../core/detail';
import { sliceSearch as createSearchSlice } from '../../../core/search';
import {
  combineReducers,
  createAction,
  createSelector,
} from '@reduxjs/toolkit';
import _ from 'lodash';

import { ListConf } from '../conf/List.conf';
import { SearchConf } from '../conf/Search.conf';
import { FilterConf } from '../conf/Filter.conf';
import { DataConf } from '../conf/Data.conf';
import { QueryConf } from '../conf/Query.conf';
import { DetailConf } from '../conf/Detail.conf';

export const tag = 'attendances';

// REDUCERS
const dataSlice = createDataSLice(`${tag}/data`, DataConf.initialState);
const listSlice = createListSlice(`${tag}/list`, ListConf.initialState);
const filterSlice = createFilterSlice(`${tag}/filter`, FilterConf.initialState);
const searchSlice = createSearchSlice(`${tag}/search`, SearchConf.initialState);
const detailSlice = createDetailSlice(`${tag}/detail`, DetailConf.initialState);

export const reducer = combineReducers({
  search: searchSlice.reducer,
  data: dataSlice.reducer,
  list: listSlice.reducer,
  filter: filterSlice.reducer,
  detail: detailSlice.reducer,
});

// ACTIONS
export const actions = {
  fetchAllAction: createAction(`${tag}/fetchAll`),
  fetchDetailAction: createAction(`${tag}/fetchOne`),
  ...searchSlice.actions,
  ...dataSlice.actions,
  ...listSlice.actions,
  ...filterSlice.actions,
  ...detailSlice.actions,
};

// QUERY
export const querySelector = createSelector(
  (state) => state[tag].list,
  (state) => state[tag].filter,
  (state) => state[tag].search,
  (list, filter, search) => QueryConf.get(list, filter, search)
);

// LIST
export const listSelector = createSelector(
  (state) => state[tag].data,
  (state) => state[tag].list,
  ({ data, meta }, { pagination, sort }) => ({
    data,
    meta,
    pagination,
    sort,
  })
);

// DETAIL
export const detailSelector = createSelector(
  (state) => state[tag].detail,
  (detail) => detail
);

// EDIT
export const editSelector = createSelector(
  (state) => state[tag].detail,
  ({ data, loading, error }) => {
    const yacht = _.get(data, 'attributes.id_yacht.data');
    const agent_NA = _.get(data, 'attributes.id_agent_NA.data');
    const user_provision = _.get(data, 'attributes.id_user_provision.data');
    const provenence_list = _.get(data, 'attributes.id_provenence_list.data');
    const provenence_group = _.get(
      data,
      'attributes.id_provenence_list.data.attributes.provenence_group.data'
    );
    const provenence_sales_channel = _.get(
      data,
      'attributes.id_provenence_list.data.attributes.provenence_sales_channel.data'
    );
    const provenence = _.get(
      data,
      'attributes.id_provenence_list.data.attributes.provenence.data'
    );
    const first_contact = _.get(
      data,
      'attributes.id_provenence_list.data.attributes.first_contact'
    );
    const users_nauticas = _.get(
      data,
      'attributes.id_provenence_list.data.attributes.users_nauticas.data'
    );
    const user_tech = _.get(data, 'attributes.id_user_tech.data');

    return {
      loading,
      error,
      data: data
        ? {
            id_yacht: yacht
              ? { name: yacht.attributes.yacht_name, id: yacht.id }
              : null,
            id_agent_NA: agent_NA
              ? {
                  name: `${agent_NA.attributes.id_users_permissions_user?.data?.attributes?.name} ${agent_NA.attributes.id_users_permissions_user?.data?.attributes?.surname}`,
                  id: agent_NA.id,
                }
              : null,
            id_user_provision: user_provision
              ? {
                  name: `${user_provision.attributes.id_users_permissions_user?.data?.attributes?.name} ${user_provision.attributes.id_users_permissions_user?.data?.attributes?.surname}`,
                  id: user_provision.id,
                }
              : null,
            provenence_group: provenence_group
              ? {
                  name: provenence_group.attributes.group,
                  id: provenence_group.id,
                }
              : null,
            provenence_sales_channel: provenence_sales_channel
              ? {
                  name: provenence_sales_channel.attributes.sales_channel,
                  id: provenence_sales_channel.id,
                }
              : null,
            provenence: provenence
              ? { name: provenence.attributes.provenence, id: provenence.id }
              : null,
            first_contact: first_contact
              ? { label: first_contact, id: first_contact }
              : null,
            users_nauticas: users_nauticas
              ? {
                  name: `${users_nauticas?.attributes?.id_users_permissions_user?.data?.attributes?.name} ${users_nauticas?.attributes?.id_users_permissions_user?.data?.attributes?.surname}`,
                  id: users_nauticas?.id,
                }
              : null,
            id_provenence_list: provenence_list ? provenence_list.id : null,
            isps: _.get(data, 'attributes.isps'),
            formalities: _.get(data, 'attributes.formalities'),
            garbage: _.get(data, 'attributes.garbage'),
            note: _.get(
              data,
              'attributes.id_provenence_list.data.attributes.note'
            ),
            registration_date: _.get(data, 'attributes.registration_date'),
            is_central_agent: _.get(data, 'attributes.is_central_agent'),
            mexal_validation: _.get(data, 'attributes.mexal_validation'),
            note_attendance: _.get(data, 'attributes.note'),
            id_user_tech: user_tech
              ? {
                  name: `${user_tech.attributes.id_users_permissions_user?.data?.attributes?.name} ${user_tech.attributes.id_users_permissions_user?.data?.attributes?.surname}`,
                  id: user_tech.id,
                }
              : null,
          }
        : null,
    };
  }
);
