import _ from 'lodash';

export const API = {
  listItems: {
    path: '/list-items?filters[id_list]={{id}}&populate[id_item]=%2A&populate[id_list]=%2A&populate[id_logbook][populate][id_supplier]=%2A&sort[1]=id_item.name_ITA:asc&sort[0]=id_logbook.id_supplier.label:asc&pagination[page]=1&pagination[pageSize]=100',
    method: 'GET',
    get: null,
    auth: true,
    fallback: null,
  },
  listItemsWithLogbook: {
    path: '/list-items?filters[id_list]={{id}}&populate[id_item]=%2A&populate[id_list]=%2A&populate[id_logbook][populate][id_supplier]=%2A&sort[0]=createdAt:desc&sort[2]=id_item.name_ITA:asc&sort[1]=id_logbook.id_supplier.label:asc&pagination[page]=1&pagination[pageSize]=10000&filters[id_logbook][id][$notNull]=true&populate[attachment]=%2A',
    method: 'GET',
    get: null,
    auth: true,
    fallback: null,
  },
  listItemsNOLogbook: {
    path: '/list-items?filters[id_list]={{id}}&populate[id_item]=%2A&populate[id_list]=%2A&populate[id_logbook][populate][id_supplier]=%2A&sort[0]=createdAt:desc&pagination[page]=1&pagination[pageSize]=10000&filters[id_logbook][id][$notNull]=false&populate[attachment]=%2A',
    method: 'GET',
    get: null,
    auth: true,
    fallback: null,
  },
  listItem: {
    path: '/list-items?filters[id_list]={{id_list}}&filters[id]={{id}}',
    method: 'GET',
    get: null,
    auth: true,
    fallback: null,
  },
  select: {
    path: '/list-items',
    method: 'GET',
    get: 'data',
    auth: true,
    fallback: [],
    transform: (data) =>
      data.map((item) => ({
        name: `${item.attributes.available}`,
        id: item.id,
      })),
  },
  delete: {
    path: '/list-items/{{id}}',
    method: 'DELETE',
    get: null,
    auth: true,
    fallback: null,
  },
  update: {
    path: '/list-items/{{id}}',
    method: 'PUT',
    get: null,
    auth: true,
    fallback: null,
    adapter: (body) => ({
      data: {
        ...body,
      },
    }),
  },
  create: {
    path: '/list-items',
    method: 'POST',
    get: null,
    auth: true,
    fallback: null,
    adapter: (body) => {
      return {
        data: [
          {
            ...body,
            available: body?.available?.id,
            quantity: !_.isEmpty(body?.quantity) ? body?.quantity : null,
            quantity_type: body?.quantity_type?.id,
            category: body?.category?.id,
            id_item: body?.id_item?.id,
          },
        ],
      };
    },
  },
  exportData: {
    path: '/export-data',
    method: 'POST',
    get: null,
    auth: true,
    fallback: null,
    adapter: (body) => ({
      data: {
        ...body,
      },
    }),
  },
};
