export const DutyPaidOptions = [
	{
		id: 'T1',
		label: 'T1',
	},
	{
		id: 'IMPORT',
		label: 'IMPORT',
	},
];

export const ShippingOptions = [
	{
		id: 'INCOMING PARCEL',
		label: 'INCOMING PARCEL',
		color: '#009ab6',
	},
	{
		id: 'OUTGOING PARCEL',
		label: 'OUTGOING PARCEL',
		color: '#009ab6',
	},
	{
		id: 'PARCEL COLLECTION',
		label: 'PARCEL COLLECTION',
		color: '#009ab6',
	},
	{
		id: 'INCOMING CUSTOMS PARCEL',
		label: 'INCOMING CUSTOMS PARCEL',
		color: '#009ab6',
	},
	{
		id: 'STORAGE',
		label: 'STORAGE',
		color: '#009ab6',
	},
];