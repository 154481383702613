import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Router from './router/Router';
import { Provider } from 'react-redux';
import ThemeCustomization from './themes';
import NavigationScroll from './layout/NavigationScroll';
import { store } from './redux/store';

function App() {
  return (
    <Provider store={store}>
        <BrowserRouter>
          <ThemeCustomization>
            <NavigationScroll>
              <Router />
            </NavigationScroll>
          </ThemeCustomization>
        </BrowserRouter>
    </Provider>
  );
}

export default App;
