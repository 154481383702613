export const SearchConf = {
    query: {
        keys: [
            {
                key: 'category',
                operator: '$containsi'
            },
            {
                key: 'item_type_ENG',
                operator: '$containsi'
            },
            {
                key: 'item_type_ITA',
                operator: '$containsi'
            },
            {
                key: 'name_ENG',
                operator: '$containsi'
            },
            {
                key: 'name_ITA',
                operator: '$containsi'
            },
        ]
    },
    placeholder: 'Search Products',
    initialState: {
        value: ''
    },
}