import React, { Fragment, useState, useCallback } from 'react';
import { Paper } from '@mui/material';
import { fetchAPI } from '../../../api/request';
import { useSelector } from 'react-redux';
import ConfirmDialog from '../../common/Confirm.component';
import Loading from '../../common/Loading.component';
import _ from 'lodash';
import TableRowSort from '../../tableSort/TableRowSort.component';
import TableVirtuoso from '../../virtuosoTable/TableVirtuoso.component';
import TableToolbarSelected from '../../table/components/TableToolbarSelected';

const TableVirtuosoItems = ({
  data,
  setSuccessEdit = () => {},
  checked,
  onSelectItem,
  orderById,
  orderType,
  columns,
  children,
  module: { query },
}) => {
  const { jwt } = useSelector((state) => state.login);
  const [editable, setEditable] = useState(null);
  const [value, setValue] = useState(false);
  const [currentState, setCurrentState] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [orderBy, setOrderBy] = useState(orderById);
  const [order, setOrder] = useState(orderType);
  const sortedData = _.orderBy(data, [orderBy], [order]);

  const handleSort = (columnId) => {
    const isAsc = orderBy === columnId && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(columnId);
  };

  const onChange = (value, name, id) => {
    setCurrentState({
      ...currentState,
      [name]: { ...currentState?.[name], [id]: value ? value : null },
    });

    onSelectItem({
      id: id,
      status: false,
    });
  };

  const handleClose = useCallback(() => {
    setOpen(false);
    setValue(null);
  }, []);

  const handleOpen = useCallback((item) => {
    setOpen(true);
    setValue(item);
  }, []);

  // eslint-disable-next-line
  const onSuccess = (item) => {
    setLoading(false);
    setEditable({ ...editable, [item?.id]: false });
    handleClose();
  };

  const onError = useCallback(() => {
    setLoading(false);
  }, []);

  const onSaveAction = useCallback(
    (item) => {
      setLoading(true);
      const data = {
        quantity: currentState?.quantity?.[item?.id],
        available: currentState?.available?.[item?.id],
        quantity_type: currentState?.quantity_type?.[item?.id],
        category: currentState?.category?.[item?.id],
        note: currentState?.note?.[item?.id],
        internal_note: currentState?.internal_note?.[item?.id],
      };

      fetchAPI(query.edit.settings, { id: item.id }, '', { ...data }, jwt)
        .then(() => onSuccess(item))
        .catch(onError);
    },
    [currentState, onSuccess, jwt, onError, query]
  );

  const onDeleteAction = useCallback(() => {
    setSuccessEdit(false);
    fetchAPI(query.delete.settings, { id: value.id }, '', {}, jwt)
      .then(() => {
        onSuccess();
        setSuccessEdit(true);
        onSelectItem({
          id: value?.id,
          status: false,
        });
      })
      .catch(onError);
  }, [setSuccessEdit, onSuccess, jwt, onError, value, onSelectItem, query]);

  const handleEditable = (item) => {
    setEditable({ ...editable, [item?.id]: true });
  };

  return (
    <Fragment>
      {loading && <Loading />}
      <TableToolbarSelected
        numSelected={
          checked &&
          Object.keys(checked).filter((key) => !!checked[key])?.length
        }
      />
      <Paper style={{ height: 450, width: '100%' }}>
        <TableVirtuoso
          data={sortedData}
          fixedHeaderContent={() => (
            <TableRowSort
              columns={columns}
              onSort={handleSort}
              order={order}
              orderBy={orderBy}
            />
          )}
          itemContent={(_index, item) => (
            <>
              {React.Children.map(children, (child) =>
                React.cloneElement(child, {
                  item,
                  onChange,
                  editable,
                  currentState,
                  checked,
                  onSelectItem,
                  handleEditable,
                  onSaveAction,
                  handleOpen,
                })
              )}
            </>
          )}
        />
      </Paper>

      <ConfirmDialog
        open={open}
        onClose={handleClose}
        title='Are you sure you want to delete this?'
        description='It will be deleted permanently. You cannot undo this action.'
        cancelAction={handleClose}
        confirmAction={onDeleteAction}
      />
    </Fragment>
  );
};
export default TableVirtuosoItems;
