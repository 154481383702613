import React, { useMemo } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Badge } from '@mui/material';
import _ from 'lodash';
import Button from '../../common/Button.component';

const FilterButton = ({ filters, showFilter, onClick }) => {
  const badgeContent = useMemo(
    () =>
      _.remove(_.compact(_.values(filters)), (value) =>
        _.isArray(value) ? !_.isEmpty(value) : value
      ).length,
    [filters]
  );

  return (
    <Badge
      badgeContent={badgeContent}
      color='error'
      sx={{ width: { xs: 100, sm: 'auto' } }}
    >
      <Button
        variant={'outlined'}
        startIcon={
          showFilter ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
        }
        onClick={onClick}
        width='100%'
      >
        Filters
      </Button>
    </Badge>
  );
};

export default FilterButton;
