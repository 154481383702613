import React from 'react';
import {
  Autocomplete,
  Box,
  Container,
  Grid,
  Paper,
  Stack,
  TextField,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchAPI } from '../../../api/request';
import Alert from '../../../components/common/Alert.component';
import Back from '../../../components/common/Back.component';
import Loading from '../../../components/common/Loading.component';
import AddView from '../../../views/Add.view';
import { UsersType } from '../../../module/contacts/conf/Data.conf';
import users_nautica from '../../../module/users_nautica';
import users_crew from '../../../module/users_crew';
import _ from 'lodash';

const ContactsAddPage = ({module:{tag,create}}) => {
  const { jwt } = useSelector((state) => state.login);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [userTypeSelect, setUserTypeSelect] = useState({
    label: 'Nautica',
    id: 'Nautica',
  });

  const handleChangeUserType = useCallback((newValue) => {
    setUserTypeSelect(newValue);
  }, []);

  const onError = useCallback((error) => {
    setSuccess(false);
    setLoading(false);
    setError(error);
    // eslint-disable-next-line
  }, []);

  const onSuccess = useCallback(() => {
    setSuccess(true);
    setError(false);
    setLoading(false);
  }, [setSuccess, setError, setLoading]);

  const handleCreateUserNa = useCallback(
    (values) => {
      const data = {
        ...values,
        id_department: values?.id_department,
        subrole: values?.subrole,
      };
      fetchAPI(
        { path: '/users-nautica-departments', method: 'POST' },
        {},
        '',
        { data: { ...data } },

        jwt
      )
        .then(() => {
          onSuccess();
        })
        .catch(onError);
    },
    [jwt, onError, onSuccess]
  );

  const onSubmit = useCallback(
    (values) => {
      setSuccess(false);
      setError(false);
      setLoading(true);
      switch (_.toLower(userTypeSelect?.id)) {
        case 'nautica':
          fetchAPI(
            users_nautica.query.create.settings,
            {},
            '',
            { ...values },
            jwt
          )
            .then((resp) => {
              handleCreateUserNa({
                id_users_nautica: resp.data.id,
                ...values,
              });
            })
            .catch(onError);
          break;
        case 'crew':
          fetchAPI(users_crew.query.create.settings, {}, '', { ...values }, jwt)
            .then(() => {
              onSuccess();
            })
            .catch(onError);
          break;
        default:
          break;
      }
    },
    [setLoading, jwt, onError, handleCreateUserNa, onSuccess, userTypeSelect]
  );

  return (
    <Container disableGutters={true} maxWidth={false} sx={{ height: '100%' }}>
      <Stack
        spacing={2}
        sx={{ padding: 2 }}
        justifyContent='center'
        alignItems='center'
      >
        <Back title={tag} />
        <Container>
          {loading && <Loading />}
          {success && (
            <Alert severity='success'>{'Contact added successfully'}</Alert>
          )}
          {error && (
            <Alert severity='error'>
              {error?.response?.data?.error.message
                ? error?.response?.data?.error.message
                : 'An error has occurred'}
            </Alert>
          )}
          <Paper variant='outlined'>
            <Box sx={{ padding: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Autocomplete
                    disablePortal
                    options={UsersType}
                    isOptionEqualToValue={(option, value) =>
                      option.label === value.label
                    }
                    getOptionLabel={(option) => option?.label || ''}
                    defaultValue={userTypeSelect}
                    disabled
                    onChange={(_, newValue) => {
                      handleChangeUserType(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label='User Type' />
                    )}
                  />
                </Grid>
              </Grid>
              {userTypeSelect && (
                <AddView
                  conf={create.conf[userTypeSelect?.id]}
                  disabled={loading || success}
                  onAddSubmit={onSubmit}
                  onAddError={onError}
                  onAddSuccess={onSuccess}
                />
              )}
            </Box>
          </Paper>
        </Container>
      </Stack>
    </Container>
  );
};

export default ContactsAddPage;
