import React from "react";
import { useSelector } from "react-redux";
import Read from "../components/crud/Read";
import { useList } from "../core/list";

const ListView = ({
    list,
    onClick = () => { },
    onSelectItem= () => {},
    selected,
    onClickToolbar = () => { }
}) => {

    const { conf: { rowsPerPageOptions, columns, render, toolbar }, actions: { pagination: paginationAction, sort: sortAction }, selector } = list
    const { handleChangePagination, handleChangeSort } = useList(paginationAction, sortAction)

    const { data, meta, pagination, sort } = useSelector(selector.data)

    return (
        <Read
            data={data}
            total={meta?.pagination ? meta.pagination.total : 0}
            pagination={pagination}
            sort={sort}
            onChangeSort={handleChangeSort}
            onChangePagination={handleChangePagination}
            onClick={onClick}
            columns={columns}
            render={render}
            rowsPerPageOptions={rowsPerPageOptions}
            onSelectItem={onSelectItem}            
            toolbar={toolbar}
            selected={selected}
            onClickToolbar={onClickToolbar}
        />
    )
}

export default ListView