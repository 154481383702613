import Text from '../../../components/detail/components/Text.component';
import { green, red } from '@mui/material/colors';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

export const DetailConf = {
  initialState: {
    loading: false,
    error: null,
    data: null,
    meta: null,
  },
  fields: [
    {
      id: 'user_type',
      type: 'section',
      label: 'User Type',
      xs: 12,
      items: [
        { type: 'text', label: 'Type', key: 'role.name', xs: 12, sm: 4 },
        {
          type: 'text',
          label: 'Disabled',
          key: 'blocked',
          xs: 12,
          sm: 8,
          md: 4,
        },
        {
          type: 'text',
          label: 'Department',
          key: 'id_users_nautica.id_users_nautica_departments[0].id_department.name_department',
          xs: 12,
          sm: 6,
          md: 4,
        },
        {
          type: 'text',
          label: 'Role',
          key: 'id_users_role.role',
          xs: 12,
          sm: 6,
          md: 4,
        },
        {
          type: 'text',
          label: 'Subrole',
          key: 'id_users_nautica.id_users_nautica_departments[0].subrole',
          xs: 12,
          sm: 6,
          md: 4,
        },
      ],
    },
    {
      id: 'contact_section',
      type: 'section',
      label: 'Contact',
      xs: 12,
      items: [
        { type: 'text', label: 'Name', key: 'name', xs: 12, sm: 6 },
        { type: 'text', label: 'Surname', key: 'surname', xs: 12, sm: 6 },
        { type: 'text', label: 'Email', key: 'email', xs: 12, sm: 6 },
        { type: 'text', label: 'Username', key: 'username', xs: 12, sm: 6 },
        {
          type: 'text',
          label: 'Phone',
          key: 'id_users_nautica.phone_number',
          xs: 12,
          sm: 6,
        },
        {
          type: 'text',
          label: 'Extension Number',
          key: 'id_users_nautica.extension_number',
          xs: 12,
          sm: 6,
        },
        {
          type: 'text',
          label: 'Site',
          key: 'id_users_nautica.id_site.description_site',
          xs: 12,
          sm: 6,
        },
      ],
    },
  ],
  formatValue: (key, value) => {
    switch (key) {
      case 'blocked':
        return (
          <Text
            label='Status'
            value={value ? <CancelIcon sx={{ color: red[500] }} /> : <CheckCircleIcon sx={{ color: green[500] }} />}
          />
        );
      default:
        return null;
    }
  },
};
