export const API = {
  selectAD: {
    path: '/arrival-departure-formalities?filters[reservation_type]=ARRIVAL %26 DEPARTURE FORMALITIES&pagination[page]=1&pagination[pageSize]=1000',
    method: 'GET',
    get: 'data',
    auth: true,
    fallback: [],
    transform: (data) =>
      data.map((item) => ({
        name: item.attributes.value,
        id: item.id,
      })),
  },
  selectCashDeclaration: {
    path: '/arrival-departure-formalities?filters[reservation_type]=CASH DECLARATION&pagination[page]=1&pagination[pageSize]=1000',
    method: 'GET',
    get: 'data',
    auth: true,
    fallback: [],
    transform: (data) =>
      data.map((item) => ({
        name: item.attributes.value,
        id: item.id,
      })),
  },
  selectImmigrationFormalities: {
    path: '/arrival-departure-formalities?filters[reservation_type]=IMMIGRATION FORMALITIES&pagination[page]=1&pagination[pageSize]=1000',
    method: 'GET',
    get: 'data',
    auth: true,
    fallback: [],
    transform: (data) =>
      data.map((item) => ({
        name: item.attributes.value,
        id: item.id,
      })),
  },
  selectSanification: {
    path: '/arrival-departure-formalities?filters[reservation_type]=SANITATION FORMALITIES&pagination[page]=1&pagination[pageSize]=1000',
    method: 'GET',
    get: 'data',
    auth: true,
    fallback: [],
    transform: (data) =>
      data.map((item) => ({
        name: item.attributes.value,
        id: item.id,
      })),
  },
};
