import React from 'react';
import TextField from '../form/components/TextField.component';
import Autocomplete from '../form/components/Autocomplete.component';
import { RegistryTypes, Types } from '../../module/yachts/conf/Data.conf';
import AsyncAutocomplete from '../form/components/AsyncAutocomplete.component';
import { Grid } from '@mui/material';
import { API as flags } from '../../module/flag';
import Divider from '../form/components/Divider.component';

const YachtAllFieldsForm = () => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Divider label='Yacht' />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          name={'yacht_name'}
          id={'yacht_name'}
          label={'Yacht name'}
          required={true}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Autocomplete
          name={'yacht_type'}
          id={'yacht_type'}
          label={'Yacht type'}
          required={true}
          options={Types}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          name={'loa'}
          id={'loa'}
          label={'Loa (m)'}
          type='number'
          required={true}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name={'beam'}
          id={'beam'}
          label={'Beam (m)'}
          type='number'
          required={true}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name={'draft'}
          id={'draft'}
          label={'Draft (m)'}
          type='number'
          required={true}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name={'gross_tonn'}
          id={'gross_tonn'}
          label={'Gross tonn'}
          type='number'
          required={true}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Autocomplete
          name={'registry_type'}
          id={'registry_type'}
          label={'Registry'}
          required={true}
          options={RegistryTypes}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name={'port_of_registry'}
          id={'port_of_registry'}
          label={'Port Of Registry'}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <AsyncAutocomplete
          name={'id_flag'}
          id={'id_flag'}
          label={'Flag'}
          required={true}
          api={flags.select}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField name={'note'} id={'note'} label={'Note'} type='text' />
      </Grid>
    </Grid>
  );
};

export default YachtAllFieldsForm;
