import * as yup from 'yup';
import _ from 'lodash';

import { SanificationConf } from './common/Sanification.conf';
import { CashDeclarationConf } from './common/CashDeclaration.conf';
import { ArrivalDepartureConf } from './common/ArrivalDeparture.conf';
import { PilotiINConf } from './common/PilotIN.conf';
import { ImmigrationFormalitiesConf } from './common/Immigration.conf';
import { GarbageConf } from './common/Garbage.conf';
import { GenericConf } from './common/Generic.conf';
import { PilotiOUTConf } from './common/PilotOUT.conf';
import { BerthBuoyConf } from './common/BerthBuoy.conf';
import { ServiceConf } from './common/Service.conf';
import { OperationCustomConf } from './common/OperationCustom.conf';

export const UpdateConf = {
  'ARRIVAL & DEPARTURE FORMALITIES': {
    schema: yup.object({
      ...ArrivalDepartureConf.schema,
      ...ServiceConf.schema,
      ...OperationCustomConf.schema,
    }),
    defaultValues: {
      ...ArrivalDepartureConf.defaultValues,
      ...ServiceConf.defaultValues,
      ...OperationCustomConf.defaultValues,
    },
    fields: _.concat(
      ArrivalDepartureConf.fields,
      ServiceConf.fields,
      OperationCustomConf.fields
    ),
  },
  'IMMIGRATION FORMALITIES': {
    schema: yup.object({
      ...ImmigrationFormalitiesConf.schema,
      ...ServiceConf.schema,

      ...OperationCustomConf.schema,
    }),
    defaultValues: {
      ...ImmigrationFormalitiesConf.defaultValues,
      ...ServiceConf.defaultValues,
      ...OperationCustomConf.defaultValues,
    },
    fields: _.concat(
      ImmigrationFormalitiesConf.fields.slice(0, 2),
      ServiceConf.fields,
      OperationCustomConf.fields.slice(0, 3),
      ImmigrationFormalitiesConf.fields.slice(2),
      OperationCustomConf.fields.slice(3)
    ),
  },
  'GARBAGE DISPOSAL': {
    schema: yup.object({
      ...GarbageConf.schema,
      ...ServiceConf.schema,
      ...OperationCustomConf.schema,
    }),
    defaultValues: {
      ...GarbageConf.defaultValues,
      ...ServiceConf.defaultValues,
      ...OperationCustomConf.defaultValues,
    },
    fields: _.concat(
      GarbageConf.fields.slice(0, 1),
      ServiceConf.fields,
      OperationCustomConf.fields.slice(0, 3),
      GarbageConf.fields.slice(1),
      OperationCustomConf.fields.slice(3)
    ),
  },
  'PILOTS IN': {
    schema: yup.object({
      ...PilotiINConf.schema,
      ...ServiceConf.schema,
      ...OperationCustomConf.schema,
    }),
    defaultValues: {
      ...PilotiINConf.defaultValues,
      ...ServiceConf.defaultValues,
      ...OperationCustomConf.defaultValues,
    },
    fields: _.concat(
      PilotiINConf.fields.slice(0, 1),
      ServiceConf.fields,
      OperationCustomConf.fields.slice(0, 3),
      PilotiINConf.fields.slice(1),
      OperationCustomConf.fields.slice(3)
    ),
  },

  'PILOTS OUT': {
    schema: yup.object({
      ...PilotiOUTConf.schema,
      ...ServiceConf.schema,
      ...OperationCustomConf.schema,
    }),
    defaultValues: {
      ...PilotiOUTConf.defaultValues,
      ...ServiceConf.defaultValues,
      ...OperationCustomConf.defaultValues,
    },
    fields: _.concat(
      PilotiOUTConf.fields.slice(0, 1),
      ServiceConf.fields,
      OperationCustomConf.fields.slice(0, 3),
      PilotiOUTConf.fields.slice(1),
      OperationCustomConf.fields.slice(3)
    ),
  },
  'BERTH RESERVATION': {
    schema: yup.object({
      ...BerthBuoyConf.schema,
      ..._.omit(ServiceConf.schema, 'service_date'),
      ...OperationCustomConf.schema,
    }),
    defaultValues: {
      ...BerthBuoyConf.defaultValues,
      ..._.omit(ServiceConf.defaultValues, 'service_date'),
      ...OperationCustomConf.defaultValues,
    },
    fields: _.concat(
      BerthBuoyConf.fields.slice(0, 1),
      ServiceConf.fields.slice(0, 1),
      BerthBuoyConf.fields.slice(1),
      OperationCustomConf.fields.slice(2, 3),
      OperationCustomConf.fields.slice(0, 2),
      OperationCustomConf.fields.slice(3)
    ),
  },

  'BUOY RESERVATION': {
    schema: yup.object({
      ...BerthBuoyConf.schema,
      ...ServiceConf.schema,
      ...OperationCustomConf.schema,
    }),
    defaultValues: {
      ...BerthBuoyConf.defaultValues,
      ...ServiceConf.defaultValues,
      ...OperationCustomConf.defaultValues,
    },
    fields: _.concat(
      BerthBuoyConf.fields.slice(0, 1),
      ServiceConf.fields,
      OperationCustomConf.fields.slice(0, 3),
      BerthBuoyConf.fields.slice(1),
      OperationCustomConf.fields.slice(3)
    ),
  },
  'GENERIC SERVICE': {
    schema: yup.object({
      ...GenericConf.schema,
      ...ServiceConf.schema,
      ...OperationCustomConf.schema,
    }),
    defaultValues: {
      ...GenericConf.defaultValues,
      ...ServiceConf.defaultValues,
      ...OperationCustomConf.defaultValues,
    },
    fields: _.concat(
      GenericConf.fields.slice(0, 1),
      ServiceConf.fields,
      OperationCustomConf.fields.slice(0, 3),
      GenericConf.fields.slice(1),
      OperationCustomConf.fields.slice(3)
    ),
  },
  'CASH DECLARATION': {
    schema: yup.object({
      ...CashDeclarationConf.schema,
      ...ServiceConf.schema,
      ...OperationCustomConf.schema,
    }),
    defaultValues: {
      ...CashDeclarationConf.defaultValues,
      ...ServiceConf.defaultValues,
      ...OperationCustomConf.defaultValues,
    },
    fields: _.concat(
      CashDeclarationConf.fields,
      ServiceConf.fields,
      OperationCustomConf.fields
    ),
  },
  'SANITATION FORMALITIES': {
    schema: yup.object({
      ...SanificationConf.schema,
      ...ServiceConf.schema,
      ...OperationCustomConf.schema,
    }),
    defaultValues: {
      ...SanificationConf.defaultValues,
      ...ServiceConf.defaultValues,
      ...OperationCustomConf.defaultValues,
    },
    fields: _.concat(
      SanificationConf.fields,
      ServiceConf.fields,
      OperationCustomConf.fields
    ),
  },
};
