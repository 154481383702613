import dayjs from 'dayjs';
import _ from 'lodash';
import * as yup from 'yup';

const CommonConf = {
  schema: {
    yacht_contact: yup
      .string()
      .nullable()
      .required('Yacht contact is required'),
    location: yup.string().nullable().required('Location is required'),
    time_in: yup.date().nullable(),
    time_out: yup.date().nullable(),
    users_technicians: yup.string().nullable(),
    work_description: yup
      .string()
      .nullable()
      .required('Work description is required'),
    work_note: yup.string().nullable(),
  },
  defaultValues: {
    yacht_contact: '',
    location: '',
    time_in: null,
    time_out: null,
    users_technicians: '',
    work_description: '',
    work_note: '',
  },
  fields: [
    {
      id: 'yacht_contact',
      name: 'yacht_contact',
      type: 'text',
      label: 'Yacht contact',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'users_technicians',
      name: 'users_technicians',
      type: 'text',
      label: 'User technicians',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'location',
      name: 'location',
      type: 'text',
      label: 'Location',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'time_in',
      name: 'time_in',
      type: 'time',
      label: 'Time in',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'time_out',
      name: 'time_out',
      type: 'time',
      label: 'Time out',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'work_description',
      name: 'work_description',
      type: 'text_multiline',
      label: 'Work description',
      required: true,
      xs: 12,
      minHeight: 100
    },
    {
      id: 'work_note',
      name: 'work_note',
      type: 'text_multiline',
      label: 'Work note',
      required: false,
      xs: 12,
      minHeight: 100
    },
  ],
};
export const UpdateTechWorkConf = {
  schema: {},
  defaultValues: {},
  fields: [
    {
      id: 'technical_divider',
      type: 'divider',
      label: 'Report',
      xs: 12,
    },
    {
      id: 'report_number',
      name: 'report_number',
      type: 'text',
      label: 'Report number',
      required: true,
      disabled: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'work_date',
      name: 'work_date',
      type: 'date',
      label: 'Work date',
      required: true,
      disabled: true,
      xs: 12,
      sm: 6,
    },
  ],
};

export const UpdateNewTechWorkConf = {
  schema: {
    work_date: yup.date().nullable().required('Work date is required'),
    yacht_crew_signature: yup
      .mixed()
      .nullable()
      .required('Yacht signature is required'),
    na_tech_signature: yup
      .mixed()
      .nullable()
      .required('NA tech signature is required'),
  },
  defaultValues: {
    work_date: dayjs(),
    yacht_crew_signature: null,
    na_tech_signature: null,
  },
  fields: [
    {
      id: 'technical_divider',
      type: 'divider',
      label: 'Report',
      xs: 12,
    },
    {
      id: 'report_number',
      name: 'report_number',
      type: 'text',
      label: 'Report number',
      required: true,
      disabled: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'work_date',
      name: 'work_date',
      type: 'date',
      label: 'Work date',
      required: true,
      xs: 12,
      sm: 6,
    },

    {
      id: 'signature_divider',
      type: 'divider',
      label: 'Signature',
      xs: 12,
    },
    {
      id: 'yacht_crew_signature',
      name: 'yacht_crew_signature',
      type: 'draw',
      title: 'A tech signature ( please draw your signature here )*',
      required: true,
      xs: 6,
    },
    {
      id: 'na_tech_signature',
      name: 'na_tech_signature',
      type: 'draw',
      title: 'NA tech signature ( please draw your signature here )*',
      required: true,
      xs: 6,
    },
  ],
};
export const UpdateConf = {
  technical_work: {
    schema: yup.object({
      ...CommonConf.schema,
      ...UpdateTechWorkConf.schema,
    }),
    defaultValues: {
      ...CommonConf.defaultValues,
      ...UpdateTechWorkConf.defaultValues,
    },
    fields: _.concat(UpdateTechWorkConf.fields, CommonConf.fields),
  },
  new_technical_work: {
    schema: yup.object({
      ...CommonConf.schema,
      ...UpdateNewTechWorkConf.schema,
    }),
    defaultValues: {
      ...CommonConf.defaultValues,
      ...UpdateNewTechWorkConf.defaultValues,
    },
    fields: _.concat(
      UpdateNewTechWorkConf.fields.slice(0, 3),
      CommonConf.fields,
      UpdateNewTechWorkConf.fields.slice(3)
    ),
  },
};
