import React, { useCallback } from 'react';
import { Box, Stack } from '@mui/material';
import { CalendarConf } from '../../../module/booking/conf/Calendar.conf';
import { useNavigate } from 'react-router-dom';

const EventComponent = ({ event, date }) => {
  const navigate = useNavigate();
  const statusColor =
    event && CalendarConf.render.eventPropGetter(event)?.style;

  const handleOnClick = useCallback(() => {
    navigate(`${event?.id}`, { state: { from: 'event' } });
    localStorage.setItem('selectedDate', date);
  }, [navigate, event, date]);

  return (
    <Box
      key={event.id}
      p={0.5}
      bgcolor={statusColor.backgroundColor}
      borderRadius='8px'
      m={0.5}
      onClick={handleOnClick}
      sx={{ cursor: 'pointer' }}
    >
      <Stack direction='row' spacing={1} useFlexGap color={statusColor.color}>
        {event?.label}
      </Stack>
    </Box>
  );
};

export default EventComponent;
