import React from 'react';
import MuiCardMedia from '@mui/material/CardMedia';
import MuiCard from '@mui/material/Card';

const CardMedia = ({ image, alt = 'item', height = 100, width = 80 }) => {
  return (
    <MuiCard>
      <MuiCardMedia
        component='img'
        alt={alt}
        height={height}
        width={width}
        image={image}
      />
    </MuiCard>
  );
};

export default CardMedia;
