import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchAPI } from '../../../../../../api/request';
import { Box, Typography } from '@mui/material';

const ReleaseVersionContainer = () => {
  const { jwt } = useSelector((state) => state.login);

  const [versionBE, setVersioneBE] = useState(null);

  useEffect(() => {
    fetchAPI(
      {
        path: `/get-release-version`,
        method: 'GET',
        auth: true,
      },
      {},
      '',
      {},
      jwt
    )
      .then((resp) => setVersioneBE(resp))
      .catch((error) => console.log(error));
  }, [jwt]);

  return (
    <Box
      p={1}
      pb={2}
      alignItems='center'
      justifyContent='center'
      width='100%'
      display='flex'
      flexDirection='column'
    >
      {process.env.REACT_APP_RELEASE_VERSION && (
        <Typography variant='caption'>
          {`FE version ${process.env.REACT_APP_RELEASE_VERSION.replace(
            /[staging\-v]/g,
            ''
          )}`}
        </Typography>
      )}
      {versionBE && versionBE?.release_version && (
        <Typography variant='caption'>
          {`BE version ${versionBE?.release_version}`}
        </Typography>
      )}
    </Box>
  );
};

export default ReleaseVersionContainer;
