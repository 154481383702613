import _ from 'lodash';

export const API = {
  all: {
    path: '/formalities-types?pagination[page]=1&pagination[pageSize]=1000&sort[label]=asc',
    method: 'GET',
    get: null,
    auth: true,
    fallback: null,
    transform: ({ data }) => {
      const obj = _.uniqBy(data, 'attributes.label');
      return obj.map((item) => ({
        name: item.attributes.label,
        id: item.attributes.label,
      }));
    },
  },
};
