import React, { useCallback } from 'react';
import Delete from '../components/crud/Delete';

const DeleteView = ({ onDeleteSubmit = () => { }, disabled,children }) => {
    const onSubmit = useCallback(() => {
        onDeleteSubmit();
    }, [onDeleteSubmit]);

    return <Delete onDelete={onSubmit} disabled={disabled}>{children}</Delete>;
};

export default DeleteView;
