export const API = {
  create: {
    path: '/users-crews',
    method: 'POST',
    get: null,
    auth: true,
    fallback: null,
  },
  update: {
    path: '/users-crews/{{id}}',
    method: 'PUT',
    get: null,
    auth: true,
    fallback: null,
  },
};
