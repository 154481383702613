// material-ui
import { createTheme } from '@mui/material/styles';

import { grey } from '@mui/material/colors';

// ==============================|| DEFAULT THEME - PALETTE  ||============================== //

const Palette = () => {
  return createTheme({
    palette: {
      mode: 'light',
      common: {
        black: '#111936',
      },
      primary: {
        light: '#e8eaf6',
        main: '#3f51b5',
        dark: '#3949ab',
        200: '#9fa8da',
        800: '#283593',
        400: '#5c6bc0',
      },
      secondary: {
        light: '#e8eaf6',
        main: '#3f51b5',
        medium: '#8CB9D4',
        medium_20: '#f0f5f7',
        medium_30: '#bcd7e8',
        dark: '#3949ab',
        200: '#9fa8da',
        800: '#283593',
      },
      yellow: {
        main: '#ffb74d',
        contrastText: 'rgba(255, 255, 255)',
      },
      cyan: {
        main: '#6397d0',
        contrastText: 'rgba(255, 255, 255)',
      },
      waiting: {
        light: '#FFFF00',
        main: '#FFFF00',
        dark: '#FFFF00',
        contrastText: 'rgba(0, 0, 0, 0.87)',
      },
      error: {
        light: '#ef9a9a;',
        main: '#f44336',
        dark: '#c62828',
      },
      orange: {
        light: '#fbe9e7',
        main: '#f16f20',
        dark: '#d84315',
      },
      text: {
        primary: grey[700],
        secondary: '#7a7c7d',
        dark: grey[900],
        hint: grey[100],
      },
      divider: grey[200],
      background: {
        paper: '#ffffff',
        default: '#ffffff',
        light_25: '#e8eaf633',
        light_30: '#e8eaf64D',
        light_40: '#8CB9D41A',
      },
    },
  });
};

export default Palette;
