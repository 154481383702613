import React, { useCallback, useEffect } from 'react';
import { Container, Paper, Typography, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../components/common/Loading.component';
import { useNavigate, useParams } from 'react-router-dom';
import ListView from '../../views/List.view';
import ToolbarComponent from '../../components/toolbar/Toolbar.component';
import _ from 'lodash';
import { DepartmentsConf } from '../../core/dashboard/conf/Data.conf';

const OperationsList = ({ module: { tag, query, filter, list, search } }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { jwt } = useSelector((state) => state.login);
  const {
    data: { loading },
    list: { selected },
  } = useSelector((state) => state[tag]);
  const { from } = useSelector((state) => state.year);

  const _query = useSelector(query.all.selector);
  const userAuth = useSelector((state) => state.login?.user);
  const hasPermissions = userAuth.permissions[_.toUpper(tag)]?.READ === 'true';

  const onClick = useCallback(
    (item) => {
      const fromDepartment = _.toLower(
        item.attributes.id_department.data.attributes.name_department
      );
      const department = _.find(
        DepartmentsConf,
        (item) => _.toLower(item?.id) === fromDepartment
      );

      //REDIRECT TO DEPARTMENT
      if (department && !department.disabled) {
        navigate(`/department/${fromDepartment}/${item.id}`);
      } else {
        navigate(`${item.id}`);
      }
    },
    [navigate]
  );

  useEffect(() => {
    const params = id ? { id } : {};
    if (hasPermissions) {
      dispatch(
        query.all.action({
          settings: query.all.settings,
          token: jwt,
          params,
          query: { ..._query, populate: query.all.populate },
        })
      );
    }
  }, [_query, dispatch, jwt, query, id, hasPermissions, from]);

  const onSelectItem = useCallback(
    (row) => dispatch(list.actions.selected(row.id)),
    [dispatch, list]
  );

  return (
    <Container disableGutters={true} maxWidth={false} sx={{ height: '100%' }}>
      <Box display={'flex'} justifyContent='space-between' padding={3}>
        <Typography variant='h4' sx={{ fontWeight: 'bold' }}>
          {_.upperFirst(tag)}
        </Typography>
      </Box>
      <Paper variant='outlined'>
        <ToolbarComponent
          tag={tag}
          search={search}
          filter={filter}
          add={list?.add}
          link={list?.link}
        />
        {loading && <Loading />}
        {hasPermissions && (
          <ListView
            list={list}
            onClick={onClick}
            onSelectItem={onSelectItem}
            selected={selected}
          />
        )}
      </Paper>
    </Container>
  );
};

export default OperationsList;
