export const API = {
  create: {
    path: '/users-nauticas',
    method: 'POST',
    get: null,
    auth: true,
    fallback: null,
    adapter: (body) => {
      return {
        data: {
          ...body,
          username: body?.username,
          email: body?.email,
          surname: body?.surname,
          name: body?.name,
          id_users_role: body?.id_users_role?.id,
          phone_number: body?.phone_number,
          extension_number: body?.extension_number,
          id_site: body?.id_site,
          id_department: body?.id_department?.id,
        },
      };
    },
  },
  yachts: {
    path: '/yachts',
    method: 'GET',
    get: null,
    auth: true,
    fallback: null,
    transform: (data) =>
      data?.data?.map((item) => ({
        name: item.attributes.yacht_name,
        id: item.id,
      })),
  },
  selectUsersRole: {
    path: '/users-roles',
    method: 'GET',
    get: null,
    auth: true,
    fallback: [],

    transform: (data) => {
      return data?.data?.map((item) => ({
        name: item?.attributes?.role,
        id: item?.id,
      }));
    },
  },

  delete: {
    path: '/users-nauticas/{{id}}',
    method: 'DELETE',
    get: null,
    auth: true,
    fallback: null,
  },

  selectUsersSite: {
    path: '/sites',
    method: 'GET',
    get: null,
    auth: true,
    fallback: [],
    transform: (data) => {
      return data?.data?.map((item) => ({
        name: item?.attributes?.description_site,
        id: item?.id,
      }));
    },
  },
  selectName: {
    path: '/users-nauticas?populate[id_users_permissions_user][fields]=%2A&pagination[page]=1&pagination[pageSize]=1000&sort[username_mexal]=asc&filters[id_users_permissions_user][blocked]=false',
    method: 'GET',
    get: null,
    auth: true,
    fallback: [],
    transform: (data) =>
      data?.data?.map((item) => ({
        name: `${item.attributes?.id_users_permissions_user?.data?.attributes?.name} ${item.attributes?.id_users_permissions_user?.data?.attributes?.surname}`,
        id: item.id,
      })),
  },
  drivers: {
    path: '/users-nauticas?populate[id_users_permissions_user][fields]=%2A&pagination[page]=1&pagination[pageSize]=1000&sort[username_mexal]=asc&filters[id_users_permissions_user][blocked]=false&filters[id_users_nautica_departments][id_department][short_description][$eq]=DR',
    method: 'GET',
    get: null,
    auth: true,
    fallback: [],
    transform: (data) =>
      data?.data?.map((item) => ({
        name: `${item.attributes?.id_users_permissions_user?.data?.attributes?.name} ${item.attributes?.id_users_permissions_user?.data?.attributes?.surname}`,
        id: item.id,
      })),
  },
  deliveries: {
    path: '/users-nauticas?populate[id_users_permissions_user][fields]=%2A&pagination[page]=1&pagination[pageSize]=1000&sort[username_mexal]=asc&filters[id_users_permissions_user][blocked]=false&filters[id_users_nautica_departments][id_department][short_description][$eq]=DEL',
    method: 'GET',
    get: null,
    auth: true,
    fallback: [],
    transform: (data) =>
      data?.data?.map((item) => ({
        name: `${item.attributes?.id_users_permissions_user?.data?.attributes?.name} ${item.attributes?.id_users_permissions_user?.data?.attributes?.surname}`,
        id: item.id,
      })),
  },
};
