import * as yup from 'yup';
import dayjs from 'dayjs';

export const CreateConf = {
  schema: yup.object({
    username: yup.string().nullable().required('Username  is required'),
    email: yup
      .string()
      .nullable()
      .email('Please enter a valid email')
      .required('Email is required'),

    country_code: yup.object().nullable(),
    phone_number: yup.string().required('Phone number is required'),
    name: yup.string().nullable().required('Name is required'),
    surname: yup.string().nullable().required('Last name is required'),
    birthdate: yup
      .date()
      .nullable()
      .required('Birth Date is required')
      .transform((value, originalValue) => {
        const parsedDate = dayjs(originalValue, 'YYYY-MM-DD', true);
        return parsedDate?.isValid() ? parsedDate.toDate() : null;
      }),
  }),
  defaultValues: {
    username: '',
    name: '',
    surname: '',
    email: '',
    country_code: null,
    phone_number: '',
    birthdate: null,
    crew_responsability: 'Captain',
  },
  fields: [
    {
      id: 'divider_0',
      type: 'divider',
      label: 'Captain',
      xs: 12,
      sm: 12,
    },
    {
      id: 'username',
      name: 'username',
      type: 'text',
      label: 'Username',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'email',
      name: 'email',
      type: 'text',
      label: 'Email',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'name',
      name: 'name',
      type: 'text',
      label: "Captain's First Name",
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'surname',
      name: 'surname',
      type: 'text',
      label: "Captain's Last Name",
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'country_code',
      name: 'country_code',
      type: 'country-autocomplete',
      label: 'Country Code',
      required: false,
      xs: 4,
      sm: 4,
    },
    {
      id: 'phone_number',
      name: 'phone_number',
      type: 'number',
      label: 'Phone Number',
      required: true,
      xs: 8,
      sm: 8,
    },
    {
      id: 'birthdate',
      name: 'birthdate',
      type: 'date',
      label: 'Birth date',
      required: true,
      disableFuture: true,
      xs: 12,
      sm: 12,
    },
  ],
};
