import * as yup from 'yup';
import dayjs from 'dayjs';
import { CrewGuestType } from '../Data.conf';
import { API as marina } from '../../../marina/api';

export const GenericConf = {
  schema: {
    service_date: yup.date().nullable().required('Service date is required'),
    service_time: yup.date().nullable(),
    service_place: yup.string().nullable(),
    id_marina: yup.object().nullable(),
    requested_service_generic: yup
      .string()
      .nullable()
      .required('Request service generic is required'),
    crew_guest: yup.object().nullable().required('Crew / Guest is required'),
    pax_number: yup.string().nullable(),
    id_yacht: yup.object().nullable().required('Yacht Name is required'),
  },
  defaultValues: {
    service_date: dayjs(),
    service_time: null,
    service_place: null,
    requested_service_generic: null,
    crew_guest: null,
    pax_number: null,
    id_marina: null,
  },
  fields: [
    {
      id: 'reservation_divider',
      type: 'divider',
      label: 'Reservation',
      xs: 12,
    },
    {
      id: 'service_place',
      name: 'service_place',
      type: 'text',
      label: 'Service place',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'requested_service_generic',
      name: 'requested_service_generic',
      type: 'text',
      label: 'Requested service generic',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'service_date',
      name: 'service_date',
      type: 'date',
      label: 'Service date',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'service_time',
      name: 'service_time',
      type: 'time',
      label: 'Service time',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'crew_guest',
      name: 'crew_guest',
      type: 'autocomplete',
      label: 'Crew guest',
      options: CrewGuestType,
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'pax_number',
      name: 'pax_number',
      type: 'number',
      label: 'Pax number',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'id_marina',
      name: 'id_marina',
      type: 'async_autocomplete',
      label: 'Location',
      api: marina.select,
      required: false,
      xs: 12,
      sm: 6,
    },
  ],
};
