import React from 'react';
import { TableVirtuoso as ReactTableVirtuoso } from 'react-virtuoso';
import { VirtuosoTableComponents } from './TableComponents.component';

const TableVirtuoso = ({ data, fixedHeaderContent, itemContent, style }) => {
  return (
    <ReactTableVirtuoso
      style={{ ...style }}
      data={data}
      components={VirtuosoTableComponents}
      fixedHeaderContent={fixedHeaderContent}
      itemContent={itemContent}
    />
  );
};
export default TableVirtuoso;
