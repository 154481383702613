import React from 'react';
import MuiFormControlLabel from '@mui/material/FormControlLabel';
import MuiCheckbox from '@mui/material/Checkbox';
import { useController, useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';
import Error from './Error.component';

import PropTypes from 'prop-types';

const Checkbox = ({ name, required, label }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const {
    field: { ref, onChange, ...inputProps },
  } = useController({
    name: name,
    control: control,
    rules: { required },

  });

  return (
    <Box display='flex' flexDirection={'column'}>
      <MuiFormControlLabel
        label={label}
        control={
          <MuiCheckbox
            required={required}
            ref={ref}
            onChange={onChange}
            checked={inputProps.value}
            error={!!errors[name]?.message}
            {...inputProps}
          />
        }
      />
      <Error error={errors[name]?.message} />
    </Box>
  );
};

Checkbox.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
};

export default Checkbox;
