// REDUX
import { reducer, actions } from './slice';

// SELECTOR
import { tag } from './slice';


export { reducer, tag };

const menu = {
    tag,
    query: {
        active: {
            action: actions.activeItem,
        },
        open: {
            action: actions.openDrawer,
        },
    },
};

export default menu;
