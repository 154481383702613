import * as yup from 'yup';

export const CreateConf = {
  schema: yup.object({
    description: yup.string().nullable(),
    document: yup.mixed().nullable().required('Document is required'),
  }),
  defaultValues: {
    description: '',
    document: null,
  },
  fields: [
    {
      id: 'shipping_document_divider',
      type: 'divider',
      label: 'Shipping Documents',
      xs: 12,
    },

    {
      id: 'description',
      name: 'description',
      type: 'text',
      label: 'Description',
      xs: 12,
    },
    {
      id: 'document',
      name: 'document',
      label: 'Document',
      type: 'upload',
      required: true,
	  multiple:false,
      xs: 12,
      sm: 6,
    },
  ],
};
