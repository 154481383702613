import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar } from '@mui/material';
import Header from './header';
import Sidebar from './header/sidebar';
import { Outlet } from 'react-router-dom';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open, drawerwidth }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shorter,
      }),
      [theme.breakpoints.up('md')]: {
        // marginLeft: -(drawerwidth - 20),
        width: `calc(100% - ${drawerwidth}px)`,
      },
      [theme.breakpoints.down('md')]: {
        marginLeft: '20px',
        width: `calc(100% - ${drawerwidth}px)`,
        padding: '16px',
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        width: `calc(100% - ${drawerwidth}px)`,
        padding: '16px',
        marginRight: 0,
      },
    }),
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.shorter,
      }),
      marginLeft: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      width: `calc(100% - ${drawerwidth}px)`,
      [theme.breakpoints.down('md')]: {
        marginLeft: '20px',
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: '10px',
      },
    }),
  })
);

const MainLayout = () => {
  const theme = useTheme();
  const { drawerOpen } = useSelector((state) => state.menu);
  const drawerWidthOpen = 260;
  const drawerWidthClose = 70;
  const header = useMemo(
    () => (
      <Toolbar>
        <Header />
      </Toolbar>
    ),
    []
  );

  // useEffect(() => {
  //     dispatch(menu.query.open.action(!matchDownMd))
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [matchDownMd]);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      {/* header */}
      <AppBar
        enableColorOnDark
        position='fixed'
        color='inherit'
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
          //  transition: drawerOpen ? theme.transitions.create('width') : 'none'
          transition: theme.transitions.create('width'),
        }}
      >
        {header}
      </AppBar>

      {/* drawer */}
      <Sidebar />

      {/* main content */}
      <Main
        theme={theme}
        open={drawerOpen}
        drawerwidth={drawerOpen ? drawerWidthOpen : drawerWidthClose}
      >
        {/* <Container sx={{ padding: 0 }}> */}
        <Outlet />
        {/* </Container> */}
      </Main>
    </Box>
  );
};

export default MainLayout;
