import dayjs from 'dayjs';

export const API = {
  attendances: {
    path: '/attendances?populate[id_agent_NA][populate][id_users_permissions_user][fields][0][1]=name,surname&populate[id_yacht]=%2A&populate[id_user_provision][populate][id_users_permissions_user][fields][0][1]=name,surname&populate[id_user_tech][populate][id_users_permissions_user][fields][0][1]=name,surname&populate[id_provenence_list][populate][provenence_group]=%2A&populate[id_provenence_list][populate][provenence_sales_channel]=%2A&populate[id_provenence_list][populate][provenence]=%2A',
    method: 'GET',
    get: null,
    auth: true,
    fallback: null,
  },
  attendance: {
    path: 'attendances/{{id}}?populate[id_provenence_list][populate][users_nauticas][populate][id_users_permissions_user][fields]=name&populate[id_provenence_list][populate][provenence_group]=%2A&populate[id_provenence_list][populate][provenence_sales_channel]=%2A&populate[id_provenence_list][populate][provenence]=%2A&populate[id_user_provision][populate][id_users_permissions_user][fields]=name,surname&populate[id_yacht][populate][id_flag]=%2A&populate[id_agent_NA][populate][id_users_permissions_user][fields]=name,surname&populate[id_provenence_list][populate][users_nauticas][populate][id_users_permissions_user][fields]=surname&populate[id_user_tech][populate][id_users_permissions_user][fields]=name,surname',
    method: 'GET',
    get: null,
    auth: true,
    fallback: null,
    transform: (data) => ({
      meta: data?.meta,
      data: {
        id: data?.data?.id,
        attributes: {
          ...data?.data?.attributes,
          yacht: {
            yacht_name:
              data?.data?.attributes?.id_yacht?.data?.attributes?.yacht_name,
            url_web:
              data?.data?.attributes?.id_yacht?.data?.attributes?.url_web,
            id: data?.data?.attributes?.id_yacht?.data?.id,
          },
        },
      },
    }),
  },
  update: {
    path: '/attendances/{{id}}',
    method: 'PUT',
    get: null,
    auth: true,
    fallback: null,
    adapter: (body) => ({
      data: {
        ...body,
        registration_date: dayjs(body?.registration_date).format('YYYY-MM-DD'),
      },
    }),
  },
  delete: {
    path: '/attendances/{{id}}',
    method: 'DELETE',
    get: null,
    auth: true,
    fallback: null,
  },
  create: {
    path: '/attendances',
    method: 'POST',
    get: null,
    auth: true,
    fallback: null,
    adapter: (body) => ({
      data: {
        ...body,
        registration_date: dayjs(body?.registration_date).format('YYYY-MM-DD'),
      },
    }),
  },
};
