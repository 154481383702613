// CONF
import { CreateConf } from './conf/Create.conf';
import { UpdateConf } from './conf/Update.conf';
import { ListConf } from './conf/List.conf';
import { FilterConf } from './conf/Filter.conf';
import { SearchConf } from './conf/Search.conf';
import { DetailConf, NewMexalFields, NewTechnicalDetailFields, TechnicalInfoFields } from '../operations/conf/Detail.conf';

// REDUX
import { reducer, actions, detailSelector, editSelector } from './slice';

// SELECTOR
import { tag, querySelector, listSelector } from './slice';

// SAGA
import { saga } from './saga';

// API
import { API } from './api';

export { reducer, saga, tag };

const technical = {
  code: 'TECH',
  tag,
  query: {
    all: {
      selector: querySelector,
      action: actions.fetchAllAction,
      settings: API.technical,
      populate: '*',
    },
    detail: {
      action: actions.fetchDetailAction,
      settings: API.operation,
      populate: '*',
    },
    edit: {
      settings: API.update,
    },
    create: {
      settings: API.create,
      settings2: API.createTechnical,
    },
    delete: {
      settings: API.delete,
    },
  },
  search: {
    conf: SearchConf,
    actions: {
      search: actions.searchChange,
    },
  },
  filter: {
    conf: FilterConf,
    actions: {
      filter: actions.filterChange,
    },
  },
  list: {
    conf: ListConf,
    actions: {
      pagination: actions.paginationChange,
      sort: actions.sortChange,
    },
    selector: {
      data: listSelector,
    },
    add: {
      to: 'add',
    },
  },
  detail: {
    conf: {
      ...DetailConf,
      fields: [
        {
          id: 'info_fields_sections',
          type: 'section',
          label: '',
          items: TechnicalInfoFields
        },
        {
          id: 'new_interiors_service_fields',
          type: 'section',
          label: 'SERVICE',
          items: NewTechnicalDetailFields
        },
        {
          id: 'new_mexal_sections',
          type: 'section',
          label: 'MEXAL',
          items: NewMexalFields
        }
      ],
    },
    selector: {
      data: detailSelector,
    },
    edit: {
      to: 'edit',
    },
    link: {
      to: 'technicalWork/add',
      label: 'Add Report',
      //target: '_blank',
    },
  },
  edit: {
    conf: UpdateConf,
    selector: {
      data: editSelector,
    },
  },
  create: {
    conf: CreateConf,
  },
  remove: {
    selector: {
      data: detailSelector,
    },
    navigate: '/department/technical',
  },
};

export default technical;
