import { StatusOperationBookingType } from '../../../operations/conf/Data.conf';
import { OperationConf as Operation } from '../../../operations/conf/Create.conf';
import _ from 'lodash';

export const OperationCustomConf = {
  schema: _.omit(Operation.schema, [
    'requested_date',
    'status_operation_booking',
  ]),
  defaultValues: {
    ..._.omit(Operation.defaultValues, [
      'requested_date',
      'status_operation_booking',
    ]),
    status_operation_booking: _.find(StatusOperationBookingType, {
      id: 'NEW REQUEST',
    }).id,
  },
  fields: [
    ...Operation.fields.slice(2, 9),

    {
      id: 'status_operation_booking',
      name: 'status_operation_booking',
      type: 'select-custom',
      label: 'Status operation booking',
      required: true,
      options: StatusOperationBookingType,
      xs: 12,
      sm: 6,
    },
    ...Operation.fields.slice(10),
  ],
};
