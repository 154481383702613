import React from 'react';
import { useSelector } from 'react-redux';
import Button from '../../detail/components/Button.component';

const LinkProductsList = ({ label, to, state, page, ...sx }) => {
  const userAuth = useSelector((state) => state.login?.user);
  return (
    <Button
      label={label}
      to={to}
      state={state}
      {...sx}
      disabled={!(userAuth.permissions?.[page]?.PRODUCT_LIST === 'true')}
    />
  );
};

export default LinkProductsList;
