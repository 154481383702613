import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import shipping from '../../../module/shipping';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Stack, Paper, Typography, Box } from '@mui/material';
import Back from '../../../components/common/Back.component';
import Loading from '../../../components/common/Loading.component';
import EditButton from '../../../components/detail/components/EditButton.component';
import TemplateDelete from '../../../pages/templates/Delete.template';
import DetailView from '../../../views/Detail.view';
import _ from 'lodash';
import shippingDocuments from '../../../module/shippingDocuments';
import ShippingDocumentsAddPage from '../../shippingDocuments/add/ShippingDocumentsAdd.page';
import ShippingDocumentsDetailPage from '../../shippingDocuments/detail/ShippingDocumentsDetail.page';
import LastUpdateInfo from '../../../components/lastUpdateInfo/LastUpdateInfo.component';

const ShippingDetailPage = ({ module }) => {
  const { tag, detail, query } = module;
  const { id } = useParams();
  const dispatch = useDispatch();
  const { jwt } = useSelector((state) => state.login);
  const {
    loading: loadingApi,
    data,
    error: errorApi,
  } = useSelector(detail.selector.data);
  const navigate = useNavigate();
  const userAuth = useSelector((state) => state.login?.user);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (userAuth.permissions[_.toUpper(tag)]?.READ === 'true') {
      dispatch(
        query.detail.action({
          settings: query.detail.settings,
          token: jwt,
          params: { id },
          query: { populate: query.detail.populate },
        })
      );
    }

    return () => {
      dispatch(
        query.detail.action({
          settings: null,
          token: null,
          params: null,
          query: null,
        })
      );
    };
    // eslint-disable-next-line
  }, [id, userAuth, success]);

  useEffect(() => {
    if (
      errorApi &&
      (errorApi?.response?.status === 404 || errorApi?.response?.status === 500)
    )
      navigate('/404');
  }, [errorApi, navigate]);

  return (
    <Container disableGutters={true} maxWidth={false} sx={{ height: '100%' }}>
      <Stack spacing={2} justifyContent='center' alignItems='center' width={1}>
        <Back title={tag} />

        {loadingApi && <Loading />}
        <Paper variant='outlined' sx={{ padding: 2, width: '100%' }}>
          <Stack direction={'row'} justifyContent='flex-end' mb={2} spacing={1}>
            {detail?.link && (
              <ShippingDocumentsAddPage
                module={shippingDocuments}
                setSuccessAdd={setSuccess}
                data={data}
              />
            )}
            {detail?.edit &&
              userAuth.permissions[_.toUpper(tag)]?.UPDATE === 'true' && (
                <EditButton to={detail.edit.to} tag={tag} />
              )}

            {userAuth.permissions[_.toUpper(tag)]?.DELETE === 'true' && (
              <TemplateDelete module={shipping}>
                {data?.attributes?.id_shipping?.data?.attributes
                  ?.id_shipping_type?.data?.attributes?.shipping_type && (
                  <Typography component='div' mt={1}>
                    The shipping type
                    <Box fontWeight={'bold'} display='inline'>
                      {` ${data?.attributes?.id_shipping?.data?.attributes?.id_shipping_type?.data?.attributes?.shipping_type} `}
                    </Box>
                    will also be deleted.
                  </Typography>
                )}
              </TemplateDelete>
            )}
          </Stack>

          {userAuth.permissions[_.toUpper(tag)]?.READ === 'true' && (
            <Fragment>
              <DetailView
                data={data}
                selector={detail.selector.data}
                conf={detail.conf}
              />
              <ShippingDocumentsDetailPage
                module={shippingDocuments}
                shippingModule={module}
                setSuccessUpdate={setSuccess}
                successUpdate={success}
              />
            </Fragment>
          )}
          {data?.attributes?.updatedAt && (
            <LastUpdateInfo
              updatedAt={data?.attributes?.updatedAt}
              username={data?.attributes?.id_user_last_updated?.data?.attributes?.id_users_permissions_user?.data?.attributes?.username}
            />
          )}
        </Paper>
      </Stack>
    </Container>
  );
};

export default ShippingDetailPage;
