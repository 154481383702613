import React, { Fragment, useCallback, useEffect } from 'react';
import { Container, Stack, Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Back from '../../components/common/Back.component';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../components/common/Loading.component';
import DetailView from '../../views/Detail.view';
import Button from '../../components/common/Button.component';
import AddIcon from '@mui/icons-material/Add';
import _ from 'lodash';
import EditButton from '../../components/detail/components/EditButton.component';
import LastUpdateInfo from '../../components/lastUpdateInfo/LastUpdateInfo.component';

const DetailTemplate = ({ module: { tag, detail, query }, deleteView }) => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const { jwt } = useSelector((state) => state.login);
	const { loading, data, error } = useSelector(detail.selector.data);
	const navigate = useNavigate();
	const userAuth = useSelector((state) => state.login?.user);

	const AddButton = ({ to, label = 'ADD' }) => {
		const navigate = useNavigate();

		const onClick = useCallback(
			(e) => {
				if (to) navigate(`${to}`, { state: { id: id, from: tag } });
			},
			[navigate, to]
		);

		return (
			<Button variant='outlined' startIcon={<AddIcon />} onClick={onClick}>
				{label}
			</Button>
		);
	};

	useEffect(() => {
		if (userAuth.permissions[_.toUpper(tag)]?.READ === 'true') {
			dispatch(
				query.detail.action({
					settings: query.detail.settings,
					token: jwt,
					params: { id },
					query: { populate: query.detail.populate },
				})
			);
		}

		return () => {
			dispatch(
				query.detail.action({
					settings: null,
					token: null,
					params: null,
					query: null,
				})
			);
		};
		// eslint-disable-next-line
	}, [id, userAuth]);

	useEffect(() => {
		if (error && (error?.response?.status === 404 || error?.response?.status === 500)) navigate('/404');
	}, [error, navigate]);

	return (
		<Container disableGutters={true} maxWidth={false} sx={{ height: '100%' }}>
			<Stack spacing={2} justifyContent='center' alignItems='center' width={1}>
				<Back title={tag} />
				{loading && <Loading />}
				<Paper variant='outlined' sx={{ padding: 2, width: '100%' }}>
					<Stack direction={'row'} justifyContent='flex-end' mb={2} spacing={1}>
						{detail?.link && userAuth.permissions[_.toUpper(tag)]?.LINK === 'true' && (
							<AddButton to={detail.link.to} label={detail.link.label} />
						)}
						{detail?.edit && userAuth.permissions[_.toUpper(tag)]?.UPDATE === 'true' && (
							<EditButton to={detail.edit.to} tag={tag} />
						)}
						{userAuth.permissions[_.toUpper(tag)]?.DELETE === 'true' && <Fragment>{deleteView}</Fragment>}
					</Stack>

					{userAuth.permissions[_.toUpper(tag)]?.READ === 'true' && (
						<DetailView data={data} selector={detail.selector.data} conf={detail.conf} />
					)}
					{data?.attributes?.updatedAt && !['attendances', 'booking', 'contacts'].includes(tag) && (
						<LastUpdateInfo
							updatedAt={data?.attributes?.updatedAt}
							username={data?.attributes?.id_user_last_updated?.data?.attributes?.id_users_permissions_user?.data?.attributes?.username}
						/>

					)}
				</Paper>
			</Stack>
		</Container>
	);
};

export default DetailTemplate;
