import React, { useCallback, useRef } from 'react';
import MuiCard from '@mui/material/Card';
import MuiTypography from '@mui/material/Typography';
import { IconFileDownload } from '@tabler/icons-react';
import { useTheme } from '@mui/material/styles';
import { Stack, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { grey } from '@mui/material/colors';

const DownlaodComponent = ({ value, tag, label }) => {
  const downloadRef = useRef(null);
  const theme = useTheme();
  const { subLabel, title, url } = value;
  const userAuth = useSelector((state) => state.login?.user);
  const hasPermissions =
    tag &&
    userAuth.permissions[tag?.toUpperCase()]?.DOWNLOAD_DOCUMENTS === 'true';
  const onButtonClick = useCallback(() => {
    if (hasPermissions) {
      const base_url = process.env.REACT_APP_BASE_URL.split('/api')[0];
      downloadRef.current.target = '_blank';
      downloadRef.current.href = `${base_url}${url}`;
      downloadRef.current.download = title; // Optional: Rename the file
      downloadRef.current.click();
    }
  }, [url, title, hasPermissions]);

  return (
    <Tooltip title={hasPermissions && `click to download ${label ? label : 'the file'}`}>
      <MuiCard
        sx={{
          background: hasPermissions ? theme.palette.primary[400] : grey[400],
          color: hasPermissions && '#fff',
          boxShadow: 2,
          cursor: hasPermissions && 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: { sx: 'left', sm: 'center' },
        }}
        onClick={onButtonClick}
      >
        <IconFileDownload
          style={{
            color: hasPermissions && '#fff',
            height: 40,
            width: 40,
            opacity: 0.75,
            filter: 'drop-shadow(3px 5px 2px rgb(0 0 0 / 0.3))',
          }}
        />
        <Stack mt={0.5} mb={0.5} width={1}>
          <MuiTypography variant='h4' align='center' color='inherit'>
            {title}
          </MuiTypography>

          {subLabel && (
            <MuiTypography
              variant='body1'
              align='center'
              color='inherit'
              sx={{ fontSize: 12 }}
            >
              {subLabel}
            </MuiTypography>
          )}
        </Stack>

        <a
          ref={downloadRef}
          style={{ visibility: 'hidden', display: 'none' }}
          href={url}
        >
          Download File
        </a>
      </MuiCard>
    </Tooltip>
  );
};

export default DownlaodComponent;

DownlaodComponent.propTypes = {
  value: PropTypes.object,
};
