export const AvailableType = [
  {
    id: 'NEW ITEM',
    label: 'NEW ITEM',
    color: '#fff',
    backgroundColor: '#365576',
  },
  {
    id: 'STAND-BY',
    label: 'STAND-BY',
    color: '#fff',
    backgroundColor: '#ff6500',
  },
  {
    id: 'SEARCHING',
    label: 'SEARCHING',
    color: '#fff',
    backgroundColor: '#f6ca00',
  },
  {
    id: 'IN STOCK',
    label: 'IN STOCK',
    color: '#fff',
    backgroundColor: '#535252',
  },
  {
    id: 'ORDERED',
    label: 'ORDERED',
    color: '#fff',
    backgroundColor: '#00c256',
  },
  { id: 'READY', label: 'READY', color: '#fff', backgroundColor: '#00c256' },
  {
    id: 'DELIVERED',
    label: 'DELIVERED',
    color: '#fff',
    backgroundColor: '#008c40',
  },
  {
    id: 'MISSING ITEM',
    label: 'MISSING ITEM',
    color: '#fff',
    backgroundColor: '#ff001b',
  },
  {
    id: 'CANCELLED',
    label: 'CANCELLED',
    color: '#fff',
    backgroundColor: '#ff001b',
  },
];
