import { combineReducers, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { sagas } from '../sagas/sagas';
import { reducer as BookingReducer, tag as BookingTag } from '../module/booking';
import { reducer as YachtsReducer, tag as YachtsTag } from '../module/yachts';
import { reducer as AttendancesReducer, tag as AttendancesTag } from '../module/attendances';
import { reducer as LoginReducer, tag as LoginTag } from '../module/login';
import { reducer as OperationsReducer, tag as OperationsTag } from '../module/operations';
import { reducer as ConciergeReducer, tag as ConciergeTag } from '../module/concierge';
import { reducer as TechnicalReducer, tag as TechnicalTag } from '../module/technical';
import { reducer as ProvisionsReducer, tag as ProvisionsTag } from '../module/provisions';
import { reducer as DepotReducer, tag as DepotTag } from '../module/depot';
import { reducer as ShippingReducer, tag as ShippingTag } from '../module/shipping';
import { reducer as InteriorsReducer, tag as InteriorsTag } from '../module/interiors';
import { reducer as LogbookReducer, tag as LogbookTag } from '../module/logbook';
import { reducer as AgencyReducer, tag as AgencyTag } from '../module/agency';
import { reducer as ProductsReducer, tag as ProductsTag } from '../module/products';
import { reducer as ListItemsProvisionsReducer, tag as ListItemsProvisionsTag } from '../module/listItemsProvisions';

import { reducer as ContactsReducer, tag as ContactsTag } from '../module/contacts';

import { reducer as TechnicalWorkReducer, tag as TechnicalWorkTag } from '../module/technicalWork';

import { reducer as MeReducer, tag as MeTag } from '../module/me';

import { reducer as MenuReducer, tag as MenuTag } from '../module/menu';
import { reducer as ListItemsDepotReducer, tag as ListItemsDepotTag } from '../module/listItemsDepot';

import { reducer as YearReducer, tag as YearTag } from '../module/year';
import { reducer as ListItemsInteriorsReducer, tag as ListItemsInteriorsTag } from '../module/listItemsInteriors';
import { reducer as ShippingDocumentsReducer, tag as ShippingDocumentsTag } from '../module/shippingDocuments';

const appReducer = combineReducers({
	[TechnicalWorkTag]: TechnicalWorkReducer,
	[LoginTag]: LoginReducer,
	[YachtsTag]: YachtsReducer,
	[BookingTag]: BookingReducer,
	[AttendancesTag]: AttendancesReducer,
	[OperationsTag]: OperationsReducer,
	[ConciergeTag]: ConciergeReducer,
	[TechnicalTag]: TechnicalReducer,
	[ProvisionsTag]: ProvisionsReducer,
	[DepotTag]: DepotReducer,
	[ShippingTag]: ShippingReducer,
	[InteriorsTag]: InteriorsReducer,
	[LogbookTag]: LogbookReducer,
	[AgencyTag]: AgencyReducer,
	[ProductsTag]: ProductsReducer,
	[ListItemsProvisionsTag]: ListItemsProvisionsReducer,
	[ContactsTag]: ContactsReducer,
	[MenuTag]: MenuReducer,
	[MeTag]: MeReducer,
	[YearTag]: YearReducer,
	[ListItemsDepotTag]: ListItemsDepotReducer,
	[ListItemsInteriorsTag]: ListItemsInteriorsReducer,
	[ShippingDocumentsTag]: ShippingDocumentsReducer,
});

const sagaMiddleware = createSagaMiddleware();

const rootReducer = (state, action) => {
	if (action.type === 'USER_LOGOUT') {
		return appReducer(undefined, action);
	}
	return appReducer(state, action);
};

const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}).concat(sagaMiddleware),
});

sagaMiddleware.run(sagas);

export { store };
