import { createSlice } from '@reduxjs/toolkit'

export const detailSlice = (name, initialState) => createSlice({
    name,
    initialState: {...initialState},
    reducers: {
        dataDetailChange: (state, action) => {            
            state.data = action.payload.data
            state.meta = action.payload.meta
            state.loading = false
            state.error = null
        },
        loadingDetailChange: (state) => {
            state.loading = true
            state.error = null
        },
        errorDetailChange: (state, action) => {
            state.loading = false
            state.error = action.error
            state.data = []
            state.meta = null
        },        
    },
})