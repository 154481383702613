import * as yup from 'yup';
import { API as users_nautica } from '../../users_nautica/api';
import dayjs from 'dayjs';

export const UpdateConf = {
  schema: yup.object({
    id_yacht: yup.object().nullable().required('Yacht is required'),
    id_agent_NA: yup.object().nullable().required('Agent NA is required'),
    is_central_agent: yup.boolean().nullable(),
    id_user_provision: yup.object().nullable(),
    id_user_tech: yup.object().nullable(),
    mexal_validation: yup.boolean().nullable(),
    provenence_group: yup
      .object()
      .nullable()
      .required('Provenence group is required'),
    provenence_sales_channel: yup.object().nullable(),
    provenence: yup.object().nullable(),
    users_nauticas: yup
      .object()
      .nullable()
      .test(
        'usersNauticasRequired',
        "User is required when 'First contact' is USER",
        function (value) {
          const firstContact = this.parent.first_contact?.id;
          if (firstContact && firstContact === 'USER') {
            return value !== null && typeof value === 'object';
          }
          return true;
        }
      ),
    first_contact: yup
      .object()
      .nullable()
      .required('First contact is required'),
    registration_date: yup.date().nullable().required('Mexal Validation Date is required'),
    note_attendance: yup.string().nullable(),
  }),
  defaultValues: {
    id_yacht: null,
    id_agent_NA: null,
    is_central_agent: false,
    id_user_provision: null,
    id_user_tech: null,
    mexal_validation: false,
    provenence_group: null,
    first_contact: null,
    provenence_sales_channel: null,
    provenence: null,
    users_nauticas: null,
    registration_date: dayjs(),
    note_attendance: null,
  },
  fields: [
    {
      id: 'date_divider',
      type: 'divider',
      label: 'User Provisions',
      xs: 12,
    },
    {
      id: 'id_user_provision',
      name: 'id_user_provision',
      type: 'async_autocomplete',
      label: 'User Provisions',
      api: users_nautica.selectName,
      xs: 12,
    },
    {
      id: 'date_divider',
      type: 'divider',
      label: 'User Tech',
      xs: 12,
    },
    {
      id: 'id_user_tech',
      name: 'id_user_tech',
      type: 'async_autocomplete',
      label: 'User Tech',
      api: users_nautica.selectName,
      xs: 12,
    },
    {
      id: 'date_divider',
      type: 'divider',
      xs: 12,
    },
    {
      id: 'note_attendance',
      name: 'note_attendance',
      type: 'text',
      label: 'Note Attendance',
      xs: 12,
    },
    {
      id: 'registration_date',
      name: 'registration_date',
      type: 'date',
      label: 'Mexal Validation Date',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'is_central_agent',
      name: 'is_central_agent',
      type: 'switch',
      label: 'Central Agent',
      xs: 12,
    },
    {
      id: 'mexal_validation',
      name: 'mexal_validation',
      type: 'switch',
      label: 'Mexal Validation',
      xs: 12,
    },
  ],
};
