import React from 'react';
import MUITextField from '@mui/material/TextField';
import Error from './Error.component';
import { useController, useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';

import PropTypes from 'prop-types';

const TextFieldMultiline = ({ name, required, ...props }) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    const {
        field: { ref, ...inputProps },
    } = useController({
        name: name,
        control: control,
        rules: { required },
    });

    return (
        <Box display='flex' flexDirection={'column'}>
            <MUITextField
                error={!!errors[name]?.message}
                {...inputProps}
                inputRef={ref}
                {...props}
                required={required}
                InputProps={{
                    style: { minHeight: `${props.minHeight}px`, alignItems: 'flex-start' },
                    ...props.InputProps
                }}
            />
            <Error error={errors[name]?.message} />
        </Box>
    );
};

TextFieldMultiline.propTypes = {
    name: PropTypes.string,
    required: PropTypes.bool,
};

export default TextFieldMultiline;
