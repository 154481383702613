import { dataSlice as createDataSLice } from '../../../core/data';
import {
  combineReducers,
  createAction,
  createSelector,
} from '@reduxjs/toolkit';
import _ from 'lodash';
import { DataConf } from '../conf/Data.conf';
import { DetailConf } from '../conf/Detail.conf';
import { detailSlice as createDetailSlice } from '../../../core/detail';

export const tag = 'shipping_documents';

// REDUCERS
const dataSlice = createDataSLice(`${tag}/data`, DataConf.initialState);
const detailSlice = createDetailSlice(`${tag}/detail`, DetailConf.initialState);

export const reducer = combineReducers({
  data: dataSlice.reducer,
  detail: detailSlice.reducer,
});

// ACTIONS
export const actions = {
  fetchDetailAction: createAction(`${tag}/fetchOne`),
  ...dataSlice.actions,
  ...detailSlice.actions,
};

// EDIT
export const editSelector = createSelector(
  (state) => state[tag].detail,
  ({ data, loading, error }) => {
    return {
      loading,
      error,
      data: data
        ? {
            description: _.get(data, 'attributes.description'),
          }
        : null,
    };
  }
);
