import { createSlice } from '@reduxjs/toolkit'

export const sliceSegmentedControls = (name, initialState) => createSlice({
    name,
    initialState: {...initialState},
    reducers: {
        valueChange: (state, action) => {            
            state.value = action.payload
        }
    },
})