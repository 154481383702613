import React, { useEffect, useState } from 'react';
import { API as departments } from '../../module/departments/api';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { fetchAPI } from '../../api/request';
import Autocomplete from '../form/components/Autocomplete.component';

const DepartmentForm = ({ api = departments.logbookDepartments,
    required = true,
    disabled = false, }) => {
    const { user, jwt } = useSelector((state) => state.login);
    const { setValue } = useFormContext();
    const [options, setOptions] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        fetchAPI(api, {}, '', {}, jwt)
            .then((resp) => {
                setOptions(resp.map((item) => ({ id: item.id, label: item.name })));
                setValue('id_department', { id: user?.id_users_nautica?.id_users_nautica_departments[0]?.id_department?.id, label: user?.id_users_nautica?.id_users_nautica_departments[0]?.id_department?.name_department })
                setLoading(false)
            })
            .catch(() => {
                setValue('id_department', null)
                setOptions([])
                setLoading(false)
            });
    }, [jwt, setValue, user, api]);

    return (
        <Grid container>
            <Grid item xs={12}>
                <Autocomplete
                    name={'id_department'}
                    id={'id_department'}
                    label={'Department'}
                    options={options}
                    required={required}
                    disabled={false}
                    disableClearable={true}
                    loading={loading}
                />

            </Grid>
        </Grid>
    );
}

export default DepartmentForm;
