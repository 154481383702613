import * as yup from 'yup';

export const CreateNewYachtConf = {
  schema: {
    yacht_name: yup.string().nullable().required('Yacht name is required'),
    loa: yup.string(),
    beam: yup.string(),
    draft: yup.string(),
    gross_tonn: yup.string(),
    yacht_type: yup.object().nullable().required('Yacht type is required'),
  },
  defaultValues: {
    yacht_name: '',
    loa: '',
    beam: '',
    draft: '',
    gross_tonn: '',
    yacht_type: null,
  },
  fields: [],
};
