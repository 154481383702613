import * as yup from 'yup';
import { Types } from '../../yachts/conf/Data.conf';
import { API as users_nautica } from '../../users_nautica/api';
import { API as formalities } from '../../formalities/api';
import { API as provenanceList } from '../../provenenceLists/api';
import dayjs from 'dayjs';
// import dayjs from 'dayjs';

export const FilterConf = {
  schema: yup.object({
    registration_date: yup
      .date()
      .nullable()
      .test('is-valid-date', 'Start date is not valid', function (value) {
        return value ? dayjs(value)?.isValid() : true;
      })
      .transform((value) => {
        if (value && dayjs(value).isValid()) {
          const formattedDate = dayjs(value).format('YYYY-MM-DD');
          return new Date(formattedDate);
        }
        return null;
      }),
  }),
  initialState: {
    value: {
      yacht_type: null,
      id_agent_NA: [],
      mexal_validation: null,
      registration_date: null,
      is_central_agent: null,
      formalities: null,
      provenence_group: null,
      provenence_sales_channel: null,
      provenence: null,
    },
  },
  fields: [
    {
      id: 'yacht_type',
      name: 'yacht_type',
      type: 'autocomplete',
      label: 'Yacht Type',
      options: Types,
      required: false,
      xs: 12,
      sm: 6,
      md: 3,
    },
    {
      id: 'id_agent_NA',
      name: 'id_agent_NA',
      type: 'async_autocomplete_multiple',
      label: 'NA Agency',
      api: users_nautica.selectName,
      required: false,
      xs: 12,
      sm: 6,
      md: 3,
    },

    {
      id: 'mexal_validation',
      name: 'mexal_validation',
      type: 'autocomplete',
      label: 'Mexal status',
      options: [
        { label: 'DISABLE', id: false },
        { label: 'ENABLE', id: true },
      ],
      required: false,
      xs: 12,
      sm: 6,
      md: 3,
    },
    {
      id: 'registration_date',
      name: 'registration_date',
      type: 'date',
      label: 'Mexal Validation Date',
      required: false,
      xs: 12,
      sm: 6,
      md: 3,
    },

    {
      id: 'is_central_agent',
      name: 'is_central_agent',
      type: 'autocomplete',
      label: 'Central status',
      options: [
        { label: 'DISABLE', id: false },
        { label: 'ENABLE', id: true },
      ],
      required: false,
      xs: 12,
      sm: 6,
      md: 3,
    },
    {
      id: 'formalities',
      name: 'formalities',
      type: 'async_autocomplete_multiple',
      label: 'Formalities',
      api: formalities.all,
      required: false,
      xs: 12,
      sm: 6,
      md: 3,
    },
    {
      id: 'provenence_group',
      name: 'provenence_group',
      type: 'async_autocomplete_multiple',
      label: 'Category',
      api: provenanceList.group,
      required: false,
      xs: 12,
      sm: 6,
      md: 3,
    },
    {
      id: 'provenence_sales_channel',
      name: 'provenence_sales_channel',
      type: 'async_autocomplete_multiple',
      label: 'Sales Channel',
      api: provenanceList.sales_channel,
      required: false,
      xs: 12,
      sm: 6,
      md: 3,
    },
    {
      id: 'provenence',
      name: 'provenence',
      type: 'async_autocomplete_multiple',
      label: 'Provenance',
      api: provenanceList.provenences,
      required: false,
      xs: 12,
      sm: 6,
      md: 3,
    },
  ],
  query: {
    keys: [
      {
        key: 'id_yacht.yacht_type',
        value: 'yacht_type.label',
        operator: '$eq',
      },
      {
        key: 'id_agent_NA.id',
        value: 'id_agent_NA.id',
        operator: '$eq',
      },
      {
        key: 'mexal_validation',
        value: 'mexal_validation.id',
        operator: '$eq',
      },
      {
        key: 'registration_date',
        value: 'registration_date',
        operator: '$eq',
      },
      {
        key: 'is_central_agent',
        value: 'is_central_agent.id',
        operator: '$eq',
      },
      {
        key: 'formalities',
        value: 'formalities.id',
        operator: '$eq',
      },
      {
        key: 'id_provenence_list.provenence_group.id',
        value: 'provenence_group.id',
        operator: '$eq',
      },
      {
        key: 'id_provenence_list.provenence_sales_channel.id',
        value: 'provenence_sales_channel.id',
        operator: '$eq',
      },
      {
        key: 'id_provenence_list.provenence.id',
        value: 'provenence.id',
        operator: '$eq',
      },
    ],
  },
};
