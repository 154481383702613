import { Save, Edit } from '@mui/icons-material';
import { IconButton, Stack, ClickAwayListener, TextField } from '@mui/material';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { fetchAPI } from '../../../api/request';
import Loading from '../../common/Loading.component';
import Alert from '../../common/Alert.component';
import { API as listAPI } from '../../../module/list/api';
import EditDepot from './components/EditDepot.component';

const EditList = ({
  item,
  department,
  setSuccessEdit = () => {},
  successEdit,
}) => {
  const { id } = useParams();
  const { jwt } = useSelector((state) => state.login);

  const [loading, setLoading] = useState(false);
  const [currentState, setCurrentState] = useState(null);
  const [editable, setEditable] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  const onChange = (value, name) => {
    setCurrentState({ ...currentState, [name]: value });
  };

  const onSuccess = useCallback(() => {
    setLoading(false);
    setSuccess(true);
    setSuccessEdit(!successEdit);
  }, [successEdit, setSuccessEdit]);

  const onError = useCallback(() => {
    setLoading(false);
    setError(true);
  }, []);

  const onSaveAction = useCallback(() => {
    setEditable(!editable);
    setLoading(true);
    setSuccess(false);
    setError(false);
    fetchAPI(
      listAPI.update,
      { id },
      '',
      {
        data: {
          notes: currentState?.notes || '',
          check_depot: currentState?.check_depot,
          warning_notes: currentState?.warning_notes,
        },
      },
      jwt
    )
      .then(onSuccess)
      .catch(onError);
  }, [editable, currentState, jwt, id, onError, onSuccess]);

  const onEditAction = useCallback(() => {
    setCurrentState({
      notes: item?.notes || '',
      check_depot: item?.check_depot,
      warning_notes: item?.warning_notes,
    });
    setEditable(false);
  }, [item]);

  useEffect(() => {
    setCurrentState({
      notes: item?.notes || '',
      check_depot: item?.check_depot,
      warning_notes: item?.warning_notes,
    });
  }, [item, success]);

  return (
    <Fragment>
      {error && <Alert severity='error'>{'An error has occurred'}</Alert>}
      {success && <Alert severity='success'>{'Success'}</Alert>}

      {loading && <Loading />}
      <ClickAwayListener onClickAway={onEditAction}>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          alignItems={{ xs: 'start', md: 'center' }}
          justifyContent={{ xs: 'left', md: 'space-between' }}
          sx={{
            padding: 1,
            width: '100%',
            backgroundColor: '#fff',
            marginTop: 4,
            marginBottom: 4,
          }}
        >
          <Stack
            spacing={2}
            direction={{ xs: 'column', md: 'row' }}
            alignItems={{ xs: 'right', md: 'center' }}
            width={'100%'}
          >
            {department === 'DEP' && (
              <EditDepot
                onChange={onChange}
                currentState={currentState}
                disabled={!editable}
              />
            )}

            <TextField
              size='small'
              value={currentState?.notes || ''}
              sx={{
                '& input': {
                  height: 17.5,
                },
                width: { sx: '100%', md: '50%' },
              }}
              name='notes'
              onChange={(event) => {
                onChange(event?.target?.value, event.target?.name);
              }}
              label={'Note'}
              disabled={!editable}
            />

            <TextField
              size='small'
              value={currentState?.warning_notes || ''}
              sx={{
                '& input': {
                  height: 17.5,
                },
                width: { sx: '100%', md: '50%' },
              }}
              name='warning_notes'
              onChange={(event) => {
                onChange(event?.target?.value, event.target?.name);
              }}
              label={'Warning Notes'}
              disabled={!editable}
            />
          </Stack>
          {editable ? (
            <IconButton
              color='info'
              component='label'
              onClick={() => onSaveAction()}
            >
              <Save />
            </IconButton>
          ) : (
            <IconButton
              color='info'
              component='label'
              onClick={() => setEditable(true)}
            >
              <Edit />
            </IconButton>
          )}
        </Stack>
      </ClickAwayListener>
    </Fragment>
  );
};

export default EditList;
