// CONF
import { LoginConf } from './conf/Login.conf';

// REDUX
import { reducer, actions } from './slice';

// SELECTOR
import { userSelector, tag } from './slice';

// SAGA
import { saga } from './saga';

// API
import { API } from './api';

export { reducer, saga, tag };

const user = {
  tag,
  query: {
    login: {
      selector: userSelector,
      action: actions.userLoginAction,
      settings: API.login,
    },
    logout: {
      action: actions.userLogout,
    },
  },
  login: {
    conf: LoginConf,
    to: '/yachts',
  },
  logout: {
    to: '/yachts',
  },
};

export default user;
