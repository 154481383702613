import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  Box,
  Card,
  Chip,
  ClickAwayListener,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useYear } from '../../../../core/year/hook/useYear';
import year from '../../../../module/year';
import _ from 'lodash';
import dayjs from 'dayjs';

const Season = () => {
  const theme = useTheme();
  const anchorRef = useRef(null);
  const { from } = useSelector((state) => state.year);
  const { handleChangeYear } = useYear(year.filter.actions.changeYear);
  const [open, setOpen] = useState(false);
  const [yearsOptions, setYearsOptions] = useState([]);

  const handleListItemClick = (event, item) => {
    handleChangeYear({
      from: item.from,
      to: item.to,
    });
    handleClose(event);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const generateYears = useCallback(() => {
    const fromYear = 2023;
    const currentYear = dayjs().year();
    let years = [];
    for (let year = fromYear; year <= currentYear; year++) {
      years.push(year.toString());
    }
    return years;
  }, []);

  const generateOptions = useCallback(() => {
    const years = generateYears();
    let options = [];
    years?.map((year) =>
      options.push({
        label: year.toString(),
        from: `${year}-01-01T00:00:00Z`,
        to: `${year}-12-31T24:00:00Z`,
      })
    );
    return options;
  }, [generateYears]);

  useEffect(() => {
    const generatedOptions = generateOptions();
    setYearsOptions(generatedOptions);
  }, [generateOptions]);

  return (
    <Box>
      <Chip
        ref={anchorRef}
        onClick={handleToggle}
        label={
          <Typography
            sx={{
              color: open ? 'white' : 'black',
              '&:hover': {
                color: 'white',
              },
              width: { xs: '30px', md: '50px' },
              textAlign: 'center',
            }}
          >
            {_.head(from.split('-'))}
          </Typography>
        }
        sx={{
          height: 38,
          alignItems: 'center',
          borderRadius: '5px',
          transition: 'all .2s ease-in-out',
          borderColor: theme.palette.primary.light,
          backgroundColor: open
            ? theme.palette.primary.main
            : theme.palette.primary.light,
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.primary.main,
            background: `${theme.palette.primary.main}!important`,
            color: theme.palette.primary.light,
          },
          '& .MuiChip-label': {
            lineHeight: 0,
          },
        }}
        color='primary'
        id='menu'
      />
      <Popper
        id={'menu'}
        open={open}
        anchorEl={anchorRef.current}
        placement='bottom'
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 5],
              },
            },
          ],
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Paper sx={{ width: 'auto' }}>
            <Card elevation={16}>
              <Stack>
                <List
                  component='nav'
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: 10,
                    padding: 0,
                    [theme.breakpoints.down('md')]: {
                      minWidth: '100%',
                    },
                    '& .MuiListItemButton-root': {
                      m: 1,
                    },
                  }}
                >
                  {yearsOptions?.map((item, index) => (
                    <ListItemButton
                      key={index}
                      sx={{ borderRadius: 2 }}
                      onClick={(event) => handleListItemClick(event, item)}
                      selected={from === item?.from}
                    >
                      <ListItemText
                        primary={
                          <Typography sx={{ textAlign: 'center' }}>
                            {item?.label}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  ))}
                </List>
              </Stack>
            </Card>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </Box>
  );
};

export default Season;
