import { detailSlice as createDetailSlice } from '../../../core/detail';

import {
  combineReducers,
  createSelector,
  createAction,
} from '@reduxjs/toolkit';
import { DetailConf } from '../conf/Detail.conf';

export const tag = 'me';

// REDUCERS

const detailSlice = createDetailSlice(`${tag}/detail`, DetailConf.initialState);

export const reducer = combineReducers({
  detail: detailSlice.reducer,
});

// ACTIONS
export const actions = {
  fetchDetailAction: createAction(`${tag}/fetchOne`),
  ...detailSlice.actions,
};

// DETAIL
export const detailSelector = createSelector(
  (state) => state[tag].detail,
  (detail) => detail
);
