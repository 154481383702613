export const Roles = [
	{ id: 'CAPTAIN', label: 'Captain'},
	{ id: 'CHEF', label: 'Chef'},
	{ id: 'PURSER', label: 'Purser'},
	{ id: 'FIRST OFFICER', label: 'First Officer'},
	{ id: 'CHIEF STEWARDESS', label: 'Chief Stewardess'},
	{ id: 'CHIEF ENGINEER', label: 'Chief Engineer'},
	{ id: 'OTHER', label: 'Other'},
];

export const DataConf = {
	initialState: {
		loading: false,
		error: null,
		data: [],
		meta: null,
	},
};


export const UsersType= [
	{ label: 'Crew', id: 'Crew' },
	{ label: 'Nautica', id: 'Nautica' },
  ]