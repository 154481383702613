import React, { useMemo, useState } from 'react';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import _ from 'lodash';
import TableBody from './components/TableBody.component';
import TableHeaderSort from '../../tableSort/TableHeaderSort.componet';

const TableProductsInLogbookEditable = ({
  data,
  orderType = 'asc',
  orderById,
  header = [],
  setSuccessUpdate = () => {},
}) => {
  const [orderBy, setOrderBy] = useState(orderById);
  const [order, setOrder] = useState(orderType);

  const handleSort = (columnId) => {
    const isAsc = orderBy === columnId && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(columnId);
  };

  const groupBy = useMemo(
    () =>
      Object.values(
        _.groupBy(data, (item) => item?.attributes?.id_logbook.data.id)
      ),
    [data]
  );

  //Sort logbooks for label supplier
  const sortedExternalData = useMemo(() => {
    const dataOrdered = _.orderBy(groupBy, `[0].${orderBy}`, [order]);
    let confirmedItems = [];
    let othersItems = [];
    dataOrdered.map((item) =>
      _.toUpper(
        item[0]?.attributes?.id_logbook?.data?.attributes?.status_logbook
      ) !== 'COMPLETED'
        ? othersItems.push(item)
        : confirmedItems.push(item)
    );

    return [...confirmedItems, ...othersItems];
  }, [orderBy, order, groupBy]);

  return (
    <TableContainer component={Paper} sx={{ height: 450 }}>
      <Table sx={{ minWidth: 650 }}>
        <TableHeaderSort
          header={header}
          handleSort={handleSort}
          order={order}
          orderBy={orderBy}
        />
        <TableBody
          data={sortedExternalData}
          idAccordion={'[0].attributes.id_logbook.data.id'}
          setSuccessUpdate={setSuccessUpdate}
        />
      </Table>
    </TableContainer>
  );
};
export default TableProductsInLogbookEditable;
