import React, { useState } from 'react';
import MuiAlert from '@mui/material/Alert';
import MuiSnackbar from '@mui/material/Snackbar';

const Alert = ({ children, ...props }) => {
  const [open, setOpen] = useState(true);

  const handleClose = (event, reason) => {
    if (reason === 'kaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <MuiSnackbar
      sx={{ mt: 8 }}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <MuiAlert onClose={handleClose} elevation={6} variant='filled' {...props}>
        {children}
      </MuiAlert>
    </MuiSnackbar>
  );
};

export default Alert;
