import { all } from 'redux-saga/effects';
import { saga as YacthSaga } from '../module/yachts';
import { saga as BookingSaga } from '../module/booking';
import { saga as AttendancesSaga } from '../module/attendances';
import { saga as LoginSaga } from '../module/login';
import { saga as OperationsSaga } from '../module/operations';
import { saga as ConciergeSaga } from '../module/concierge';
import { saga as TechnicalSaga } from '../module/technical';
import { saga as ProvisionsSaga } from '../module/provisions';
import { saga as DepotSaga } from '../module/depot';
import { saga as ShippingSaga } from '../module/shipping';
import { saga as InteriorsSaga } from '../module/interiors';
import { saga as LogbookSaga } from '../module/logbook';
import { saga as AgencySaga } from '../module/agency';
import { saga as ProductsSaga } from '../module/products';
import { saga as ListItemsProvisionsSaga } from '../module/listItemsProvisions';
import { saga as ContactsSaga } from '../module/contacts';
import { saga as TechnicalWorkSaga } from '../module/technicalWork';
import { saga as MeSaga } from '../module/me';
import { saga as ListItemsDepotSaga } from '../module/listItemsDepot';
import { saga as ListItemsInteriorsSaga } from '../module/listItemsInteriors';
import { saga as ShippingDocumetsSaga } from '../module/shippingDocuments';

export function* sagas() {
	yield all([
		LoginSaga(),
		YacthSaga(),
		AttendancesSaga(),
		BookingSaga(),
		OperationsSaga(),
		ConciergeSaga(),
		ProvisionsSaga(),
		DepotSaga(),
		ShippingSaga(),
		InteriorsSaga(),
		TechnicalSaga(),
		LogbookSaga(),
		AgencySaga(),
		ProductsSaga(),
		ListItemsProvisionsSaga(),
		ContactsSaga(),
		TechnicalWorkSaga(),
		MeSaga(),
		ListItemsDepotSaga(),
		ListItemsInteriorsSaga(),
		ShippingDocumetsSaga(),
	]);
}
