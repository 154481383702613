import React, {
  useState,
  useCallback,
  Fragment,
  useEffect,
  useMemo,
} from 'react';
import { Box, Collapse, Stack, Divider } from '@mui/material';
import { useSelector } from 'react-redux';
import FilterView from '../../views/Filter.view';
import SearchView from '../../views/Search.view';
import AddButton from './components/Add.component';
import FilterButton from './components/Filter.component';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { getItem } from '../../utility/Storage.utility';

const ToolbarComponent = ({
  tag,
  search,
  add,
  filter,
  children,
  link,
  tagPermissions,
}) => {
  const navigate = useNavigate();
  const filters = useSelector((state) => state[tag].filter.value);
  const [showFilter, setShowFilter] = useState(false);
  const userAuth = useSelector((state) => state.login?.user);
  const permissions = useMemo(
    () =>
      tagPermissions
        ? userAuth.permissions[_.toUpper(tagPermissions)]
        : userAuth.permissions[_.toUpper(tag)],
    [tagPermissions, tag, userAuth]
  );
  const defaultFilters = useMemo(
    () => _.omit(getItem(tag)?.filters, 'season') || filters,
    [tag, filters]
  );

  const onClickFilter = useCallback(() => {
    setShowFilter(!showFilter);
  }, [showFilter, setShowFilter]);

  const onClickAdd = useCallback(
    (to, target) => (e) => {
      if (target === '_blank') {
        window.open(to, target);
      } else {
        navigate(to, { state: { from: tag } });
      }
    },
    [navigate, tag]
  );

  useEffect(() => {
    setShowFilter(false);
  }, [tag]);

  return (
    <Fragment>
      <Box
        sx={{ padding: 2 }}
        display={{ sm: 'flex' }}
        justifyContent={'space-between'}
      >
        {search && <SearchView search={search} tag={tag} />}
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2 }}
        >
          {link && permissions?.LINK === 'true' && (
            <AddButton
              onClick={onClickAdd(link.to, link.target || '_self')}
              label={link?.label}
            />
          )}

          {add && permissions?.CREATE === 'true' && (
            <AddButton
              onClick={onClickAdd(add.to, add.target || '_self')}
              label={add?.label}
            />
          )}
          {filter && (
            <FilterButton
              filters={defaultFilters}
              showFilter={showFilter}
              onClick={onClickFilter}
            />
          )}
        </Stack>
      </Box>
      <Divider />
      {filter && (
        <Collapse in={showFilter}>
          <Box sx={{ padding: 2 }}>
            <FilterView tag={tag} filter={filter}>
              {children}
            </FilterView>
          </Box>
          <Divider />
        </Collapse>
      )}
    </Fragment>
  );
};

export default ToolbarComponent;
