import * as yup from 'yup';

export const UpdateConf = {
  schema: yup.object({
    description: yup.string().nullable(),
  }),
  defaultValues: {
    description: '',
  },
  fields: [
    {
      id: 'shipping_document_divider',
      type: 'divider',
      label: 'Shipping Documents',
      xs: 12,
    },

    {
      id: 'description',
      name: 'description',
      type: 'text',
      label: 'Description',
      xs: 12,
    },
  ],
};
