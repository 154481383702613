import * as yup from 'yup';

import { CreateConf as UsersNauticaConf } from '../../users_nautica/conf/Create.conf';
import { CreateConf as UsersCrewConf } from '../../users_crew/conf/Create.conf';
import { CommonConf } from '../conf/common/CommonConf.conf';
import _ from 'lodash';

export const CreateConf = {
	Nautica: {
		schema: yup.object({
			...UsersNauticaConf.schema,
			...CommonConf.schema,
		}),
		defaultValues: {
			...UsersNauticaConf.defaultValues,
			...CommonConf.defaultValues,
		},
		fields: _.concat(CommonConf.fields, UsersNauticaConf.fields),
	},
	Crew: {
		schema: yup.object({
			...UsersCrewConf.schema,
			...CommonConf.schema,
		}),
		defaultValues: {
			...UsersCrewConf.defaultValues,
			...CommonConf.defaultValues,
		},
		fields: _.concat(CommonConf.fields, UsersCrewConf.fields),
	},
};
