import React, { Fragment } from 'react';
import { TextField, Typography } from '@mui/material';

import _ from 'lodash';

const ControlTextField = ({
  item,
  editable,
  onChange = () => {},
  name,
  label,
  currentState,
  visibleText = true,
}) => {
  return (
    <Fragment>
      {!editable?.[item?.id] ? (
        visibleText && (
          <Typography>
            {_.has(currentState, `${name}.${item?.id}`)
              ? currentState?.[name]?.[item?.id] || '-'
              : item?.attributes?.[name] || '-'}
          </Typography>
        )
      ) : (
        <TextField
          size='small'
          value={
            _.has(currentState, `${name}.${item?.id}`)
              ? currentState?.[name]?.[item?.id]
              : item?.attributes?.[name] || ''
          }
          sx={{
            '& input': {
              height: '14px',
            },
            marginTop: 1,
            marginBottom: 1,
          }}
          onChange={(event) =>
            onChange(parseInt(event?.target?.value || '0'), name, item?.id)
          }
          label={label}
          fullWidth
        />
      )}
    </Fragment>
  );
};
export default ControlTextField;
