import * as yup from 'yup';
import { Roles, UsersType } from './Data.conf';
import { API as yachts } from '../../yachts/api';

export const FilterConf = {
  schema: yup.object({}),
  initialState: {
    value: {
      id_yacht: null,
      role: { label: 'Crew', id: 'Crew' },
      crew_responsability: null,
    },
  },
  fields: [
    {
      id: 'role',
      name: 'role',
      type: 'autocomplete',
      label: 'User Type',
      options: UsersType,
      required: false,
      disableClearable: true,
      xs: 12,
      sm: 4,
    },
    {
      id: 'id_yacht',
      name: 'id_yacht',
      type: 'async_autocomplete',
      label: 'Yacht',
      api: yachts.select,
      required: false,
      xs: 12,
      sm: 4,
    },
    {
      id: 'crew_responsability',
      name: 'crew_responsability',
      type: 'autocomplete',
      label: 'Role',
      options: Roles,
      required: false,
      xs: 12,
      sm: 4,
    },
  ],
  query: {
    keys: [
      {
        key: 'role.name',
        value: 'role.id',
        operator: '$eq',
      },
      {
        key: 'id_yacht.id',
        value: 'id_yacht.id',
        operator: '$eq',
      },
      {
        key: 'id_users_crew.crew_responsability',
        value: 'crew_responsability.label',
        operator: '$eq',
      },
    ],
  },
};
