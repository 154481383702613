export const ListCheckDepot = ['OPEN', 'CLOSED'];

export const StatusDepot = [
  {
    label: 'COMPLETED'.toUpperCase(),
    id: 1,
    hex: '#8bc34a73',
    textHex: '#558b2f',
  },
  {
    label: 'WAITING FOR SUPPLIER CONFIRMATION',
    id: 2,
    hex: '#ffeb3ba6',
    textHex: '#616161',
  },
  {
    label: 'TO ORDERED'.toUpperCase(),
    id: 3,
    hex: '#fb8c0073',
    textHex: '#ef6c00',
  },
  {
    label: 'Canceled'.toUpperCase(),
    id: 5,
    hex: '#e5393573',
    textHex: '#b71c1c',
  },
  {
    label: 'TO SEARCH'.toUpperCase(),
    id: 6,
    hex: '#2196f373',
    textHex: '#0277bd',
  },
  {
    label: 'READY'.toUpperCase(),
    id: 7,
    hex: '#9575cd73', //purple
    textHex: '#4527a0',
  },
  {
    label: 'WAITING FOR CUSTOMER CONFIRMATION',
    id: 8,
    hex: '#ffeb3ba6',
    textHex: '#616161',
  },
  {
    label: 'WAITING FOR ITEMS',
    id: 9,
    hex: '#ffeb3ba6',
    textHex: '#616161',
  },
];

export const ServiceOptions = [

  {
    id: 'E-COMMERCE',
    label: 'E-COMMERCE',
    color: '#009ab6',
  },
  {
    id: 'DEPOT ORDER',
    label: 'DEPOT ORDER',
    color: '#009ab6',
  },
  {
    id: 'QUOTATION REQUEST',
    label: 'QUOTATION REQUEST',
    color: '#009ab6',
  },
];

export const ContactType = [
  {
    id: 'EMAIL',
    label: 'EMAIL',
  },
  {
    id: 'WHATSAPP',
    label: 'WHATSAPP',
  },
  {
    id: 'SHOP',
    label: 'SHOP',
  },
];
