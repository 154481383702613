export const DetailConf = {
  initialState: {
    loading: false,
    error: null,
    data: null,
    meta: null,
  },
  fields: [
    {
      id: 'user_type',
      type: 'section',
      label: 'User Type',
      xs: 12,
      items: [{ type: 'text', label: 'Role', key: 'role.name', xs: 12, sm: 6 }],
    },
    {
      id: 'contact_section',
      type: 'section',
      label: 'Contact',
      xs: 12,
      items: [
        { type: 'text', label: 'NAME', key: 'name', xs: 6 },
        { type: 'text', label: 'SURNAME', key: 'surname', xs: 6 },
        { type: 'text', label: 'EMAIL', key: 'email', xs: 6 },
        {
          type: 'text',
          label: 'PHONE',
          key: 'user.phone_number',
          xs: 6,
        },
        {
          type: 'boolean',
          label: 'CONFIRMED',
          key: 'confirmed',
          xs: 12,
          sm: 6,
        },
        { type: 'boolean', label: 'BLOCKED', key: 'blocked', xs: 12, sm: 6 },
      ],
    },

    {
      id: 'yacht_section',
      type: 'section',
      label: 'Yacht',
      xs: 12,
      items: [
        {
          type: 'text',
          label: 'NAME',
          key: 'id_yacht.yacht_name',
          xs: 12,
          sm: 6,
        },
        {
          type: 'text',
          label: 'ROLE',
          key: 'id_users_crew.crew_responsability',
          xs: 6,
        },
        {
          type: 'text',
          label: 'MANAGER ON BOARD',
          key: 'id_users_crew.is_delivery_manager_on_board',
          xs: 6,
        },
      ],
    },
  ],
  formatValue: (key, value) => null,
};
