import React, { useEffect } from 'react';
import {
  Autocomplete,
  Box,
  Container,
  Grid,
  Paper,
  Stack,
  TextField,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { fetchAPI } from '../../../api/request';
import Alert from '../../../components/common/Alert.component';
import Back from '../../../components/common/Back.component';
import Loading from '../../../components/common/Loading.component';
import { UsersType } from '../../../module/contacts/conf/Data.conf';
import dayjs from 'dayjs';
import EditView from '../../../views/Edit.view';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

const ContactsEditPage = ({module:{edit,query,tag}}) => {
  const { jwt } = useSelector((state) => state.login);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [userTypeSelect, setUserTypeSelect] = useState(null);
  const { loading: loadingGet, data } = useSelector(
    edit.selector.data
  );
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onError = useCallback((error) => {
    setSuccess(false);
    setLoading(false);
    setError(error);
    // eslint-disable-next-line
  }, []);

  const onSuccess = useCallback(() => {
    setSuccess(true);
    setError(false);
    setLoading(false);
  }, [setSuccess, setError]);

  const handleUpdateUserNa = useCallback(
    (values) => {
      const obj = {
        id_department: values?.id_department,
        subrole: values?.subrole,
      };

      fetchAPI(
        {
          path: `/users-nautica-departments/${data?.id_users_nautica?.id_users_nautica_departments[0]?.id}`,
          method: 'PUT',
        },
        {},
        '',
        { data: { ...obj } },

        jwt
      )
        .then(() => {
          onSuccess();
        })
        .catch(onError);
    },
    [jwt, onError, onSuccess, data]
  );

  const onSubmit = useCallback(
    (values) => {
      setSuccess(false);
      setError(false);
      setLoading(true);

      if (userTypeSelect?.id === 'Nautica') {
        const obj = {
          username: values?.username,
          email: values?.email,
          surname: values?.surname,
          name: values?.name,
          id_users_role: values?.id_users_role,
          phone_number: values?.phone_number,
          extension_number: values?.extension_number,
          id_site: values?.id_site,
          blocked: values?.blocked,
        };
        const nautica_id = data?.id_users_nautica?.id;
        if (values?.password?.trim() === '') {
          delete obj?.password;
        } else {
          obj.password = values.password;
        }

        fetchAPI(
          { path: `/users-nauticas/${nautica_id}`, method: 'PUT' },
          {},
          '',
          { data: obj },
          jwt
        )
          .then((resp) => {
            handleUpdateUserNa({
              id_users_nautica: resp.data.id,
              ...values,
            });
            onSuccess();
          })
          .catch(onError);
      } else {
        const obj = {
          username: values?.username,
          email: values?.email,
          name: values?.name,
          surname: values?.surname,
          phone_number: values?.phone_number,
          birthdate: values?.birthdate
            ? dayjs(values?.birthdate).format('YYYY-MM-DD')
            : null,
          crew_responsability: values?.crew_responsability?.label,
        };
        const user_id = data?.id_users_crew.id;
        fetchAPI(
          { path: `/users-crews/${user_id}`, method: 'PUT' },
          {},
          '',
          { data: obj },
          jwt
        )
          .then(() => {
            onSuccess();
            setLoading(false);
          })
          .catch(onError);
      }
    },
    [
      userTypeSelect?.id,
      data?.id_users_nautica?.id,
      data?.id_users_crew?.id,
      jwt,
      onError,
      handleUpdateUserNa,
      onSuccess,
    ]
  );

  useEffect(() => {
    if (data && data?.userType?.label) {
      setUserTypeSelect({
        id: data?.userType?.label,
        label: data?.userType?.label,
      });
      if (
        !_.find(UsersType, function (o) {
          return o.id === data?.userType?.label;
        })
      ) {
        navigate('/404');
      }
    }
  }, [data, navigate]);

  useEffect(() => {
    dispatch(
      query.detail.action({
        settings: query.detail.settings,
        token: jwt,
        params: { id },
        query: { populate: query.detail.populate },
      })
    );

    return () => {
      dispatch(
        query.detail.action({
          settings: null,
          token: null,
          params: null,
          query: null,
        })
      );
    };
  }, [id, dispatch, jwt, query]);

  return (
    <Container disableGutters={true} maxWidth={false} sx={{ height: '100%' }}>
      <Stack
        spacing={2}
        sx={{ padding: 2 }}
        justifyContent='center'
        alignItems='center'
      >
        <Back title={tag} />
        <Container>
          {loading && <Loading />}
          {success && (
            <Alert severity='success'>{'Contact updated successfully'}</Alert>
          )}
          {error && (
            <Alert severity='error'>
              {error?.response?.data?.error.message
                ? error?.response?.data?.error.message
                : 'An error has occurred'}
            </Alert>
          )}
          {loadingGet ? (
            <Loading />
          ) : (
            <Paper variant='outlined'>
              <Box sx={{ padding: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Autocomplete
                      disablePortal
                      options={UsersType}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.label
                      }
                      getOptionLabel={(option) => option?.label || ''}
                      value={userTypeSelect}
                      disabled
                      renderInput={(params) => (
                        <TextField {...params} label='User Type' />
                      )}
                    />
                  </Grid>
                </Grid>
                {userTypeSelect && userTypeSelect.id && (
                  <EditView
                    data={data}
                    id={id}
                    conf={edit.conf[userTypeSelect?.id]}
                    disabled={loading}
                    onEditSubmit={onSubmit}
                    onEditError={onError}
                    onEditSuccess={onSuccess}
                  />
                )}
              </Box>
            </Paper>
          )}
        </Container>
      </Stack>
    </Container>
  );
};

export default ContactsEditPage;
