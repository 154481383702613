import { z } from "zod";

// Define a schema
export const schemaValidation = z.object({
    id: z.string().or(z.number()),
    name: z.string().or(z.number()).or(z.undefined()),
    type: z.string(),
    label: z.string().or(z.number()).or(z.undefined()),
    required: z.boolean().or(z.undefined()),
    xs: z.number(),
    sm: z.number().or(z.undefined()),
    options: z.array(z.object({
        label: z.string().or(z.number()),
        id: z.string().or(z.number()).or(z.boolean()),
    })).or(z.undefined())
});