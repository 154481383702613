import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchAPI } from '../../../api/request';
import { API as departments } from '../../../module/departments/api';
import _ from 'lodash';
import {
  Autocomplete,
  Box,
  Container,
  Grid,
  Paper,
  Stack,
  TextField,
} from '@mui/material';
import Alert from '../../../components/common/Alert.component';
import Back from '../../../components/common/Back.component';
import Loading from '../../../components/common/Loading.component';
import AddView from '../../../views/Add.view';
import { API } from '../../../module/shipping/api';
import YachtForm from '../../../components/yacht/YachtForm.component';
import { API as yachts } from '../../../module/yachts/api';
import AgentNAForm from '../../../components/agentNA/AgentNAForm.component';
import Divider from '../../../components/form/components/Divider.component';

const ShippingAddPage = ({module:{code,tag,create}}) => {
  const { jwt, user } = useSelector((state) => state.login);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [idDepartment, setIdDepartment] = useState(null);
  const [shippingOptions, setShippingOptions] = useState([]);
  const [shippingType, setShippingType] = useState(null);
  const hadleChangeShippingType = (newValue) => {
    setShippingType(newValue);
  };

  useEffect(() => {
    fetchAPI(API.selectShippingType, {}, '', null, jwt).then((response) => {
      setShippingOptions(response);
    });
    // eslint-disable-next-line
  }, []);

  const onError = useCallback(() => {
    setSuccess(false);
    setLoading(false);
    setError(true);
    // eslint-disable-next-line
  }, []);

  const onSuccess = useCallback(
    (values) => {
      const data = {
        ...values,
        id_shipping_type: shippingType.id,
      };
      setLoading(false);
      fetchAPI(API.createShipping, {}, '', data, jwt)
        .then(() => {
          setSuccess(true);
          setError(false);
        })
        .catch(onError);
    },
    [setLoading, setSuccess, jwt, onError, shippingType]
  );

  const onSubmit = useCallback(
    (values) => {
      const data = {
        ...values,
        id_department: idDepartment,
        id_user_last_updated: user?.id_users_nautica?.id || null,
      };

      setSuccess(false);
      setError(false);
      setLoading(true);
      fetchAPI(API.create, {}, '', data, jwt)
        .then((resp) =>
          onSuccess({
            ...values,
            id_operation_list: resp?.data?.id,
          })
        )
        .catch(onError);
    },
    [idDepartment, user?.id_users_nautica?.id, jwt, onError, onSuccess]
  );

  useEffect(() => {
    fetchAPI(departments.select, { code: code }, '', null, jwt)
      .then((response) => {
        setIdDepartment(_.head(response)?.id);
      })
      .catch(onError);
    // eslint-disable-next-line
  }, []);

  return (
    <Container disableGutters={true} maxWidth={false} sx={{ height: '100%' }}>
      <Stack
        spacing={2}
        sx={{ padding: 2 }}
        justifyContent='center'
        alignItems='center'
      >
        <Back title={tag} />
        <Container>
          {loading && <Loading />}
          {success && <Alert severity='success'>{'Success: Add'}</Alert>}
          {error && <Alert severity='error'>{'An error has occurred'}</Alert>}

          <Paper variant='outlined'>
            <Box sx={{ padding: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Autocomplete
                    disablePortal
                    options={shippingOptions}
                    getOptionLabel={(option) => option?.name || ''}
                    onChange={(_, newValue) => {
                      hadleChangeShippingType(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label='Shipping Type' />
                    )}
                    disableClearable
                  />
                </Grid>
              </Grid>
              {shippingType && (
                <AddView
                  conf={create.conf[shippingType?.name]}
                  disabled={loading || success}
                  onAddSubmit={onSubmit}
                  onAddError={onError}
                  onAddSuccess={onSuccess}
                  actions={
                    <Fragment>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <YachtForm api={yachts.selectNoDRAFT} />
                        </Grid>
                        <Grid item xs={12}>
                          <AgentNAForm />
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <Divider label='Parcel Details' />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Fragment>
                  }
                >
                </AddView>
              )}
            </Box>
          </Paper>
        </Container>
      </Stack>
    </Container>
  );
};

export default ShippingAddPage;
