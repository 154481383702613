import { createSelector } from '@reduxjs/toolkit';
import { tag as tagContacts } from '../../contacts/index';

export const tag = 'contacts_nautica';

// DETAIL
export const detailSelector = createSelector(
	(state) => state[tagContacts].detail,
	({ data, loading, error }) => {
		const user = data?.id_users_crew ? data?.id_users_crew : data?.id_users_nautica;
		return {
			loading,
			error,
			data: {
				...data,
				user: user,
			},
		};
	}
);
