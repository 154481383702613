import React, { Fragment, useCallback, useEffect, useState } from 'react';
import {
  Box,
  IconButton,
  Paper,
  Collapse,
  Stack,
  Modal,
  Button,
} from '@mui/material';
import { Add, Close } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAPI } from '../../../api/request';
import Loading from '../../common/Loading.component';
import Alert from '../../common/Alert.component';
import AddView from '../../../views/Add.view';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import CloseIcon from '@mui/icons-material/Close';

const ItemAdd = ({
  setSuccessAdd = () => {},
  module: { query, create },
  idLogbook,
  productsList,
  isModal = false,
  disabled = false,
}) => {
  const { id } = useParams();
  const { jwt } = useSelector((state) => state.login);
  const _query = useSelector(query.all.selector);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [error, setError] = useState(false);
  const [conf, setConf] = useState(create.conf);

  const handleOpen = useCallback(() => setShowAdd(true), []);
  const handleClose = useCallback(() => setShowAdd(false), []);

  useEffect(() => {
    if (showAdd) {
      const idRandom = uuidv4();
      let fields = create.conf.fields.map((item) => {
        let newItem = { ...item, id: `${item.id}_${idRandom}` };
        return newItem;
      });
      setConf({ ...create.conf, fields: fields });
    } else {
      setConf(null);
    }
  }, [showAdd, create]);

  const onShowAdd = () => {
    setShowAdd(!showAdd);
  };

  const onError = useCallback(() => {
    setSuccess(false);
    setLoading(false);
    setError(true);
    // eslint-disable-next-line
  }, []);

  const onSuccess = useCallback(() => {
    setSuccess(true);
    setError(false);
    setLoading(false);
    setSuccessAdd(true);
    dispatch(
      query.all.action({
        settings: query.all.settings,
        token: jwt,
        params: { id },
        query: { ..._query, populate: query.all.populate },
      })
    );
    setShowAdd(false);
    // eslint-disable-next-line
  }, [setLoading, setSuccess, jwt, query, dispatch, id, _query]);

  const onAddToLogbook = useCallback(
    (resp) => {
      if (idLogbook && !_.isEmpty(productsList)) {
        const id_list_items = _.concat(productsList, resp?.id);

        const data = {
          id_list_items: id_list_items,
        };
        fetchAPI(
          { path: `/logbooks/${idLogbook}`, method: 'PUT', auth: true },
          {},
          '',
          { data: data },
          jwt
        )
          .then(onSuccess)
          .catch(onError);
      } else {
        onSuccess();
      }
    },
    [idLogbook, jwt, onSuccess, onError, productsList]
  );

  const onSubmit = useCallback(
    async (values) => {
      setSuccess(false);
      setError(false);
      setLoading(true);
      setSuccessAdd(false);

      const data = {
        id_list: id,
        available: values.available?.id,
        quantity: !_.isEmpty(values.quantity) ? values.quantity : null,
        quantity_type: values.quantity_type?.id,
        category: values.category?.id,
        note: values?.note,
        internal_note: values?.internal_note,
        id_item: values?.id_item?.id,
      };
      const formData = new FormData();

      if (values?.attachment) {
        Array.from(values?.attachment)?.map((file) =>
          formData.append('files.attachment', file, file?.name)
        );
      }

      formData.append('data', JSON.stringify(data));

      fetchAPI({ path: '/list-items', method: 'POST' }, {}, '', formData, jwt)
        .then((resp) => onAddToLogbook(resp?.data))
        .catch(onError);
    },
    [setLoading, jwt, onError, onAddToLogbook, id, setSuccessAdd]
  );

  return (
    <Stack>
      {loading && <Loading />}
      {success && <Alert severity='success'>{'Success: Add'}</Alert>}
      {error && <Alert severity='error'>{'An error has occurred'}</Alert>}
      {!!isModal ? (
        <Fragment>
          <Button
            onClick={handleOpen}
            variant='contained'
            sx={{ margin: 1 }}
            disabled={disabled}
          >
            Add Item
          </Button>
          <Modal open={showAdd} onClose={handleClose}>
            <Box sx={style}>
              <IconButton
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                }}
              >
                <CloseIcon />
              </IconButton>
              {conf && (
                <AddView
                  conf={conf}
                  onAddSubmit={onSubmit}
                  onAddError={onError}
                  onAddSuccess={onSuccess}
                  disabled={loading}
                />
              )}
            </Box>
          </Modal>
        </Fragment>
      ) : (
        <Fragment>
          <Box display={'flex'} justifyContent={'flex-end'} sx={{ mb: 1 }}>
            <IconButton color='info' component='label' onClick={onShowAdd}>
              {showAdd ? <Close /> : <Add />}
            </IconButton>
          </Box>
          <Collapse in={showAdd}>
            <Paper variant='outlined' sx={{ padding: 2 }}>
              {showAdd && conf && (
                <AddView
                  conf={conf}
                  onAddSubmit={onSubmit}
                  onAddError={onError}
                  onAddSuccess={onSuccess}
                  disabled={loading}
                />
              )}
            </Paper>
          </Collapse>
        </Fragment>
      )}
    </Stack>
  );
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  pt: 10,
  borderRadius: 2,
};

export default ItemAdd;
