import { loginSlice as createLoginSlice } from '../../../core/login'
import { createAction, createSelector } from '@reduxjs/toolkit'
import { LoginConf } from '../conf/Login.conf'

export const tag = 'login'

// REDUCERS
const loginSlice = createLoginSlice(`${tag}/login`, LoginConf.initialState)

export const reducer = loginSlice.reducer


// ACTIONS
export const actions = {
    ...loginSlice.actions,
    userLoginAction: createAction(`${tag}/login`),
}

// USER
export const userSelector = createSelector(
    (state) => state[tag].user,
    (user) => user
)