import * as yup from 'yup';

import _ from 'lodash';
import dayjs from 'dayjs';
import { API as users_nautica } from '../../users_nautica/api';
import { API as servicesOperationList } from '../api/index'
import { API as marina } from '../../marina/api';
import {
  AccountType,
  StatusOperationBookingType,
  StatusOperationType,
} from '../../operations/conf/Data.conf';
import { ContactType } from './Data.conf';

export const CommonFiels = [
  {
    id: 'yacht_divider',
    type: 'divider',
    label: 'Yacht',
    xs: 12,
  },
  {
    id: 'yacht_name',
    name: 'yacht_name',
    type: 'text',
    label: 'Yacht',
    required: true,
    disabled: true,
    xs: 12,
  },
  {
    id: 'agent_NA_name',
    name: 'agent_NA_name',
    type: 'text',
    label: 'NA Agent',
    disabled: true,
    required: false,
    xs: 12,
  },
];

export const UpdateConf = {
  schema: yup.object({
    id_mexal_person_in_charge: yup.object().nullable(),
    id_user_in_charge: yup
      .object()
      .nullable()
      .required('User in charge is required'),
    requested_date: yup
      .date()
      .nullable()
      .required('Requested date is required'),
    operation_service: yup.string().nullable(),
    service_detail: yup.string().nullable(),
    selling_price: yup.string().nullable(),
    purchase_price: yup.string().nullable(),
    status_operation_BC: yup.object().nullable(),
    status_operation_BF: yup.object().nullable(),
    OC_BC_number: yup.string().nullable(),
    OF_BF_number: yup.string().nullable(),
    OC_fees: yup.string().nullable(),
    note: yup.string().nullable(),
    contact: yup.string().nullable(),
    type_of_contact: yup.object().nullable(),
    id_service: yup.object().nullable().required('Service is required'),
    status_operation_booking: yup
      .string()
      .nullable()
      .required('Status operation is required'),
  }),
  defaultValues: {
    operation_date: dayjs().format('YYYY-MM-DD').toString(),
    requested_date: dayjs(),
    id_yacht: null,
    id_mexal_person_in_charge: null,
    id_user_in_charge: null,
    service_detail: null,
    selling_price: null,
    purchase_price: null,
    status_operation_BC: null,
    status_operation_BF: null,
    OC_BC_number: null,
    OF_BF_number: null,
    OC_fees: null,
    note: null,
    check_out_date: null,
    operation_service: null,
    contact: null,
    type_of_contact: null,
    status_operation_booking: null,
    requested_hour: null,
    id_service: null,
  },
  fields: _.concat(CommonFiels, [
    {
      id: 'service_generic',
      type: 'divider',
      label: 'Service',
      xs: 12,
    },
    {
      id: 'id_service',
      name: 'id_service',
      type: 'async_autocomplete',
      api: servicesOperationList.servicesOperationList,
      label: 'Service',
      required: true,
      xs: 12,
    },

    {
      id: 'requested_date',
      name: 'requested_date',
      type: 'date',
      label: 'Delivery Date',
      required: true,
      xs: 12,
      sm: 6,
    },

    {
      id: 'requested_hour',
      name: 'requested_hour',
      type: 'time',
      label: 'Delivery Time',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'operation_generic',
      type: 'divider',
      label: 'Operation details',
      xs: 12,
    },
    {
      id: 'operation_date',
      name: 'operation_date',
      type: 'date',
      label: 'Operation date',
      disabled: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'id_user_in_charge',
      name: 'id_user_in_charge',
      type: 'async_autocomplete',
      label: 'User in charge',
      api: users_nautica.selectName,
      required: true,
      xs: 12,
      sm: 6,
    },

    {
      id: 'contact',
      name: 'contact',
      type: 'text',
      label: 'Contact',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'type_of_contact',
      name: 'type_of_contact',
      type: 'autocomplete',
      label: 'E-mail / Whatsapp',
      required: false,
      options: ContactType,
      xs: 12,
      sm: 6,
    },
    {
      id: 'id_marina',
      name: 'id_marina',
      type: 'async_autocomplete',
      label: 'Location',
      api: marina.select,
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'status_operation_booking',
      name: 'status_operation_booking',
      type: 'select-custom',
      label: 'Status operation booking',
      required: true,
      options: StatusOperationBookingType,
      xs: 12,
      sm: 6,
      disableClearable: true,
    },

    {
      id: 'note',
      name: 'note',
      type: 'text',
      label: 'Note',
      required: false,
      xs: 12,
    },
    {
      id: 'price_generic',
      type: 'divider',
      label: 'Price',
      xs: 12,
    },
    {
      id: 'selling_price',
      name: 'selling_price',
      type: 'text',
      label: 'Selling price',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'purchase_price',
      name: 'purchase_price',
      type: 'text',
      label: 'Purchase price',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'account_type',
      name: 'account_type',
      type: 'autocomplete',
      label: 'Account CH/BOAT',
      required: false,
      options: AccountType,
      xs: 12,
      sm: 6,
    },


    {
      id: 'user_divider',
      type: 'divider',
      label: 'Mexal',
      xs: 12,
    },
    {
      id: 'id_mexal_person_in_charge',
      name: 'id_mexal_person_in_charge',
      type: 'async_autocomplete',
      label: 'User',
      api: users_nautica.selectName,
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'OC_fees',
      name: 'OC_fees',
      type: 'text',
      label: 'OC fees ',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'status_operation_BC',
      name: 'status_operation_BC',
      type: 'autocomplete',
      label: 'Status operation BC',
      required: false,
      options: StatusOperationType,
      xs: 12,
      sm: 6,
    },
    {
      id: 'OC_BC_number',
      name: 'OC_BC_number',
      type: 'text',
      label: 'OC BC number',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'status_operation_BF',
      name: 'status_operation_BF',
      type: 'autocomplete',
      label: 'Status operation BF',
      required: false,
      options: StatusOperationType,
      xs: 12,
      sm: 6,
    },
    {
      id: 'OF_BF_number',
      name: 'OF_BF_number',
      type: 'text',
      label: 'OF BF number',
      required: false,
      xs: 12,
      sm: 6,
    },
    {
      id: 'is_cagliari',
      name: 'is_cagliari',
      type: 'switch',
      label: 'Is cagliari',
      required: false,
      disabled: true,
      xs: 12,
    },
    {
      id: 'ft',
      name: 'ft',
      label: 'Invoiced',
      type: 'switch',
      required: false,
      xs: 12,
    },
  ]),
};
