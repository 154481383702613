import * as yup from 'yup';
import dayjs from 'dayjs';

export const ServiceConf = {
  schema: {
    service_date: yup
    .date()
    .nullable()
      .required('Date is required or invalid')
    .transform((value, originalValue) => {
      const parsedDate = dayjs(originalValue, 'YYYY-MM-DD', true);
      return parsedDate?.isValid() ? parsedDate.toDate() : null;
    }),
    operation_service: yup.string().nullable().required('Service is required'),
  },
  defaultValues: {
    service_date: dayjs(),
    operation_service: '',
  },
  fields: [
    {
      id: 'operation_service',
      name: 'operation_service',
      type: 'text',
      label: 'Service details',
      required: true,
      xs: 12,
      sm: 6,
    },
    {
      id: 'service_date',
      name: 'service_date',
      type: 'date',
      label: 'Date',
      required: true,
      xs: 12,
      sm: 6,
    },
  ],
};
