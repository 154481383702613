import { dataSlice as createDataSLice } from '../../../core/data';
import { sliceList as createListSlice } from '../../../core/list';
import { sliceFilter as createFilterSlice } from '../../../core/filter';
import { detailSlice as createDetailSlice } from '../../../core/detail';
import { sliceSearch as createSearchSlice } from '../../../core/search';
import {
  combineReducers,
  createAction,
  createSelector,
} from '@reduxjs/toolkit';
import _ from 'lodash';

import { ListConf } from '../../operations/conf/List.conf';
import { SearchConf } from '../../operations/conf/Search.conf';
import { FilterConf } from '../../operations/conf/Filter.conf';
import { DataConf } from '../../operations/conf/Data.conf';
import { QueryConf } from '../../operations/conf/Query.conf';
import { DetailConf } from '../../operations/conf/Detail.conf';
import dayjs from 'dayjs';

export const tag = 'shipping';

// REDUCERS
const dataSlice = createDataSLice(`${tag}/data`, DataConf.initialState);
const listSlice = createListSlice(`${tag}/list`, ListConf.initialState);
const filterSlice = createFilterSlice(`${tag}/filter`, FilterConf.initialState);
const searchSlice = createSearchSlice(`${tag}/search`, SearchConf.initialState);
const detailSlice = createDetailSlice(`${tag}/detail`, DetailConf.initialState);

export const reducer = combineReducers({
  search: searchSlice.reducer,
  data: dataSlice.reducer,
  list: listSlice.reducer,
  filter: filterSlice.reducer,
  detail: detailSlice.reducer,
});

// ACTIONS
export const actions = {
  fetchAllAction: createAction(`${tag}/fetchAll`),
  fetchDetailAction: createAction(`${tag}/fetchOne`),
  ...searchSlice.actions,
  ...dataSlice.actions,
  ...listSlice.actions,
  ...filterSlice.actions,
  ...detailSlice.actions,
};

// QUERY
export const querySelector = createSelector(
  (state) => state[tag].list,
  (state) => state[tag].filter,
  (state) => state[tag].search,
  (list, filter, search) => QueryConf.get(list, filter, search)
);

// LIST
export const listSelector = createSelector(
  (state) => state[tag].data,
  (state) => state[tag].list,
  ({ data, meta }, { pagination, sort }) => ({
    data,
    meta,
    pagination,
    sort,
  })
);

// DETAIL
export const detailSelector = createSelector(
  (state) => state[tag].detail,
  (detail) => detail
);

// EDIT
export const editSelector = createSelector(
  (state) => state[tag].detail,
  ({ data, loading, error }) => {
    const yacht = _.get(data, 'attributes.id_yacht.data');
    const mexal_person_in_charge = _.get(
      data,
      'attributes.id_mexal_person_in_charge.data'
    );
    const department = _.get(data, 'attributes.id_department.data');
    const marina = _.get(data, 'attributes.id_marina.data');
    const status_operation_BC = _.get(data, 'attributes.status_operation_BC');
    const status_operation_BF = _.get(data, 'attributes.status_operation_BF');
    const status_operation_booking = _.get(
      data,
      'attributes.status_operation_booking'
    );

    const account_type = _.get(data, 'attributes.account_type');
    const reservation = _.get(data, 'attributes.id_reservation.data');
    const driver_in_charge = _.get(data, 'attributes.id_driver_in_charge.data');
    const id_user_in_charge = _.get(data, 'attributes.id_user_in_charge.data');
    const id_agent_NA = _.get(
      data,
      'attributes.id_yacht.data.attributes.id_attendance.data[0].attributes.id_agent_NA.data.attributes.id_users_permissions_user.data.attributes'
    );
    const courier = _.get(
      data,
      'attributes.id_shipping.data.attributes.id_courier.data'
    );
    const duty_paid = _.get(
      data,
      'attributes.id_shipping.data.attributes.duty_paid'
    );
    const id_shipping_status = _.get(
      data,
      'attributes.id_shipping.data.attributes.id_shipping_status.data'
    );

    return {
      loading,
      error,
      data: data
        ? {
          shipping_type: _.get(
            data,
            'attributes.id_shipping.data.attributes.id_shipping_type.data.attributes.shipping_type'
          ),
          tracking_number:
            _.get(
              data,
              'attributes.id_shipping.data.attributes.tracking_number'
            ) || '',
          sender: _.get(
            data,
            'attributes.id_shipping.data.attributes.sender'
          ),
          details:
            _.get(data, 'attributes.id_shipping.data.attributes.details') ||
            '',
          item:
            _.get(data, 'attributes.id_shipping.data.attributes.item') || '',
          is_parcel_for_you:
            _.get(
              data,
              'attributes.id_shipping.data.attributes.is_parcel_for_you'
            ),
          storage: _.get(
            data,
            'attributes.id_shipping.data.attributes.storage'
          ),
          note_shipping:
            _.get(data, 'attributes.id_shipping.data.attributes.note') || '',
          id_courier: courier
            ? { name: courier.attributes.name, id: courier.id }
            : null,
          requested_hour: _.get(data, 'attributes.requested_hour')
            ? dayjs(
              `${_.get(data, 'attributes.requested_date')}T${_.get(
                data,
                'attributes.requested_hour'
              )}`
            )
            : null,
          date:
            _.get(data, 'attributes.id_shipping.data.attributes.date') ||
            null,
          requested: _.get(
            data,
            'attributes.id_shipping.data.attributes.requested'
          ),
          collected_from:
            _.get(
              data,
              'attributes.id_shipping.data.attributes.collected_from'
            ) || '',
          collected_to:
            _.get(
              data,
              'attributes.id_shipping.data.attributes.collected_to'
            ) || '',
          collected_date:
            _.get(
              data,
              'attributes.id_shipping.data.attributes.collected_date'
            ) || null,
          price:
            _.get(data, 'attributes.id_shipping.data.attributes.price') || '',
          customer_price:
            _.get(
              data,
              'attributes.id_shipping.data.attributes.customer_price'
            ) || '',
          invoice_value:
            _.get(
              data,
              'attributes.id_shipping.data.attributes.invoice_value'
            ) || '',
          courier_price:
            _.get(
              data,
              'attributes.id_shipping.data.attributes.courier_price'
            ) || '',
          unimare_price:
            _.get(
              data,
              'attributes.id_shipping.data.attributes.unimare_price'
            ) || '',
          markup:
            _.get(data, 'attributes.id_shipping.data.attributes.markup') ||
            '',
          handling:
            _.get(data, 'attributes.id_shipping.data.attributes.handling') ||
            '',
          receiver:
            _.get(data, 'attributes.id_shipping.data.attributes.receiver') ||
            '',
          eta:
            _.get(data, 'attributes.id_shipping.data.attributes.eta') || null,
          country:
            _.get(data, 'attributes.id_shipping.data.attributes.country') ||
            '',
          UE:
            _.get(data, 'attributes.id_shipping.data.attributes.UE'),
          is_pre_alert:
            _.get(data, 'attributes.id_shipping.data.attributes.is_pre_alert'),
          status_operation_booking: status_operation_booking,
          id_shipping_status_options: _.get(
            data,
            'attributes.shippingStatusOptions'
          ),
          id_shipping: _.get(data, 'attributes.id_shipping.data.id'),

          id_shipping_status: id_shipping_status
            ? {
              label: id_shipping_status.attributes.shipping_status,
              id: id_shipping_status.id,
            }
            : null,

          duty_paid: duty_paid
            ? {
              label: duty_paid,
              id: duty_paid,
            }
            : null,

          id_agent_NA: id_agent_NA
            ? {
              label: `${id_agent_NA.name} ${id_agent_NA.surname}`,
              id: id_agent_NA.name,
            }
            : null,
          id_user_in_charge: id_user_in_charge
            ? {
              name: `${id_user_in_charge.attributes.id_users_permissions_user.data.attributes.name} ${id_user_in_charge.attributes.id_users_permissions_user.data.attributes.surname}`,
              id: id_user_in_charge.id,
            }
            : null,
          id_driver_in_charge: driver_in_charge
            ? {
              name: driver_in_charge.attributes.username_mexal,
              id: driver_in_charge.id,
            }
            : null,
          id_reservation: reservation
            ? {
              name: reservation.attributes.reservation_type,
              id: reservation.id,
            }
            : null,
          id_yacht: yacht
            ? { name: yacht.attributes.yacht_name, id: yacht.id }
            : null,

          id_mexal_person_in_charge: mexal_person_in_charge
            ? {
              name: `${mexal_person_in_charge.attributes.id_users_permissions_user.data.attributes.name} ${mexal_person_in_charge.attributes.id_users_permissions_user.data.attributes.surname}`,
              id: mexal_person_in_charge.id,
            }
            : null,

          id_department: department.id,
          operation_service:
            _.get(data, 'attributes.operation_service') || '',

          requested_date: _.get(data, 'attributes.requested_date') || null,

          service_detail: _.get(data, 'attributes.service_detail') || '',
          OC_BC_number: _.get(data, 'attributes.OC_BC_number') || '',
          OF_BF_number: _.get(data, 'attributes.OF_BF_number') || '',
          OC_fees: _.get(data, 'attributes.OC_fees') || '',

          check_out_date: _.get(data, 'attributes.check_out_date') || null,
          operation_date: _.get(data, 'attributes.operation_date') || null,
          status_operation_BC: status_operation_BC
            ? { label: status_operation_BC, id: status_operation_BC }
            : null,
          status_operation_BF: status_operation_BF
            ? { label: status_operation_BF, id: status_operation_BF }
            : null,
          selling_price: _.get(data, 'attributes.selling_price') || '',

          purchase_price: _.get(data, 'attributes.purchase_price') || '',
          purchase_date: _.get(data, 'attributes.purchase_date') || '',

          account_type: account_type
            ? { label: account_type, id: account_type }
            : null,
          supplier_correspondent:
            _.get(data, 'attributes.supplier_correspondent') || '',
          id_marina: marina
            ? {
              name: `${marina.attributes.location} - ${marina.attributes.marina}`,
              id: marina.id,
            }
            : null,
          is_cagliari: _.get(data, 'attributes.is_cagliari')
            ? _.get(data, 'attributes.is_cagliari')
            : false,
          is_booked: _.get(data, 'attributes.is_booked')
            ? _.get(data, 'attributes.is_booked')
            : false,
          ft: _.get(data, 'attributes.ft'),
          note: _.get(data, 'attributes.note') || '',

          yacht_name: yacht ? yacht.attributes.yacht_name : '',
          agent_NA_name: id_agent_NA
            ? `${id_agent_NA.name} ${id_agent_NA.surname}`
            : '',
        }
        : null,
    };
  }
);
