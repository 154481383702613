import React from 'react';
import { Paper, Stack, Divider } from '@mui/material';
import AccordionComponent from '../../common/Accordion.component';
import TableProducts from './table/TableProducts.component';

const ProductsListComponent = ({
  data,
  expanded,
  checked,
  setChecked = () => {},
  setSuccess = () => {},
  handleChange = () => {},
  title = 'Products',
}) => {
  return (
    <Paper variant='outlined'>
      <Stack divider={<Divider />}>
        <AccordionComponent
          title={title}
          expanded={expanded['products']}
          onChange={handleChange('products')}
          details={
            <TableProducts
              data={data}
              setSuccessEdit={setSuccess}
              checked={checked}
              viewSelected={false}
              setChecked={setChecked}
            />
          }
        />
      </Stack>
    </Paper>
  );
};

export default ProductsListComponent;
