import dayjs from 'dayjs';

export const API = {
  yachts: {
    path: '/yachts',
    method: 'GET',
    get: null,
    auth: true,
    fallback: null,
  },
  yacht: {
    path: '/yachts/{{id}}?populate[id_user_crew][populate][id_users_permissions_user]=%2A&populate[yacht_document][populate]=%2A&populate[id_nautica_known]=%2A&sort[0]=yacht_name%3Aasc',
    method: 'GET',
    get: 'data',
    auth: true,
    fallback: null,
  },
  yacht_crew: {
    path: '/yachts/{{id}}?populate[id_user_crew][populate][id_users_permissions_user]=%2A&populate[id_nautica_known]=%2A&populate[id_flag]=%2A&populate[yacht_document][populate]=%2A&populate[payment_preferences]=%2A',
    method: 'GET',
    get: null,
    auth: true,
    fallback: null,
    transform: (resp) => {
      const data = resp?.data?.attributes;
      return {
        meta: resp?.meta,
        data: {
          id: resp?.data?.id,
          attributes: {
            ...data,
            yacht: {
              yacht_name: data?.yacht_name,
              url_web: data?.url_web,
            },
            invoicing: {
              invoicing_entity: data?.invoicing_entity,
              invoice_company_name: data?.invoice_company_name,
              invoice_street_address: data?.invoice_street_address,
              invoice_street_address2: data?.invoice_street_address2,
              vat_number_company_invoicing: data?.vat_number_company_invoicing,
              invoice_person_of_payment: data?.invoice_person_of_payment,
              invoice_position: data?.invoice_position,
              invoice_email: data?.invoice_email,
              invoice_phone_number: data?.invoice_phone_number,

              //Natural person
              invoice_person_name: data?.invoice_person_name,
              invoice_person_lastname: data?.invoice_person_lastname,
              invoice_person_place_of_birth:
                data?.invoice_person_place_of_birth,
              invoice_person_birthdate: data?.invoice_person_birthdate,
              invoice_city_address: data?.invoice_city_address,
              invoice_country_address: data?.invoice_country_address,
              invoice_zipcode_addess: data?.invoice_zipcode_addess,
            },
          },
        },
      };
    },
  },

  select: {
    path: '/yachts?pagination[page]=1&pagination[pageSize]=1000&sort[yacht_name]=asc',
    method: 'GET',
    get: 'data',
    auth: true,
    fallback: [],
    transform: (data) =>
      data.map((item) => ({
        name: item.attributes.yacht_name,
        id: item.id,
      })),
  },
  flags: {
    path: '/flags?pagination[page]=1&pagination[pageSize]=1000&sort[0]=locality_flag',
    method: 'GET',
    get: null,
    auth: true,
    fallback: [],
    transform: (data) =>
      data?.data?.map((item) => ({
        name: item.attributes.locality_flag,
        id: item.id,
      })),
  },
  // update: {
  //   path: '/yachts/{{id}}',
  //   method: 'PUT',
  //   get: null,
  //   auth: true,
  //   fallback: null,
  //   adapter: (body) => ({
  //     data: {
  //       ...body,
  //     },
  //   }),
  // },

  update: {
    path: '/yachts/{{id}}',
    method: 'PUT',
    get: null,
    auth: true,
    fallback: null,
    adapter: (body) => ({
      data: {
        ...body,
        id_yacht: body?.id_yacht?.id,
        // invoicing_entity: body?.invoicing_entity,
        yacht_type: body?.yacht_type?.id,
        invoice_company_name: body?.invoice_company_name,
        invoice_person_birthdate: body?.invoice_person_birthdate
          ? dayjs(body?.invoice_person_birthdate).format('YYYY-MM-DD')
          : null,

        fees_type: body?.fees_type?.id,
        registry_type: body?.registry_type?.id,
      },
    }),
  },
  selectWithAttendances: {
    path: '/yachts?populate[id_attendance]=%2A&filters[id_attendance][id][$null]=false&pagination[page]=1&pagination[pageSize]=1000&sort[0]=yacht_name%3Aasc',
    method: 'GET',
    get: 'data',
    auth: true,
    fallback: [],
    transform: (data) =>
      data.map((item) => ({
        name: item.attributes.yacht_name,
        id: item.id,
      })),
  },
  create: {
    path: '/yachts',
    method: 'POST',
    get: null,
    auth: true,
    fallback: null,
    adapter: (body) => ({
      data: {
        ...body,
        registration_date: dayjs(body?.registration_date).format('YYYY-MM-DD'),
        date_submit_signature: dayjs(body?.date_submit_signature).format(
          'YYYY-MM-DD'
        ),
        yacht_type: body?.yacht_type?.id,
        registry_type: body?.registry_type?.id,
        registration_status: 'PENDING',
        id_flag: body?.id_flag,
      },
    }),
  },
  selectWithStatus: {
    path: '/yachts?pagination[page]=1&pagination[pageSize]=1000&sort[yacht_name]=asc&filters[registration_status][$ne]=DRAFT',
    method: 'GET',
    get: 'data',
    auth: true,
    fallback: [],
    transform: (data) =>
      data.map((item) => ({
        name: `${item.attributes.yacht_name} (${item.attributes.registration_status})`,
        id: item.id,
      })),
  },
  selectNoDRAFT: {
    path: '/yachts?pagination[page]=1&pagination[pageSize]=1000&sort[yacht_name]=asc&filters[registration_status][$ne]=DRAFT',
    method: 'GET',
    get: 'data',
    auth: true,
    fallback: [],
    transform: (data) =>
      data.map((item) => ({
        name: item.attributes.yacht_name,
        id: item.id,
      })),
  },
};
