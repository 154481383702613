import React, { useCallback } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import _find from 'lodash/find';
import { useSelector } from 'react-redux';
import { useSegmentedControls } from '../../core/segmentedControls';

import PropTypes from 'prop-types';

const SegmentedControls = ({ tag, views }) => {
  const { value } = useSelector((state) => state[tag].view);
  const {
    conf: { options },
    actions: { change: valueChange },
  } = views;
  const { handleChange } = useSegmentedControls(valueChange);

  const onChange = useCallback(
    (_, newAlignment) => {
      if (newAlignment) {
        const option = _find(options, (item) => item.value === newAlignment);
        handleChange(option);
      }
    },
    [handleChange, options]
  );

  return (
    <ToggleButtonGroup
      color='secondary'
      exclusive
      value={value.value}
      onChange={onChange}
    >
      {options.map(({ value, label }) => (
        <ToggleButton key={value} value={value}>
          {label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default SegmentedControls;

SegmentedControls.propTypes = {
  tag: PropTypes.string,
  views: PropTypes.object,
};

