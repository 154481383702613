// CONF
import { CreateConf } from './conf/Create.conf';

// REDUX
import { reducer, actions, detailSelector } from './slice';

// SELECTOR
import { tag, querySelector } from './slice';

// SAGA
import { saga } from './saga';

// API
import { API } from './api';
import { ListConf } from './conf/List.conf';
import { DetailConf } from './conf/Detail.conf';

export { reducer, saga, tag };

const itemsInteriors = {
  code: 'INT',
  tag,
  query: {
    all: {
      selector: querySelector,
      action: actions.fetchAllAction,
      settings: API.listItems,
      populate: '*',
    },
    detail: {
      action: actions.fetchDetailAction,
      settings: API.listItemsNOLogbook,
      populate: '*',
    },
    edit: {
      settings: API.update,
    },
    create: {
      settings: API.create,
    },
    delete: {
      settings: API.delete,
    },
  },
  create: {
    conf: CreateConf,
  },
  detail: {
    conf: DetailConf,
    selector: {
      data: detailSelector,
    },
  },
  list: { conf: ListConf },
};

export default itemsInteriors;
